import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import JwtDecode from 'jwt-decode';
import { UserPreferencesService } from './user-preferences.service';
import { sprintf } from 'sprintf-js';
import { replacer } from '@app/helpers/utils.js';
import { MixPanelService } from './mix-panel.service';

@Injectable({
  providedIn: 'root'
})
export class CceFilesService {

  constructor(private http: HttpClient, private userPreferencesService: UserPreferencesService, private mixPanelServ: MixPanelService) { }

  loadCceFiles(): Observable<any> {
    return this.http.get<any>(environment.getCceFilesEndpoint);
  }

  deleteCceFiles(ids): Observable<any> {
    let parameters = { ids: ids };
    return this.http.get<any>(environment.deleteCceFiles, { params: parameters });
  }

  getSheetsFromCceFilesById(ids): Observable<any> {
    let parameters = { ids: ids };
    return this.http.get<any>(environment.getSheetsFromCceFilesById, { params: parameters });
  }

  saveCceFiles(sheet, oldRecId, fileName): Observable<any> {
    let sheetJson = this.sheetDataToJson(sheet);
    let data = { sheet: sheetJson.sheet, name: fileName, _id: oldRecId };
    return this.http.post<any>(environment.saveCceFileEndpoint, data);
  }

  saveCceFileObject(cceFileRecord): Observable<any> {
    return this.http.post<any>(environment.updateCceFileMetaEndpoint, cceFileRecord);
  }

  matches(element: any, filter: any): boolean {
    return Object.keys(filter).every(filterKey => {
      if (filterKey === 'owner') {
        const trimmed = filter[filterKey].trim().toLowerCase();
        const elemTrimmed = element['owner'].trim().toLowerCase();
        return trimmed === elemTrimmed;
      } else if (filterKey === 'ownerDis') {
        const trimmed = filter[filterKey].trim().toLowerCase();
        const elemTrimmed = element['owner'].trim().toLowerCase();
        return ((trimmed !== elemTrimmed) && (element['permissions'].trim().toLowerCase() !== "none"));
      }
    });
  }

  saveSheetToDevice(sheetSomponent) {
    const sheet = sheetSomponent.getSheet();
    let data = this.sheetDataToJson(sheet);
    let date = new Date();
    let month = date.getUTCMonth() + 1;
    let day = date.getUTCDate();
    let year = date.getUTCFullYear();
    let newdate = day + "." + month + "." + year;
    let fileContent = JSON.stringify(data);
    this.download(sheet.title + " " + newdate + ".ccej", fileContent);
    let additionalObj = {
      eventKey: "SaveFile"
    }
    let endpoint = '/cce/transactions/SaveFile';
    this.mixPanelServ.logData(additionalObj, endpoint);
  }

  sheetDataToJson(currentsheet) {
    let currencyUnit = currentsheet.currencyID;
    let calculationModel = currentsheet.calcModel;
    let distanceUnit = currentsheet.distanceID;
    let language = this.userPreferencesService.getUserPreferencesNow().language;
    let tco = null;
    let label = currentsheet.title;
    if (!!currentsheet && !!currentsheet.dataTCO) {
      tco = currentsheet.dataTCO
    }
    let equipment = null;
    if (!!currentsheet && !!currentsheet.dataEquipment) {
      equipment = currentsheet.dataEquipment;
    }
    let maintenance = null;
    if (!!currentsheet && !!currentsheet.dataMaintenance) {
      maintenance = {
        servicePlan: currentsheet.dataMaintenance["servicePlan"],
        additionalTasks: currentsheet.dataMaintenance["additionalTasks"],
        wearParts: currentsheet.dataMaintenance["wearParts"]
      };
    }
    let today = new Date();
    let dateOfLastSave = sprintf("%02d.%02d.%04d", today.getDate(), today.getMonth() + 1, today.getFullYear());
    let exchangeRates = JSON.parse(JSON.stringify(currentsheet.exchangeRates, replacer));
    let requestMetadata = JSON.parse(JSON.stringify(currentsheet.requestMetadata, replacer));
    let canSeePrice = JSON.parse(JSON.stringify(currentsheet.canSeePrice, replacer));

    let data = {
      sheet: {
        tco: tco,
        equipment: equipment,
        maintenance: maintenance,
        columnIds: currentsheet.columnIds,
        failedColumns: Array.from(currentsheet.failedColumns),
        currencyUnit: currencyUnit,
        distanceUnit: distanceUnit,
        benchmarkColumnId: currentsheet.benchmarkColumnId,
        calculationModel: calculationModel,
        exchangeRates: exchangeRates,
        requestMetadata: requestMetadata,
        parameters: [],
        residualType: currentsheet.residualType,
        viewingMode: currentsheet.viewingMode,
        isMaintenanceDefault: currentsheet.isMaintenanceDefault,
        canSeePrice: canSeePrice,
        language: language,
        equipmentFilterMode: currentsheet.equipmentFilteringMode,
        label: label,
        dateOfLastSave: dateOfLastSave,
        version: currentsheet.version
      }
    }
    return data;
  }

  download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
