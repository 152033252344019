import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-update-trigger',
    templateUrl: './update-trigger.component.html',
    styleUrls: ['./update-trigger.component.scss']
})
export class UpdateTriggerComponent implements OnInit {

    constructor(private httpClient: HttpClient) { }

    ngOnInit(): void {
    }

    public triggerUpdate() {
        this.httpClient.get(environment.updateEndpoint).subscribe(
            data => {
                console.log("data", data);
            },
            error => {
                console.log("error", error);
            });
    }
}
