import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    paramConfigOpened = false;
    fileStorageOpened = false;

    constructor() { }

    ngOnInit(): void {
    }

    toggleParamConfig() {
        if(this.paramConfigOpened) {
            this.paramConfigOpened = false;
        }else {
            this.fileStorageOpened = false;
            this.paramConfigOpened = true;
        }
    }

    toggleFileStorage() {
        if(this.fileStorageOpened) {
            this.fileStorageOpened = false;
        }else {
            this.paramConfigOpened = false;
            this.fileStorageOpened = true;
        }
    }
}
