import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainComponent } from '../main/main.component';



@Component({
  selector: 'app-dialog-pdf-export',
  templateUrl: './dialog-pdf-export.component.html',
  styleUrls: ['./dialog-pdf-export.component.scss']
})
export class DialogPDFExportComponent {

  parent;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.parent = data;
  }

  download3ColPdf() {
    this.parent.download3ColPdf();
  }
  download5ColPdf() {
    this.parent.download5ColPdf();
  }

}


