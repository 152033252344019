<div *ngIf="isReady">
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#1d71b8" type="line-scale"></ngx-spinner>
  <div id="header2-topnav" class="cce-row">
    <span>
      <button mat-button color="primary" style="font-size:26px" [ngClass]="{'active-mode': isViewActive(TYPE_VIEW_TCO)}"
        (click)="changeView(TYPE_VIEW_TCO)">TCO</button>
      <button mat-button color="primary" style="font-size:26px"
        [ngClass]="{'active-mode': isViewActive(TYPE_VIEW_EQUIPMENT)}" (click)="changeView(TYPE_VIEW_EQUIPMENT)">
        EQUIPMENT</button>
      <button mat-button color="primary" style="font-size:26px"
        [ngClass]="{'active-mode': isViewActive(TYPE_VIEW_MAINTENANCE)}"
        (click)="changeView(TYPE_VIEW_MAINTENANCE)">MAINTENANCE</button>
      <button mat-button style="margin-bottom: 5px;" color="primary"><img src="assets/configure.png" width="26"
          height="26" (click)="openParamsConfig()"></button>
    </span>
  </div>

  <div id="header3-topnav" class="cce-row">

    <mat-menu #newMenu="matMenu">
      <div style="max-width: 400px">
        <div class="calc-model-container">
          <app-calc-model #calcModel></app-calc-model>
        </div>
        <button id="new-sheet-button" (click)="newSheetFromUI()">CREATE NEW SHEET</button>
        <button id="duplicate-sheet-button" (click)="duplicateSheet()">DUPLICATE SHEET WITH NEW SCENARIO</button>
      </div>
    </mat-menu>

    <mat-menu #openMenu="matMenu">
      <input type="file" ng2FileSelect (click)="myUpload.value = null" (change)="upload($event)" (uploader)="uploader"
        hidden #myUpload />
      <button mat-menu-item (click)="myUpload.click()">FROM DEVICE</button>
      <button mat-menu-item (click)="openFileShere()">FROM CCE</button>
    </mat-menu>

    <mat-menu #saveMenu="matMenu">
      <button mat-menu-item (click)="saveToDevice()">TO DEVICE</button>
      <button mat-menu-item (click)="saveCceDbFile()">TO CCE</button>
    </mat-menu>

    <mat-menu #exportMenu="matMenu">
      <button mat-menu-item (click)="newExcelDialog()">EXCEL</button>
      <button mat-menu-item (click)="newPDFDialog()">PDF</button>
      <button mat-menu-item (click)="newCsvDialog()">CSV</button>
    </mat-menu>

    <button mat-button title="New" color="primary" [matMenuTriggerFor]="newMenu" (menuClosed)="menuClosed()">
      New&nbsp;<mat-icon>arrow_drop_down</mat-icon>
    </button>

    <button mat-button title="Open" [matMenuTriggerFor]="openMenu">
      Open&nbsp;<mat-icon>arrow_drop_down</mat-icon>
    </button>
    <button mat-button title="Add Vehicle" (click)="newBatchCalculation()">Add Vehicle</button>
    <button mat-button title="Save" [matMenuTriggerFor]="saveMenu">
      Save&nbsp;<mat-icon>arrow_drop_down</mat-icon>
    </button>
    <button mat-button title="Export" [matMenuTriggerFor]="exportMenu">
      Export&nbsp;<mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-divider class="header3-div" [vertical]="true"></mat-divider>
    <button mat-button (click)="setLock()"><img matTooltip="Unlock editing" [src]="locked" width="16"
        height="16"></button>
    <button mat-button (click)="backStep()"><img matTooltip="Undo" src="assets/undo.png" width="16"
        height="16"></button>
    <button mat-button (click)="loadOrigData()"><mat-icon class="cce-gray" matTooltip="Undo all TCO changes" width="16"
        height="16">reply_all</mat-icon></button>
    <button mat-button *ngIf="isVisibleRefreshButton" (click)="refreshSheet()"><img matTooltip="Refresh"
        src="assets/revert.png" width="16" height="16"></button>
    <button mat-button (click)="expandCols()"><img matTooltip="Widen all columns" src="assets/expand.png" width="16"
        height="16"></button>
    <button mat-button (click)="collapseCols()"><img matTooltip="Shrink all columns" src="assets/colapse.png" width="16"
        height="16"></button>
    <button mat-button (click)="openAll()"><img matTooltip="Open all rows" src="assets/AV_CEE_OpenedFolder.svg"
        width="16" height="16"></button>
    <button mat-button (click)="closeAll()"><img matTooltip="Close all rows" src="assets/AV_CCE_Folder.svg" width="16"
        height="16"></button>
    <button mat-flat-button *ngIf="isVisibleCalcButton()" (click)="loadCalc(undefined)"
      matTooltip="This sheets needs to be recalculated to reflect your changes." color="warn"
      style="color: white">RE-CALCULATE</button>

    <span class="cce-spacer"></span>
    <div [ngStyle]="{'display' : typeView===TYPE_VIEW_EQUIPMENT? '':'none'}">
      <button mat-button [ngClass]="getClassEQActiv(TYPE_EQ_ALL)" (click)="changeEQView(TYPE_EQ_ALL)">ALL</button>
      <button mat-button [ngClass]="getClassEQActiv(TYPE_EQ_STD)" (click)="changeEQView(TYPE_EQ_STD)">STD</button>
      <button mat-button [ngClass]="getClassEQActiv(TYPE_EQ_OPT)" (click)="changeEQView(TYPE_EQ_OPT)">OPT</button>
      <button mat-button [ngClass]="getClassEQActiv(TYPE_EQ_INC)" (click)="changeEQView(TYPE_EQ_INC)">INC</button>
    </div>

    <div class="cce-row" [ngStyle]="{'display' : typeView===TYPE_VIEW_TCO? '':'none'}">
      <mat-divider class="header3-div" [vertical]="true"></mat-divider>
      <div>
        <button mat-button [ngClass]="getClassSumActiv(ACTIVE_TOTAL)"
          (click)="clickToChangeVMB(ACTIVE_TOTAL)">Total</button>
        <button mat-button [ngClass]="getClassSumActiv(ACTIVE_KM)"
          (click)="clickToChangeVMB(ACTIVE_KM)">{{getDistance()}}</button>
        <button mat-button [ngClass]="getClassSumActiv(ACTIVE_MONTH)"
          (click)="clickToChangeVMB(ACTIVE_MONTH)">Month</button>
      </div>

      <mat-divider class="header3-div" [vertical]="true"></mat-divider>
      <div>
        <button mat-button [ngClass]="getClassTradeActiv(ACTIVE_TRADE)"
          (click)="changeResidualButtonsView(ACTIVE_TRADE)">Trade</button>
        <button mat-button [ngClass]="getClassTradeActiv(ACTIVE_RETAIL)"
          (click)="changeResidualButtonsView(ACTIVE_RETAIL)">Retail</button>
      </div>
    </div>

  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#1d71b8" type="line-scale"></ngx-spinner>
  <div id="pdf-view" class="pdf-view">
    <div class="pdf-header" *ngIf="isPDF > 0">
      <div style="flex:1">
        <img style="height: 30px;" src='../../assets/CCE.png' />
      </div>
      <div style="flex:2;" class="pdf-span">Calculation {{pdfPageIndex}}
      </div>
      <div style="flex:1" class="pdf-span">{{username}}
      </div>
    </div>
    <div class="cce-row">
      <div style="width:100%">
        <div class="cce-row" style="margin: 0px 0px -5px 0px; overflow:auto;position: relative;" *ngIf="isPDF === 0">
          <div *ngFor="let sheet of sheets; let tabIndex = index" (click)="clickOnSheetTitle($event, tabIndex)">
            <button
              [ngClass]="{'tablinksactiv': selectedSheetIdx===tabIndex , 'tablinks': selectedSheetIdx!==tabIndex }">
              <div (keyup)="onKeyUp($event, sheet)" style="display:flex ;position: relative;">
                <input (focusout)="focusOutFunction($event, sheet)" #inputField [hidden]="!inTitleBoundClick">
                <span [hidden]="inTitleBoundClick" class="titleTab">{{ sheet.constructSheetLabel() }}</span>
                <span [hidden]="inTitleBoundClick" class="cce-spacer"></span>
                <mat-icon id="cancelTab" (click)="removeSheet(tabIndex)">cancel</mat-icon>
              </div>
            </button>
          </div>
          <div>
            <mat-icon id="addTab" (click)="newSheetFromUI()">add_circle</mat-icon>
          </div>
        </div>
        <div class="cce-col" *ngFor="let sheet of sheets; let tabIndex = index">
          <app-sheet [ngClass]="getClassNameWithIndex(tabIndex)" [sheetIdx]="tabIndex" [parent]="this"
            [expandedOldRowsEquip]="sheet.expandedOldRowsEquip" [expandedOldRowsTCO]="sheet.expandedOldRowsTCO"
            [expandedOldRowsMaint]="sheet.expandedOldRowsMaint" [columnsWidths]="columnsWidths"
            [ngStyle]="{'display' : selectedSheetIdx===tabIndex? 'block':'none'}" [typeView]="typeView"
            [isPDF]="isPDF"></app-sheet>
        </div>
      </div>
    </div>
    <div class="pdf-footer" *ngIf="isPDF > 0">@ {{yearNow}} Autovista Group. All rights reserved.</div>
  </div>
</div>
