import { Component } from '@angular/core';

@Component({
  selector: 'app-selected-cars-header',
  templateUrl: './selected-cars.header.html',
  styleUrls: ['./selected-cars.header.scss']
})
export class SelectedCarsHeader {

  constructor() { }
  parent;

  agInit(params): void {
    //console.log(params);
    this.parent = params.parent;
  }

  clearAllSelCars(): void {
    //console.log(this.parent);
    if(this.parent){
      this.parent.clearAllSelCarsAndAvailableCars();
    }
  }
}
