import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, of, Subject, EMPTY } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { CalcModel } from '@app/domain/calc-model';
import { UserPreferences } from '../domain/UserPreferences';
import { getCurrencyIDByAbbr } from '@app/helpers/RemapUnitsUtils';
import { Constants } from '@app/helpers/Constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';



@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {
  private EMPTY = new UserPreferences();
  private currentPreferences: UserPreferences = this.EMPTY;

  constructor(private http: HttpClient, private spinner: NgxSpinnerService,private messageService: HandleUiMessagesService) {
  }

  getUserPreferencesNow(): UserPreferences {
    return this.currentPreferences;
  }

  loadUserPreferences(): Observable<UserPreferences> {
    const preferences = this.http.get<UserPreferences>(environment.loadUserPreferencesEndpoint).pipe(share());
    preferences.subscribe(data => {
      if (data) {
        this.currentPreferences = data;
      } else {
        this.currentPreferences = this.EMPTY;
      }
    }, error => {
      this.currentPreferences = this.EMPTY;
    });
    return preferences;
  }

  saveUserPreferences(userPreferences: UserPreferences): Observable<UserPreferences> {
    const preferences = this.http.post<UserPreferences>(environment.saveUserPreferencesEndpoint, userPreferences).pipe(share());
    preferences.subscribe(data => {
      if (data) {
        this.currentPreferences = data;
      } else {
        this.currentPreferences = this.EMPTY;
      }
    }, error => {
      this.currentPreferences = this.EMPTY;
    });
    return preferences;
  }



  public getScenario(distanceUnitId) {
    let retval;
    if (distanceUnitId === Constants.UNIT_ID__KILOMETRE) {
      retval = this.currentPreferences.defaultScenarioKM;
    } else {
      retval = this.currentPreferences.defaultScenarioMl;
    }
    return retval;
  }

  public setScenario(distanceUnitId, scenario) {
    if (distanceUnitId === Constants.UNIT_ID__KILOMETRE) {
      this.currentPreferences.defaultScenarioKM = scenario;
    } else {
      this.currentPreferences.defaultScenarioMl = scenario;
    }
    this.spinner.show();
    this.saveUserPreferences(this.currentPreferences).subscribe(data => {
      this.spinner.hide();
      this.messageService.showMessage("Successfully saved scenario!");
    }, error => {
      this.spinner.hide();
      this.messageService.showMessage("Invalid scenario!");
    });
  }

  setLanguage(lang: string) {
    this.currentPreferences.language = lang;
  }

  setCurrency(currency: string) {
    this.currentPreferences.currency = currency;
    this.currentPreferences.currencyUnitId = getCurrencyIDByAbbr(currency);
    if (this.currentPreferences.currencyUnitId === Constants.CURRENCY_GBP_ID) {
      this.currentPreferences.distanceUnitId = Constants.UNIT_ID__MILE;
    } else {
      this.currentPreferences.distanceUnitId = Constants.UNIT_ID__KILOMETRE;
    }
    // this.spinner.show();
    // this.saveUserPreferences(this.currentPreferences).subscribe(data => {
    //   this.spinner.hide();
    //   this.messageService.showMessage("Successfully saved currency!");
    // }, error => {
    //   this.spinner.hide();
    //   this.messageService.showMessage("Invalid currency!");
    // });
  }




}
