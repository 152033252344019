import { Component, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnInit } from '@angular/core';
import { GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';
import { NewBatchCalculationComponent } from '../new-batch-calculation/new-batch-calculation.component';
import { Scenario } from '../../objects/Scenario';
import { SheetService } from '@app/services/sheet.service';
import { ActionCellRendererComponent } from '../action-cell/action-cell-renderer-component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ScenarioSelectionComponent } from '../scenario-selection/scenario-selection.component';
@Component({
  selector: 'app-selected-scenario',
  templateUrl: './selected-scenario.component.html',
  styleUrls: ['./selected-scenario.component.scss']
})
export class SelectedScenarioComponent implements OnInit {

  constructor() { }

  @Input() selectedScenario: Scenario[];
  gridRows = [];
  @Input() singleSelection: boolean = false;
  @Input() parent: any;
  @Output() updateScenarios = new EventEmitter<any>();

  @ViewChild('theGrid') theGrid;

  public columnDefs = [{
    headerName: '', field: 'description', flex: 1,
    cellRendererFramework: ActionCellRendererComponent,
    cellRendererParams: {
      icon: '../../assets/cross-remove.png',
      type: 'scenario',
      parent: this
    }
  }];

  public gridOptions: GridOptions = {
    context: this,
    headerHeight: 0,
    rowData: this.gridRows,
    columnDefs: this.columnDefs
  };

  ngOnInit(): void {
    if (this.selectedScenario === null || this.selectedScenario === undefined) {
      this.selectedScenario = [];
    }
    this.refreshGridData();
  }


  loadCars() {
    this.updateScenarios.emit(this.selectedScenario);
  }

  closeMe() {
    this.parent.closeDialog();
  }

  addSelectedScenario(scenario: Scenario): void {
    for (let index = this.selectedScenario.length - 1; index >= 0; index--) {
      if (scenario.id === this.selectedScenario[index].id) {
        this.selectedScenario.splice(index, 1);
        break;
      }
    }
    this.selectedScenario.push(scenario);
    // console.log('added', this.selectedScenario);
    this.refreshGridData();
  }

  delRow(duration) {
    this.selectedScenario.forEach(t => {
      this.parent.availableScenarioGrid.selectedScenarios[t.id] = t.duration !== duration;
    });
    this.selectedScenario = this.selectedScenario.filter(t => { return t.duration !== duration });
    this.refreshGridData();
  };

  delSelectedScenario(scenario: Scenario): void {
    console.log('del', scenario);
    for (let index = this.selectedScenario.length - 1; index >= 0; index--) {
      if (scenario.id === this.selectedScenario[index].id) {
        this.selectedScenario.splice(index, 1);
        break;
      }
    }
    console.log('deleted', this.selectedScenario);
    this.refreshGridData();
  }

  clearAllSelScenario(): void {
    this.selectedScenario = [];
    this.refreshGridData();
  }

  refreshGridData(): void {
    if (!this.singleSelection) {
      console.log('refreshing', this.selectedScenario);
      if (this.selectedScenario !== null && this.selectedScenario !== undefined && this.selectedScenario.length > 0) {
        this.selectedScenario.sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0);
        this.gridRows = [];
        let tmpDur = this.selectedScenario[0].duration;
        let tmpDistanceArray = [];
        this.selectedScenario.forEach(scen => {
          if (scen.duration === tmpDur) {
            tmpDistanceArray.push(scen.nodeName);
          } else {
            let tmpEntry = {};
            tmpEntry['description'] = tmpDur + ' months / ' + tmpDistanceArray.join(' - ');
            tmpEntry['duration'] = tmpDur;
            this.gridRows.push(tmpEntry);
            tmpDur = scen.duration;
            tmpDistanceArray = [];
            tmpDistanceArray.push(scen.nodeName);
          }
        });
        if (tmpDistanceArray.length > 0) {
          let tmpEntry = {};
          tmpEntry['description'] = tmpDur + ' months / ' + tmpDistanceArray.join(' - ');
          tmpEntry['duration'] = tmpDur;
          this.gridRows.push(tmpEntry);
        }
      } else {
        this.gridRows = [];
      }
      console.log('refresh', this.gridRows);
      this.parent.checkEnableButton();
    }
  }

}
