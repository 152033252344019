<div class="cce-center-both-local">
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#1d71b8" type="line-scale"></ngx-spinner>
    <form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()">
        <mat-card class="login-card">
            <mat-card-header>
                <mat-card-title><img src="/assets/CCE.png" title="Car Cost Expert"></mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="full-width-100">
                            <input matInput placeholder="Username" formControlName="username">
                            <mat-error *ngIf="loginForm.controls['username'].hasError('required')">
                                Username is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="full-width-100">
                            <input type="password" matInput placeholder="Password" formControlName="password">
                            <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
                                Password is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions  class="cce-text-align-center">
                <button mat-raised-button [disabled]="loading" color="accent" type="submit" >
                    Login
                </button>
            </mat-card-actions>
            <div class="forgotPass">
              <button mat-button class="forgotPassButton" (click)="openResetPass()" type="button">Reset password</button>
            </div>
            <mat-card-footer class="cce-text-align-center">
                <span *ngIf="loading"><mat-progress-bar mode="indeterminate"></mat-progress-bar></span><br>
                <img src="/assets/AG.png"><br><br>
                <span class="mat-caption">&copy; {{logoYear.getFullYear()}} Autovista Group</span>
            </mat-card-footer>
        </mat-card>
    </form>
</div>
