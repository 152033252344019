import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { GridOptions, ColDef, Module } from 'ag-grid-community';
import { EqrCheckboxesComponent } from '@app/components/eqr-checkboxes/eqr-checkboxes.component';
import { Constants } from '@app/helpers/Constants';
import { HeaderMainComponent } from '../header-main/header-main.component';
import { Sheet } from '@app/domain/sheet';
import { AgGridAngular } from 'ag-grid-angular';
import { EquipmentService } from '@app/services/equipment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SheetComponent } from '../sheet/sheet.component';
import { getColIndex22ToLength, getNameOfColumn, switchColWidth } from '@app/helpers/utils.js';
import { MixPanelService } from '@app/services/mix-panel.service';



@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss']
})
export class EquipmentComponent implements OnInit {

  readonly TYPE_EQ_ALL = Constants.TYPE_EQ_ALL;
  readonly TYPE_EQ_STD = Constants.TYPE_EQ_STD;
  readonly TYPE_EQ_OPT = Constants.TYPE_EQ_OPT;
  readonly TYPE_EQ_INC = Constants.TYPE_EQ_INC;

  @Input() sheetComponent: SheetComponent;
  @Input() columnsWidths;
  @Input() expandedOldRowsEquip;
  dataSheet: Sheet;
  expandedRows: String[];
  @ViewChild('theGrid') theGrid: AgGridAngular;

  constructor(private equipmentService: EquipmentService, private spinner: NgxSpinnerService,
    private mixPanelServ: MixPanelService) {
    this.frameworkComponents = { agColumnHeader: HeaderMainComponent };
    let that = this;
    this.gridOptions = {
      context: this,
      // this is the trick to display tree in the grid
      getNodeChildDetails: function getNodeChildDetails(rowItem) {
        if (rowItem.children) {
          return {
            expanded: (that.expandedRows) ? that.expandedRows.includes(rowItem.field) : false,
            group: true,
            children: rowItem.children,
            key: rowItem.field
          };
        } else {
          return null;
        }
      },

      getRowHeight: function getRowHeight(params) {
        let retval = 30;
        let valueLength = 0;
        let hasSP = false;
        if (params.data.values && params.data.children === undefined) {
          for (var i in params.data.values) {
            if (params.data.values.hasOwnProperty(i) && !!params.data.values[i]) {
              let visible = params.data.values[i].filter(e => { return that.isItVisible(e) }).length;
              if (params.data.values[i].some(x => x.isSP)) {
                // add a N/A placeholder if service package
                visible++;
              }
              if (visible > valueLength) {
                valueLength = visible;
              }
            }
          }
          retval = valueLength * retval;
          // // console.log(retval);
        }
        return retval;
      },
      onColumnMoved: function onColumnMoved(event) {
        this.context.toIndex = event.toIndex;
        this.context.fromIndex = getColIndex22ToLength(event.column['colDef']['field']) + 1;
      },
      onColumnResized: function onColumnResized(params) {
        //on finish set all cols size in columnsWidths
        if (params.finished && !!params.column && params.column.getColId().length > 22) {
          let allCols = that.gridOptions.columnApi.getAllGridColumns();
          if (allCols) {
            let indexOfMap = 0;
            for (const col of allCols) {
              if (col['colDef'].field !== "") {
                let width = col['actualWidth'];
                that.columnsWidths.set(indexOfMap, width);
                indexOfMap++;
              }
            }
          }
        }
      },
      onDragStopped: function onDragStopped(event) {
        const toIndex = this.context.toIndex;
        const fromIndex = this.context.fromIndex;

        if (!toIndex || !fromIndex || fromIndex === toIndex) {
          return;
        }
        const columnIds = this.context.dataSheet.columnIds;
        if (toIndex <= 0 || fromIndex <= 0 || toIndex > columnIds.length
          || fromIndex > columnIds.length) {
          this.context.toIndex = 0;
          this.context.fromIndex = 0;
          this.context.reloadData();
          return;
        }

        const toIndexInArray = toIndex - 1;
        const fromIndexInArray = fromIndex - 1;

        if (toIndexInArray < fromIndexInArray) {
          columnIds.splice(toIndexInArray, 0, columnIds[fromIndexInArray]);
          columnIds.splice(fromIndexInArray + 1, 1);
        } else {
          columnIds.splice(toIndexInArray + 1, 0, columnIds[fromIndexInArray]);
          columnIds.splice(fromIndexInArray, 1);
        }

        switchColWidth(fromIndex, toIndex, this.context.columnsWidths);

        this.context.toIndex = 0;
        this.context.fromIndex = 0;

        this.context.reloadData();
      },
      suppressDragLeaveHidesColumns: true,
      onRowGroupOpened: function onRowGroupOpened(params) {
        if ((params.node)) {
          if (params.node.key == "/" && params.node.expanded === true) {
            that.logCheckIn();
          }

          if (params.node.expanded === false) {
            let field = params.node.data.field;
            let arrChild = Array<String>();
            that.getChildren(arrChild, params.node.childrenAfterFilter);
            if (field !== null) {
              that.expandedRows = that.expandedRows.filter(item => item !== field);
            }
            if (arrChild !== null) {
              for (let tmp in arrChild) {
                that.expandedRows = that.expandedRows.filter(item => item !== arrChild[tmp]);
              }
            }
          } else {
            if (!that.expandedRows) {
              that.expandedRows = [];
            }
            let field = params.node.data.field;
            that.expandedRows.push(field);
          }
        }
      },
    };
  }

  fromIndex: number;
  toIndex: number;
  frameworkComponents;
  gridData: any;
  labels: any;
  filterType = 'all';
  columnDefs: ColDef[];
  gridOptions: GridOptions;

  logCheckIn() {
    let additionalObj = {
      eventKey: "equipmentModuleCheckIn"
    }
    let endpoint = '/cce/transactions/equipmentCheckIn';
    this.mixPanelServ.logData(additionalObj, endpoint);
  }

  groupNameValueGetter(params) {
    if (params.data.field === '/') {
      return 'SUMMARY';
    } else {
      let translation = params.context.labels[params.data.field];
      if (!translation) {
        translation = 'missing translation (' + params.data.field + ')';
      }
      return translation;
    }
  }

  getColumnWidths() {
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    if (allCols) {
      let indexOfMap = 0;
      this.columnsWidths.clear();
      for (let index in allCols) {
        if (indexOfMap == allCols.length - 1) {
          continue;
        }
        let col = allCols[index];
        let width = col['actualWidth'];
        this.columnsWidths.set(indexOfMap, width);
        indexOfMap++;
      }
    }
    return this.columnsWidths;
  }

  reloadWidths(colWidths) {
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    if (allCols.length > 0) {
      let indexOfMap = 0;
      for (let arrIndex in allCols) {
        if (indexOfMap == allCols.length - 1) {
          continue;
        }
        let col = allCols[arrIndex];
        this.gridOptions.columnApi.setColumnWidth(col, colWidths.get(indexOfMap));
        indexOfMap++;
      }
    }
  }

  getChildren(arr, childrens) {
    childrens.forEach(child => {
      arr.push(child.data.field);
      if (child.childrenAfterFilter) {
        this.getChildren(arr, child.childrenAfterFilter);
      }
    });
  }

  setExpandedRows(fieldName) {
    if (fieldName === undefined) {
      return;
    }
    this.expandedRows = fieldName;
    this.gridOptions.api.setRowData(this.gridData); // Refresh grid
  }

  getExpandedRows() {
    return this.expandedRows;
  }

  ngOnInit(): void {
    if (this.expandedOldRowsEquip !== undefined && this.expandedOldRowsEquip !== null) {
      this.expandedRows = this.expandedOldRowsEquip;
    }
    this.spinner.show();
    this.equipmentService.loadEsacoClasses().subscribe(res => {
      this.labels = { ...res.subgroups, ...res.groups, ...res.esacos };
      this.spinner.hide();
      this.reloadData();
    }, err => {
      console.log("unable to load!");
      this.spinner.hide();
    });

  }

  public reloadData() {
    this.dataSheet = this.sheetComponent.getSheet();

    const numberOfColumns = this.dataSheet.columnIds.length;
    this.columnDefs = [];

    this.columnDefs.push({
      headerComponentParams: { typeHC: Constants.HEADER_COMPONENT_EQUIPMENT, name: 'EQUIPMENT', menu: false },
      headerName: '', valueGetter: this.groupNameValueGetter,
      cellRenderer: 'agGroupCellRenderer', cellRendererParams: { suppressCount: true }, pinned: 'left',
      width: this.columnsWidths.size > 0 ? this.columnsWidths.get(0) : 250, resizable: true, cellStyle: { 'font-weight': 'bold' }, cellClass: 'cce-group-cell', minWidth: 250, lockPosition: true, sort: 'asc'
    });

    for (let i = 0; i < numberOfColumns; i++) {
      let currWidth = this.columnsWidths.get(i + 1);

      const colName = getNameOfColumn(this.dataSheet.dataTCO, this.dataSheet.columnIds[i]);
      this.columnDefs.push({
        headerComponentParams: {
          typeHC: Constants.HEADER_COMPONENT_EQUIPMENT, name: colName, menu: true, idsCol: this.dataSheet.columnIds[i],
          sheetComponent: this.sheetComponent
        },
        headerName: '', field: 'values[data.columnIds[' + i + ']]', cellRendererFramework: EqrCheckboxesComponent,
        type: 'rightAligned', minWidth: 220, width: currWidth, resizable: true,
        cellRendererParams: {
          filterType: this.filterType
        }
      });
    }

    this.columnDefs.push({
      headerName: '', field: '', flex: 1
    });

    this.gridData = [];
    this.gridData.push(this.dataSheet.dataEquipment);

  }

  getDataSheet() {
    return this.dataSheet;
  }

  redrawRows(rows) {
    this.theGrid.api.redrawRows({ rowNodes: rows });
  }

  expandAll() {
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    if (allCols.length > 0) {
      let minWidth = this.getMinWidth(allCols);
      for (let arrIndex in allCols) {
        let col = allCols[arrIndex];
        if (arrIndex !== "0" && col.getColDef()['field'] !== "") {
          this.gridOptions.columnApi.setColumnWidth(col, minWidth + 20);
        }
      }
    }
  }

  collapseAll() {
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    if (allCols.length > 0) {
      let minWidth = this.getMinWidth(allCols);
      minWidth -= 20;
      for (let arrIndex in allCols) {
        let col = allCols[arrIndex];
        if (arrIndex !== "0" && col.getColDef()['field'] !== "") {
          if (col['minWidth'] > minWidth - 20) {
            minWidth = col['minWidth'];
          }
          this.gridOptions.columnApi.setColumnWidth(col, minWidth);
        }
      }
    }
  }

  getMinWidth(arrColDefs) {
    let minWidth;
    for (let arrIndex in arrColDefs) {
      let col = arrColDefs[arrIndex];
      if (arrIndex !== "0" && col.colDef['field'] !== "") {
        //value[0] >
        if (minWidth) {
          let width = col['actualWidth'];
          if (minWidth > width) {
            minWidth = width;
          }
        } else {
          minWidth = col['actualWidth'];
        }
      }
    }
    return minWidth;
  }

  setFilterType(type, selectedSheetIdx) {
    this.filterType = type;
    this.reloadData();
  }

  isItVisible(option: any): boolean {
    if (this.filterType === this.TYPE_EQ_ALL) {
      return true;
    } else if (this.filterType === this.TYPE_EQ_STD) {
      return option.isStandard;
    } else if (this.filterType === this.TYPE_EQ_OPT) {
      return !option.isStandard;
    } else if (this.filterType === this.TYPE_EQ_INC) {
      return option.isStandard || option.isOptionalIncluded;
    } else {
      return true;
    }
  }
}
