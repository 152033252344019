import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { numberWithCommas, getColIndex22ToLength, getModifyState } from '@app/helpers/utils.js';
import { Constants } from '@app/helpers/Constants.ts';
import { metadata } from '@environments/environment'
import { Sheet } from '@app/domain/sheet';

@Component({
  selector: 'app-csr-distance',
  templateUrl: './csr-distance.component.html',
  styleUrls: ['./csr-distance.component.scss']
})
export class CsrDistanceComponent implements OnInit {

  @Input() params: ICellRendererParams;

  NO_MODIFIED_CELL = Constants.NO_MODIFIED_CELL;
  MODIFIED_CELL = Constants.MODIFIED_CELL;
  ERROR_MODIFIED_CELL = Constants.ERROR_MODIFIED_CELL;
  TEXT_COLOR_GREEN = Constants.TEXT_COLOR_GREEN;
  TEXT_COLOR_RED = Constants.TEXT_COLOR_RED;
  TEXT_COLOR_GRAY = Constants.TEXT_COLOR_GRAY;
  TEXT_COLOR_BLUE = Constants.TEXT_COLOR_BLUE;

  value: string;
  style: string;
  modified: number;
  indexCol: number;
  benchmarkColumnId: number;
  idsCol: number;
  colorSpan: number;
  wrongValue: string;

  constructor() {
  }

  ngOnInit(): void {
    const sheet: Sheet = this.params.context.getDataSheet();
    this.indexCol = getColIndex22ToLength(this.params.colDef.field);
    const idsCol = this.params.context.getDataSheet().columnIds[this.indexCol];
    this.idsCol = idsCol;
    const ctx = this.params.context;
    const field = this.params.data.field;
    const editData = this.params.data.editData;
    this.style = metadata[field].style;

    this.benchmarkColumnId = ctx.getBenchMarkColumn();

    if (this.params.data.values[idsCol] === null || this.params.data.values[idsCol] === undefined ||
      this.params.data.values[idsCol] === '') {
      if (metadata[field].insuffDataWhenEmpty === true) {
        this.value = 'Insufficient data';
      } else {
        this.value = '';
      }
      if (this.params.data.values[this.idsCol] !== '') {
        this.modified = this.NO_MODIFIED_CELL;
        return;
      }
    }

    if (Number.isNaN(Number(this.params.data.values[idsCol]))) {
      this.params.data.editData[idsCol].error = true;
      editData[idsCol].modified = false;
      this.wrongValue = this.params.data.values[idsCol];
      const path = metadata[field].path[0] + '.editData[\'' + idsCol + '\'].origValue';
      const value = ctx.getOldValue(path);
      this.params.data.values[idsCol] = value;
      this.value = value;
    }
    else {
      this.params.data.editData[idsCol].error = false;
    }
    this.modified = getModifyState(editData[idsCol].modified, editData[idsCol].error);

    if (this.modified) {
      this.colorSpan = this.TEXT_COLOR_BLUE;

    }

    if (this.benchmarkColumnId >= 0 && this.benchmarkColumnId !== idsCol) {
      let bmValue = this.params.data.bmValues[idsCol];
      if (bmValue.value > 0) {
        this.value = '+' + numberWithCommas(bmValue.value);
      } else if (bmValue.value < 0) {
        this.value = numberWithCommas(bmValue.value);
      } else {
        this.value = bmValue.value;
      }
      if (!!bmValue.color) {
        this.colorSpan = bmValue.color
      }

    }
    else {
      this.value = numberWithCommas(this.params.data.values[idsCol]) + ' ' + this.params.context.distanceUnit;
    }

  }

  setOldValue() {
    this.params.context.setOldValueMainTable(this.params, this.idsCol, this.benchmarkColumnId);
  }

  getToolTip() {
    return 'Invalid value: ' + this.wrongValue;
  }
}
