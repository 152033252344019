import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-logout-confiramation',
  templateUrl: './logout-confiramation.component.html',
  styleUrls: ['./logout-confiramation.component.scss']
})
export class LogoutConfiramationComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<LogoutConfiramationComponent>) { }

  ngOnInit(): void {
  }


  Logout(){

    this.data.parent.accountService.logout();
    this.dialogRef.close();

  }
}
