import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'csr-parts-subgrid-inner',
  templateUrl: './csr-parts-subgrid-inner.component.html',
  styleUrls: ['./csr-parts-subgrid-inner.component.scss']
})
export class CsrPartsSubgridInnerComponent implements ICellRendererAngularComp {

  params: ICellRendererParams;
  description: string;
  altDescription: string;
  partNr: string;
  showAlternative = false;
  showPartNr: boolean;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    //console.log(params, params['descriptionField'], params['altDescriptionField']);
    this.params = params;
    this.description = params.data[params['descriptionField']];
    this.altDescription = params.data[params['altDescriptionField']];
    const sheet = params['sheet'];
    this.showPartNr = !sheet.changePartsDescription.has(this.description)

    let pnF = params['partNumberField'];
    if (!!pnF) {
      this.partNr = ' (' + params.data[pnF] + ')';
    } else {
      pnF = params['partDataField'];
      if (!!pnF && !!params.data[pnF]) {
        let pd = params.data[pnF][0];
        this.partNr = ' (' + pd.partNr + ')';
      } else {
        this.partNr = '';
      }
    }

    if (this.altDescription && this.altDescription != null && this.altDescription.length > 0) {
      this.showAlternative = true;
    }
  }

  getPartNr() {
    if (this.showPartNr) {
      return this.partNr;
    } else {
      return '';
    }
  }

  refresh(params: any): boolean {
    // TODO: implement this properly
    return false;
  }
}
