import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileShareComponent } from '../file-share/file-share.component';
import { MainComponent } from '../main/main.component';



@Component({
  selector: 'app-yes-no-message',
  templateUrl: './yes-no-message.component.html',
  styleUrls: ['./yes-no-message.component.scss']
})
export class YesNoMessageComponent {

  parent;
  question;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<YesNoMessageComponent>) {
    this.parent = data.parent;
    this.question = data.question;
  }

  submit(answer) {
    if (answer) {
      this.parent.deleteRows();
    }
  }

}


