import { Component, ViewChild, Input } from '@angular/core';
import { GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';
import { NewBatchCalculationComponent } from '../new-batch-calculation/new-batch-calculation.component';
import { SelectedCarsHeader } from '../selected-cars-header/selected-cars.header';
import { ActionCellRendererComponent } from '../action-cell/action-cell-renderer-component';

@Component({
  selector: 'app-selected-cars',
  templateUrl: './selected-cars.component.html',
  styleUrls: ['./selected-cars.component.scss']
})
export class SelectedCarsComponent {
  public frameworkComponents;
  constructor() {
    this.frameworkComponents = { agColumnHeader: SelectedCarsHeader };
  }


  @Input() selectedCars: any = [];
  @Input() parent: NewBatchCalculationComponent;
  @ViewChild('theGrid') theGrid;
  public columnDefs = [{
    headerName: '', field: 'car', flex: 1,
    headerComponentParams: { parent: this },
    cellRendererFramework: ActionCellRendererComponent,
    cellRendererParams: {
      icon: '../../assets/cross-remove.png',
      type: 'car',
      parent: this
    }
  }];

  public gridOptionsSpecs: GridOptions = {
    enableSorting: false,
    context: this,
    rowData: this.selectedCars,
    columnDefs: this.columnDefs,
    rowSelection: 'multiple',
    onRowDoubleClicked: this.onRowDoubleClicked
  };

  onRowDoubleClicked(params: RowDoubleClickedEvent) {
    const ctx = params.context;
    const cars = params.api.getSelectedNodes();
    ctx.dellCars(cars);
    ctx.parent.availableCarsGrid.deselectRow(cars);
  }

  addSelectedCars(cars: any): void {
    for (let index = cars.length - 1; index >= 0; index--) {
      if (this.selectedCars.some(car => car.carID === cars[index].carID)) {
        cars.splice(index, 1);
      }
    }
    cars.forEach(car => { this.selectedCars.push(car); });
    this.refreshGridData();
  }

  dellCars(cars: any): void {
    for (let index = cars.length - 1; index >= 0; index--) {
      this.dellCarById(cars[index].carID);
    }
    this.refreshGridData();
  }

  dellCarById(idCar: any): void {
    for (let index = this.selectedCars.length - 1; index >= 0; index--) {
      const car = this.selectedCars[index];
      if (car.carID === idCar) {
        this.selectedCars.splice(index, 1);
      }
    }
  }

  dellCar(car: any): void {
    this.dellCars([car]);
    this.parent.availableCarsGrid.deselectRowsByObjectArr([car]);
  }

  clearAllSelCars(): void {
    const tmp = [];
    this.parent.selCarRows = tmp;
    this.selectedCars = tmp;
  }

  clearAllSelCarsAndAvailableCars(): void {
    this.selectedCars = [];
    this.parent.availableCarsGrid.clearSelection();
  }


  refreshGridData(): void {
    const tmp = [];
    this.selectedCars.forEach(car => { tmp.push(car); });
    this.selectedCars = tmp;
    this.parent.selCarRows = tmp;
    this.parent.display = tmp.length > 0 ? "" : "none";
  }

  nextStep() {
    if (this.selectedCars.length > 0) {
      let arr = this.parent.availableCarsGrid.gridOptionsSpecs.api.getSelectedNodes();
      this.parent.selectedAvailableNodes =arr.map(node=>node.id);
      this.parent.onClickNextPage();
    }
  }
}
