import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { formatCurrency, formatWithSuffixAndPrecision } from '@app/helpers/utils.js';
import { AcarSpecificationsComponent } from '../acar-specifications/acar-specifications.component';
import { AcarEquipmentComponent } from '../acar-equipment/acar-equipment.component';
import { AcarResidualComponent } from '../acar-residual/acar-residual.component';
import { AcarUtilOptionalComponent } from '../acar-util-optional/acar-util-optional.component';
import { AcarUtilComponent } from '../acar-util/acar-util.component';
import { GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';


@Component({
  selector: 'app-file-storage',
  templateUrl: './file-storage.component.html',
  styleUrls: ['./file-storage.component.scss']
})
export class FileStorageComponent implements OnInit {

  constructor() { }

  specsRow: any = [];
  cosnt: any; self = this;

  public columnDefs = [
    { headerName: 'Car', field: 'car', pinned: 'left', width: 400 },
    {
      headerName: '', field: 'srcSpecification', cellRendererFramework: AcarSpecificationsComponent,
      width: 34, resizable: false, sortable: false
    },
    {
      headerName: '', field: 'srcEquipment', cellRendererFramework: AcarEquipmentComponent,
      width: 34, resizable: false, sortable: false
    },
    {
      headerName: '', field: 'srcResidualValue', cellRendererFramework: AcarResidualComponent,
      width: 34, resizable: false, sortable: false
    },
    {
      headerName: '', field: 'srcSWPL', cellRendererFramework: AcarUtilOptionalComponent,
      width: 34, resizable: false, sortable: false
    },
    {
      headerName: '', field: 'srcITFC', cellRendererFramework: AcarUtilComponent,
      width: 34, resizable: false, sortable: false
    },

    {
      headerName: 'Net Price', field: 'netPrice', filter: 'agNumberColumnFilter',
      valueFormatter: this.formatCurrency, type: 'rightAligned'
    },
    { headerName: 'Market', field: 'market' },
    { headerName: 'Make', field: 'make' },
    { headerName: 'Model', field: 'model' },
    { headerName: 'Trim Level', field: 'trimLevel' },
    { headerName: 'Sale Start', field: 'saleStart' },
    { headerName: 'Sale End', field: 'saleEnd' },
    { headerName: 'Body', field: 'body' },
    { headerName: 'Doors', field: 'doors', filter: 'agNumberColumnFilter', type: 'rightAligned' },
    { headerName: 'Seats', field: 'seats', filter: 'agNumberColumnFilter', type: 'rightAligned' },
    { headerName: 'Drive', field: 'drive' },
    { headerName: 'Transmission', field: 'transmission' },
    { headerName: 'Speeds', field: 'speeds', filter: 'agNumberColumnFilter', type: 'rightAligned' },
    { headerName: 'Fuel', field: 'fuel' },
    {
      headerName: 'Engine Size', field: 'engineSize', filter: 'agNumberColumnFilter',
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'ccm', 0), type: 'rightAligned'
    },
    { headerName: 'Cylinders', field: 'cylinders', filter: false, type: 'rightAligned' },
    {
      headerName: 'Horse Power', field: 'horsePower', filter: 'agNumberColumnFilter',
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'PS', 0), type: 'rightAligned'
    },
    { headerName: 'Acc 0 to 100', field: 'acc0to100', filter: false },
    {
      headerName: 'Tax Horse Power', field: 'taxHorsePower', filter: 'agNumberColumnFilter',
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, '', 2), type: 'rightAligned'
    },
    { headerName: 'Insurance Classification', field: 'insuranceClassification' },
    {
      headerName: 'Fuel Cons.', field: 'fuelConsumption', filter: 'agNumberColumnFilter',
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'l', 1), type: 'rightAligned'
    },
    {
      headerName: 'CO2', field: 'emissionCO2', filter: 'agNumberColumnFilter',
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'g', 0), type: 'rightAligned'
    },
    { headerName: 'Emmission std.', field: 'emissionStandard' },
    {
      headerName: 'Kerb Weight', field: 'kerbWeight', filter: 'agNumberColumnFilter',
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'kg', 0), type: 'rightAligned'
    },
    {
      headerName: 'Gross Vehicle Weight', field: 'grossVehicleWeight', filter: 'agNumberColumnFilter',
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'kg', 0), type: 'rightAligned'
    },
    {
      headerName: 'Length', field: 'length', filter: 'agNumberColumnFilter',
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'mm', 0), type: 'rightAligned'
    },
    {
      headerName: 'Height', field: 'height', filter: 'agNumberColumnFilter',
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'mm', 0), type: 'rightAligned'
    },
    {
      headerName: 'Width', field: 'width', filter: 'agNumberColumnFilter',
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'mm', 0), type: 'rightAligned'
    },
    {
      headerName: 'Wheelbase', field: 'wheelbase', filter: 'agNumberColumnFilter',
      valueFormatter: params => formatWithSuffixAndPrecision(params.value, 'mm', 0), type: 'rightAligned'
    },
    { headerName: 'Segment', field: 'segment' },
    { headerName: 'ID', field: 'carID', filter: false },
  ];



  @ViewChild('theGrid') theGrid;

  ngOnInit(): void {

  }

  onClickSelect() {

  }

  public gridOptionsSpecs: GridOptions = {
    context: this,
    rowData: this.specsRow,
    columnDefs: this.columnDefs,
    rowSelection: 'multiple',
    defaultColDef: {
      width: 100,
      resizable: true,
      sortable: true,
      filter: true
    },

    onRowDoubleClicked: this.onRowDoubleClicked,
  };

  formatCurrency(params) {
    return formatCurrency(params.value, '€');
  }

  formatYesNo(params) {
    if (params.value === 'false') {
      return 'no';
    }
    return 'yes';
  }


  onRowDoubleClicked(params: RowDoubleClickedEvent) {
    const ctx = params.context;
    const selectedRows = params.api.getSelectedRows();
    ctx.parent.selectedCars.addSelectedCars(selectedRows);
  }



}
