import { ɵConsole } from '@angular/core';
import { CalcModel } from './calc-model';
import { Constants } from '@app/helpers/Constants';


export class Sheet {
  private static lastNumberOfSheet = 0;
  public numberOfSheet = 0;

  public columnIds = [];
  public userChangesBeforeLastCall: Map<number, any> = new Map();
  public userChangesAfterLastCall: Map<number, any> = new Map();
  //For mixPanel
  public partDetailsTCOChanges: Map<number, any> = new Map();
  public allChangesMaintenance: Map<string, any> = new Map();//TODO replace columnChangedfromMaintenance
  public isMaintenanceDefault: boolean = true;//this is to know there are modify in maintenance and to show question when click refresh button
  public columnChangedfromMaintenance = new Set();
  public columnChangedfromEquipment = new Set();
  public changePartsDescription = new Set();
  public benchmarkColumnId = -1;
  public saveId: any;
  public title: string;
  public active: boolean;
  public dataTCO: any;
  public dataEquipment: any;
  public dataMaintenance: any;
  public residualType: string; // 'trade' or 'retail' ???
  public viewingMode: string; // 'sum', 'per-month', 'per-distance' ???
  public equipmentFilteringMode: string;  // 'all', 'std', 'opt', 'inc' ???
  public locked: boolean; // for editing table
  public calcModel: CalcModel = new CalcModel();//have to be by sheet
  public canSeePrice: Map<string, boolean> = new Map();//key makeCode
  public exchangeRates: Map<number, number> = new Map();
  public requestMetadata: Map<number, any> = new Map();//TODO this is use like a object not map
  public currencyID;
  public distanceID;
  public dateOfLastSave;
  public failedColumns = new Set();
  //TODO expandedOld not used, any in main.html with no sense
  public expandedOldRowsEquip;
  public expandedOldRowsTCO;
  public expandedOldRowsMaint;
  public version = Constants.CURRENT_SHEET_VERSION;

  public transientFields = {
    isLoadedFromFile: false,
    isRefreshed: false
  };


  constructor(title: string) {
    this.title = title;
    this.numberOfSheet = Sheet.lastNumberOfSheet++;
    this.locked = true;
    this.residualType = 'trade';
    this.viewingMode = 'sum';
    this.equipmentFilteringMode = 'all';

  }

  constructSheetLabel() {
    if (!this.transientFields.isLoadedFromFile && !this.transientFields.isRefreshed && this.columnIds.length > 0) {
      return this.title + " (" + (this.columnIds.length - this.failedColumns.size) + "/" + this.columnIds.length + ")";
    }
    if (this.transientFields.isLoadedFromFile && this.transientFields.isRefreshed) {
      return this.title + " - TCO REFRESHED";
    }
    if (this.transientFields.isLoadedFromFile && !this.transientFields.isRefreshed) {
      return this.title + " - TCO " + this.dateOfLastSave;
    }
    return this.title;
  }

  addChange(idsCol, field, newValue) {
    const tmp = this.userChangesAfterLastCall.get(idsCol);
    const before = this.userChangesBeforeLastCall.get(idsCol);
    if (before && before.get(field) == newValue && !!tmp && tmp.has(field)) {
      tmp.delete(field);
      if (tmp.size == 0) {
        this.userChangesAfterLastCall.delete(idsCol);
      }
      return;
    }
    if (tmp !== undefined) {
      tmp.set(field, newValue);
    } else {
      const map = new Map();
      map.set(field, newValue);
      this.userChangesAfterLastCall.set(idsCol, map);
    }
  }

  addPartDetailsTCOChanges(idsCol, type) {
    let innerSet = this.partDetailsTCOChanges.get(idsCol);
    if (innerSet == null) {
      innerSet = new Set();
      innerSet.add(type);
      this.partDetailsTCOChanges.set(idsCol, innerSet)
    } else {
      innerSet.add(type);
    }
  }

  addAllChangesMaintenance(idsCol, idRow, obj, fieldName) {
    let changes = this.allChangesMaintenance.get(idsCol + Constants.SEPARATOR + fieldName);
    if (changes == null) {
      changes = new Map();
      changes.set(idRow, obj);
      this.allChangesMaintenance.set(idsCol + Constants.SEPARATOR + fieldName, changes)
    } else {
      changes.set(idRow, obj);
    }
    this.isMaintenanceDefault = false;
  }

  getRowFromAllChangesMaintenance(idsCol, idRow, fieldName) {
    let changes = this.allChangesMaintenance.get(idsCol + Constants.SEPARATOR + fieldName);
    if (changes == null) {
      return null;
    } else {
      return changes.get(idRow);
    }
  }

  checkForChanges() {

    let isWithOtionalIncluded = false;

    let checkEquipmentOtionalIncluded = (nodes: any[]) => {
      if (nodes == undefined || nodes == null) {
        return;
      }

      for (let i = 0; i < nodes.length; i++) {
        if (!!nodes[i] && !!nodes[i].children) {
          checkEquipmentOtionalIncluded(nodes[i].children);
        } else {
          let values = nodes[i].values;
          Object.keys(values).forEach(j => {
            let subzoneOptions = values[j];
            if (subzoneOptions) {
              subzoneOptions.forEach(o => {
                if (o.isOptionalIncluded == true) {
                  isWithOtionalIncluded = true;
                }
              });
            }
          });
        }
      }
    };
    checkEquipmentOtionalIncluded(this.dataEquipment.children);

    return (this.userChangesAfterLastCall != null && this.userChangesAfterLastCall !== undefined && this.userChangesAfterLastCall.size > 0)
      || (this.userChangesBeforeLastCall != null && this.userChangesBeforeLastCall !== undefined && this.userChangesBeforeLastCall.size > 0)
      || (this.columnChangedfromEquipment != null && this.columnChangedfromEquipment !== undefined && this.columnChangedfromEquipment.size > 0)
      || (this.allChangesMaintenance != null && this.allChangesMaintenance !== undefined && this.allChangesMaintenance.size > 0)
      || isWithOtionalIncluded || !this.isMaintenanceDefault;
  }
}
