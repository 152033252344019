<div *ngIf="modified==NO_MODIFIED_CELL && render==OVERLAP_RENDER" [ngClass]="{'colorRed': colorSpan === TEXT_COLOR_RED,
  'colorGreen': colorSpan === TEXT_COLOR_GREEN,'colorGray': colorSpan === TEXT_COLOR_GRAY}">
  <img class="img-cell-rend pderr-cell-rend" src='../../assets/overlap.png' matTooltip="{{getToolTip(OVERLAP_RENDER)}}"
    matTooltipClass="multiline-tooltip" />
  <span class="pm-lr-2">{{value}}</span>
</div>
<div *ngIf="modified==NO_MODIFIED_CELL && render==REF_RENDER" [ngClass]="{'colorRed': colorSpan === TEXT_COLOR_RED,
  'colorGreen': colorSpan === TEXT_COLOR_GREEN,'colorGray': colorSpan === TEXT_COLOR_GRAY}">
  <img class="img-cell-rend pderr-cell-rend" src='{{refImg}}' />
  <span class="pm-lr-2">{{value}}</span>
</div>
<div *ngIf="modified==NO_MODIFIED_CELL" [ngClass]="{'colorRed': colorSpan === TEXT_COLOR_RED,
  'colorGreen': colorSpan === TEXT_COLOR_GREEN,'colorGray': colorSpan === TEXT_COLOR_GRAY}">
  <span class="pm-lr-2">{{value}}</span>
</div>
<div [ngClass]="{'error-cell-rend': true, 'colorRed': colorSpan === TEXT_COLOR_RED,
  'colorGreen': colorSpan === TEXT_COLOR_GREEN,'colorGray': colorSpan === TEXT_COLOR_GRAY,'colorBlue': colorSpan === TEXT_COLOR_BLUE}" *ngIf="modified==1">
  <img class="img-cell-rend pderr-cell-rend" src='../../assets/cross-remove-red.png' [matTooltip]="getToolTip(NO_RENDER)"
    matTooltipClass="example-tooltip-red" />
  <span class="pderr-cell-rend pm-lr-2">{{value}}</span>
</div>
<div *ngIf="modified==2;" style="flex:1" [ngClass]="{'colorRed': colorSpan === TEXT_COLOR_RED,
  'colorGreen': colorSpan === TEXT_COLOR_GREEN,'colorGray': colorSpan === TEXT_COLOR_GRAY,'colorBlue': colorSpan === TEXT_COLOR_BLUE}">
  <img class="img-cell-rend" src='../../assets/pencil.jpg' (click)="setOldValue()" />
  <span class="pm-lr-2">{{value}}</span>
</div>
