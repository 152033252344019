<div class="cce-nbc-selection-body">
  <div class="cce-col">
    <ag-grid-angular *ngIf="!singleSelection" style="height: calc(55vh - 50px);" class="ag-theme-balham"
      [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="gridRows">
    </ag-grid-angular>
    <footer style=" height: 40px;">
      <button mat-raised-button class="cce-footer-blue-button" (click)="loadCars()">FINISH SELECTION</button>
      <button mat-raised-button class="cce-footer-grey-button" (click)="closeMe()">CANCEL</button>
    </footer>
  </div>
</div>
