import { Component } from '@angular/core';

@Component({
  selector: 'app-checkbox-img-cell-renderer-component ',
  templateUrl: './checkbox-img-cell-renderer-component.html',
  styleUrls: ['./checkbox-img-cell-renderer-component.scss']
})
export class CheckboxImgCellRendererComponent {
  params: any;
  field: any;
  img: string;
  checked: boolean;
  disable: boolean;

  constructor() {
  }

  agInit(params: any): void {
    this.params = params;
    this.checked = false;
    this.field = this.params['field'];
    this.disable = !!this.params['disable'];
    this.checked = this.params['data'][this.field];
    if(this.checked){
      this.img = this.params['selectImg'];
    }else{
      this.img = this.params['unSelectImg'];
    }
  }

  onCheck(): void {
    if (this.disable) {
      return;
    }
    this.checked = !this.checked;
    if(this.checked){
      this.img = this.params['selectImg'];
      this.params['data'][this.field] = true;
    }else{
      this.img = this.params['unSelectImg'];
      this.params['data'][this.field] = false;
    }
  }
}
