import { Component, OnInit, IterableDiffers, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridOptions,  SelectionChangedEvent } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { CheckboxImgCellRendererComponent } from '../checkbox-img-cell/checkbox-img-cell-renderer-component';
import { AdminAddUserComponent } from '../admin-add-user/admin-add-user.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { getLocaleFirstDayOfWeek } from '@angular/common';
import { FinancingModel } from '@app/domain/calc-model';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';


interface CheckBox {
  name: string
  isChecked: boolean
}


@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {

  selectedRows: any = [];
  selectedFiles: FileList;

  uploader: FileUploader;

  @ViewChild('myGrid') myGrid: AgGridAngular;
  @ViewChild('myUpload') myUpload: ElementRef;

  fieldnames = {
    freezedCo2: 'Freeze CO₂',
    heatmapping: 'Heatmapping',
    jobOverlap: 'Job overlap'
  };

  financingNames = {
    leasing: 'Leasing',
    purchase: 'Purchase'
  };


  filterMenu: CheckBox[] = [{
    name: 'freezedCo2',
    isChecked: false
  }, {
    name: 'heatmapping',
    isChecked: false
  }, {
    name: 'jobOverlap',
    isChecked: false
  }];

  financing = [FinancingModel.LEASING, FinancingModel.PURCHASE];

  selectedValue = this.financing[0];

  getFieldname(key):string {
    return this.fieldnames[key];
  }

  getFinancingName(key):string {
    return this.financingNames[key];
  }


  upload(event: any) {
    this.selectedFiles = event.target.files;
    let currentFileUpload = this.selectedFiles.item(0);

    const data: FormData = new FormData();
    data.append('file', currentFileUpload);

    this.http.post<any>(environment.uploadUserFile, data).subscribe(response => {
      // this.showSuccess("The Users were successfully created!")
      this.refreshGrid();
    }, error => {
      this.messageService.showMessage(error.error);
    });
  }

  changeStatus():void {

    for (let i = 0 ; i < this.filterMenu.length; ++i) {
      this.organisationData[this.filterMenu[i].name] = !!this.filterMenu[i].isChecked;
    }
    this.organisationData['financing'] = this.selectedValue;
    this.http.put(environment.saveOrganisation, this.organisationData).subscribe(response => {
    });
  }

  public columnDefs = [
    {
      headerName: '', children: [{
        headerName: 'USER NAME', field: 'username', checkboxSelection: true,
        flex: 2, sortable: true
      }]
    },
    {
      headerName: '', children: [{
        headerName: 'DESCRIPTION', field: 'description', flex: 2, sortable: true
      }],
    },
    {
      headerName: 'OPEN', children: [
        {
          headerName: 'LOCALLY', field: 'locallyOpen', sortable: true,
          headerComponentParams: { parent: this },
          cellRendererFramework: CheckboxImgCellRendererComponent,
          cellRendererParams: {
            selectImg: '/assets/foursquare-check-in@2x.png',
            unSelectImg: '../../assets/cross-remove.png',
            field: 'locallyOpen',
            disable: true
          }, flex: 1
        },
        {
          headerName: 'REMOTELY', field: 'remotelyOpen', sortable: true,
          headerComponentParams: { parent: this },
          cellRendererFramework: CheckboxImgCellRendererComponent,
          cellRendererParams: {
            selectImg: '/assets/foursquare-check-in@2x.png',
            unSelectImg: '../../assets/cross-remove.png',
            field: 'remotelyOpen',
            disable: true
          }, flex: 1
        }]
    },
    {
      headerName: 'SAVE', children: [{
        headerName: 'LOCALLY', field: 'locallySave', sortable: true,
        headerComponentParams: { parent: this },
        cellRendererFramework: CheckboxImgCellRendererComponent,
        cellRendererParams: {
          selectImg: '/assets/foursquare-check-in@2x.png',
          unSelectImg: '../../assets/cross-remove.png',
          field: 'locallySave',
          disable: true
        }, flex: 1
      },
      {
        headerName: 'REMOTELY', field: 'remotelySave', sortable: true,
        headerComponentParams: { parent: this },
        cellRendererFramework: CheckboxImgCellRendererComponent,
        cellRendererParams: {
          selectImg: '/assets/foursquare-check-in@2x.png',
          unSelectImg: '../../assets/cross-remove.png',
          field: 'remotelySave',
          disable: true
        }, flex: 1
      }]
    },
    {
      headerName: 'EXPORT', children: [{
        headerName: 'PDF', field: 'pdf', sortable: true,
        headerComponentParams: { parent: this },
        cellRendererFramework: CheckboxImgCellRendererComponent,
        cellRendererParams: {
          selectImg: '/assets/foursquare-check-in@2x.png',
          unSelectImg: '../../assets/cross-remove.png',
          field: 'pdf',
          disable: true
        }, flex: 1
      },
      {
        headerName: 'EXCEL', field: 'excel',  sortable: true,
        headerComponentParams: { parent: this },
        cellRendererFramework: CheckboxImgCellRendererComponent,
        cellRendererParams: {
          selectImg: '/assets/foursquare-check-in@2x.png',
          unSelectImg: '../../assets/cross-remove.png',
          field: 'excel',
          disable: true
        }, flex: 1
      }, {
        headerName: 'EXCEL: DETAILS', field: 'excelDetails',  sortable: true,
        headerComponentParams: { parent: this },
        cellRendererFramework: CheckboxImgCellRendererComponent,
        cellRendererParams: {
          selectImg: '/assets/foursquare-check-in@2x.png',
          unSelectImg: '../../assets/cross-remove.png',
          field: 'excelDetails',
          disable: true
        }, flex: 1.3
      }]
    }];

  rowData = [];
  organisationData = {};

  constructor(private aboutDialog: MatDialog,private askDialog: MatDialog, private http: HttpClient,private messageService: HandleUiMessagesService) {
    let that = this;
    this.gridOptionsSpecs = {
      context: this,
      rowHeight: 34,
      rowData: this.rowData,
      enableRangeSelection: true,
      columnDefs: this.columnDefs,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      onSelectionChanged: (params: SelectionChangedEvent) => {
        that.selectedRows = params.api.getSelectedRows();
      },
      rowClassRules:{
        'grid-odd-row': function(params) {
          return params.node.rowIndex % 2 === 0;
        },
      }
    };
  }

  gridOptionsSpecs: GridOptions;

  hasPolicy(policies, name) {
    for (let i = 0; i < policies.length; ++i) {
      if (policies[i].name === name) {
        return true;
      }
    }
    return false;
  }

  ngOnInit(): void {
    this.http.get(environment.getOrganisation).subscribe(response => {
      this.organisationData = response;
      for (let i = 0 ; i < this.filterMenu.length; ++i) {
        this.filterMenu[i].isChecked = response[this.filterMenu[i].name];
      }

      for (let i = 0; i < this.financing.length; ++i) {
        if (this.financing[i] === response['financing']) {
          this.selectedValue = this.financing[i];
        }
      }
    });
    this.refreshGrid();
  }

  onDeleteUser() {
    // let selectedRows = this.gridOptionsSpecs.api.getSelectedNodes();
    let userIds = [];
    for (var i = 0; i < this.selectedRows.length; ++i) {
      userIds.push(this.selectedRows[i].id);
    }
    if (userIds.length > 0) {
      let message = "DO YOU REALLY WANT TO REMOVE THE SELECTED USER(s)?";
      var dialogRef = this.askDialog.open(ConfirmDialogComponent, {  panelClass: "cce-dialog-container", data: message });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.http.post(environment.deleteUsers, userIds).subscribe(response => {
            this.refreshGrid();
            this.messageService.showMessage(response["message"]);
            }, error => {
              this.messageService.showMessage(error.error);
            });
        }

      });

    }
  }

  onCellDoubleClicked(event) {
    this.openAddUserModal({id: event.data.id});
  }

  onClickOpenForm() {
    this.openAddUserModal({});
  }

  openAddUserModal(id):void {
    this.aboutDialog.open(AdminAddUserComponent, { width: "500px", minWidth: "500px", maxWidth: "700px", panelClass: "cce-dialog-container", data: id })
        .afterClosed().subscribe(result => {
          if (result) {
            this.refreshGrid();
          }
    });
  }

  refreshGrid():void {
    this.http.get<any>(environment.retrieveGroup).subscribe(response => {
      this.rowData = [];
      // this.gridOptionsSpecs.api.setRowData(this.rowData);
      for (let i = 0; i < response.length; ++i) {
        let description = "";
        if (!!response[i].dataExtension && !!response[i].dataExtension.description) {
          description = response[i].dataExtension.description;
        }
        this.rowData.push({ id: response[i].id, username: response[i].name,
          description: description,
          locallyOpen: this.hasPolicy(response[i].policies, "cceOpenLocal"),
          remotelyOpen: this.hasPolicy(response[i].policies, "cceOpenRemote"),
          locallySave: this.hasPolicy(response[i].policies, "cceSaveLocal"),
          remotelySave: this.hasPolicy(response[i].policies, "cceSaveRemote"),
          pdf: this.hasPolicy(response[i].policies, "cceExportPDF"),
          excel: this.hasPolicy(response[i].policies, "cceExportExcel"),
          excelDetails: this.hasPolicy(response[i].policies, "cceExportExcelFull") });

      }
      this.gridOptionsSpecs.api.setRowData(this.rowData);
      });
  }
}
