<div id="car-equipment-config">
  <div class="car-equipment-scope cce-row">
    <div class="default-parameters cce-col" style="flex:8">CAR EQUIPMENT EQUALISATION</div>
    <div class="cce-col" style="flex:1">
      <mat-label class="cce-label">MODE</mat-label>
      <mat-form-field class="cce-combo-field" floatLabel="never" appearance="none">
        <mat-select [value]="mode" (selectionChange)="onModeChange($event.value)" class="cce-combo">
          <mat-option *ngFor="let m of modeTypes" value="{{m}}">
            {{ m }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="cce-col" style="flex:1">
      <mat-label class="cce-label">MATCH</mat-label>
      <mat-form-field class="cce-combo-field" floatLabel="never" appearance="none">
        <mat-select [value]="match" (selectionChange)="onMatchChange($event.value)" class="cce-combo">
          <mat-option *ngFor="let m of matchTypes" value="{{m}}">
            {{ m }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="car-equipment-form">
    <mat-card class="car-equipment-grid">
      <mat-card-content class="car-equipment-grid-content">
        <div style="display: inline-flex; height: 100%;">
          <div *ngFor="let idx of colIds;" class="cce-eq-vertical" style="border: solid 1px #707070;">
            <!-- A VERTICAL LAYOUT FOR THE CAR-->
            <!-- <span style="flex:1; flex-flow: row;"> -->
            <div [ngClass]="(idx==selectedCol)?'col-header':'col-header-white'">{{colNames[idx]}}</div>
            <div class="cce-eq-eq">
              <div class="cce-eq-section">
                <!-- HEADER -->
                <div class="cce-eq-header">
                  <div class="section header-section">
                    <span style="width:65%">EXACT MATCH</span>
                    <span style="width:35%; border-left: 1px solid; padding-left: 3px;">NET PRICE</span>
                  </div>
                </div>
                <!-- BODY -->
                <div class="cce-eq-body">
                  <div class="section" *ngFor="let eMatch of finalNeeded[idx]">
                    <span style="width:65%">
                      <div style="display: flex;" class="cce-cell">
                        <span>
                          <mat-checkbox [(ngModel)]="eMatch.isOptionSuggested"></mat-checkbox>
                        </span>
                        <span [matTooltip]="eMatch.description" style="word-break: break-all; margin-left: 5px;">{{eMatch.descriptionShort}}</span>
                      </div>
                    </span>
                    <span style="width:35%">
                      <div class="cce-cell price">{{formatCurrency(eMatch.price)}}</div>
                    </span>
                  </div>
                </div>
              </div>
              <!-- HEADER -->
              <div class="cce-eq-section">
                <div class="cce-eq-header">
                  <div class="section">
                    <span style="width:100%">
                      <div class="header-section">CLOSE MATCH</div>
                    </span>
                  </div>
                </div>
                <!-- BODY -->
                <div class="cce-eq-body">
                  <div *ngFor="let key of finalEsacos[idx]">
                    <div class="label">{{esacos[key]}}</div>
                    <div class="section" *ngFor="let cmData of finalAmbigious[idx][key]">
                      <span style="width:60%">
                        <div style="display: flex;" class="cce-cell">
                          <span>
                            <mat-checkbox [(ngModel)]="cmData.isOptionSuggested"></mat-checkbox>
                          </span>
                          <span  [matTooltip]="cmData.description" style="word-break: break-all; margin-left: 5px;">{{cmData.descriptionShort}}</span>
                        </div>
                      </span>
                      <span style="width:40%">
                        <div class="cce-cell price">{{formatCurrency(cmData.price)}}</div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- HEADER -->
              <div class="cce-eq-section">
                <div class="cce-eq-header">
                  <div class="section">
                    <span style="width:100%">
                      <div class="header-section">UNAVAILABLE</div>
                    </span>
                  </div>
                </div>
                <!-- BODY -->
                <!-- for below-->
                <div class="cce-eq-body">
                  <div class="cce-filter-list">
                    <span *ngFor="let unavailable of finalUnavailable[idx];"
                      [class]="getStyle(unavailable)">{{unavailable}}</span>
                  </div>
                </div>
              </div>
              <!-- for above-->
              <!-- </span> -->
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="button_container">
      <button class="button_apply" type="submit" (click)="onApply()" mat-dialog-close mat-button>APPLY</button>
      <button class="button_close" type="submit" (click)="onClose()" mat-dialog-close mat-button>CLOSE</button>
    </div>
  </div>
</div>
