import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ICellRendererParams, ColDef, GridOptions } from 'ag-grid-community';
import { calcPriceByRate, formatCurrency, formatWithSuffixAndPrecision, cleanArray } from '@app/helpers/utils.js';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { remapIntervalUnit, remapIntervalOccurenceUnit, getCurrencySignByID } from '@app/helpers/RemapUnitsUtils.ts';
import { MatDialog } from '@angular/material/dialog';
import { DialogFrequencyComponent } from '@app/components/dialog-frequency/dialog-frequency.component';
import { Constants } from '@app/helpers/Constants';
import { getColIndex22ToLength } from '@app/helpers/utils.js';
import { metadata } from '@environments/environment'
import { Sheet } from '@app/domain/sheet';
import { IChangeDetect } from '@app/helpers/IChangeDetect';
import * as lodash from 'lodash'; //copy array
import { LocalStorageService } from '@app/services/local-storage.service';




@Component({
  selector: 'app-mr-accordion',
  templateUrl: './mr-accordion.component.html',
  styleUrls: ['./mr-accordion.component.scss']
})
export class MrAccordionComponent implements ICellRendererAngularComp, IChangeDetect {

  ADD_TYPE = Constants.ADD_TYPE;
  MOD_TYPE = Constants.MOD_TYPE;

  TYPE_ID__DISTANCE = Constants.TYPE_ID__DISTANCE;
  TYPE_ID__DURATION = Constants.TYPE_ID__DURATION;

  OCCURRENCE_ID__ONCE_AFTER = Constants.OCCURRENCE_ID__ONCE_AFTER;
  OCCURRENCE_ID__FIRST_TIME_AFTER = Constants.OCCURRENCE_ID__FIRST_TIME_AFTER;
  OCCURRENCE_ID__EVERY = Constants.OCCURRENCE_ID__EVERY;
  OCCURRENCE_ID__DISPLAY = Constants.OCCURRENCE_ID__DISPLAY;

  UNIT_ID__MONTH = Constants.UNIT_ID__MONTH;
  UNIT_ID__YEAR = Constants.UNIT_ID__YEAR;
  UNIT_ID__KILOMETRE = Constants.UNIT_ID__KILOMETRE;
  UNIT_ID__MILE = Constants.UNIT_ID__MILE;
  KM_TO_MILE = Constants.KM_TO_MILE;

  params: ICellRendererParams;
  readonly TYPE_SERV_PLAN = 0;
  readonly TYPE_ADD_TASKS = 1;
  readonly TYPE_WEAR_PARTS = 2;

  TYPE_SERV_PLAN_NAME = Constants.TYPE_SERV_PLAN_NAME;
  TYPE_ADD_TASKS_NAME = Constants.TYPE_ADD_TASKS_NAME;
  TYPE_WEAR_PARTS_NAME = Constants.TYPE_WEAR_PARTS_NAME;

  showPrice = false;
  parent;
  typeShow;
  values = [];
  indexCol: number;
  idsCol: number;
  sheet: Sheet;

  constructor(private localStorageService: LocalStorageService, private dialogFrequency: MatDialog) { }

  valueChange(value, newValue: number, accordionType?) {
    this.localStorageService.add(this.sheet, Constants.TYPE_VIEW_MAINTENANCE);
    if (this.TYPE_WEAR_PARTS == accordionType) {
      let oldObj = new DescMaintenanceRow(lodash.cloneDeep(value.intervalPlanType), value.labourTime);
      let rowChanges = this.getRowChanges(value.partDescription, this.idsCol, value.idWP, this.MOD_TYPE, oldObj, this.TYPE_WEAR_PARTS_NAME);
      value.labourTime = newValue;
      let newObj = new DescMaintenanceRow(lodash.cloneDeep(value.intervalPlanType), lodash.cloneDeep(newValue), value.price);
      rowChanges[newObj.mastercode].new = lodash.cloneDeep(newObj);
    } else if (this.TYPE_ADD_TASKS == accordionType) {
      let oldObj = new DescMaintenanceRow(lodash.cloneDeep(value.intervalPlanType), value.labourTime);
      let rowChanges = this.getRowChanges(value.task, this.idsCol, value.id, this.MOD_TYPE, oldObj, this.TYPE_ADD_TASKS_NAME);
      value.labourTime = newValue;
      let newObj = new DescMaintenanceRow(lodash.cloneDeep(value.intervalPlanType), lodash.cloneDeep(newValue));
      rowChanges[newObj.mastercode].new = lodash.cloneDeep(newObj);
    } else {
      let oldObj = new DescMaintenanceRow(lodash.cloneDeep(value.intervalPlanType), value.labourtime);
      let rowChanges = this.getRowChanges(value.description, this.idsCol, value.id, this.MOD_TYPE, oldObj, this.TYPE_SERV_PLAN_NAME);
      value.labourtime = newValue;
      let newObj = new DescMaintenanceRow(lodash.cloneDeep(value.intervalPlanType), lodash.cloneDeep(newValue));
      rowChanges[newObj.mastercode].new = lodash.cloneDeep(newObj);
    }
    this.onChange();
  }

  onChange(params?: any) {
    this.sheet.columnChangedfromMaintenance.add(this.idsCol);
  }
  // onChangePrice(event, value) {
  //   value.price = event.target.value / this.sheet.exchangeRates.get(this.idsCol);
  //   this.onChange(value.price);
  // }

  valueChangePrice(value, newValue?: number) {
    this.localStorageService.add(this.sheet, Constants.TYPE_VIEW_MAINTENANCE);

    let oldObj = new DescMaintenanceRow(lodash.cloneDeep(value.intervalPlanType), value.labourTime, value.price);
    let rowChanges = this.getRowChanges(value.partDescription, this.idsCol, value.idWP, this.MOD_TYPE, oldObj, this.TYPE_WEAR_PARTS_NAME);
    value.price = newValue / this.sheet.exchangeRates.get(this.idsCol);
    let newObj = new DescMaintenanceRow(lodash.cloneDeep(value.intervalPlanType), value.labourTime, value.price);
    rowChanges[newObj.mastercode].new = lodash.cloneDeep(newObj);
    this.onChange();
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.sheet = this.params.context.getDataSheet();

    this.indexCol = getColIndex22ToLength(this.params.colDef.field);
    this.idsCol = this.sheet.columnIds[this.indexCol];
    this.parent = params.context;

    if (this.idsCol === undefined || !params.data.values || params.data.values[this.idsCol].length === 0) {
      return;
    }

    this.values = params.data.values[this.idsCol];

    const field = this.params.data.field;
    const renderAs = metadata[field].renderAs;

    if (renderAs === 'SPAcordion') {
      this.typeShow = this.TYPE_SERV_PLAN;
    } else if (renderAs === 'ATAcordion') {
      this.typeShow = this.TYPE_ADD_TASKS;
    } else if (renderAs === 'List') {
      this.typeShow = this.TYPE_WEAR_PARTS;
      if (this.values[0].price !== null) {
        this.showPrice = true;
      }
    }
  }

  setSizeCell(divElem, heightFix) {
    const node = this.params.node;
    const size = this.parent.setColumnsSize(divElem.offsetHeight + heightFix, this.idsCol, this.typeShow);
    node.setRowHeight(size);
    this.parent.theGrid.api.onRowHeightChanged();
  }

  getDescription(value) {
    return this.typeShow === this.TYPE_SERV_PLAN ? value.description :
      (this.typeShow === this.TYPE_WEAR_PARTS ? value.partDescription : value.task);
  }

  calcInMonths(value): string {
    if (value.intervalType === this.TYPE_ID__DURATION && value.intervalUnit === this.UNIT_ID__YEAR) {
      value.intervalUnit = this.UNIT_ID__MONTH;
      value.intervalVal = Number(value.intervalVal * 12).toString();
    }
    if (value.intervalType === this.TYPE_ID__DISTANCE && value.intervalUnit == this.UNIT_ID__KILOMETRE && this.sheet.distanceID === this.UNIT_ID__MILE) {
      return formatWithSuffixAndPrecision(value.intervalVal * this.KM_TO_MILE, '', 0);
    }
    return value.intervalVal;
  }

  refresh(params: any): boolean {
    // TODO: properly implement this
    return false;
  }

  public formatCurrency(value) {
    return formatCurrency(calcPriceByRate(value), this.getCurrency());
  }

  calcPriceByRate(value) {
    return value * this.sheet.exchangeRates.get(this.idsCol);
  }

  getCurrency() {
    return getCurrencySignByID(this.sheet.currencyID);
  }

  remapIntervalOccurenceUnit(value) {
    return remapIntervalOccurenceUnit(value);
  }

  remapIntervalUnit(value) {
    if (value == this.UNIT_ID__KILOMETRE) {
      return remapIntervalUnit(this.sheet.distanceID);//show unit from sheet
    }
    return remapIntervalUnit(value)

  }

  newDialog(value, accordionType?) {
    let elem = value.intervalPlanType;
    const that = this;
    const dialogRef = this.dialogFrequency.open(DialogFrequencyComponent, {
      height: '500px', width: '400px',
      data: { elem, sheet: that.sheet, parent: this },
    });
    dialogRef.afterClosed().subscribe(newPlans => {
      if (newPlans !== null && newPlans !== undefined && newPlans !== '') {
        this.localStorageService.add(this.sheet, Constants.TYPE_VIEW_MAINTENANCE);

        let rowChanges;
        if (this.TYPE_WEAR_PARTS == accordionType) {
          let oldObj = new DescMaintenanceRow(lodash.cloneDeep(value.intervalPlanType), value.labourTime);
          rowChanges = this.getRowChanges(value.partDescription, this.idsCol, value.idWP, this.MOD_TYPE, oldObj, this.TYPE_WEAR_PARTS_NAME);
        } else if (this.TYPE_ADD_TASKS == accordionType) {
          let oldObj = new DescMaintenanceRow(lodash.cloneDeep(value.intervalPlanType), value.labourTime);
          rowChanges = this.getRowChanges(value.task, this.idsCol, value.id, this.MOD_TYPE, oldObj, this.TYPE_ADD_TASKS_NAME);
        } else {
          let oldObj = new DescMaintenanceRow(lodash.cloneDeep(value.intervalPlanType), value.labourtime);
          rowChanges = this.getRowChanges(value.description, this.idsCol, value.id, this.MOD_TYPE, oldObj, this.TYPE_SERV_PLAN_NAME);
        }
        cleanArray(elem);
        for (let np of newPlans) {
          elem.push(np);
        }
        let newObj;
        if (this.TYPE_WEAR_PARTS == accordionType) {
          newObj = new DescMaintenanceRow(lodash.cloneDeep(value.intervalPlanType), value.labourTime, value.price);
        } else if (this.TYPE_ADD_TASKS == accordionType) {
          newObj = new DescMaintenanceRow(lodash.cloneDeep(value.intervalPlanType), value.labourTime);
        } else {
          newObj = new DescMaintenanceRow(lodash.cloneDeep(value.intervalPlanType), value.labourtime);
        }
        rowChanges[newObj.mastercode].new = lodash.cloneDeep(newObj);

        this.onChange();

      }
    });
  }

  getRowChanges(name, idsCol, idRow, type, modObj, fieldName) {
    let rowChanges = {} as any;
    rowChanges = this.sheet.getRowFromAllChangesMaintenance(idsCol, idRow, fieldName);
    if (rowChanges != null) {
      if (rowChanges[modObj.mastercode]) {
        rowChanges[modObj.mastercode].type = rowChanges[modObj.mastercode].type == this.ADD_TYPE ? this.ADD_TYPE : type;
      } else {
        rowChanges[modObj.mastercode] = {};
        rowChanges.name = name;
        rowChanges[modObj.mastercode].old = lodash.cloneDeep(modObj);
        rowChanges[modObj.mastercode].type = type;
      }
      return rowChanges;
    } else {
      rowChanges = {};
      rowChanges.name = name;
      rowChanges[modObj.mastercode] = {};
      rowChanges[modObj.mastercode].type = type;
      rowChanges[modObj.mastercode].old = lodash.cloneDeep(modObj);
      this.sheet.addAllChangesMaintenance(idsCol, idRow, rowChanges, fieldName);
      return rowChanges;
    }
  }

}

export class DescMaintenanceRow {
  price: number;
  intervalPlanType;
  labourTime: number;
  mastercode

  public constructor(intervalPlanType, labourTime: number, price?: number) {
    this.intervalPlanType = intervalPlanType;
    this.labourTime = labourTime;
    this.price = price;
    this.mastercode = "IntervalDesc";
  }

}
