<mat-card style="margin: 10px;">
    <mat-card-subtitle>Available Cars</mat-card-subtitle>
    <mat-card-content>
      <div class="cce-col">
        <div style="margin-bottom: 10px;">
          <button mat-raised-button color="accent" class="mr10">SEARCH</button>
          <button mat-raised-button class="mr10">CSV</button>
          <button mat-raised-button>FREE SEARCH</button>
        </div>
        <ag-grid-angular #theGrid style=" height: 470px" class="ag-theme-balham" [gridOptions]="gridOptionsSpecs"
          [columnDefs]="columnDefs" [rowData]="specsRow"></ag-grid-angular>
      </div>
    </mat-card-content>
  </mat-card>