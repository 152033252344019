import { Component, OnInit } from '@angular/core';
import { User } from '@app/domain/user';
import { AccountService } from '@app/services/account.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'CCE-NG';
    user: User;

    constructor(private accountService: AccountService) { 
        this.accountService.user.subscribe(x => this.user = x);
    }

    logout() {
        this.accountService.logout();
    }
}
