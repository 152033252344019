import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserPreferences } from '@app/domain/UserPreferences';
import { UserPreferencesService } from '@app/services/user-preferences.service';
import { Constants } from '@app/helpers/Constants.ts';

@Component({
  selector: 'app-default-scenarion',
  templateUrl: './default-scenario.component.html',
  styleUrls: ['./default-scenario.component.scss']
})
export class DefaultScenarioComponent implements OnInit {

  distanceID: number;
  childName: string;
  childSection: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DefaultScenarioComponent>, private userPrefService: UserPreferencesService) {
    this.distanceID = data.distanceID;
  }

  ngOnInit(): void {
    this.childName = 'DEFAULT SCENARIOS';
    this.childSection = 'SETTINGS: ';
  }

  finishSelection(selectedScenario) {
    this.userPrefService.setScenario(this.distanceID, selectedScenario);
    this.dialogRef.close();
  }

}
