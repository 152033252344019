import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-secured-image',
  templateUrl: './secured-image.component.html',
  styleUrls: ['./secured-image.component.scss']
})
export class SecuredImageComponent implements OnChanges  {
  // This code block just creates an rxjs stream from the src
  // this makes sure that we can handle source changes
  // or even when the component gets destroyed
  // So basically turn src into src$
  @Input() private src: string = '';
  private src$;
  public dataUrl$;
  ngOnChanges(): void {
    this.src$.next(this.src);
  }



  // we need HttpClient to load the image
  // inject the domSanitizer here as well
  constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer) {
    this.src$ = new BehaviorSubject(this.src);
    // this stream will contain the actual url that our img tag will load
  // everytime the src changes, the previous call would be canceled and the
  // new resource would be loaded
    this.dataUrl$ = this.src$.pipe(switchMap((url:string) => this.loadImage(url)));
  }

  private loadImage(url: string): Observable<any> {
    return this.httpClient
      // load the image as a blob
      .get(url, {responseType: 'blob'}).pipe(
        map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e)))
      );
  }
}
