import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class HandleUiMessagesService {

  constructor(private snackBar: MatSnackBar) {
  }

  showMessage(msg) {
    this.snackBar.open(msg,"Dismiss", {
      panelClass: ['dismiss-snackbar'],
      duration: 8000
    });
  }

}
