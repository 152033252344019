import { Component, OnInit, Input } from '@angular/core';
import { AccountService } from '@app/services/account.service';
import { MatDialog } from '@angular/material/dialog';
import { AboutComponent } from '@app/components/about/about.component';
import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { AdminComponent } from '../admin/admin.component';
import { AdminPanelComponent } from '../admin-panel/admin-panel.component';
import { LogoutConfiramationComponent } from '../logout-confiramation/logout-confiramation.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userName: String;
  companyName: String;
  isAdmin: boolean = false;
  @Input() hasAdminButton: ICellRendererParams;

  constructor(
    private accountService: AccountService,
    private aboutDialog: MatDialog,
    private adminDialog: MatDialog,
    private router: Router,
    private logoutAthentication: MatDialog,

  ) { }

  ngOnInit(): void {
    this.userName = this.accountService.subjectName;
    this.companyName = this.accountService.companyName;
    this.isAdmin = this.accountService.hasRole("cceClientAdmin");
  }

  logout() {

    const dialogRef = this.logoutAthentication.open(LogoutConfiramationComponent
      , {
        data: {
          parent: this
        }
      });

  }

  openHelp() {
    const dialogRef = this.aboutDialog.open(AboutComponent, {
      closeOnNavigation: false, width: "85vw", minWidth: "1200px", maxWidth: "1400px",
      panelClass: "cce-dialog-container"
    });
  }


  openAbout() {
    const dialogRef = this.aboutDialog.open(AboutComponent, {

    });
  }

  openAdmin() {

    const dialogRef = this.adminDialog.open(AdminPanelComponent, {
      width: "85vw", minWidth: "1200px", maxWidth: "1400px",
      panelClass: "cce-dialog-container"
    });

    // this.router.navigate(['/admin']);
  }

}
