import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Constants } from '@app/helpers/Constants';

@Component({
  selector: 'app-dialog-option-choose',
  templateUrl: './dialog-option-choose.component.html',
  styleUrls: ['./dialog-option-choose.component.scss']
})
export class DialogOptionChooseComponent {


  constructor(private dialogRef: MatDialogRef<DialogOptionChooseComponent>) {
  }

  withChange() {
    this.dialogRef.close(Constants.WITH_CHANGE);

  }

  withoutChange() {
    this.dialogRef.close(Constants.WITHOUT_CHANGE);

  }
}


