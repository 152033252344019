import { Component, OnInit, ViewChild, OnDestroy, ElementRef, QueryList, ViewChildren, ViewEncapsulation, ɵConsole } from '@angular/core';
import { Sheet } from '@app/domain/sheet';
import { SheetService } from '@app/services/sheet.service';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { MatDialog } from '@angular/material/dialog';
import { NewBatchCalculationComponent } from '@app/components/new-batch-calculation/new-batch-calculation.component';
import { DialogExcelExportComponent } from '@app/components/dialog-excel-export/dialog-excel-export.component';
import { DialogCsvExportComponent } from '@app/components/dialog-csv-export/dialog-csv-export.component';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { SheetComponent } from '../sheet/sheet.component';
import { ParamConfigComponent } from '../param-config/param-config.component';
import { DialogPDFExportComponent } from '../dialog-pdf-export/dialog-pdf-export.component';
import { Constants } from '@app/helpers/Constants.ts';
import { CCEConst } from '@app/helpers/CCEConst.ts';
import { getDistanceUnitByID, getCurrencyIDByAbbr } from '@app/helpers/RemapUnitsUtils.ts';
import { UserPreferencesService } from '@app/services/user-preferences.service';
import { CalcModelComponent } from '../calc-model/calc-model.component';
import { environment } from '@environments/environment';
import { FileShareComponent } from '../file-share/file-share.component';
import { MixPanelService } from '@app/services/mix-panel.service';
import { CceFilesService } from '@app/services/cce-db-files.service';
import { FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from '@app/services/account.service';
import { FinancingModel, CalcModel, copyCalcModel } from '@app/domain/calc-model';
import { HttpClient } from '@angular/common/http';
import { disableScroll, enableScroll, setSheetScenario } from '@app/helpers/utils.js';
import { BehaviorSubject } from 'rxjs';
import * as lodash from 'lodash'; //copy array
import { LocalStorageService } from '@app/services/local-storage.service';
import { UndoStorage } from '@app/domain/UndoStorage';
import { SingleSelectedScenarioComponent } from '../single-selected-scenario/single-selected-scenario.component';
import { DialogOptionChooseComponent } from '../dialog-option-choose/dialog-option-choose.component';



@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy {




  readonly ACTIVE_TRADE = Constants.ACTIVE_TRADE;
  readonly ACTIVE_RETAIL = Constants.ACTIVE_RETAIL;

  readonly ACTIVE_TOTAL = Constants.ACTIVE_TOTAL;
  readonly ACTIVE_KM = Constants.ACTIVE_KM;
  readonly ACTIVE_MONTH = Constants.ACTIVE_MONTH;

  readonly TYPE_EQ_ALL = Constants.TYPE_EQ_ALL;
  readonly TYPE_EQ_STD = Constants.TYPE_EQ_STD;
  readonly TYPE_EQ_OPT = Constants.TYPE_EQ_OPT;
  readonly TYPE_EQ_INC = Constants.TYPE_EQ_INC;

  readonly PERMISION_OPEN_LOCAL = CCEConst.PERMISION_OPEN_LOCAL;
  readonly PERMISION_MESSAGE = CCEConst.PERMISION_MESSAGE;
  readonly PERMISION_SAVE_LOCAL = CCEConst.PERMISION_SAVE_LOCAL;
  readonly PERMISION_OPEN_REMOTE = CCEConst.PERMISION_OPEN_REMOTE;
  readonly PERMISION_SAVE_REMOTE = CCEConst.PERMISION_SAVE_REMOTE;
  readonly PERMISION_EXPORT_EXCEL = CCEConst.PERMISION_EXPORT_EXCEL;
  readonly PERMISION_FULL_EXPORT_EXCEL = CCEConst.PERMISION_FULL_EXPORT_EXCEL;
  readonly PERMISION_EXPORT_PDF = CCEConst.PERMISION_EXPORT_PDF;
  readonly PERMISION_EXPORT_CSV = CCEConst.PERMISION_EXPORT_CSV;

  readonly TYPE_VIEW_TCO = Constants.TYPE_VIEW_TCO;
  readonly TYPE_VIEW_EQUIPMENT = Constants.TYPE_VIEW_EQUIPMENT;
  readonly TYPE_VIEW_MAINTENANCE = Constants.TYPE_VIEW_MAINTENANCE;
  readonly LOCATE_GENERAL_CHENGE = Constants.LOCATE_GENERAL_CHENGE;
  readonly jp = require('jsonpath');

  typeView: number;
  pdfDialog;
  exelDialog;
  csvDialog;
  lockedOn = "/assets/unlocked.jpg";
  lockedOff = "/assets/locked.png";
  locked = this.lockedOff;
  isVisibleRefreshButton = false;

  uploader: FileUploader;
  response: string;
  selectedFiles: FileList;
  progress: { percentage: number } = { percentage: 0 };
  columnsWidths = new Map();
  clickCount = 0;
  semaphor = 0;
  inTitleBoundClick = false;
  isReady = false;
  isPDF: number = 0;
  pdfPageIndex: string = "";
  pdfDateNow: string;
  yearNow: number;
  username: string;


  @ViewChild('screen') screen: ElementRef;
  @ViewChild('myUpload') myUpload: ElementRef;
  @ViewChildren(SheetComponent) sheetComponents: QueryList<any>;
  @ViewChild('calcModel') calcModel: CalcModelComponent;
  @ViewChild("inputField") input: ElementRef;

  sheets: Sheet[] = [];
  selectedSheetIdx: number;
  private lastSheetIdx = 0;
  fullExportPermision: boolean;
  exportPermision: boolean;


  constructor(
    private sheetService: SheetService, private accountService: AccountService, private userPreferencesService: UserPreferencesService,
    private messageService: HandleUiMessagesService, private mixPanelServ: MixPanelService,
    private newBatchCalcDialog: MatDialog, private newDuplicateDialog: MatDialog, private localStorageService: LocalStorageService,
    private excelDialog: MatDialog, private optionDialog: MatDialog,
    private paramConfigDialog: MatDialog, private fileShereDialog: MatDialog,
    private cceFilesService: CceFilesService, private http: HttpClient, private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.sheetService.sub.subscribe(sheets => {
      this.sheets = sheets;
      this.selectedSheetIdx = sheets.findIndex(sheet => sheet.active);
    });

    this.sshow('prefs');
    this.userPreferencesService.loadUserPreferences().subscribe(
      res => {
        this.shide('prefs');
        this.isReady = true;
        this.newSheet();
        setTimeout(() => {
          this.changeView(this.TYPE_VIEW_TCO);
          this.loadTabData();
        }, 0);
      },
      err => {
        this.shide('prefs');
        this.isReady = true;
      }
    );
    this.localStorageService.clear();
    let user = JSON.parse(sessionStorage['user']);
    this.username = user['username'];
    //get date now
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    this.yearNow = yyyy;
    this.pdfDateNow = yyyy + "-" + mm + "-" + dd;
  }

  ngOnDestroy(): void {
    this.sheetService.removeAllSheets();
  }

  getFinancingLabel(financing): string {
    switch (financing) {
      case FinancingModel.LEASING:
        return financing.toUpperCase();
      case FinancingModel.PURCHASE:
        return financing.toUpperCase();
      default:
        return "";
    }

  }

  duplicateSheet() {
    let currentSheet: Sheet = this.getActiveSheet().getSheet();
    if (!currentSheet || !currentSheet.columnIds || currentSheet.columnIds.length == 0) {
      let msg = "Must select a filled sheet for duplication!";
      this.messageService.showMessage(msg);
      return;
    }

    // console.log(currentSheet, "currentSheet");
    const dialogRef = this.newDuplicateDialog.open(SingleSelectedScenarioComponent, {
      width: "85vw", minWidth: "1200px", maxHeight: "80vh", panelClass: "cce-dialog-container", data: {
        distanceID: this.getActiveSheet().getSheet().distanceID
      }
    }).afterClosed().subscribe(scenario => {
      if (scenario !== undefined && scenario !== null) {

        if (currentSheet.checkForChanges()) {
          this.optionDialog.open(DialogOptionChooseComponent).afterClosed().subscribe(isWithChange => {
            if (isWithChange === Constants.WITH_CHANGE) {
              let newSheet = this.sheetService.addFilledSheet(currentSheet);
              setSheetScenario(newSheet, scenario);
              //add all cols for recalculate in equipment because after calc will clean the set
              newSheet.columnChangedfromEquipment = new Set(newSheet.columnIds);
              setTimeout(() => {
                this.getActiveSheet().startSheetBasedOnOld();
                this.loadCalc(currentSheet);
                this.getRefreshButtonState();
              }, 0);
            }

            if (isWithChange === Constants.WITHOUT_CHANGE) {
              this.loadInNewSheet(currentSheet, scenario);
            }
          });
        } else {
          this.loadInNewSheet(currentSheet, scenario);
        }

      }
    });
  }

  newSheetFromUI() {
    let prefs = this.userPreferencesService.getUserPreferencesNow();
    let correctModel = copyCalcModel(prefs.calcModel);
    if (!!correctModel) {
      correctModel.correctModel();
    }
    prefs.calcModel = correctModel;
    this.userPreferencesService.saveUserPreferences(prefs).subscribe(data => {
      this.calcModel.calcModel = data.calcModel;
      this.lastSheetIdx++;
      let financing = this.getFinancingLabel(correctModel.financing);
      if (financing !== "") {
        financing = " - " + financing;
      }
      this.sheetService.addSheet(new Sheet('SHEET ' + this.lastSheetIdx + financing));
      setTimeout(() => { this.getActiveSheet().formatByCalcModel(); this.loadTabData(); }, 0);
    });
  }


  newSheet() {
    let prefs = this.userPreferencesService.getUserPreferencesNow();
    let correctModel = copyCalcModel(prefs.calcModel);
    if (!!correctModel) {
      correctModel.correctModel();
    }
    prefs.calcModel = correctModel;
    this.userPreferencesService.saveUserPreferences(prefs).subscribe(data => {
      this.calcModel.calcModel = data.calcModel;
    });

    this.lastSheetIdx++;
    let financing = this.getFinancingLabel(correctModel.financing);
    if (financing !== "") {
      financing = " - " + financing;
    }
    this.sheetService.addSheet(new Sheet('SHEET ' + this.lastSheetIdx + financing));
    setTimeout(() => { this.getActiveSheet().formatByCalcModel(); this.loadTabData(); }, 0);
  }

  upload(event: any) {
    if (!this.accountService.hasPermission(this.PERMISION_OPEN_LOCAL)) {
      this.messageService.showMessage("You don't have permission to open sheets from device!");
      return;
    }

    this.spinner.show();
    this.selectedFiles = event.target.files;
    this.progress.percentage = 0;
    let currentFileUpload = this.selectedFiles.item(0);

    const data: FormData = new FormData();
    data.append('file', currentFileUpload);
    this.http.post<any>(environment.getCceFromDeviceEndpoint, data, { reportProgress: true }).subscribe(response => {
      if (!!response) {
        let newSheet = new Sheet(response.sheet.label);
        newSheet.transientFields.isLoadedFromFile = true;
        newSheet.dateOfLastSave = response.sheet.dateOfLastSave;
        newSheet.version = response.sheet.version;
        this.sheetService.addSheet(newSheet, response.sheet.calculationModel, response.sheet.distanceUnit, response.sheet.currencyUnit);
        setTimeout(() => {
          this.getActiveSheet().loadDataFromDevice(response, newSheet);
          this.getRefreshButtonState();
          this.locked = this.lockedOff;
          // Do not reset the currency of existing sheets -- currency setting will only be applied for new sheets
          // [VNP 2020-11-13]
          //this.sheetService.resetCurrencyOnSheet(this.getActiveSheet().getSheet());
        }, 0);
        let additionalObj = {
          eventKey: "OpenFile"
        }
        let endpoint = '/cce/transactions/OpenFile';
        this.mixPanelServ.logData(additionalObj, endpoint);
      }
      this.spinner.hide();
    }, error => {
      console.log(error);
      this.spinner.hide();
    });
  }


  sshow(reason?) {
    console.log('showing for ' + reason, this.semaphor, this.semaphor + 1);
    this.spinner.show();
    this.semaphor++;
  }

  shide(reason?) {
    console.log('hiding for ' + reason, this.semaphor, this.semaphor - 1);
    this.semaphor--;
    if (this.semaphor <= 0) {
      this.semaphor = 0;
      this.spinner.hide();
    }
  }

  removeSheet(index) {
    var result = this.sheetService.removeSheet(index);
    if (result === -1) {
      this.messageService.showMessage("One sheet has to remain open!");

    } else {
      this.selectedSheetIdx = result;
    }
    setTimeout(() => { this.getRefreshButtonState(); }, 0);
  }

  isTabActive(idx) {
    if (idx === this.selectedSheetIdx) {
      return 'activeTabTitle';
    } else {
      return 'tabTitle';
    }
  }
  getDistance() {
    return getDistanceUnitByID(this.sheetService.getSheet(this.selectedSheetIdx).distanceID);
  }

  loadTabData() {
    this.changeResidualButtonsView(this.getActiveSheet().getSheet().residualType);
    this.changeViewingModeButton(this.getActiveSheet().getSheet().viewingMode);
    this.getLockedState();
    this.getRefreshButtonState();
  }


  newExcelDialog() {
    this.fullExportPermision = this.accountService.hasPermission(this.PERMISION_FULL_EXPORT_EXCEL);
    this.exportPermision = this.accountService.hasPermission(this.PERMISION_EXPORT_EXCEL);
    this.exelDialog = this.excelDialog.open(DialogExcelExportComponent, { data: { parent: this, sheetComponent: this.getActiveSheet() } });
  }

  newCsvDialog() {
    if (!this.accountService.hasPermission(this.PERMISION_EXPORT_CSV)) {
      this.messageService.showMessage("You don't have permission to export sheets to CSV!");
      return;
    }
    this.csvDialog = new DialogCsvExportComponent({ parent: this, sheetComponent: this.getActiveSheet() }, this.spinner, this.http, this.mixPanelServ);
    this.csvDialog.csvLevel(0);
    // this.csvDialog = this.excelDialog.open(DialogCsvExportComponent, { data: { parent: this, sheetComponent: this.getActiveSheet() } });
  }

  closeCsvDialog() {
    // this.csvDialog.close();
  }

  closeExcelDialog() {
    this.exelDialog.close();
  }

  closePDFDialog() {
    this.pdfDialog.close();
  }

  newPDFDialog() {

    if (!this.accountService.hasPermission(this.PERMISION_EXPORT_PDF)) {
      this.messageService.showMessage("You don't have permission to export sheets to PDF!");
      return;
    }

    this.pdfDialog = this.excelDialog.open(DialogPDFExportComponent, { data: this });

  }

  download3ColPdf() {
    this.spinner.show();

    let additionalObj = {
      eventKey: "exportPdfSameView"
    }
    let endpoint = '/cce/transactions/exportPDFSameView';
    this.mixPanelServ.logData(additionalObj, endpoint);

    disableScroll();
    let oldViewType = this.typeView;
    this.setIsPdf(3);
    if (oldViewType !== this.TYPE_VIEW_TCO) {
      this.changeView(this.TYPE_VIEW_TCO);
    }
    this.retryUntilReviewTab3(oldViewType, 100, this.TYPE_VIEW_TCO);
  }

  downloadPDF(images: any[]) {
    let doc = new jsPDF('l', 'mm');
    const dateStr = new Date().toLocaleDateString('fr-CA').replace(/\-/gi, ''); //2020-07-19
    const name = this.getActiveSheet().getSheet().title;
    let filename: string;
    if (!!name) {
      filename = name.replace(/\s/gi, '') + "-" + dateStr + ".pdf"
    } else {
      filename = "CCEExport-" + dateStr + ".pdf"
    }
    for (let idx = 0; idx < images.length; idx++) {
      if (idx > 0) {
        doc.addPage();
      }
      doc.addImage(images[idx], 'JPEG', 30, 10, 260, 184, undefined, 'FAST', 0);
    }
    doc.save(filename);
    enableScroll();
  }

  downloadImage(oldViewType) {
    const grid = document.getElementById("pdf-view");
    let pageSizeInPixels = 1600 + 120;
    let pageHeightInPixels = 1280;
    let sheet = this.getActiveSheet().getTCOComp();
    this.columnsWidths = sheet.getColumnWidths();//PDF change values
    const oldColWidth = new Map(sheet.getColumnWidths())
    let selectedRows = sheet.getExpandedRows();
    let colCount = sheet.getRealCols();
    let startIndex = 0;
    let images: string[] = [];
    let firstColWidthForPdf = 330;
    let oldFirstColWidth = sheet.getFirstColWidth();
    sheet.setFirstColWidth(firstColWidthForPdf);
    sheet.setHasGridChange(false);

    let singleColumn = ((1600 - firstColWidthForPdf) / 3) - 10;
    if (singleColumn < 220) {
      singleColumn = 220;
    } else if (singleColumn > 420) {
      singleColumn = 420;
    }

    let captureCanvas = ((canvas: HTMLCanvasElement) => {
      let imgData = canvas.toDataURL('image/jpeg');
      images.push(imgData);
      this.setPdfPageIndex(startIndex + 1, 3, colCount);
      startIndex = sheet.showThreeColsFromIndex(startIndex, singleColumn);
      if (startIndex != null) {
        this.retryUntilReadyForSnapshot(() => {
          html2canvas(grid, { scale: 3, height: pageHeightInPixels, width: pageSizeInPixels, logging: true, imageTimeout: 15000, allowTaint: true }).then(canvas => captureCanvas(canvas));
        }, 1000, sheet);
      } else {
        this.downloadPDF(images);
        sheet.clearExpandedRows();
        sheet.showAllColumns();
        sheet.setExpandedRows(selectedRows);
        sheet.setFirstColWidth(oldFirstColWidth);
        this.setIsPdf(0);
        this.spinner.hide();
        this.closePDFDialog();
        if (oldViewType !== this.TYPE_VIEW_TCO) {
          this.changeView(oldViewType);
        } else {
          sheet.setColWidthSize(oldColWidth);
          sheet.reloadData();
        }
      }
    });

    this.setPdfPageIndex(startIndex + 1, 3, colCount);
    startIndex = sheet.showThreeColsFromIndex(startIndex, singleColumn);

    this.retryUntilReadyForSnapshot(() => {
      html2canvas(grid, { scale: 3, height: pageHeightInPixels, width: pageSizeInPixels, logging: true, imageTimeout: 15000, allowTaint: true }).then(canvas => captureCanvas(canvas));
    }, 1000, sheet);
  }

  retryUntilReviewTab3(oldViewType, t, expectedType) {
    let headerElement = document.getElementsByClassName("pdf-header");
    if (this.TYPE_VIEW_TCO === expectedType) {
      if (this.getActiveSheet().getTCOComp() === undefined || headerElement.length === 0) {
        setTimeout(() => this.retryUntilReviewTab3(oldViewType, t, expectedType), t);
      } else {
        this.downloadImage(oldViewType);
      }
    } else if (this.TYPE_VIEW_EQUIPMENT === expectedType) {
      if (this.getActiveSheet().getEquipmentComp() === undefined) {
        setTimeout(() => this.retryUntilReviewTab3(oldViewType, t, expectedType), t);
      } else {
        this.downloadImage(oldViewType);
      }
    } else if (this.TYPE_VIEW_MAINTENANCE === expectedType) {
      if (this.getActiveSheet().getMaintenanceComp() === undefined) {
        setTimeout(() => this.retryUntilReviewTab3(oldViewType, t, expectedType), t);
      } else {
        this.downloadImage(oldViewType);
      }
    }
  }

  setIsPdf(num: number) {
    this.isPDF = num;
  }

  setPdfPageIndex(startIndex, range, count) {
    if (startIndex > count) {
      return;
    } else if (startIndex === count) {
      this.pdfPageIndex = startIndex + " of " + count;
    } else {
      let lastIndex;
      if ((startIndex + range - 1) < count) {
        lastIndex = startIndex + range - 1;
      } else {
        lastIndex = count;
      }
      this.pdfPageIndex = startIndex + " - " + lastIndex + " of " + count;
    }
  }

  download5ColPdf() {
    this.spinner.show();
    let additionalObj = {
      eventKey: "exportPdf5Vehicles"
    }
    let endpoint = '/cce/transactions/exportPDF5Vehicles';
    this.mixPanelServ.logData(additionalObj, endpoint);

    disableScroll();
    let oldViewType = this.typeView;
    if (oldViewType !== this.TYPE_VIEW_TCO) {
      this.changeView(this.TYPE_VIEW_TCO);
      this.retryUntilReviewTab5(oldViewType, 100, this.TYPE_VIEW_TCO);
    } else {
      this.downloadImageMIF(this.TYPE_VIEW_TCO);
    }
  }

  downloadImageMIF(oldViewType) {
    let grid = document.getElementById("pdf-view");
    let pageSizeInPixels = 1600 + 120;
    let pageHeightInPixels = 1280;

    let sheetComp = this.getActiveSheet();
    let sheet = sheetComp.getTCOComp();
    this.columnsWidths = sheet.getColumnWidths();//PDF change values
    const oldColWidth = new Map(sheet.getColumnWidths())
    let selectedRows = sheet.getExpandedRows();
    let colCount = sheet.getRealCols();
    this.setIsPdf(5);
    let startIndex = 0;
    this.setPdfPageIndex(startIndex + 1, 5, colCount);
    let images: string[] = [];
    let firstColWidthForPdf = 250;
    let oldFirstColWidth = sheet.getFirstColWidth();
    sheet.setFirstColWidth(firstColWidthForPdf);

    let singleColumn = ((1600 - firstColWidthForPdf) / 5) - 10;
    if (singleColumn < 220) {
      singleColumn = 220;
    } else if (singleColumn > 420) {
      singleColumn = 420;
    }

    let captureCanvas = (async (canvas: HTMLCanvasElement) => {
      let imgData = canvas.toDataURL('image/jpeg');
      images.push(imgData);
      sheet.setExpandedRows(['acquisition', 'depreciation', 'utilisation', 'TCO']);
      this.setPdfPageIndex(startIndex + 1, 5, colCount);
      startIndex = sheet.showFiveColsFromIndex(startIndex, singleColumn);
      if (startIndex != null) {
        this.retryUntilReadyForSnapshot(() => {
          html2canvas(grid, { scale: 3, height: pageHeightInPixels, width: pageSizeInPixels, logging: true, imageTimeout: 15000, allowTaint: true }).then(canvas => captureCanvas(canvas));
        }, 1000, sheet);
      } else {
        this.downloadPDF(images);
        sheet.clearExpandedRows();
        sheet.showAllColumns();
        sheet.setExpandedRows(selectedRows);
        sheet.setFirstColWidth(oldFirstColWidth);
        this.setIsPdf(0);
        this.spinner.hide();
        this.closePDFDialog();
        if (oldViewType !== this.TYPE_VIEW_TCO) {
          this.changeView(oldViewType);
        } else {
          sheet.setColWidthSize(oldColWidth);
          sheet.reloadData();
        }
      }
    });
    sheet.setExpandedRows(['acquisition', 'depreciation', 'utilisation', 'TCO']);
    startIndex = sheet.showFiveColsFromIndex(startIndex, singleColumn);
    this.retryUntilReadyForSnapshot(() => {
      html2canvas(grid, { scale: 3, height: pageHeightInPixels, width: pageSizeInPixels, logging: true, imageTimeout: 15000, allowTaint: true }).then(canvas => captureCanvas(canvas));
    }, 1000, sheet);
  }

  retryUntilReadyForSnapshot(f, t, sheet) {
    setTimeout(() => {
      if (sheet.isAnimationReady()) {
        f();
      } else {
        this.retryUntilReadyForSnapshot(f, t, sheet);
      }
    }, t);
  }

  retryUntilReviewTab5(oldViewType, t, expectedType) {
    if (this.TYPE_VIEW_TCO === expectedType) {
      if (this.getActiveSheet().getTCOComp() === undefined) {
        setTimeout(() => this.retryUntilReviewTab5(oldViewType, t, expectedType), t);
      } else {
        this.downloadImageMIF(oldViewType);
      }
    } else if (this.TYPE_VIEW_EQUIPMENT === expectedType) {
      if (this.getActiveSheet().getEquipmentComp() === undefined) {
        setTimeout(() => this.retryUntilReviewTab5(oldViewType, t, expectedType), t);
      } else {
        this.downloadImageMIF(oldViewType);
      }
    } else if (this.TYPE_VIEW_MAINTENANCE === expectedType) {
      if (this.getActiveSheet().getMaintenanceComp() === undefined) {
        setTimeout(() => this.retryUntilReviewTab5(oldViewType, t, expectedType), t);
      } else {
        this.downloadImageMIF(oldViewType);
      }
    }
  }

  getSheetFromTypeView() {
    let sheet;
    if (this.typeView === 0) {
      sheet = this.getActiveSheet().getTCOComp();
    } else if (this.typeView === 1) {
      sheet = this.getActiveSheet().getEquipmentComp();
    } else if (this.typeView === 2) {
      sheet = this.getActiveSheet().getMaintenanceComp();
    }
    return sheet;
  }

  openFileShere() {
    let dialogRef;
    if (!this.accountService.hasPermission(this.PERMISION_OPEN_REMOTE)) {
      this.messageService.showMessage("You don't have permission to open sheets from CCE!");
      return;
    }
    dialogRef = this.fileShereDialog.open(FileShareComponent, {
      width: "85vw", minWidth: "1200px", maxWidth: "1400px", panelClass: "cce-dialog-container", data: {
        isForSave: false
      }
    })
      .afterClosed().subscribe(response => {
        this.spinner.show();
        if (response) {
          let rows = response[0];
          // let fileComponent = response[1];
          if (!!rows) {
            let ids = [];
            for (let i = 0; i < rows.length; ++i) {
              ids[i] = rows[i]["_id"];
            }
            this.cceFilesService.getSheetsFromCceFilesById(ids).subscribe(
              data => {
                this.loadSheetsFromResponse(data, 0);
                this.spinner.hide();
                let additionalObj = {
                  eventKey: "OpenFile"
                }
                let endpoint = '/cce/transactions/OpenFile';
                this.mixPanelServ.logData(additionalObj, endpoint);
              }, error => {
                this.spinner.hide();
                this.showError(error.error);
              }
            );
          }
        }
        this.spinner.hide();
      });
  }

  // must load them one by one, because getActiveSheet will move after each new sheet
  loadSheetsFromResponse(data, i) {
    let el = data[i]
    if (el) {

      let newSheet = new Sheet(el['name']);
      newSheet.dateOfLastSave = el.sheet.dateOfLastSave;
      newSheet.version = el.sheet.version;
      newSheet.transientFields.isLoadedFromFile = true;
      this.sheetService.addSheet(newSheet, el.sheet.calculationModel, el.sheet.distanceUnit,
        el.sheet.currencyUnit);
      setTimeout(() => {
        this.getActiveSheet().loadDataFromServer(el, {});
        this.loadTabData();
        this.loadSheetsFromResponse(data, i + 1);
        // Do not reset the currency of existing sheets -- currency setting will only be applied for new sheets
        // [VNP 2020-11-13]
        //this.sheetService.resetCurrencyOnSheet(this.getActiveSheet().getSheet());
      }, 0);
    }
  }

  openParamsConfig() {
    const dialogRef = this.paramConfigDialog.open(ParamConfigComponent, { width: "85vw", minWidth: "1200px", maxWidth: "1400px", panelClass: "cce-dialog-container" });

    dialogRef.afterClosed().subscribe(result => {
      this.userPreferencesService.loadUserPreferences().subscribe(prefs => {
        let newCurrCode = prefs.currency;
        let newCurrID = getCurrencyIDByAbbr(newCurrCode);
        // Do not reset the currency of existing sheets -- currency setting will only be applied for new sheets
        // [VNP 2020-11-13]
        //this.sheetService.resetCurrencyOfAllSheets(newCurrID);
      });
    });
  }

  private getActiveSheet(): SheetComponent {
    return this.sheetComponents.find((_, i) => i === this.selectedSheetIdx);
  }

  newBatchCalculation() {
    const dialogRef = this.newBatchCalcDialog.open(NewBatchCalculationComponent, {
      width: "85vw", minWidth: "1200px", panelClass: "cce-dialog-container-breadcrump", data: {
        distanceID: this.getActiveSheet().getSheet().distanceID,
        currencyID: this.getActiveSheet().getSheet().currencyID
      }
    }).afterClosed().subscribe(response => {
      if (response !== undefined) {
        this.sshow('addCar');
        this.getActiveSheet().addCar(response.cars, response.scens).subscribe(data => {
          console.log('DONE!');
          this.shide('addCar');
        },
          error => {
            console.log("error", error);
            let errorMsg = "Error adding vehicle calculation";
            if (!!error && !!error.error && !!error.error.status) {
              errorMsg = error.error.status;
            }
            this.messageService.showMessage(errorMsg);
            this.shide('addCar');
          });
      }
    });
  }

  isActiveInView(buttonNumber): string {
    return this.typeView === buttonNumber ? 'accent' : '';
  }

  isViewActive(viewNumber): boolean {
    return this.typeView === viewNumber;
  }

  changeView(viewNumber) {
    const sheetComp = this.getActiveSheet();
    if (sheetComp) {
      const calcSheet = sheetComp.getTCOComp();
      const equipment = sheetComp.getEquipmentComp();
      const maintanance = sheetComp.getMaintenanceComp();

      let allCols;
      if (this.typeView === 0) {
        sheetComp.expandedOldRowsTCO = calcSheet.getExpandedRows();
        allCols = calcSheet.gridOptions.columnApi.getAllGridColumns();
      } else if (this.typeView === 1) {
        sheetComp.expandedOldRowsEquip = equipment.getExpandedRows();
        allCols = equipment.gridOptions.columnApi.getAllGridColumns();
      } else if (this.typeView === 2) {
        sheetComp.expandedOldRowsMaint = maintanance.getExpandedRows();
        allCols = maintanance.gridOptions.columnApi.getAllGridColumns();
        if (sheetComp.getSheet().columnChangedfromMaintenance.size > 0) {
          this.loadCalc(sheetComp.getSheet());
        }
      } else {
        allCols = undefined;
      }
      if (allCols) {
        let indexOfMap = 0;
        for (const col of allCols) {
          if (col['colDef'].field !== "") {
            let width = col['actualWidth'];
            this.columnsWidths.set(indexOfMap, width);
            indexOfMap++;
          }
        }
      }
    }
    this.typeView = viewNumber;
  }

  isVisibleCalcButton() {
    if (this.getActiveSheet() === undefined) {
      return false;
    }
    return this.sheetService.isDirty(this.getActiveSheet().getSheet());
  }

  getRefreshButtonState() {
    this.isVisibleRefreshButton = this.getActiveSheet().getSheet().transientFields.isLoadedFromFile
      && !this.getActiveSheet().getSheet().transientFields.isRefreshed;
  }

  getClassEQActiv(type): string {
    if ((this.getActiveSheet() === undefined && type === this.TYPE_EQ_ALL) ||
      (this.getActiveSheet() && type === this.getActiveSheet().getSheet().equipmentFilteringMode)) {
      return 'active-mode-eq';
    } else {
      return 'notactive-mode-eq';
    }
  }

  getClassTradeActiv(type): string {
    if ((this.getActiveSheet() === undefined && type === this.ACTIVE_TRADE) ||
      (this.getActiveSheet() && type === this.getActiveSheet().getSheet().residualType)) {
      return 'active-mode-trade';
    } else {
      return 'notactive-mode-trade';
    }
  }

  getClassSumActiv(type): string {
    if ((this.getActiveSheet() === undefined && type === this.ACTIVE_TOTAL) ||
      (this.getActiveSheet() && type === this.getActiveSheet().getSheet().viewingMode)) {
      return 'active-mode-sum';
    } else {
      return 'notactive-mode-sum';
    }
  }

  changeResidualButtonsView(type) {
    if (this.sheetComponents && type !== this.getActiveSheet().getSheet().residualType && this.getActiveSheet().getSheet().columnIds.length > 0) {
      this.localStorageService.add(this.getActiveSheet().getSheet(), this.LOCATE_GENERAL_CHENGE);
      this.getActiveSheet().getSheet().residualType = type;
      this.sheetService.markSheetAsDirty(this.getActiveSheet().getSheet());
      this.loadCalc(this.getActiveSheet().getSheet(), true);
    }
  }

  clickToChangeVMB(type) {
    let event;
    switch (type) {
      case this.ACTIVE_KM:
        event = "Km";
        break;
      case this.ACTIVE_MONTH:
        event = "Month";
        break;
      case this.ACTIVE_TOTAL:
        event = "Total";
        break;
    }
    let additionalObj = {
      eventKey: "view" + event
    }
    let endpoint = '/cce/transactions/view' + event;
    this.mixPanelServ.logData(additionalObj, endpoint);

    this.changeViewingModeButton(type);
  }

  changeViewingModeButton(type) {
    this.getActiveSheet().getSheet().viewingMode = type;
    let sheet = this.getActiveSheet();
    if (sheet && sheet.getTCOComp()) {
      this.columnsWidths = sheet.getTCOComp().getColumnWidths();
      sheet.getTCOComp().reloadData();
      sheet.getTCOComp().reloadWidths(this.columnsWidths);
    }
  }

  changeTab() {
    this.getActiveSheet().getTCOComp().reloadData();
  }

  changeEQView(type) {
    this.getActiveSheet().getSheet().equipmentFilteringMode = type;
    this.getActiveSheet().getEquipmentComp().setFilterType(type, this.selectedSheetIdx);
  }

  backStep() {
    let numberOfSheet = this.getActiveSheet().getSheet().numberOfSheet;
    let pop = this.localStorageService.pop(this.typeView, numberOfSheet);
    if (!!pop) {
      this.sshow('backStep');
      pop.subscribe((data: UndoStorage) => {
        if (data) {
          this.restorefieldsType(data.sheet, this.getActiveSheet().getSheet());
          this.loadTabData();
          this.getActiveSheet().reloadComponents();
        }
        this.shide('backStep');
      },
        error => {
          console.log("error by backStep")
          this.shide('backStep');
        });
    }

  }

  restorefieldsType(oldSheet: any, newSheet: Sheet) {

    newSheet.columnIds = oldSheet.columnIds

    //Map with number key and Inner Maps
    oldSheet.userChangesBeforeLastCall = new Map(lodash.toPairs(oldSheet.userChangesBeforeLastCall));
    let temp = new Map();
    let tempOuther: Map<number, any> = new Map();
    oldSheet.userChangesBeforeLastCall.forEach((value: any, key: number) => {
      Object.entries(value)
        .forEach(([keyInner, valueInner]) => temp.set(keyInner, valueInner))
      tempOuther.set(Number(key), temp);//Outher key is number!!!
      temp = new Map();
    });
    newSheet.userChangesBeforeLastCall = tempOuther;

    //Map with number key and Inner Maps
    tempOuther = new Map();
    oldSheet.userChangesAfterLastCall = new Map(lodash.toPairs(oldSheet.userChangesAfterLastCall));
    oldSheet.userChangesAfterLastCall.forEach((value: any, key: number) => {
      Object.entries(value)
        .forEach(([keyInner, valueInner]) => temp.set(keyInner, valueInner))
      tempOuther.set(Number(key), temp);//Outher key is number!!!
      temp = new Map();
    });
    newSheet.userChangesAfterLastCall = tempOuther;

    //Map with number key and Inner Sets
    let tempSet: Set<number>;
    oldSheet.partDetailsTCOChanges = new Map(lodash.toPairs(oldSheet.partDetailsTCOChanges));
    oldSheet.partDetailsTCOChanges.forEach((value, key) => {
      tempSet = new Set(value);
      newSheet.partDetailsTCOChanges.set(Number(key), tempSet);
    });

    //Map with number key and Inner Maps
    oldSheet.allChangesMaintenance = new Map(lodash.toPairs(oldSheet.allChangesMaintenance));
    oldSheet.allChangesMaintenance.forEach((value, key) => {
      Object.entries(value)
        .forEach(([keyInner, valueInner]) => temp.set(keyInner, valueInner))
      newSheet.allChangesMaintenance.set(key, temp);
      temp = new Map();
    });

    //Set of numbers
    if (oldSheet.columnChangedfromMaintenance !== undefined) {
      newSheet.failedColumns = new Set();
      oldSheet.columnChangedfromMaintenance.forEach(item => newSheet.columnChangedfromMaintenance.add(Number(item)));
    }
    if (oldSheet.columnChangedfromEquipment !== undefined) {
      newSheet.failedColumns = new Set();
      oldSheet.columnChangedfromEquipment.forEach(item => newSheet.columnChangedfromEquipment.add(Number(item)));
    }

    newSheet.isMaintenanceDefault = oldSheet.isMaintenanceDefault;
    newSheet.changePartsDescription = new Set(oldSheet.changePartsDescription);
    newSheet.benchmarkColumnId = oldSheet.benchmarkColumnId;
    newSheet.saveId = oldSheet.saveId;
    newSheet.title = oldSheet.title;
    newSheet.active = oldSheet.active
    newSheet.dataTCO = oldSheet.dataTCO
    newSheet.dataEquipment = oldSheet.dataEquipment
    newSheet.dataMaintenance = oldSheet.dataMaintenance
    newSheet.residualType = oldSheet.residualType
    // newSheet.viewingMode = oldSheet.viewingMode
    // newSheet.equipmentFilteringMode = oldSheet.equipmentFilteringMode
    newSheet.locked = oldSheet.locked
    newSheet.calcModel = oldSheet.calcModel
    newSheet.canSeePrice = new Map(Object.entries(oldSheet.canSeePrice));

    //Map with number key
    oldSheet.exchangeRates = new Map(lodash.toPairs(oldSheet.exchangeRates));
    oldSheet.exchangeRates.forEach((value, key) => {
      newSheet.exchangeRates.set(Number(key), value);
    });

    // //Map with number key
    // oldSheet.requestMetadata = new Map(lodash.toPairs(oldSheet.requestMetadata));
    // oldSheet.requestMetadata.forEach((value, key) => {
    //   newSheet.requestMetadata.set(Number(key), value);
    // });
    newSheet.requestMetadata = oldSheet.requestMetadata;
    newSheet.currencyID = oldSheet.currencyID
    newSheet.distanceID = oldSheet.distanceID
    newSheet.dateOfLastSave = oldSheet.dateOfLastSave

    //Set of numbers
    if (oldSheet.failedColumns !== undefined) {
      newSheet.failedColumns = new Set();
      oldSheet.failedColumns.forEach(item => newSheet.failedColumns.add(Number(item)));
    }

    newSheet.version = oldSheet.version
    newSheet.transientFields = oldSheet.transientFields

  }

  loadOrigData() {
    if (this.getActiveSheet().getTCOComp()) {
      this.localStorageService.add(this.getActiveSheet().getSheet(), this.TYPE_VIEW_TCO);
      this.getActiveSheet().getTCOComp().reloadOrigData();
    }
  }

  refreshSheet() {
    let currentSheet = this.getActiveSheet().getSheet();
    if (currentSheet.checkForChanges()) {
      this.optionDialog.open(DialogOptionChooseComponent).afterClosed().subscribe(isWithChange => {
        if (isWithChange === Constants.WITH_CHANGE) {
          let newSheet = this.sheetService.addFilledSheet(currentSheet);
          //add all cols for recalculate in equipment because after calc will clean the set
          newSheet.columnChangedfromEquipment = new Set(newSheet.columnIds);
          setTimeout(() => {
            this.loadCalc(currentSheet);
            this.getRefreshButtonState();
          }, 0);
        }

        if (isWithChange === Constants.WITHOUT_CHANGE) {
          this.loadInNewSheet(currentSheet);
        }
      });
    } else {
      this.loadInNewSheet(currentSheet);
    }
  }

  removeDuplicateColumn(sheet) {
    let natcodesSet: Set<String> = new Set();
    let path;
    for (let index = sheet.columnIds.length - 1; index >= 0; index--) {
      const id = sheet.columnIds[index];
      path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'id\')]' + '.values[\'' + id + '\']';
      let natcode = this.jp.value(sheet.dataTCO, path);
      if (natcodesSet.has(natcode)) {
        sheet.columnIds.splice(index, 1);
      } else {
        natcodesSet.add(natcode);
      }
    }
  }

  loadInNewSheet(currentSheet, scenario?) {
    let oldSheet: Sheet = currentSheet;
    if (scenario) {// add new scenario in sheet before recalculate
      currentSheet = lodash.cloneDeep(currentSheet);
      this.removeDuplicateColumn(currentSheet);
      setSheetScenario(currentSheet, scenario);
    }
    // console.log(scenario, "currentSheet", currentSheet);
    const newSheet = new Sheet(currentSheet.title /* + statuslable*/);
    newSheet.transientFields.isLoadedFromFile = true;
    newSheet.transientFields.isRefreshed = true;
    this.spinner.show();
    this.sheetService.addSheet(newSheet, currentSheet.calcModel, currentSheet.distanceID, currentSheet.currencyID);
    setTimeout(() => {
      this.getActiveSheet().loadInNewSheet(oldSheet, currentSheet).subscribe(
        (response) => {
          this.spinner.hide();
          this.getRefreshButtonState();
          this.locked = this.lockedOff;
        }, error => {
          this.removeSheet(this.selectedSheetIdx);
          console.log("error", error);
          let errorMsg = "Error with calculation";
          if (!!error && !!error.error && !!error.error.status) {
            errorMsg = error.error.status;
          }
          this.messageService.showMessage(errorMsg);
          this.spinner.hide();
        }
      );
    }, 0);
  }

  loadCalc(oldSheet: Sheet, stopStorage?: boolean) {
    if (!oldSheet) {
      oldSheet = lodash.cloneDeep(this.getActiveSheet().getSheet());
    } else {
      oldSheet = lodash.cloneDeep(oldSheet);
    }

    const calcSheet = this.getActiveSheet().getTCOComp();
    if (!!calcSheet) {
      calcSheet.stopEditing();
    }
    // this.columnsWidths = calcComponent.getColumnWidths();
    this.spinner.show();
    this.getActiveSheet().loadCalc(oldSheet, stopStorage).subscribe(res => {
      this.spinner.hide();
      // calcComponent.reloadWidths(this.columnsWidths);
    }, error => {
      console.log("error", error);
      let errorMsg = "Error with recalculation";
      if (!!error && !!error.error && !!error.error.status) {
        errorMsg = error.error.status;
      }
      this.messageService.showMessage(errorMsg);
      this.spinner.hide();
    });
  }

  setLock() {
    if (this.getActiveSheet().getSheet().locked) {
      this.getActiveSheet().getSheet().locked = false;
      this.locked = this.lockedOn;
    } else {
      this.getActiveSheet().getSheet().locked = true;
      this.locked = this.lockedOff;
    }
    if (!!this.getActiveSheet().getTCOComp()) {
      this.getActiveSheet().getTCOComp().refreshGrid();
    }
  }

  getLockedState() {
    if (this.getActiveSheet().getSheet().locked) {
      this.locked = this.lockedOff;
    } else {
      this.locked = this.lockedOn;
    }
  }

  getClassNameWithIndex(index) {
    //return 'calc-sheet' + index;
    return (this.isPDF > 0) ? 'pdf-zoom calc-sheet' + index : 'calc-sheet' + index;
  }

  menuClosed() {
    let prefs = this.userPreferencesService.getUserPreferencesNow();
    let correctModel = copyCalcModel(this.calcModel.calcModel);
    if (!!correctModel) {
      correctModel.correctModel();
    }
    prefs.calcModel = correctModel;
    // console.log(prefs, 'onMenuClosed');
    this.userPreferencesService.saveUserPreferences(prefs).subscribe(data => {
      this.calcModel.calcModel = data.calcModel;
    });

  }


  showError(msg) {
    this.messageService.showMessage(msg);

  }

  saveToDevice() {
    if (!this.accountService.hasPermission(this.PERMISION_SAVE_LOCAL)) {
      this.messageService.showMessage("You don't have permission to save sheets to device!");
      return;
    }

    if (this.sheetService.isDirty(this.getActiveSheet().getSheet())) {
      this.messageService.showMessage("You cannot save sheets that need recalculation!");
      return;
    }
    this.cceFilesService.saveSheetToDevice(this.getActiveSheet());
  }

  saveCceDbFile() {
    let sheet = this.getActiveSheet().getSheet();

    if (!this.accountService.hasPermission(this.PERMISION_SAVE_REMOTE)) {
      this.messageService.showMessage("You don't have permission to save sheets to CCE!");
      return;
    }

    if (this.sheetService.isDirty(sheet)) {
      this.messageService.showMessage("You cannot save sheets that need recalculation!");
      return;
    }

    let dialogRef;
    dialogRef = this.fileShereDialog.open(FileShareComponent, {
      width: "85vw", minWidth: "1200px", maxWidth: "1400px", panelClass: "cce-dialog-container", data: {
        isForSave: true, fileName: sheet['title']
      },
    })
      .afterClosed().subscribe(response => {
        if (response) {
          let rows = response[0];
          let fileName = response[1];
          let recordId;
          if (!!rows && rows.length > 0) {
            if (fileName === rows[0]["name"]) {
              recordId = rows[0]["_id"];
            }
          }

          this.spinner.show();
          this.cceFilesService.saveCceFiles(this.getActiveSheet().getSheet(), recordId, fileName).subscribe(
            data => {
              let additionalObj = {
                eventKey: "SaveFile"
              }
              let endpoint = '/cce/transactions/SaveFile';
              this.mixPanelServ.logData(additionalObj, endpoint);

              this.spinner.hide();
              let sheet = this.getActiveSheet().getSheet();
              sheet["saveId"] = data._id;
              this.messageService.showMessage("The sheet has been successfully saved!");
            },
            error => {
              this.spinner.hide();
              this.messageService.showMessage("Error during save!");
            });
        }
      });
  }

  expandCols() {
    if (this.typeView === 0) {
      let sheet = this.getActiveSheet().getTCOComp();
      sheet.expandAll();
    } else if (this.typeView === 1) {
      let equipment = this.getActiveSheet().getEquipmentComp();
      equipment.expandAll();
    } else if (this.typeView === 2) {
      let maintanance = this.getActiveSheet().getMaintenanceComp();
      maintanance.expandAll();
    }
  }

  collapseCols() {
    if (this.typeView === 0) {
      let sheet = this.getActiveSheet().getTCOComp();
      sheet.collapseAll();
    } else if (this.typeView === 1) {
      let equipment = this.getActiveSheet().getEquipmentComp();
      equipment.collapseAll();
    } else if (this.typeView === 2) {
      let maintanance = this.getActiveSheet().getMaintenanceComp();
      maintanance.collapseAll();
    }
  }

  finalizeTitleEdit(update: boolean, event: Event, sheet: Sheet): void {
    if (update) {
      let eref: ElementRef = new ElementRef(event.target);
      sheet.title = eref.nativeElement.value;
    }
    this.inTitleBoundClick = false;
  }

  onKeyUp(event: KeyboardEvent, sheet: Sheet) {
    if (event.key === "Enter") {
      this.finalizeTitleEdit(true, event, sheet);
    } else if (event.key === "Escape") {
      this.finalizeTitleEdit(false, event, sheet);
    }
  }

  focusOutFunction(event: FocusEvent, sheet: Sheet) {
    if (this.inTitleBoundClick) {
      this.finalizeTitleEdit(true, event, sheet);
    }
  }

  clickOnSheetTitle(event: MouseEvent, tabIndex: number) {
    let eref: ElementRef = new ElementRef(event.currentTarget);
    // first, change the active sheet (regardless if there is a second click or not)
    if (this.selectedSheetIdx != tabIndex) {
      this.selectedSheetIdx = tabIndex;
      this.loadTabData();
    }

    let sheet = this.getActiveSheet().getSheet();
    this.clickCount++;
    setTimeout(() => {
      if (this.clickCount === 2) {
        this.inTitleBoundClick = !this.inTitleBoundClick;
        let elem = eref.nativeElement.getElementsByTagName("INPUT")[0];
        elem.value = sheet.title;
        setTimeout(() => {
          elem.focus();
        }, 0);
      }
      this.clickCount = 0;
    }, 250);
  }

  openAll() {
    let comp = this.getActiveComponent();
    if (comp) {
      comp.theGrid.api.expandAll();
    }
  }
  closeAll() {
    let comp = this.getActiveComponent();
    if (comp) {
      comp.theGrid.api.collapseAll();
    }
  }

  getActiveComponent() {
    if (this.typeView === 0) {
      let sheet = this.getActiveSheet().getTCOComp();
      return sheet;
    } else if (this.typeView === 1) {
      let equipment = this.getActiveSheet().getEquipmentComp();
      return equipment;
    } else if (this.typeView === 2) {
      let maintanance = this.getActiveSheet().getMaintenanceComp();
      return maintanance;
    }
  }

}
