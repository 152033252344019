import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@environments/environment';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  data;
  esacoResponse;

  constructor(private http: HttpClient) {
    this.data = this.http.get<any>(environment.equipmentEndpoint);
  }

  loadBasket(): Observable<any> {
    return this.data;
  }

  loadEsacoClasses(): Observable<any> {
    if (this.esacoResponse) {
      return of(this.esacoResponse);
    } else {
      let esacoCall = this.http.get<any>(environment.esacosEndpoint).pipe(share());
      esacoCall.subscribe(res => {
        this.esacoResponse = res;
      });
      return esacoCall;
    }
  }
}
