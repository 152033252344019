<app-csr-image-url *ngIf="renderAs==='imageURL'" [params]="params"></app-csr-image-url>
<app-csr-currency *ngIf="renderAs==='currency'" [params]="params"></app-csr-currency>
<app-csr-distance *ngIf="renderAs==='distance'" [params]="params"></app-csr-distance>
<app-csr-distance-rate *ngIf="renderAs==='distanceRate'" [params]="params"></app-csr-distance-rate>
<app-csr-literal *ngIf="renderAs==='literal'" [params]="params"></app-csr-literal>
<app-csr-real *ngIf="renderAs==='real'" [params]="params"></app-csr-real>
<app-csr-tco-chart *ngIf="renderAs==='tcoChart'" [params]="params" #tcoData></app-csr-tco-chart>
<app-csr-parts-subgrid *ngIf="renderAs==='partsSubgrid'" [params]="params" [benchData]="benchData"></app-csr-parts-subgrid>




