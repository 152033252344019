import { MixPanelService } from '../../services/mix-panel.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { EquipmentService } from '@app/services/equipment.service';
import { SheetService } from '@app/services/sheet.service';
import { getCurrencySignByID, getDistanceUnitByID } from '@app/helpers/RemapUnitsUtils.ts';
import { UserPreferencesService } from '@app/services/user-preferences.service';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { calcPriceByRate } from '@app/helpers/utils.js';


import { environment } from '@environments/environment';
import { SheetComponent } from '../sheet/sheet.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-dialog-csv-export',
    templateUrl: './dialog-csv-export.component.html',
    styleUrls: ['./dialog-csv-export.component.scss']
})
export class DialogCsvExportComponent {

    parent;
    sheetComponent: SheetComponent;
    currencyUnit;
    distanceUnit;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private spinner: NgxSpinnerService, private http: HttpClient,
      private mixPanelServ: MixPanelService) {
        this.parent = data.parent;
        this.sheetComponent = data.sheetComponent;
        this.currencyUnit = getCurrencySignByID(this.sheetComponent.getSheet().currencyID);//TODO getFromSheet?
        this.distanceUnit = getDistanceUnitByID(this.sheetComponent.getSheet().distanceID);
    }

    csvLevel(aLevel:number) {
        let level = aLevel;
        if (level != 1 && level != 2) {
          level = 0;
        }

        this.spinner.show();

        let additionalObj = {
          eventKey: "exportCSV",
          level: level
        }
        let endpoint = '/cce/transactions/exportCSV';
        this.mixPanelServ.logData(additionalObj, endpoint);

        const sheet = this.sheetComponent.getSheet();
        // sheet.dataMaintenance.servicePlan.children[0].values = this.servicePlanPriceConversion(sheet.dataMaintenance.servicePlan.children[0].values);
        // sheet.dataMaintenance.additionalTasks.children[0].values = this.additionalTaskPriceConversion(sheet.dataMaintenance.additionalTasks.children[0].values);
        // sheet.dataMaintenance.wearParts.children[0].values = this.wearPartPriceConversion(sheet.dataMaintenance.wearParts.children[0].values);

        let url = environment.exportCSV  + '/' + level;
        this.http.post(url, {
            "colIds": sheet.columnIds,
            "dataTCO": sheet.dataTCO,
            "equipment": sheet.dataEquipment,
            // "maintenance": sheet.dataMaintenance,
            "currencyUnit": this.currencyUnit,
            "distanceUnit": this.distanceUnit
        }, {
            observe: 'response',
            responseType: 'blob'
        }).subscribe(response => {
            this.downloadFile(response.body, "TCO-Report.txt");
        }, error => {
            this.spinner.hide();
        });

    }

    downloadFile(data, fileName) {
        var a = document.createElement("a");
        document.body.appendChild(a);
        // a.style = "display: none";
        a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.spinner.hide();
        this.parent.closeCsvDialog();
    }
}


