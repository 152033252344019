<div class="head cce-row">
  <span>EXPORT SHEET TO PDF</span>
  <div class="close">
    <mat-icon class="gray" [mat-dialog-close]="true">cancel</mat-icon>
  </div>
</div>
<div class="content cce-row">
  <button (click)="download3ColPdf()">KEEP SAME VIEW</button>
  <button (click)="download5ColPdf()">5 VEHICLES / PAGE</button>
</div>

<!-- <button mat-menu-item >Keep View As Is</button>
  <button mat-menu-item >5 Calculations Per Page, Most Important Folders Open</button> -->
