import { Component, OnInit, ViewChild, destroyPlatform, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { formatCurrency, getNameOfColumn } from '@app/helpers/utils.js';
import { GridOptions } from 'ag-grid-community';
import { Sheet } from '@app/domain/sheet';
import { EqualizationResult } from '@app/domain/equalization-result';
import { isDataSource } from '@angular/cdk/collections';
import { EquipmentService } from '@app/services/equipment.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-car-equipment',
  templateUrl: './car-equipment.component.html',
  styleUrls: ['./car-equipment.component.scss']
})
export class CarEquipmentComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataFromMain: any,
    private equipmentService: EquipmentService, private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<CarEquipmentComponent>) {
    const sheet = dataFromMain.sheet;
  }

  all = {};
  data = [];
  eqResults: EqualizationResult[] = [];
  equipmentPerColumnById = [];
  equipmentPerColumnByClass = [];
  classNames = {};
  colIds = [];
  finalNeeded = [];
  finalAmbigious = [];
  finalEsacos = [];
  finalUnavailable = [];
  colNames = [];
  esacos = {};
  selectedCol = -1;
  matchTypes = ['omit', 'closest price', 'lowest price', 'highest price'];
  match = this.matchTypes[0];
  // modeTypes = ['push only', 'pull only', 'push, then pull', 'pull, then push', 'complete'];
  modeTypes = ['push, then pull', 'pull, then push'];
  mode = this.modeTypes[0];
  semaphor: number = 0;


  touchArrays() {
    this.finalNeeded = [];
    this.finalEsacos = [];
    this.finalAmbigious = [];
    this.finalUnavailable = [];
    this.colIds.forEach(idx => {
      this.finalNeeded[idx] = [];
      this.finalEsacos[idx] = [];
      this.finalAmbigious[idx] = [];
      this.finalUnavailable[idx] = [];
      this.finalNeeded[idx] = this.showNeeded(idx);
      this.finalEsacos[idx] = this.sortEsacoObj(this.eqResults[idx].ambiguous);
      this.finalEsacos[idx].forEach(key => {
        this.finalAmbigious[idx][key] = this.sortOptions(this.eqResults[idx].ambiguous[key]);
      });
      this.finalUnavailable[idx] = this.showMissing(idx);
    })

  }


  ngOnInit(): void {
    console.log('fromMain', this.dataFromMain);
    this.match = this.matchTypes[0];
    this.mode = this.modeTypes[0];
    this.show();
    this.initializeData();
    this.equipmentService.loadEsacoClasses().subscribe(r => {
      this.esacos = r.esacos;
      this.shide();
      this.onModeChange(this.mode);
    }, err => {
      console.log(err);
      this.shide();
    });
  }

  onModeChange(val) {
    this.mode = val;
    this.initializeData();
    switch (val) {
      case 'push only':
        this.doPush();
        break;
      case 'pull only':
        this.doPull();
        break;
      case 'push, then pull':
        this.doPush();
        this.doPull();
        break;
      case 'pull, then push':
        this.doPull();
        this.doPush();
        break;
      case 'complete':
        this.doComplete();
        break;
      default:
        break;
    }
    this.touchArrays();
  }

  onMatchChange(val) {
    this.match = val;
    this.onModeChange(this.mode);
  }

  initializeData() {
    const sheet: Sheet = this.dataFromMain['sheet'];
    let equipment = sheet.dataEquipment;
    this.selectedCol = this.dataFromMain['ids'];
    let colIdsFromMain = sheet.columnIds;
    let idx = colIdsFromMain.indexOf(this.selectedCol);
    this.colIds = [];
    this.colIds.push(colIdsFromMain[idx]);
    colIdsFromMain.forEach(element => {
      this.colNames[element] = getNameOfColumn(sheet.dataTCO, element);
      if (element !== this.selectedCol) {
        this.colIds.push(element);
      }
    });

    this.colIds.forEach(element => {
      this.finalNeeded[element] = [];
      this.finalEsacos[element] = [];
      this.finalAmbigious[element] = [];
      this.finalUnavailable[element] = [];
      this.equipmentPerColumnById[element] = {};
      this.equipmentPerColumnByClass[element] = {};
      this.eqResults[element] = new EqualizationResult();
      this.eqResults[element].sheetId = element;
      this.eqResults[element].needed = new Set();
      this.eqResults[element].ambiguous = {};
      this.eqResults[element].missing = new Set();
    });

    equipment.children.forEach(subzone1 => {
      subzone1.children.forEach(subzone2 => {
        subzone2.children.forEach(subzone3 => {
          this.colIds.forEach(colId => {
            let colArray = subzone3.values[colId];
            if (colArray && colArray != undefined && colArray.length > 0) {
              colArray.forEach(opt => {
                if (this.equipmentPerColumnById[colId][opt.id] === undefined) {
                  this.equipmentPerColumnById[colId][opt.id] = { ...opt };
                  opt.classIds.forEach(element => {
                    if (this.equipmentPerColumnByClass[colId][element] === undefined) {
                      this.equipmentPerColumnByClass[colId][element] = [];
                    }
                    this.equipmentPerColumnByClass[colId][element].push(opt.id);
                  });
                }
              });
            }
          });
        });
      });
    });
  }

  doComplete() {
    let hasChangesAfterPush = false;
    let hasChangesAfterDisambiguation = false;
    let ambiguousMap = {};
    let srcItems = this.equipmentPerColumnById[this.selectedCol];
    do {
      hasChangesAfterDisambiguation = false;
      do {
        // perform push from each to each :)
        hasChangesAfterPush = false;
        this.colIds.forEach(srcId => {
          this.colIds.forEach(dstId => {
            if (srcId !== dstId) {
              hasChangesAfterPush = this.doSinglePushWithMemory(srcId, dstId) || hasChangesAfterPush;
            }
          });
        });
      } while (hasChangesAfterPush);

      ambiguousMap = this.purgeAmbiguousClassesWithMemory(ambiguousMap);

      if ('omit' != this.match) {
        hasChangesAfterDisambiguation = this.performDisambiguation() || hasChangesAfterDisambiguation;
      }
    } while (hasChangesAfterDisambiguation);
  }

  doSinglePushWithMemory(sourceId, targetId) {
    return this.doSinglePush(sourceId, targetId);
  }

  doSinglePush(sourceId, targetId) {
    let countSuggested = true;
    console.log('push from ' + sourceId + ' to ' + targetId);
    let srcIncludedItemIds = Object.keys(this.equipmentPerColumnById[sourceId])
      .filter(key => this.isIncluded(this.equipmentPerColumnById[sourceId][key], true));
    // console.log(srcIncludedItemIds.map(i => this.equipmentPerColumnById[sourceId][i]));
    let hasNeededItems = false;
    srcIncludedItemIds.forEach(srcItemId => {
      let srcItem = this.equipmentPerColumnById[sourceId][srcItemId];
      let dstItem = this.equipmentPerColumnById[targetId][srcItemId];
      // console.log('for item', srcItem);
      if (dstItem) {
        // console.log('target exists ', dstItem);
        if (!this.isIncluded(dstItem, true)) {
          // item is available, but is not included
          dstItem.isOptionSuggested = true;
          this.eqResults[targetId].needed.add(dstItem);
          hasNeededItems = true;
        }
      } else {
        // item is not available, reading for match in its class(es)
        srcItem.classIds.forEach(cl => {
          let itemsForClass = this.equipmentPerColumnByClass[targetId][cl];
          // console.log('looking for items in target class ', itemsForClass);
          if (!itemsForClass || itemsForClass.length === 0) {
            // no items for the class
            // console.log('class missing ', cl);
            this.eqResults[targetId].missing.add(cl);
          } else if (itemsForClass && itemsForClass.length === 1) {
            // single item for the class
            let classsDstItem = this.equipmentPerColumnById[targetId][+itemsForClass[0]];
            // console.log('found item in class ', classsDstItem);
            if (!this.isIncluded(classsDstItem, true)) {
              classsDstItem.isOptionSuggested = true;
              this.eqResults[targetId].needed.add(classsDstItem);
              hasNeededItems = true;
            }
          } else {
            // many items for the class
            let classItems = itemsForClass.map(k => this.equipmentPerColumnById[targetId][+k]);
            // console.log('found many items for class', classItems);
            if (!this.isAnyIncluded(classItems, true) && (this.eqResults[targetId].ambiguous[cl] === undefined)) {
              this.eqResults[targetId].ambiguous[cl] = classItems;
              hasNeededItems = true;
            }
          }
        });
      }
    });

    console.log('after push', this.eqResults);
    return hasNeededItems;
  }

  purgeAmbiguousClassesWithMemory(ambiguousMap) {
    let newMemory = {};
    this.colIds.forEach(idx => {
      let ambiguousAfterPush = this.eqResults[idx].ambiguous;
      Object.keys(ambiguousAfterPush).forEach(cl => {
        if (ambiguousMap && ambiguousMap[idx] && ambiguousMap[idx][cl]) {
          // do nothing
        } else {
          if (this.isAnyIncluded(ambiguousAfterPush[cl], true)) {
            delete ambiguousAfterPush[cl];
          }
        }
      });
      newMemory[idx] = ambiguousAfterPush;
    });
    return newMemory;
  }

  // purgeAmbiguousClasses() {
  //   this.eqResults.forEach(resColumn => {
  //     let ambiguous = resColumn.ambiguous;
  //     Object.keys(ambiguous).forEach(cl => {
  //       if (this.isAnyIncluded(ambiguous[cl], true)) {
  //         delete ambiguous[cl];
  //       }
  //     });
  //   });
  // }

  show(reason?) {
    console.log('showing for ' + reason, this.semaphor, this.semaphor + 1)
    this.spinner.show();
    this.semaphor++;
  }


  shide(reason?) {
    console.log('hiding for ' + reason, this.semaphor, this.semaphor - 1)
    this.semaphor--;
    if (this.semaphor <= 0) {
      this.semaphor = 0;
      this.spinner.hide();
    }
  }

  performDisambiguation() {
    let hasNeededItems = false;
    this.eqResults.forEach(resColumn => {
      let ambiguous = resColumn.ambiguous;
      Object.keys(ambiguous).forEach(cl => {
        let possibleItems = ambiguous[cl];
        if (this.isAnyIncluded(possibleItems, false)) {
          return;
        } else {
          let selectedItem: any;
          switch (this.match) {
            case 'omit':
              break;
            case 'closest price':
              selectedItem = this.getByClosestPrice(possibleItems, cl);
              break;
            case 'lowest price':
              selectedItem = this.getByLowestPrice(possibleItems);
              break;
            case 'highest price':
              selectedItem = this.getByHighestPrice(possibleItems);
              break;
            case 'median price':
              selectedItem = this.getByMedianPrice(possibleItems);
              break;
            default:
              break;
          }
          if (selectedItem) {
            selectedItem.isOptionalIncluded = true;
            selectedItem.isOptionSuggested = true;
            // resColumn['needed'].add(selectedItem);
            hasNeededItems = true;
          }
        }
      });
    });
    return hasNeededItems;
  }

  private doPush() {
    this.doSafePush();
    // if (doPurge) {
    //   this.purgeAmbiguousClasses();
    // }
    if ('omit' != this.match) {
      this.performDisambiguation();
    }
  }

  private doSafePush() {
    let hasNeededItems = false;
    this.colIds.forEach(id => {
      if (id !== this.selectedCol) {
        hasNeededItems = this.doSinglePush(this.selectedCol, id) || hasNeededItems;
      }
    });
    return hasNeededItems;
  }

  doPull() {
    this.doSafePull();
    // if (doPurge) {
    //   this.purgeAmbiguousClasses();
    // }
    if ('omit' != this.match) {
      this.performDisambiguation();
    }
  }

  private doSafePull() {
    let hasNeededItems = false;
    this.colIds.forEach(id => {
      if (id !== this.selectedCol) {
        hasNeededItems = this.doSinglePush(id, this.selectedCol) || hasNeededItems;
      }
    });
    return hasNeededItems;
  }



  isAnyIncluded(opt: any[], countSuggested: boolean = false) {
    return opt.some(element => this.isIncluded(element, countSuggested));
  }

  isIncluded(opt: any, countSuggested: boolean) {
    return !!opt.isStandard || !!opt.isOptionalIncluded || (!!countSuggested && !!opt.isOptionSuggested);
  }

  getByLowestPrice(opt: any[]) {
    if (opt && opt.length > 0) {
      let tmp = opt[0];
      opt.forEach(element => {
        if (element.price < tmp.price) {
          tmp = element;
        }
      });
      return tmp;
    } else {
      return null;
    }
  }

  getByHighestPrice(opt: any[]) {
    if (opt && opt.length > 0) {
      let tmp = opt[0];
      opt.forEach(element => {
        if (element.price > tmp.price) {
          tmp = element;
        }
      });
      return tmp;
    } else {
      return null;
    }
  }

  getByMedianPrice(opt: any[]) {
    if (opt && opt.length > 0) {
      let sorted = opt.sort((o1, o2) => o1.price - o2.price);
      return sorted[Math.floor(opt.length / 2)];
    } else {
      return null;
    }
  }

  getMaxPriceByClass(colId: number, cl: string) {
    let maxPrice = 0;
    let options = [];
    this.equipmentPerColumnByClass[colId][cl].forEach(element => {
      let anOption = this.equipmentPerColumnByClass[colId][+element];
      if (anOption) {
        options.push(anOption);
      }
    });
    if (options.length > 0) {
      options.forEach(opt => {
        if (this.isIncluded(opt, false) && opt.price > maxPrice) {
          maxPrice = opt.price;
        }
      });
    }
    return maxPrice;
  }

  getByClosestPrice(opt: any[], cl: string) {
    let referencePrice = 0;
    if (!!this.equipmentPerColumnByClass[this.selectedCol][cl] && this.equipmentPerColumnByClass[this.selectedCol][cl].length > 0) {
      referencePrice = this.getMaxPriceByClass(this.selectedCol, cl);
    } else {
      let cnt = 0;
      this.colIds.forEach(id => {
        if (id != this.selectedCol) {
          referencePrice = this.getMaxPriceByClass(id, cl) + referencePrice;
          cnt++;
        }
      });
      if (cnt !== 0) {
        referencePrice = referencePrice / cnt;
      }
    }

    let closestItem: any;
    let smallestDelta = Number.MAX_VALUE;
    opt.forEach(item => {
      let delta = Math.abs(item.price - referencePrice);
      if (smallestDelta > delta) {
        smallestDelta = delta;
        closestItem = item;
      }
    });
    return closestItem;
  }

  sortOptions(arr: any[]) {
    if (arr && arr.length > 1) {
      return arr.sort((a, b) => (a.descriptionShort > b.descriptionShort) ? 1 : ((b.descriptionShort > a.descriptionShort) ? -1 : 0));
    } else {
      return arr;
    }

  }

  showNeeded(idx) {
    let arr = Array.from(this.eqResults[idx].needed);
    return this.sortOptions(arr);
  }

  sortEsacos(arr: any[]) {
    let res = [];
    arr.forEach(x => {
      if (this.esacos[x]) {
        res.push(this.esacos[x]);
      } else {
        res.push(x);
      }
    });
    return res.sort();
  }

  sortEsacoObj(obj: any) {
    let arr = Object.keys(obj);
    return arr.sort((a, b) => (this.esacos[a] > this.esacos[b]) ? 1 : ((this.esacos[b] > this.esacos[a]) ? -1 : 0));
  }

  showMissing(idx) {
    let arr = Array.from(this.eqResults[idx].missing);
    return this.sortEsacos(arr);
  }

  formatCurrency(params) {
    return formatCurrency(params, this.dataFromMain.currencySymbol);
  }

  getStyle(item): string {
    return (item['selected']) ? 'cce-filter-item-selected' : 'cce-filter-item';
  }

  onApply() {
    this.dialogRef.close(this.eqResults);
  }

  onClose() {
    this.dialogRef.close();
  }

}

