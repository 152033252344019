import { MaintenanceChange } from './../../domain/MaintenanceChange';
import { MrAccordionComponent } from '@app/components/mr-accordion/mr-accordion.component';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ICellRendererParams, ColDef, GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { formatCurrency, getColIndex22ToLength } from '@app/helpers/utils.js';
import { getCurrencySignByID } from '@app/helpers/RemapUnitsUtils.ts';
import { TYPE_SERV_PLAN, TYPE_ADD_TASKS, TYPE_WEAR_PARTS } from '@app/objects//constants.ts';
import { Sheet } from '@app/domain/sheet';
import { IChangeDetect } from '@app/helpers/IChangeDetect';
import { CsrPartsSubgridInnerComponent } from '../csr-parts-subgrid-inner/csr-parts-subgrid-inner.component';
import { AgGridAngular } from 'ag-grid-angular';
import { ViewChild } from '@angular/core';
import * as lodash from 'lodash'; //copy array
import { v4 as uuid } from 'uuid';
import { Constants } from '@app/helpers/Constants';
import { LocalStorageService } from '@app/services/local-storage.service';

@Component({
  selector: 'app-mr-parts-subgrid',
  templateUrl: './mr-parts-subgrid.component.html',
  styleUrls: ['./mr-parts-subgrid.component.scss']
})
export class MrPartsSubgridComponent implements OnInit, IChangeDetect {

  @Input() data;
  @Input() params: ICellRendererParams;
  @Input() typeShow;
  @Input() acordion: MrAccordionComponent;
  @Input() acordionDiv;
  @ViewChild('theGrid') theGrid: AgGridAngular;
  typeShowName;
  private idsCol = 0;
  indexOfLastClickedRow;
  rowSelection;// for turn on tableSelection
  priceShow;
  showTable;
  refresh;

  sheet: Sheet;
  rowData: any = [];
  columnDefs: ColDef[] = [];
  gridOptions: GridOptions;

  ADD_TYPE = Constants.ADD_TYPE;
  DEL_TYPE = Constants.DEL_TYPE;
  MOD_TYPE = Constants.MOD_TYPE;

  constructor(private localStorageService: LocalStorageService) {
    let that = this;
    this.gridOptions = {
      context: this,
      defaultColDef: {
        width: 80,
        resizable: true
      }, onRowClicked: function onRowClicked(params) {
        that.indexOfLastClickedRow = params.rowIndex;
      }, onGridReady: function (event) {
        if (that.refresh) {
          that.setCellHightMRComp(201);
          that.refresh = false;
        }
      }
    };
    this.rowSelection = 'single';
  }

  onChange(params?: any) {
    this.sheet.columnChangedfromMaintenance.add(this.idsCol);
  }

  ngOnInit(refresh?): void {
    this.refresh = refresh;
    this.sheet = this.params.context.getDataSheet();
    const indexCol = getColIndex22ToLength(this.params.colDef.field);
    this.idsCol = this.sheet.columnIds[indexCol];

    this.rowData = [];

    let data;

    if (this.typeShow === TYPE_SERV_PLAN) {
      this.typeShowName = Constants.TYPE_SERV_PLAN_NAME;
      data = this.data.serviceParts;
    } else if (this.typeShow === TYPE_ADD_TASKS) {
      this.typeShowName = Constants.TYPE_ADD_TASKS_NAME;
      data = this.data.parts;
    }
    this.setShowTable();

    const make = String(this.params.context.getValueFromTCO('$[?(@.field==\'carSpecs\')].children[?(@.field==\'make\')]' + '.editData[\'' + this.idsCol + '\'].metadata.makeCode'));
    this.priceShow = this.sheet.canSeePrice.get(make);

    this.columnDefs = [];
    this.columnDefs.push({
      headerName: 'Part Description', field: 'partDescription',
      autoHeight: true,
      cellRendererFramework: CsrPartsSubgridInnerComponent,
      cellRendererParams: {
        descriptionField: 'partDescription',
        altDescriptionField: 'partAltDescription',
        partDataField: 'partData',
        sheet: this.sheet,
      },
      editable: this.priceShow,
      flex: 1, cellStyle: { 'text-align': 'left' },
      valueSetter: this.valueSetterDescr
    });

    if (this.priceShow) {
      this.columnDefs.push({
        headerName: 'Price', valueGetter: this.getValuesCol, valueFormatter: this.formatCurrency, type: 'rightAligned', editable: this.priceShow
        , valueSetter: this.valueSetterPrice

      });
    }
    this.columnDefs.push({
      headerName: 'Qty', valueGetter: this.getValuesQuan, valueFormatter: this.formatQuantity, type: 'rightAligned', editable: this.priceShow,
      valueSetter: this.valueSetterQty
    });

    for (const el of data) {
      this.rowData.push(el);
    }

  }



  getValuesCol(params) {
    if (params.data.partData[0] === undefined) {
      return '';
    }
    const ctx = params.context;
    if (ctx.typeShow === TYPE_SERV_PLAN) {
      return params.data.partData[0].price * ctx.sheet.exchangeRates.get(ctx.idsCol);
    } else if (ctx.typeShow === TYPE_ADD_TASKS) {
      return params.data.partData[0].price * ctx.sheet.exchangeRates.get(ctx.idsCol);
    }
  }

  getValuesQuan(params) {
    if (params.data.partData[0] === undefined) {
      return '';
    }
    const ctx = params.context;
    if (ctx.typeShow === TYPE_SERV_PLAN) {
      return params.data.partData[0].quantity;
    } else if (ctx.typeShow === TYPE_ADD_TASKS) {
      return params.data.partData[0].quantity;
    }
  }

  formatQuantity(params: ValueFormatterParams): string {
    if (params.data.partData[0].quantityUnitID === "1") {
      return params.value + " l";
    } else {
      return params.value;
    }
  }

  private formatCurrency(params) {
    return formatCurrency(params.value, getCurrencySignByID(params.context.sheet.currencyID));
  }

  valueSetterDescr(params) {
    let that = params.context;
    that.localStorageService.add(that.sheet, Constants.TYPE_VIEW_MAINTENANCE);

    params.context.sheet.changePartsDescription.delete(params.data.partDescription);
    params.context.sheet.changePartsDescription.add(params.newValue);
    let rowChanges;
    if (params.context.typeShow === TYPE_SERV_PLAN) {
      rowChanges = params.context.acordion.getRowChanges(params.context.data.partDescription, params.context.idsCol, params.context.data.id, params.context.MOD_TYPE, params.data, params.context.typeShowName);
    } else {
      rowChanges = params.context.acordion.getRowChanges(params.context.data.task, params.context.idsCol, params.context.data.id, params.context.MOD_TYPE, params.data, params.context.typeShowName);
    }
    params.data.partDescription = params.newValue;
    rowChanges[params.data.mastercode].new = lodash.cloneDeep(params.data);
    params.context.onChange();
    return true;
  }

  valueSetterPrice(params) {
    var newPrice = Number(params.newValue);
    var data = params.data.partData[0];

    if (newPrice.toString() === "NaN") {
      return false;
    } else if (data.price !== newPrice / params.context.sheet.exchangeRates.get(params.context.idsCol)) {
      let that = params.context;
      that.localStorageService.add(that.sheet, Constants.TYPE_VIEW_MAINTENANCE);

      let rowChanges;
      if (params.context.typeShow === TYPE_SERV_PLAN) {
        rowChanges = params.context.acordion.getRowChanges(params.context.data.partDescription, params.context.idsCol, params.context.data.id, params.context.MOD_TYPE, params.data, params.context.typeShowName);
      } else {
        rowChanges = params.context.acordion.getRowChanges(params.context.data.task, params.context.idsCol, params.context.data.id, params.context.MOD_TYPE, params.data, params.context.typeShowName);
      }

      data.price = newPrice / params.context.sheet.exchangeRates.get(params.context.idsCol);
      rowChanges[params.data.mastercode].new = lodash.cloneDeep(params.data);
      params.context.onChange();
      return true;
    }
    else {
      return false;
    }
  }

  valueSetterQty(params) {
    var quantity = Number(params.newValue);
    var data = params.data.partData[0];
    if (quantity.toString() === "NaN") {
      return false;
    } else if (data.quantity !== quantity) {
      let that = params.context;
      that.localStorageService.add(that.sheet, Constants.TYPE_VIEW_MAINTENANCE);

      let rowChanges;
      if (params.context.typeShow === TYPE_SERV_PLAN) {
        rowChanges = params.context.acordion.getRowChanges(params.context.data.partDescription, params.context.idsCol, params.context.data.id, params.context.MOD_TYPE, params.data, params.context.typeShowName);
      } else {
        rowChanges = params.context.acordion.getRowChanges(params.context.data.task, params.context.idsCol, params.context.data.id, params.context.MOD_TYPE, params.data, params.context.typeShowName);
      }
      data.quantity = quantity;
      rowChanges[params.data.mastercode].new = lodash.cloneDeep(params.data);
      params.context.onChange();
      return true;
    }
    else {
      return false;
    }
  }

  delSelectedRow() {
    if (undefined == this.theGrid) {
      return;
    }
    this.localStorageService.add(this.sheet, Constants.TYPE_VIEW_MAINTENANCE);

    if (this.theGrid.api.getSelectedRows() != null && this.theGrid.api.getSelectedRows() != undefined && this.theGrid.api.getSelectedRows().length > 0) {
      if (this.typeShow === TYPE_SERV_PLAN) {
        this.data.serviceParts.splice(this.indexOfLastClickedRow, 1);
        if (this.data.serviceParts.length <= 0 && this.showTable) {
          this.setShowTable();
          this.ngOnInit(true);
          this.setCellHightMRComp(-201);
        }
      } else if (this.typeShow === TYPE_ADD_TASKS) {
        this.data.parts.splice(this.indexOfLastClickedRow, 1);
        if (this.data.parts.length <= 0 && this.showTable) {
          this.setShowTable();
          this.ngOnInit(true);
          this.setCellHightMRComp(-201);
        }
      }
      let row = this.theGrid.api.getSelectedRows()[0];

      let rowChanges;
      if (this.typeShow === TYPE_SERV_PLAN) {
        rowChanges = this.acordion.getRowChanges(this.data.partDescription, this.idsCol, this.data.id, this.DEL_TYPE, row, this.typeShowName);
      } else {
        rowChanges = this.acordion.getRowChanges(this.data.task, this.idsCol, this.data.id, this.DEL_TYPE, row, this.typeShowName);
      }

      this.theGrid.api.applyTransaction({ remove: (this.theGrid.api.getSelectedRows()) });
      rowChanges[row.mastercode].new = null;
      this.onChange();
    }
  }

  pushNewRow() {
    this.localStorageService.add(this.sheet, Constants.TYPE_VIEW_MAINTENANCE);

    let newRow: any = {};

    newRow.mastercode = uuid();
    newRow.partAltDescription = '';
    newRow.partData = [{
      criteria: [],
      partNr: '',
      price: 0.0,
      priceHidden: '',
      quantity: 1,
      quantityUnitID: ''
    }];
    newRow.partDescription = 'New Part';

    let rowChanges;
    if (this.typeShow === TYPE_SERV_PLAN) {
      rowChanges = this.acordion.getRowChanges(this.data.partDescription, this.idsCol, this.data.id, this.ADD_TYPE, newRow, this.typeShowName);
    } else {
      rowChanges = this.acordion.getRowChanges(this.data.task, this.idsCol, this.data.id, this.ADD_TYPE, newRow, this.typeShowName);
    }
    rowChanges[newRow.mastercode].new = newRow;

    if (this.typeShow === TYPE_SERV_PLAN) {
      this.data.serviceParts.push(newRow);
      if (this.data.serviceParts.length > 0 && this.showTable == false) {
        this.ngOnInit(true);
      } else {
        this.theGrid.api.setRowData(this.data.serviceParts);
      }
    } else if (this.typeShow === TYPE_ADD_TASKS) {
      this.data.parts.push(newRow);
      if (this.data.parts.length > 0 && this.showTable == false) {
        this.ngOnInit(true);
      } else {
        this.theGrid.api.setRowData(this.data.parts);
      }
    }
    this.onChange();
  }

  isCellEditable() {
    return this.priceShow;
  }

  setShowTable() {
    if (this.typeShow === TYPE_SERV_PLAN) {
      this.showTable = this.data.serviceParts.length > 0;
    } else if (this.typeShow === TYPE_ADD_TASKS) {
      this.showTable = this.data.parts.length > 0;
    }
  }

  setCellHightMRComp(heightFix) {
    this.acordion.setSizeCell(this.acordionDiv, heightFix);
  }
}
