<div style=" height: 100%;">
  <ag-grid-angular #theGrid [ngStyle]="{'height' : isCellEditable() ?  '90%': '100%'}" class="ag-theme-balham-subGrid"
    [gridOptions]="gridOptions" [rowSelection]="rowSelection" [columnDefs]="columnDefs" [rowData]="rowData"></ag-grid-angular>
  <div *ngIf="isCellEditable()" style="background-color: white; height: 10%;">
    <button mat-button (click)="delSelectedRow()">
      <mat-icon>remove</mat-icon>
    </button>
    <button mat-button (click)="pushNewRow()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
