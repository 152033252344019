import { CalcModel } from './calc-model';

export class UserPreferences {

  calcModel: CalcModel = new CalcModel();
  filterState: string[] = [];
  filterCombos: any = {};
  filterValues: any = {};
  currency: string;
  language: string;
  currencyUnitId = 2;
  distanceUnitId = 3;
  defaultScenarioKM: any[] = [];
  defaultScenarioMl: any[] = [];

  constructor() {
    this.calcModel = new CalcModel();
    this.filterState = [];
    this.filterValues = {};
    this.filterCombos = {};
    this.currency = 'EUR';
    this.language = 'NONE';
    this.currencyUnitId = 2;
    this.distanceUnitId = 3;
    this.defaultScenarioMl = [];
    this.defaultScenarioKM = [];
  }
}
