<div class="cce-nbc-selection-body">
  <span *ngFor="let row of scenarioRows" class="cce-row scenarios">
    <div class="months cce-col" style=" flex: 1;">
      <span style="flex:1">{{ row.nodeName }}</span>
    </div>
    <div class="cce-col" style=" flex:7">
      <div style="display: flex; flex-flow: row wrap;">
        <span *ngFor="let child of row.children " class="cce-row">
          <span #spaner style="flex:1" (click)="toggleSelection(child)" [class]="getStyle(child)">
            {{ child.nodeName }}
          </span>
        </span>
      </div>
    </div>
  </span>
</div>
