import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { getColIndex22ToLength, getModifyState } from '@app/helpers/utils.js';
import { Constants } from '@app/helpers/Constants.ts';
import { metadata } from '@environments/environment'
import { Sheet } from '@app/domain/sheet';

@Component({
  selector: 'app-csr-literal',
  templateUrl: './csr-literal.component.html',
  styleUrls: ['./csr-literal.component.scss']
})
export class CsrLiteralComponent implements OnInit {

  @Input() params: ICellRendererParams;


  REF_RENDER = 1;
  NO_RENDER = 0;

  value: string;
  style: string;
  modified: number;
  indexCol: number;
  benchmarkColumnId: number;
  idsCol: number;
  colorSpan: number;
  metadataValue;
  render = 0;
  refImg: string;

  NO_MODIFIED_CELL = Constants.NO_MODIFIED_CELL;
  MODIFIED_CELL = Constants.MODIFIED_CELL;
  ERROR_MODIFIED_CELL = Constants.ERROR_MODIFIED_CELL;
  TEXT_COLOR_GREEN = Constants.TEXT_COLOR_GREEN;
  TEXT_COLOR_RED = Constants.TEXT_COLOR_RED;
  TEXT_COLOR_GRAY = Constants.TEXT_COLOR_GRAY;
  TEXT_COLOR_BLUE = Constants.TEXT_COLOR_BLUE;

  constructor() { }

  ngOnInit(): void {
    const sheet: Sheet = this.params.context.getDataSheet();
    this.indexCol = getColIndex22ToLength(this.params.colDef.field);
    const idsCol = this.params.context.getDataSheet().columnIds[this.indexCol];
    const ctx = this.params.context;
    const field = this.params.data.field;
    const editData = this.params.data.editData;
    this.style = metadata[field].style;
    this.idsCol = idsCol;
    this.metadataValue = editData[this.idsCol].metadata;


    this.benchmarkColumnId = ctx.getBenchMarkColumn();

    if (this.params.data.values[idsCol] === null || this.params.data.values[idsCol] === undefined ||
      this.params.data.values[idsCol] === '') {
      if (metadata[field].insuffDataWhenEmpty === true) {
        this.value = 'Insufficient data';
      } else {
        this.value = '';
      }
      if (this.params.data.values[this.idsCol] !== '') {
        this.modified = this.NO_MODIFIED_CELL;
        return;
      }
    }

    if (this.metadataValue !== undefined && this.metadataValue !== null && (this.metadataValue.ref === 'REFA' || this.metadataValue.ref === 'A' || this.metadataValue.ref === 'REFB' || this.metadataValue.ref === 'B')) {
      this.render = this.REF_RENDER;
      this.refImg = '../../assets/' + this.metadataValue.ref + '.png';
    }

    this.modified = getModifyState(editData[idsCol].modified, editData[idsCol].error);

    if (this.modified) {
      this.colorSpan = this.TEXT_COLOR_BLUE;
    }
    if (this.benchmarkColumnId >= 0 && this.benchmarkColumnId !== idsCol) {
      let bmValue = this.params.data.bmValues[idsCol];
      this.value = bmValue.value;
      if (!!bmValue.color) {
        this.colorSpan = bmValue.color
      }
    } else {
      this.value = this.params.data.values[idsCol];
    }

  }

  setOldValue() {
    this.params.context.setOldValueMainTable(this.params, this.idsCol, this.benchmarkColumnId);
  }

}
