import { Injectable, IterableDiffers } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { environment, filters } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { LabelType } from 'ng5-slider';
import { CarsSpecFilters } from '@app/domain/CarsSpecFilters';


@Injectable({
  providedIn: 'root'
})
export class SorcService {



  constructor(private http: HttpClient, private storage: StorageMap) { }

  loadDefaultBasket(): Observable<any> {
    if (sessionStorage.getItem('basket') === 'true') {
      console.log('User basket is already available!')
      return this.storage.get('basket');
    } else {
      console.log('User basket not yet available!')
      let path = environment.defaultBasketEndpoint;
      return this.http.get<any>(path);
    }
  }

  getScope(scope, nextField) {
    let params = new HttpParams();
    Object.keys(scope).forEach(scopeName => {
      params = params.append(scopeName, scope[scopeName]);
    });
    params = params.append('valuesFor', nextField);
    return this.http.get<any>(environment.combosEndpoint, { params: params });
  }

  loadFilters(): Observable<any> {
    return of(filters);
  }

  turnFilterToSpecRequest(filter, modifiedField, allCombos, currencyID) {
    let result = {};
    let criteria = {};
    let toFilter: string[] = [];
    let isFilter = true;
    Object.keys(filter).forEach(function (k) {
      // console.log(k, typeof(filter[k]));
      let currentCrit = filter[k];
      if (typeof (filter[k]) === 'object' && !(currentCrit instanceof Set)) {
        currentCrit = { ...filter[k] };
        delete (currentCrit['options']);
        criteria[k] = currentCrit;
      } else if (currentCrit instanceof Set) {
        criteria[k] = [];
        currentCrit.forEach(element => {
          criteria[k].push(element);
        });
      } else {
        criteria[k] = currentCrit;
      }
    });
    Object.keys(allCombos).forEach(function (k) {
      if (!modifiedField || isFilter == false) {
        delete (criteria[allCombos[k]]);
        toFilter.push(allCombos[k]);
      }
      if (modifiedField === allCombos[k]) {
        isFilter = false;
      }
    });
    result['criteria'] = criteria;
    result['toFilter'] = toFilter;
    result['currencyId'] = currencyID;
    return result;
  }

  postForFilters(req, filters: CarsSpecFilters) {
    console.log("Requesting with", req);
    req.startRow = filters.getStartRow();
    req.endRow = filters.getEndRow();
    req.colSort = filters.getColSort();
    req.sortOrder = filters.getSortOrder();
    // TODO ISPASOV - better exception handling is required
    // ON error 400 the grid locks, nobody removes the spinner!!!!
    // to reproduce - sort on MARKET
    return this.http.post<any>(environment.filterEndpoint, req);
  }

  getUoMTranslator(prefix: string, suffix: string) {
    return (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + ((suffix) ? (' ' + suffix) : '');
        case LabelType.High:
          return value + ((suffix) ? (' ' + suffix) : '');
        default:
          return value + ((suffix) ? (' ' + suffix) : '');
      }
    }
  }

  aggregate(meta, cceResponse, currencySign) {
    // console.log('agg', meta, cceResponse);
    switch (meta.type) {
      case 'int': {
        let item = {};
        let min = 0;
        let max = 0;
        if (!!cceResponse.min) {
          min = cceResponse.min;
        }
        if (!!cceResponse.max) {
          max = cceResponse.max;
        }
        item['from'] = min;
        item['to'] = max;
        item['options'] = {};
        item['options'].floor = min;
        item['options'].ceil = max;
        item['options'].vertical = true;
        item['options'].animate = false;
        item['options'].step = 1;
        item['options'].translate = this.getUoMTranslator(meta.prefix, meta.suffix);
        return item;
      }
      case 'float': {
        let item = {};
        let min = 0;
        let max = 0;
        if (!!cceResponse.min) {
          min = cceResponse.min;
        }
        if (!!cceResponse.max) {
          max = cceResponse.max;
        }
        item['from'] = min.toFixed(2);
        item['to'] = max.toFixed(2);
        item['options'] = {};
        item['options'].floor = min.toFixed(2);
        item['options'].ceil = max.toFixed(2);
        item['options'].vertical = true;
        item['options'].animate = false;
        item['options'].step = 0.01;
        if (!!meta.subType && meta.subType === 'currency') {
          item['options'].translate = this.getUoMTranslator(meta.prefix, currencySign);
        } else {
          item['options'].translate = this.getUoMTranslator(meta.prefix, meta.suffix);
        }
        return item;
      }
      case 'set': {
        let item = {};
        item['values'] = new Set();
        item['selected'] = new Set();
        cceResponse.forEach(element => {
          item['values'].add(element);
        });
        return item;
      }
      default: {
        console.log('Type unknown:', meta);
        return cceResponse;
      }
    }
  };

}
