import { UserPreferencesService } from '@app/services/user-preferences.service';
import { Component, OnInit, ViewChild, destroyPlatform } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ScenarioSelectionComponent } from '../scenario-selection/scenario-selection.component';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { DefaultScenarioComponent } from '../default-scenario/default-scenario.component';
import { ParamFormComponent, PrefState } from '../param-form/param-form.component';
import { ParamScopeComponent } from '../param-scope/param-scope.component';
import { UserPreferences } from '../../domain/UserPreferences';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChangePassComponent } from '../change-pass/change-pass.component';

@Component({
  selector: 'app-param-config',
  templateUrl: './param-config.component.html',
  styleUrls: ['./param-config.component.scss']
})
export class ParamConfigComponent {

  @ViewChild('theScope') theScope: ParamScopeComponent;
  @ViewChild('theParameters') theParameters: ParamFormComponent;
  childName: string;
  childSection: string;
  semaphor: number = 0;


  constructor(
    private defaultScenarioDialog: MatDialog,
    private spinner: NgxSpinnerService,
    private changePassDialog: MatDialog,
    private userPreferenceService: UserPreferencesService) {
  }

  ngOnInit(): void {
  }

  openScenarioSelection() {
    const dialogRef = this.defaultScenarioDialog.open(DefaultScenarioComponent, {
      data: {
        distanceID: this.userPreferenceService.getUserPreferencesNow().distanceUnitId,
      },
      width: "85vw", minWidth: "1200px", maxWidth: "1400px", panelClass: "cce-dialog-container"
    });

  }

  public applySelection() {
    this.theParameters.loadDefaultParameters(true);
  }

  public resetThisLevel() {
    this.theParameters.loadDefaultParameters(false);
  }

  public getMake() {
    if (this.theScope.editModuleForm.controls['makeControl'].value) {
      return this.theScope.editModuleForm.controls['makeControl'].value;
    } else {
      return null;
    }
  }

  public getModel() {
    if (this.theScope.editModuleForm.controls['modelControl'].value) {
      return this.theScope.editModuleForm.controls['modelControl'].value;
    } else {
      return null;
    }
  }

  public getMarket() {
    if (this.theScope.editModuleForm.controls['marketControl'].value) {
      return this.theScope.editModuleForm.controls['marketControl'].value;
    } else {
      return null;
    }
  }

  public getFuel() {
    if (this.theScope.editModuleForm.controls['fuelControl'].value) {
      return this.theScope.editModuleForm.controls['fuelControl'].value;
    } else {
      return null;
    }
  }

  public getPreferences(): UserPreferences {
    return this.theScope.userPreferences;
  }

  public setPreferences(data: UserPreferences) {
    this.theScope.userPreferences = data;
  }

  show(reason?) {
    console.log('showing for ' + reason, this.semaphor, this.semaphor + 1)
    this.spinner.show();
    this.semaphor++;
  }


  shide(reason?) {
    console.log('hiding for ' + reason, this.semaphor, this.semaphor - 1)
    this.semaphor--;
    if (this.semaphor <= 0) {
      this.semaphor = 0;
      this.spinner.hide();
    }
  }

  openChangePass() {
    this.changePassDialog.open(ChangePassComponent);
  }

}
