<div class="selectedCarsGrid">
  <div class="cce-col selected-cars-grid">
    <ag-grid-angular #theGrid style=" height: calc(100vh - 20px - 30px - 160px - 45px - 155px - 45px - 45px)" class="ag-theme-balham"
      [gridOptions]="gridOptionsSpecs" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
      [rowData]="selectedCars">
    </ag-grid-angular>
    <footer style=" height: 40px;">
      <button mat-raised-button (click)="nextStep()" class="cce-footer-blue-button">NEXT</button>
    </footer>
  </div>
</div>
