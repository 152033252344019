<div *ngIf="message" class="modal" style="display:block!important">
    <div class="custom-alert" role="document">
        <div class="header">
            {{message}}
        </div>
        <div class="alert-message">YOU CANNOT RESTORE THE DATA ONCE YOU REMOVE IT</div>
        <div class="confirm-button">
            <a (click)="getYes()">
                <button class="btn btn-yes">Yes</button>
            </a>
            <a (click)="getNo()">
                <button class="btn btn-no">No</button>
            </a>
        </div>
    </div>
</div>