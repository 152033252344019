<div class="cce-header-nbc-vehicle">
  <div class="cce-col" id="admin-menu" style="width: 85%;padding-left: 15px;">
    <div class="cce-row" style="height: 30%; margin-top: 20px">
      <span class="header-label">ADMIN PANEL</span>
    </div>
    <div class="cce-row" style="height: 70% ;padding-top: 20px;">
      <a class="admin-panel-button" (click)="onClickOpenForm()">New User</a>
      <a [ngClass]="(this.selectedRows.length > 0)?'admin-panel-button':'admin-panel-delete-button'" (click)="onDeleteUser()">Delete User</a>
      <span class="cce-spacer"></span>
      <div class="cce-row">

        <input type="file" ng2FileSelect (change)="upload($event)" (uploader)="uploader" hidden #myUpload />
        <button id="fileUploadButton" style="margin-right: 20px;" mat-raised-button (click)= "myUpload.click()" class="admin-import-button">IMPORT</button>
        <div class="verticalDelimiter"></div>
        <mat-select class="admin-leasing-button" [(ngModel)]="selectedValue" (selectionChange)="changeStatus()" >
          <mat-option class="cce-option" *ngFor="let f of financing" [value]="f">
            {{ getFinancingName(f) }}
          </mat-option>
        </mat-select>
        <div class="verticalDelimiter"></div>
      </div>
    </div>
  </div>
  <div class="cce-col" id="admin-checkbox" style="width: 15% ;padding-left: 15px;padding-top: 10px; margin-top: 20px">
    <li *ngFor="let option of filterMenu" class="admin-checkboxes">
      <mat-checkbox class="admin-checkbox" (change)="changeStatus()" [(ngModel)]="option.isChecked">
        {{getFieldname(option.name)}}
      </mat-checkbox>
    </li>
  </div>

</div>


<mat-card class="availableCarsGrid">
  <mat-card-content style="height: 100%;width: 100%">
    <ag-grid-angular myGrid id="admin-panel-grid" class="ag-theme-balham" (cellDoubleClicked)="onCellDoubleClicked($event)" [gridOptions]="gridOptionsSpecs">
    </ag-grid-angular>
  </mat-card-content>
</mat-card>
