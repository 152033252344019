import { Component, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { getColIndex22ToLength } from '@app/helpers/utils.js';
import { metadata } from '@environments/environment'

@Component({
    selector: 'app-csr-dispatch',
    templateUrl: './csr-dispatch.component.html',
    styleUrls: ['./csr-dispatch.component.scss']
})
export class CsrDispatchComponent implements ICellRendererAngularComp {

    params: ICellRendererParams;
    renderAs: string;
    benchData:{};
    //rendered: boolean = false;
    @ViewChild('tcoData') tcoData;
    @ViewChild('imageData') imageData;

    constructor() { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        var ctx = params.context;
        var field = params.data.field;
        this.renderAs = metadata[field].renderAs;

        const indexCol = getColIndex22ToLength(this.params.colDef.field);
        let idsCol = ctx.getDataSheet().columnIds[indexCol];
        let benchMarkId = ctx['benchmarkColumnId'];
        this.benchData = {};
        this.benchData['benchMarkId'] = benchMarkId;
        this.benchData['isBench'] = (idsCol === benchMarkId);
    }

    refresh(params: any): boolean {
        // TODO: properly implement this
        return false;
    }
}
