<div class="margin" #DivSP *ngIf="typeShow==TYPE_SERV_PLAN">
  <mat-accordion #acordionSP>
    <mat-expansion-panel (afterExpand)="(setSizeCell(DivSP,0))" (afterCollapse)="(setSizeCell(DivSP,0))"
      *ngFor="let value of values">
      <mat-expansion-panel-header class="maintenance-top" [collapsedHeight]="'25px'" [expandedHeight]="'25px'">
        {{getDescription(value)}}
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="maintenance-row cce-row" style="height: 15px;">
          <div class="cce-row overflow-hidden">
            <span *ngFor="let row of value.intervalPlanType; let last = last"
              (click)='newDialog(value,TYPE_SERV_PLAN)'>{{remapIntervalOccurenceUnit(row.intervalOccurence)}}
              {{calcInMonths(row)}} {{remapIntervalUnit(row.intervalUnit)}}{{!last?" / ":"&nbsp;"}} &nbsp;</span>
            <span *ngIf="value.intervalPlanType.length == 0" (click)='newDialog(value,TYPE_SERV_PLAN)'>no
              intervals</span>
          </div>
          <span class="cce-spacer"></span>
          <div class="cce-row" style="height: 21px;">
            <input class="formInput no-border" matInput type="number" min="0" [ngModel]='value.labourtime'
              (change)="valueChange(value, $event.target.value, TYPE_SERV_PLAN)">
            <span style="padding-left: 1px;">h</span>
          </div>
        </div>
        <br>
        <app-mr-parts-subgrid style="width: 100%" [typeShow]="typeShow" [acordion]="this" [acordionDiv]="DivSP"
          [params]="params" [data]="value"></app-mr-parts-subgrid>
      </ng-template>
    </mat-expansion-panel>

  </mat-accordion>
</div>

<div class="margin" #DivAT *ngIf="typeShow==TYPE_ADD_TASKS">
  <mat-accordion #acordionAT>
    <mat-expansion-panel (afterExpand)="setSizeCell(DivAT,0)" (afterCollapse)="setSizeCell(DivAT,0)"
      *ngFor="let value of values">
      <mat-expansion-panel-header class="maintenance-top" [collapsedHeight]="'30px'" [expandedHeight]="'30px'">
        {{getDescription(value)}}
      </mat-expansion-panel-header>
      <div class="cce-row maintenance-row" style="max-height: 30px;">
        <div class="cce-row overflow-hidden">
          <span *ngFor="let row of value.intervalPlanType; let last = last"
            (click)='newDialog(value,TYPE_ADD_TASKS)'>{{remapIntervalOccurenceUnit(row.intervalOccurence)}}
            {{calcInMonths(row)}} {{remapIntervalUnit(row.intervalUnit)}}{{!last?" / ":"&nbsp;"}} &nbsp;</span>
          <span *ngIf="value.intervalPlanType.length == 0" (click)='newDialog(value,TYPE_ADD_TASKS)'>no
            intervals</span>
        </div>
        <span class="cce-spacer"></span>
        <div class="cce-row">
          <input class="formInput no-border " matInput type="number" min="0" [ngModel]='value.labourTime'
            (change)="valueChange(value, $event.target.value,TYPE_ADD_TASKS)">
          <span style="padding-left: 1px;">h</span>
        </div>
      </div>
      <br>
      <app-mr-parts-subgrid [typeShow]="typeShow" [params]="params" [data]="value" [acordion]="this"
        [acordionDiv]="DivAT"></app-mr-parts-subgrid>
    </mat-expansion-panel>
  </mat-accordion>
</div>


<div class="margin" style="background-color: rgba(255, 255, 255, 1);" *ngIf="typeShow==TYPE_WEAR_PARTS">
  <mat-list role="list" style='flex: 1;overflow: auto;height: 100%;'>
    <div>
      <mat-list-item class="maintenance-wear" role="listitem" *ngFor="let value of values">
        <div mat-line class="maintenance-row" style="width:100%;display:flex;">
          <div style="text-align:left;width:100%;overflow: hidden;" class="cce-col">
            <span matTooltip='{{value.partDescription +" ("+ value.partNr + ")"}}'>{{value.partDescription +" ("+
              value.partNr + ")"}}
            </span>
            <span *ngIf="value.partAltDescription != null" style="font-style: italic;"
              matTooltip='{{value.partAltDescription}}'>{{value.partAltDescription}}
            </span>
          </div>
          <div class="cce-col">
            <input *ngIf="showPrice" matInput type="number" min="0" class="formInput no-border "
              [ngModel]="calcPriceByRate(value.price)" (change)="valueChangePrice(value, $event.target.value)">
            <span *ngIf="showPrice && value.partAltDescription != null">&nbsp;</span>
          </div>
          <span *ngIf="showPrice" style="padding-left: 1px;">{{getCurrency()}}</span>
          <span style="text-align:right;width:10px;padding-left: 20px;">{{value.quantity}}</span>
        </div>
        <div mat-line class="cce-row maintenance-row" style="width:100%;display:flex;">
          <div (click)='newDialog(value,TYPE_WEAR_PARTS)' class="cce-row overflow-hidden">
            <span
              *ngFor="let rowPT of value.intervalPlanType; let last = last">{{remapIntervalOccurenceUnit(rowPT.intervalOccurence)}}
              {{calcInMonths(rowPT)}} {{remapIntervalUnit(rowPT.intervalUnit)}}{{!last?" / ":"&nbsp;"}} &nbsp;</span>
          </div>
          <span class="cce-spacer"></span>
          <div class="cce-row">
            <input class="formInput no-border " matInput type="number" min="0" [ngModel]='value.labourTime'
              (change)="valueChange(value, $event.target.value, TYPE_WEAR_PARTS)">
            <span style="padding-left: 1px;">h</span>
          </div>
        </div>
      </mat-list-item>
    </div>
  </mat-list>
</div>
