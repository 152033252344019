import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-acar-equipment',
    templateUrl: './acar-equipment.component.html',
    styleUrls: ['./acar-equipment.component.scss']
})
export class AcarEquipmentComponent implements ICellRendererAngularComp {

    params: ICellRendererParams;
    value: string;
    exists: boolean;

    constructor() { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.value = params.value;
        this.exists = true;
        if(params.value == null || params.value === "" || params.value.substring(0, 3) === "n/a") {
            this.exists = false;
        }
    }

    refresh(params: any): boolean {
        //TODO: properly implement this
        return false;
    }

}
