<div id="calcModelSelection" (click)="preventMenuClose($event)">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

        <!-- LEAF NODES -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [matTreeNodePaddingIndent]="20">
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled></button>
            <mat-checkbox *ngIf="!node.radioGroup" [checked]="leafCheckboxChecked(node)"
                (change)="leafCheckboxToggle(node)">
                {{node.label}}</mat-checkbox>
            <mat-radio-button *ngIf="node.radioGroup != null" [name]="node.radioGroup" [checked]="radioChecked(node)"
                (change)="radioToggle(node)">{{node.label}}</mat-radio-button>
        </mat-tree-node>

        <!-- NON-LEAF NODES -->
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="20">
            <button mat-icon-button matTreeNodeToggle>
                <mat-icon class="mat-icon-rtl-mirror">{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            <mat-checkbox [checked]="nonLeafCheckboxChecked(node)" [indeterminate]="nonLeafCheckboxIndeterminate(node)"
                (change)="nonLeafCheckboxToggle(node)">
                {{node.label}}</mat-checkbox>
        </mat-tree-node>
    </mat-tree>
</div>