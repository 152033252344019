import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { AccountService } from '@app/services/account.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private accountService: AccountService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var user = this.accountService.userValue;
        if (user && !req.url.startsWith(environment.auditRoot)) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${user.token}`
            }
          });
          return next.handle(req).pipe(catchError(x=> this.handleAuthError(x)));
        } else {
            return next.handle(req).pipe(catchError(x=> this.handleAuthError(x)));
        }
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
      //handle your auth error or rethrow
      if (!err.url.startsWith(environment.auditRoot) && (err.status === 401 || err.status === 403)) {
          window.history.back();
          this.router.navigate(['/account/login']);
          return of(err.message);
      }
      return throwError(err);
  }
}
