import { Component } from '@angular/core';

@Component({
  selector: 'app-action-cell-renderer-component ',
  templateUrl: './action-cell-renderer-component.html',
  styleUrls: ['./action-cell-renderer-component.scss']
})
export class ActionCellRendererComponent {
  params: any;
  parent: any;

  style = '';

  constructor() {
  }

  agInit(params: any): void {
    this.params = params;
    this.style = (params.type === 'car')?'actionCell':'scenarioCell';
  }

  onClick(): void {
  }

  removeRow(): void {
    if (this.params.type === 'car') {
      let car = this.params.data;
      let parent = this.params.parent;
      parent.dellCar(car);
    } else {
      let gridRow = this.params.data;
      let parent = this.params.parent;
      parent.delRow(gridRow.duration);
    }
  }
}
