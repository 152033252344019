<div>
  <span *ngIf="typeShow == 1 && filterType !== TYPE_EQ_STD">{{amountField}}</span>
  <div *ngIf="typeShow == 2" class="cce-col">
    <div *ngIf="hasSP === false">
      <div style="display: flex;" *ngFor="let value of values; let index = index"
        [matTooltip]="getLongDescription(value)">
        <div *ngIf="isItVisible(value)" style="width: 100%;display: flex;">
          <div class="cce-spacer" style="overflow: hidden;">
            <mat-checkbox style="float: left;margin-left: 10px;" (change)="onChange(value)"
              [ngModel]="value.isStandard || value.isOptionalIncluded" [disabled]="value.isStandard">
              {{ value.descriptionShort }}
            </mat-checkbox>
          </div>
          <span style="margin-left: 10px;" *ngIf="!value.isStandard">{{getPrice(value.price)}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="hasSP === true && filterType !== TYPE_EQ_OPT">
      <mat-radio-group [(ngModel)]="selected" >
        <mat-radio-button style="display: flex;" [value]="notAvailable" (change)="radioChange(notAvailable)">
          {{ notAvailable.descriptionShort }}
        </mat-radio-button>
        <mat-radio-button style="display: flex;" *ngFor="let value of values; let index = index" [value]="value" (change)="radioChange(value)">
          {{ value.descriptionShort }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
