<div style="padding: 5px;" id="scenario-page">
  <div class="cce-header-nbc-scenario">
    <span id="boldedText">{{childName}}</span>
  </div>
  <div class="cce-header-nbc-scenario-body">
    <div class="cce-row" style="padding: 10px">
      <div class="months cce-col" style=" flex: 2; text-align: center; padding-left: 10px;">
        <span>DURATION</span>
      </div>
      <div class="cce-col" style="flex:14; padding-left: 10px">
        <span>DISTANCE</span>
      </div>
    </div>
    <div class="cce-col">
      <app-available-scenario style="overflow: auto; max-height: calc(80vh - 250px);" #availableScenarioGrid
        class="app-available-scenario" [parent]="this" [scenarioRows]="scenRows" [singleSelection]="true">
      </app-available-scenario>

      <app-selected-scenario style="padding-bottom: 10px;" #selectedScenarioGrid class="app-selected-scenario"
        [parent]="this" (updateScenarios)="finishSelection($event)" [singleSelection]="true">
      </app-selected-scenario>

    </div>
  </div>
</div>
