import { Injectable } from '@angular/core';
import { Constants } from '@app/helpers/Constants';
import { Observable, of } from 'rxjs';
import SampleData from '../../assets/scenario.json';
import dataMl from '../../assets/scenarioMl.json';

@Injectable({
  providedIn: 'root'
})
export class ScenarioService {

  constructor() { }

  loadBasket(type: number): Observable<any> {
    if (Constants.UNIT_ID__MILE === type) {
      return of(dataMl);
    } else {
      return of(SampleData);
    }
  }

  getScenarioObject(type: number, distance, duration) {
    let data;
    if (Constants.UNIT_ID__MILE === type) {
      data = dataMl;
    } else {
      data = SampleData;
    }
    for (let index = 0; index < data.rows.length; index++) {
      const k = data.rows[index];
      if (k.duration == duration) {
        for (let i = 0; i < k.children.length; i++) {
          const scen = k.children[i];
          if (scen.distance == distance) {
            return scen;
          }
        }
      }
    }
  }

}

