<div style="height: 100%">
    <div class="head cce-row">
        <span class="title">CHANGE PLANS</span>
    </div>

    <form [formGroup]="formGroup" novalidate>
        <div class="content cce-col">

            <!-- ONCE AFTER -->

            <div class="cce-row" style="padding-top: 20px">
                <div class="cce-col">
                    <span class="leftLabel">{{remapIntervalOccurenceUnit(OCCURRENCE_ID__ONCE_AFTER)}}</span>
                </div>
                <div class="cce-col">
                    <input matInput class="formInput" formControlName="onceAfterDur">
                </div>
                <div class="cce-col">
                    <span class="rightLabelTop">Months</span>
                </div>
            </div>

            <div class="cce-row" style="padding-left: 30px">
                <mat-error
                    *ngIf="formGroup.controls['onceAfterDur'].hasError('min') || formGroup.controls['onceAfterDur'].hasError('pattern')">
                    Please enter an integer greater than zero!
                </mat-error>
            </div>

            <div class="cce-row" style="padding-top: 5px">
                <div class="cce-col">
                    <span class="leftLabel">&nbsp;</span>
                </div>
                <div class="cce-col">
                    <input matInput class="formInput" formControlName="onceAfterDst">
                </div>
                <div class="cce-col">
                    <span class="rightLabelTop">{{this.distanceUnit}}</span>
                </div>
            </div>
            <div class="cce-row" style="padding-left: 30px">
                <mat-error
                    *ngIf="formGroup.controls['onceAfterDst'].hasError('min') || formGroup.controls['onceAfterDst'].hasError('pattern')">
                    Please enter an integer greater than zero!
                </mat-error>
            </div>

            <!-- FIRST TIME AFTER -->

            <div class="cce-row" style="padding-top: 20px">
                <div class="cce-col">
                    <span class="leftLabel">{{remapIntervalOccurenceUnit(OCCURRENCE_ID__FIRST_TIME_AFTER)}}</span>
                </div>
                <div class="cce-col">
                    <input matInput class="formInput" formControlName="firstTimeAfterDur">
                </div>
                <div class="cce-col">
                    <span class="rightLabelTop">Months</span>
                </div>
            </div>

            <div class="cce-row" style="padding-left: 30px">
                <mat-error
                    *ngIf="formGroup.controls['firstTimeAfterDur'].hasError('min') || formGroup.controls['firstTimeAfterDur'].hasError('pattern')">
                    Please enter an integer greater than zero!
                </mat-error>
            </div>

            <div class="cce-row" style="padding-top: 5px">
                <div class="cce-col">
                    <span class="leftLabel">&nbsp;</span>
                </div>
                <div class="cce-col">
                    <input matInput class="formInput" formControlName="firstTimeAfterDst">
                </div>
                <div class="cce-col">
                    <span class="rightLabelTop">{{this.distanceUnit}}</span>
                </div>
            </div>
            <div class="cce-row" style="padding-left: 30px">
                <mat-error
                    *ngIf="formGroup.controls['firstTimeAfterDst'].hasError('min') || formGroup.controls['firstTimeAfterDst'].hasError('pattern')">
                    Please enter an integer greater than zero!
                </mat-error>
            </div>

            <!-- EVERY -->

            <div class="cce-row" style="padding-top: 20px">
                <div class="cce-col">
                    <span class="leftLabel">{{remapIntervalOccurenceUnit(OCCURRENCE_ID__EVERY)}}</span>
                </div>
                <div class="cce-col">
                    <input matInput class="formInput" formControlName="everyDur">
                </div>
                <div class="cce-col">
                    <span class="rightLabelTop">Months</span>
                </div>
            </div>

            <div class="cce-row" style="padding-left: 30px">
                <mat-error
                    *ngIf="formGroup.controls['everyDur'].hasError('min') || formGroup.controls['everyDur'].hasError('pattern')">
                    Please enter an integer greater than zero!
                </mat-error>
            </div>

            <div class="cce-row" style="padding-top: 5px">
                <div class="cce-col">
                    <span class="leftLabel">&nbsp;</span>
                </div>
                <div class="cce-col">
                    <input matInput class="formInput" formControlName="everyDst">
                </div>
                <div class="cce-col">
                    <span class="rightLabelTop">{{this.distanceUnit}}</span>
                </div>
            </div>
            <div class="cce-row" style="padding-left: 30px">
                <mat-error
                    *ngIf="formGroup.controls['everyDst'].hasError('min') || formGroup.controls['everyDst'].hasError('pattern')">
                    Please enter an integer greater than zero!
                </mat-error>
            </div>

            <div class="cce-row button_container">
                <button class="button_save" (click)="onSave()" mat-button>SAVE</button>
                <button class="button_close" mat-dialog-close mat-button>CLOSE</button>
            </div>
        </div>

    </form>
</div>