import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Sheet } from '@app/domain/sheet';
import { StorageMap } from '@ngx-pwa/local-storage';
import { HandleUiMessagesService } from './handle-ui-messages.service';
import { replacer } from '@app/helpers/utils.js';
import { map } from 'rxjs/operators';
import { UndoStorage } from '@app/domain/UndoStorage';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public stepIndexMap: Map<number, number> = new Map();
  public static NUMBERS_OF_STEP_BACK = 10;
  private lastDBOperationReady = true;

  constructor(private storage: StorageMap, private messageService: HandleUiMessagesService,) { }



  add(sheet: Sheet, locate) {
    let pivot = this.stepIndexMap.get(sheet.numberOfSheet);
    pivot = (pivot === null || pivot === undefined) ? 0 : ++pivot;

    let temp: UndoStorage = {
      locate: locate,
      sheet: sheet
    };

    this.lastDBOperationReady = false;
    this.storage.set(sheet.numberOfSheet + "&&&" + (pivot % LocalStorageService.NUMBERS_OF_STEP_BACK),
      JSON.stringify(temp, replacer)).subscribe(data => {
        this.stepIndexMap.set(sheet.numberOfSheet, pivot);
        this.lastDBOperationReady = true;
      }, error => {
        this.messageService.showMessage("Error saving to storage! Contact administrator!");
        this.lastDBOperationReady = true;
      });
  }

  pop(locate, numberOfSheet): Observable<any> {

    if (!this.lastDBOperationReady) {
      this.messageService.showMessage("Last save not complete! Please, try again!");
      return null;
    }

    let pivot = this.stepIndexMap.get(numberOfSheet);
    if (pivot === null || pivot === undefined) {
      this.messageService.showMessage("There are no available changes for this sheet!");
      return null;
    } else {
      let keySufix = (pivot % LocalStorageService.NUMBERS_OF_STEP_BACK);

      return this.storage.get(numberOfSheet + "&&&" + keySufix).pipe(map((data: any) => {

        let undoObj: UndoStorage;
        if (data) {
          undoObj = JSON.parse(data);
          if (locate == undoObj.locate || 999 == undoObj.locate) {
            this.lastDBOperationReady = false;
            this.storage.delete(numberOfSheet + "&&&" + keySufix).subscribe(del => {
              this.lastDBOperationReady = true;
              this.stepIndexMap.set(numberOfSheet, --pivot);
            }, error => {
              this.lastDBOperationReady = true;
            });
          } else {
            this.messageService.showMessage("The last change is in other tab!");
          }
        } else {
          this.messageService.showMessage("There are no available changes for this sheet!");
        }
        return undoObj;
      }));

    }

  }

  clear() {
    this.storage.clear().subscribe();
  }

  removeAllForSheet(numberOfSheet) {
    let pivot = this.stepIndexMap.get(numberOfSheet);
    if (pivot === null || pivot === undefined) {
      return;
    } else {
      this.stepIndexMap.delete(numberOfSheet)
    }

    for (let index = pivot; index < pivot + LocalStorageService.NUMBERS_OF_STEP_BACK; index++) {
      let key = (index % LocalStorageService.NUMBERS_OF_STEP_BACK);
      this.storage.delete(numberOfSheet + "&&&" + key).subscribe();
    }
  }


}
