<div class="head cce-row">
  <span>SHEET CONTAINS MANUAL CHANGES</span>
  <div class="close">
    <mat-icon class="gray" [mat-dialog-close]="true">cancel</mat-icon>
  </div>
</div>
<div class="content cce-row">
  <button (click)="withChange()">KEEP CHANGES</button>
  <button (click)="withoutChange()">DISCARD CHANGES</button>
</div>
