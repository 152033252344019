<div class="cce-center-both-local">
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#1d71b8" type="line-scale"></ngx-spinner>
  <form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()">
    <mat-card class="login-card">
      <span class="header">Reset password</span>
      <mat-card-content>

        <div class="cce-col">
          <div class="cce-col" style="color:gray">
            <div style="margin-top:40px" class="cce-row">
              <h4 class="lable">ENTER EMAIL</h4>
              <h4 class="required">*</h4>
            </div>

            <mat-form-field appearance="fill" [style.fontSize.px]="10">
              <input matInput email type="email" formControlName="email" [style.fontSize.px]="12">
              <mat-error style="font-size: 12px;" *ngIf="loginForm.controls['email'].hasError('required')">
                Email is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="text-content cce-col">
          <span>Password reset instructions will be sent to</span>
          <span>your registrated
            email address</span>
        </div>

      </mat-card-content>
      <mat-card-actions class="cce-text-align-center">
        <button class="send-button" mat-flat-button [disabled]="loading" type="submit">
          Send Instructions
        </button>
        <button class="cancel-button" (click)="onClose()" mat-flat-button [disabled]="loading" type="submit">
          Cancel
        </button>
      </mat-card-actions>

    </mat-card>
  </form>
</div>
