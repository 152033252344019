<div class="head cce-row">
  <span>Change password</span>
  <div class="close">
    <mat-icon class="gray" [mat-dialog-close]="true">cancel</mat-icon>
  </div>
</div>
<div class="content cce-row">
  <form style="margin:10px" class="full-width-100" [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()">
    <div class="row" style="margin-top: 50px;">
      <div class="col" style="color:black">
        <!-- <mat-form-field class="full-width-100 marg-bot-30">
          <input matInput placeholder="ENTER EMAIL" type="email" formControlName="email" required>
          <mat-error *ngFor="let validation of account_validation_messages.email">
            <mat-error class="error-message" *ngIf="loginForm.get('email').hasError(validation.type) && (loginForm.get('email').dirty
              || loginForm.get('email').touched)">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field> -->

        <mat-form-field class="full-width-100 marg-bot-30">
          <input matInput placeholder="OLD PASSWORD" password type="password" formControlName="oldPassword">
          <mat-error *ngIf="loginForm.controls['oldPassword'].hasError('required')">
            OLD PASSWORD is required
          </mat-error>
        </mat-form-field>

        <div formGroupName="matchingPasswords" class="row">
          <div style="flex:1">
            <mat-form-field class="full-width-100 marg-bot-30">
              <input matInput type="password" placeholder="NEW PASSWORD" formControlName="password" required>
              <mat-error *ngFor="let validation of account_validation_messages.password">
                <mat-error class="error-message"
                  *ngIf="loginForm.get('matchingPasswords').get('password').hasError(validation.type) && (loginForm.get('matchingPasswords').get('password').dirty || loginForm.get('matchingPasswords').get('password').touched)">
                  {{validation.message}}</mat-error>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width-100 marg-bot-30">
              <input matInput type="password" placeholder="Please, repeat NEW PASSWORD"
                formControlName="confirm_password" [errorStateMatcher]="parentErrorStateMatcher" required>
              <mat-error *ngFor="let validation of account_validation_messages.confirm_password">
                <mat-error class="error-message" *ngIf="(loginForm.get('matchingPasswords').get('confirm_password').hasError(validation.type)||
                    loginForm.get('matchingPasswords').hasError(validation.type)) && (loginForm.get('matchingPasswords').get('confirm_password').dirty ||
                    loginForm.get('matchingPasswords').get('confirm_password').touched)">
                  {{validation.message}}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="cce-col">
      <button mat-raised-button class="cce-text-align-center" [disabled]="!loginForm.valid || loading" color="accent"
        type="submit">
        Confirm
      </button>
    </div>
  </form>
</div>
