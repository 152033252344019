<div style=" height: 100%;">
  <ag-grid-angular *ngIf="showTable" #theGrid style=" height: 200px; margin-top: 1px" class="ag-theme-balham-subGrid taskGrid"
    [gridOptions]="gridOptions" [rowSelection]="rowSelection" [columnDefs]="columnDefs" [rowData]="rowData">
  </ag-grid-angular>

  <div *ngIf="isCellEditable()" style="background-color: white; height: 10%;">
    <button mat-button (click)="delSelectedRow()">
      <mat-icon>remove</mat-icon>
    </button>
    <button mat-button (click)="pushNewRow()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
