export enum FinancingModel {
  NONE = "none",
  LEASING = "leasing",
  PURCHASE = "purchase"
}

export enum InsuranceModel {
  NONE = "none",
  COMPREHENSIVE_COVERAGE = "comprehensiveCoverage",
  PARTIAL_COVERAGE = "partialCoverage",
  THIRD_PARTY_LIABILITY = "thirdPartyLiability"
}

export enum InsuranceRateModel {
  NONE = "none",
  COMPREHENSIVE_COVERAGE = "ccRate",
  PARTIAL_COVERAGE = "ratePC",
  THIRD_PARTY_LIABILITY = "rateTPL"
}

export enum OwnershipTaxModel {
  NONE = "none",
  COMMERCIAL = "commercial",
  PRIVATE = "_private"
}

export enum OtOwnershipTaxModel {
  NONE = "none",
  COMMERCIAL = "otCommercialPA",
  PRIVATE = "otPrivatePA"
}

export enum RoadTaxModel {
  NONE = "none",
  COMMERCIAL = "commercial",
  PRIVATE = "_private"
}

export enum CPRoadTaxModel {
  NONE = "none",
  COMMERCIAL = "rtCommercial",
  PRIVATE = "rtPrivate"
}

export class CalcModel {
  acquisition: boolean = true;
  depreciation: boolean = true;
  financing: FinancingModel = FinancingModel.LEASING;
  acquisitionTaxes: boolean = true;
  acquisitionVAT: boolean = true;
  registrationTax: boolean = true;
  utilisation: boolean = true;
  insurance: InsuranceModel = InsuranceModel.COMPREHENSIVE_COVERAGE;
  fuelTotal: boolean = true;
  service: boolean = true;
  serviceParts: boolean = true;
  serviceLabour: boolean = true;
  unplannedService: boolean = true;
  wear: boolean = true;
  wearParts: boolean = true;
  overhaulLabour: boolean = true;
  unplannedWear: boolean = true;
  tyres: boolean = true;
  utilisationTaxes: boolean = true;
  utilisationVAT: boolean = true;
  ownershipTax: OwnershipTaxModel = OwnershipTaxModel.COMMERCIAL;
  roadTax: RoadTaxModel = RoadTaxModel.COMMERCIAL;

  constructor() {
    this.acquisition = true;
    this.depreciation = true;
    this.financing = FinancingModel.LEASING;
    this.acquisitionTaxes = true;
    this.acquisitionVAT = true;
    this.registrationTax = true;
    this.utilisation = true;
    this.insurance = InsuranceModel.COMPREHENSIVE_COVERAGE;
    this.fuelTotal = true;
    this.service = true;
    this.serviceParts = true;
    this.serviceLabour = true;
    this.unplannedService = true;
    this.wear = true;
    this.wearParts = true;
    this.overhaulLabour = true;
    this.unplannedWear = true;
    this.tyres = true;
    this.utilisationTaxes = true;
    this.utilisationVAT = true;
    this.ownershipTax = OwnershipTaxModel.COMMERCIAL;
    this.roadTax = RoadTaxModel.COMMERCIAL;
  }

  // Create a new CalcModel based on this one, making sure that a parent gets checked if any of its children is checked.
  // This can then be used to call the TCO WS (otherwise e.g. we have depreciation = true, but acquisition = false)
  // We need a separate one so the intermediate logic is preserved.
  public correctModel(): void {
    if(this.acquisitionVAT || this.registrationTax) {
        this.acquisitionTaxes = true;
    }
    if(this.depreciation || this.financing != FinancingModel.NONE || this.acquisitionTaxes) {
        this.acquisition = true;
    }
    if(this.serviceParts || this.serviceLabour || this.unplannedService) {
        this.service = true;
    }
    if(this.wearParts || this.overhaulLabour || this.unplannedWear) {
        this.wear = true;
    }
    if(this.utilisationVAT || this.ownershipTax != OwnershipTaxModel.NONE || this.roadTax != RoadTaxModel.NONE) {
        this.utilisationTaxes = true;
    }
    if(this.insurance != InsuranceModel.NONE || this.fuelTotal || this.service || this.wear || this.tyres || this.utilisationTaxes) {
        this.utilisation = true;
    }
  }
}

export function copyCalcModel(other: CalcModel) : CalcModel {
    let that = new CalcModel();
    that.acquisition = other.acquisition;
    that.depreciation = other.depreciation;
    that.financing = other.financing;
    that.acquisitionTaxes = other.acquisitionTaxes;
    that.acquisitionVAT = other.acquisitionVAT;
    that.registrationTax = other.registrationTax;
    that.utilisation = other.utilisation;
    that.insurance = other.insurance;
    that.fuelTotal = other.fuelTotal;
    that.service = other.service;
    that.serviceParts = other.serviceParts;
    that.serviceLabour = other.serviceLabour;
    that.unplannedService = other.unplannedService;
    that.wear = other.wear;
    that.wearParts = other.wearParts;
    that.overhaulLabour = other.overhaulLabour;
    that.unplannedWear = other.unplannedWear;
    that.tyres = other.tyres;
    that.utilisationTaxes = other.utilisationTaxes;
    that.utilisationVAT = other.utilisationVAT;
    that.ownershipTax = other.ownershipTax;
    that.roadTax = other.roadTax;
    return that;
}
