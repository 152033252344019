<div *ngIf="show" class="chart-div">
    <canvas baseChart
      #theChart="base-chart"
      [chartType]="chartType"
      [datasets]="datasets"
      [labels]="labels"
      [options]="options"
      >
    </canvas>
  </div>
