import { map } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { calcPriceByRate, formatCurrency, getColIndex22ToLength } from '@app/helpers/utils.js';
import { IChangeDetect } from '@app/helpers/IChangeDetect.ts';
import { getCurrencySignByID } from '@app/helpers/RemapUnitsUtils';
import { Constants } from '@app/helpers/Constants';
import { Sheet } from '@app/domain/sheet';
import { LocalStorageService } from '@app/services/local-storage.service';



@Component({
  selector: 'app-eqr-checkboxes',
  templateUrl: './eqr-checkboxes.component.html',
  styleUrls: ['./eqr-checkboxes.component.scss']
})
export class EqrCheckboxesComponent implements ICellRendererAngularComp, IChangeDetect {

  readonly TYPE_EQ_ALL = Constants.TYPE_EQ_ALL;
  readonly TYPE_EQ_STD = Constants.TYPE_EQ_STD;
  readonly TYPE_EQ_OPT = Constants.TYPE_EQ_OPT;
  readonly TYPE_EQ_INC = Constants.TYPE_EQ_INC;

  sheet: Sheet;
  values: any[];
  amountPriceMap;
  params: ICellRendererParams;
  idsCol: number;
  amountField: string;
  nodeForRedraw: any[];
  isCkeckedExtra = false;
  filterType = this.TYPE_EQ_ALL;
  selected: any;
  notAvailable = { descriptionShort: "N/A" };

  typeShow = 0;
  hasSP = false;
  constructor(private localStorageService: LocalStorageService) {
  }

  refresh(params: any): boolean {
    // TODO: properly implement this
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const indexCol = getColIndex22ToLength(this.params.colDef.field);
    const ctx = params.context;
    this.sheet = ctx.getDataSheet();

    this.idsCol = this.sheet.columnIds[indexCol];
    this.amountField = '';
    this.filterType = params["filterType"];

    if (params.data.children !== undefined) {
      this.amountField = this.fillAmountField();
      this.typeShow = 1;
      return;
    } else {
      this.typeShow = 2;
      this.values = params.data.values[this.idsCol];
      if (this.values && this.values.length > 0) {
        if (this.values.some(x => x.isSP)) {
          this.hasSP = true;
        } else {
          this.hasSP = false;
        }
        if (this.hasSP) {
          this.selected = this.notAvailable;
          this.values.forEach(element => {
            if (element.isSP && element.isOptionalIncluded) {
              this.selected = element;
            }
          });
        }
      }
    }
  }

  getPrice(value) {
    return formatCurrency(this.calcPriceByRate(value), getCurrencySignByID(this.sheet.currencyID));
  }

  calcPriceByRate(value) {
    return calcPriceByRate(value, this.sheet.exchangeRates.get(this.idsCol));
  }

  onChange(value) {
    this.localStorageService.add(this.sheet, Constants.TYPE_VIEW_EQUIPMENT);
    if (value.isOptionalIncluded) {
      value.isOptionalIncluded = false;
    } else {
      value.isOptionalIncluded = true;
    }
    this.recalcExtrasPrice(value);
    this.sheet.columnChangedfromEquipment.add(this.idsCol);
  }

  radioChange(value) {
    this.localStorageService.add(this.sheet, Constants.TYPE_VIEW_EQUIPMENT);

    this.values.forEach(x => x.isOptionalIncluded = false);
    value.isOptionalIncluded = true;
    this.selected = value;

    this.sheet.columnChangedfromEquipment.add(this.idsCol);
  }

  getRootNode() {
    let rootNode = this.params.node;
    while (rootNode.parent != null) {
      rootNode = rootNode.parent;
    }
    return rootNode;
  }

  recalcExtrasPrice(value) {
    let classes = value.classIds;
    let nodesForRedraw = new Set<RowNode>();
    let rootNode = this.getRootNode();
    rootNode.allLeafChildren.forEach(child => {
      let classId = child.data.field;
      if (classes.indexOf(classId) >= 0) {
        let tmpNode = child;
        let tmpOptionsInNode = tmpNode.data.values[this.idsCol];
        if (!!tmpOptionsInNode && tmpOptionsInNode.length > 0) {
          tmpOptionsInNode.forEach(option => {
            if (option.id === value.id) {
              option.isOptionalIncluded = value.isOptionalIncluded;
              do {
                nodesForRedraw.add(tmpNode);
                tmpNode = tmpNode.parent;
              } while (tmpNode.parent != null);
            }
          })
        }
      }
    });
    this.params.context.redrawRows(Array.from(nodesForRedraw.values()));
  }

  getLongDescription(value) {
    return value.description;
  }

  isItVisible(value) {
    return this.params.context.isItVisible(value);
  }

  fillAmountField(): string {
    if (!this.params.node.allLeafChildren) {
      return '';
    }
    let retval = '';
    let amountPriceSingle = 0;
    let amountPriceMulti = 0;

    let countSingle = 0;
    let countMulti = 0;

    let used = new Set<number>();
    this.params.node.allLeafChildren.forEach(child => {
      let options = child.data.values[this.idsCol];
      if (!!options && options.length > 0) {
        options.filter(option => { return !used.has(option.id) && !option.isStandard && option.isOptionalIncluded }).forEach(element => {
          used.add(element.id);
          if (element.isSingleOpt) {
            amountPriceSingle += element.price;
            countSingle++;
          } else {
            amountPriceMulti += element.price;
            countMulti++;
          }
        });
      }
    });

    if (countMulti > 0 && countSingle === 0) {
      retval = countMulti + (countMulti > 1 ? ' multi-class extras: ' : ' multi-class extra: ') +
        formatCurrency(this.calcPriceByRate(amountPriceMulti.toFixed(2)), getCurrencySignByID(this.sheet.currencyID));
    } else if (countSingle > 0 && countMulti === 0) {
      retval = countSingle + (countSingle > 1 ? ' single-class extras: ' : ' single-class extra: ') +
        formatCurrency(this.calcPriceByRate(amountPriceSingle.toFixed(2)), getCurrencySignByID(this.sheet.currencyID));
    } else if (countSingle > 0 && countMulti > 0) {
      retval = countSingle + ' single- and ' + countMulti + ' multi-class extras: ' +
        formatCurrency(this.calcPriceByRate(amountPriceSingle.toFixed(2)), getCurrencySignByID(this.sheet.currencyID)) + ' + ' +
        formatCurrency(this.calcPriceByRate(amountPriceMulti.toFixed(2)), getCurrencySignByID(this.sheet.currencyID));
    }
    if (this.params.data.field === '/') {
      let sumExtrasCount = countSingle + countMulti;
      if (sumExtrasCount > 1) {
        retval = sumExtrasCount + ' extras: ' + formatCurrency(this.calcPriceByRate((amountPriceSingle + amountPriceMulti).toFixed(2)),
          getCurrencySignByID(this.sheet.currencyID));
      }
      if (sumExtrasCount === 1) {
        retval = sumExtrasCount + ' extra: ' + formatCurrency(this.calcPriceByRate((amountPriceSingle + amountPriceMulti).toFixed(2)),
          getCurrencySignByID(this.sheet.currencyID));
      }
    }

    return retval;
  }
}
