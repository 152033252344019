import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { GridOptions, ColDef, CellDoubleClickedEvent } from 'ag-grid-community';
import { ScopeService } from '@app/services/param-scope.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ParamConfigComponent } from '../param-config/param-config.component';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { UserPreferences } from '../../domain/UserPreferences';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { SorcService } from '@app/services/sorc.service';

@Component({
  selector: 'app-param-scope',
  templateUrl: './param-scope.component.html',
  styleUrls: ['./param-scope.component.scss']
})
export class ParamScopeComponent implements OnInit {
  basket = {};
  combos = {};
  // markets = [];
  // makes = [];
  // models = [];
  // fuels = [];
  currencies = ['EUR', 'GBP', 'PLN', 'CHF', 'CZK', 'RON'];
  languages = ['NONE', 'DE', 'ES', 'FR'];
  all = '';
  public userPreferences: UserPreferences;
  scopeName = '';

  @Input() paramConfig: ParamConfigComponent;
  editModuleForm: FormGroup;

  popData() {
    this.editModuleForm.controls['moduleControl'].valueChanges.subscribe(
      value => {
        this.editModuleForm.controls['courseControl'].setValue(value.id);
      }
    );
  }

  constructor(
    public fb: FormBuilder, private paramScopeService: ScopeService, private _specsService: SorcService, private spinner: NgxSpinnerService,
    private userPreferenceService: UserPreferencesService) { }

  ngOnInit() {
    this.sshow();
    this._specsService.getScope({}, 'marketDummy').subscribe(data => {
      this.combos['marketControl'] = data;
      this.editModuleForm.controls['marketControl'].valueChanges.subscribe(
        value => {
          this.doComboFilter(value, 'makeControl');
        }
      );
      this.editModuleForm.controls['makeControl'].valueChanges.subscribe(
        value => {
          this.doComboFilter(value, 'modelControl');
        }
      );
      this.editModuleForm.controls['modelControl'].valueChanges.subscribe(
        value => {
          this.doComboFilter(value, 'fuelControl');
        }
      );
      this.editModuleForm.controls['fuelControl'].valueChanges.subscribe(
        value => {
          this.applySelection();
        }
      );
      this.shide();
    });

    this.userPreferences = this.userPreferenceService.getUserPreferencesNow();

    this.editModuleForm = this.fb.group({
      makeControl: null,
      marketControl: null,
      modelControl: null,
      fuelControl: null
    });
    this.editModuleForm.controls['marketControl'].setValue(this.all);
    this.editModuleForm.controls['makeControl'].setValue(this.all);
    this.editModuleForm.controls['modelControl'].setValue(this.all);
    this.editModuleForm.controls['fuelControl'].setValue(this.all);
  }


  replaceControlString(k) {
    let re = /Control$/i;
    return k.replace(re, '');
  }

  getComboFilters() {
    let filter = {};

    for (let key in this.editModuleForm.controls) {
      let value = this.editModuleForm.controls[key].value;
      if (value === '' || value) {
        filter[this.replaceControlString(key)] = value;
      }
    }
    return filter;
  }

  doComboFilter(value, detail) {
    this.editModuleForm.controls[detail].setValue(this.all);
    if (this.all === value) {
      this.editModuleForm.controls[detail].disable();
    } else {
      this.sshow(detail);
      let scope = this.getComboFilters();
      let crit = {};
      Object.keys(scope).forEach(key => {
        if (scope[key] != this.all) {
          crit[key] = scope[key];
        }
      });
      let valuesFor = this.replaceControlString(detail);
      this._specsService.getScope(crit, valuesFor).subscribe((data) => {
        console.log('detail filters', detail, data);
        this.combos[detail] = (data) ? data : [];
        this.editModuleForm.controls[detail].enable();
        this.shide(detail)
      });
    }

  }

  semaphor = 0;
  sshow(reason?) {
    console.log('showing for ' + reason, this.semaphor, this.semaphor + 1)
    this.spinner.show();
    this.semaphor++;
  }

  shide(reason?) {
    console.log('hiding for ' + reason, this.semaphor, this.semaphor - 1)
    this.semaphor--;
    if (this.semaphor <= 0) {
      this.semaphor = 0;
      this.spinner.hide();
    }
  }

  applySelection() {
    this.paramConfig.applySelection();
  }

  onCurrencyChange(params) {

    // switch (params.value) {
    //   case "EUR":
    //     this.paramConfig.theParameters.currency = "€";
    //     break;
    //   case "GBP":
    //     this.paramConfig.theParameters.currency = "£";
    //     break;
    //   case "PLN":
    //     this.paramConfig.theParameters.currency = "zł";
    //     break;
    //   case "CHF":
    //     this.paramConfig.theParameters.currency = "Fr.";
    //     break;
    //   case "CZK":
    //     this.paramConfig.theParameters.currency = "Kč";
    //     break;
    //   case "RON":
    //     this.paramConfig.theParameters.currency = "lei";
    //     break;

    // }
    this.userPreferenceService.setCurrency(params.value);

  }

  onLanguageChange(params) {
    this.userPreferenceService.setLanguage(params.value);
  }
}
