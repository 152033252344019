<mat-card class="availableCarsGrid">
  <mat-card-content>
    <div class="cce-col">
      <!-- some padding 20, 30 bread, 160 header, 45 custom filter, 155 filters, 45 showing-->
      <ag-grid-angular #theGrid style=" height: calc(100vh - 20px - 30px - 160px - 45px - 155px - 45px)"
        class="ag-theme-balham" [gridOptions]="gridOptionsSpecs" [columnDefs]="columnDefs" [rowBuffer]="rowBuffer"
        [rowSelection]="rowSelection" [rowModelType]="rowModelType" [paginationPageSize]="paginationPageSize"
        [cacheOverflowSize]="cacheOverflowSize" [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
        [infiniteInitialRowCount]="infiniteInitialRowCount" [maxBlocksInCache]="maxBlocksInCache"
        [cacheBlockSize]="cacheBlockSize"
        [blockLoadDebounceMillis]="blockLoadDebounceMillis">
      </ag-grid-angular>
    </div>
  </mat-card-content>
</mat-card>
