import { Component, ViewChild, Input, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { ScenarioService } from '@app/services/scenario.service';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';

@Component({
  selector: 'app-single-selected-scenario',
  templateUrl: './single-selected-scenario.component.html',
  styleUrls: ['./single-selected-scenario.component.scss']
})
export class SingleSelectedScenarioComponent implements OnInit {
  @ViewChild('availableScenarioGrid') availableScenarioGrid;
  @ViewChild('selectedScenarioGrid') selectedScenarioGrid;

  distanceID: number;
  scenRows = [];
  childName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataFromMain: any, private _scenService: ScenarioService,
    private dialogRef: MatDialogRef<SingleSelectedScenarioComponent>, private messageService: HandleUiMessagesService,
    private spinner: NgxSpinnerService) {
    this.distanceID = this.dataFromMain.distanceID;
  }

  ngOnInit(): void {
    this.childName = 'SCENARIO SELECTION';
    this.spinner.show();
    this._scenService.loadBasket(this.distanceID).subscribe(data => {
      this.scenRows = data.rows;
      this.spinner.hide();
    },
      error => {
        this.messageService.showMessage("Error loading scenarios!");
        this.spinner.hide();
      });
  }

  finishSelection(selectedScenario) {
    if (selectedScenario.length === 0) {
      this.messageService.showMessage("Please select scenario!");
      return;
    }
    this.dialogRef.close(selectedScenario[0]);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
