import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MixPanelService {

  constructor(private http: HttpClient,
  ) { }

  logData(data, endpoint) {
    let user = JSON.parse(sessionStorage['user']);

    if (data == null) {
      data = {};
    }

    let wrapper = {};
    wrapper['parameters'] = data;
    // wrapper['endpoint'] = endpoint;
    wrapper['entryPoint'] = "/cce";
    wrapper['status '] = "200";
    wrapper['results '] = "{}";
    let headers = {
      type: 'application/json; charset=utf-8',
      Authorization: `Bearer ${user.amsToken}`,
      'x-avh-realm': "cce",
    };

    this.http.post<Object>(environment.auditRoot, wrapper, {
      headers: new HttpHeaders(headers),
    }).subscribe(response => { });

  }

}
