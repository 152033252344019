import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-acar-residual',
  templateUrl: './acar-residual.component.html',
  styleUrls: ['./acar-residual.component.scss']
})
export class AcarResidualComponent implements ICellRendererAngularComp {

  params: ICellRendererParams;
  value: string;
  exists: boolean;

  constructor() {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
    this.exists = true;
    if (params.value == null || params.value === '' || params.value.substring(0, 3) === 'n/a') {
      this.exists = false;
    }

  }

  refresh(params: any): boolean {
    // TODO: implement this properly
    return false;
  }

}
