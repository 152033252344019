<div class="cce-center-both-local">
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#1d71b8" type="line-scale"></ngx-spinner>
  <form style="margin:10px" [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()">

    <mat-card class="login-card">
      <span class="header">Change password </span>
      <mat-card-content>

        <div class="cce-col">
          <div class="cce-col">
            <div style="margin-top:40px" class="cce-row">
              <h4 class="lable">OLD PASSWORD</h4>
              <h4 class="required">*</h4>
            </div>

            <mat-form-field appearance="fill" [style.fontSize.px]="10">
              <input matInput password type="password" formControlName="oldPassword" [style.fontSize.px]="12">
              <mat-error *ngIf="loginForm.controls['oldPassword'].hasError('required')" style="font-size: 12px;">
                OLD PASSWORD is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div formGroupName="matchingPasswords" class="cce-col">

          <div class="cce-col">
            <div style="margin-top:40px" class="cce-row">
              <h4 class="lable">NEW PASSWORD</h4>
              <h4 class="required">*</h4>
            </div>
            <mat-form-field appearance="fill" [style.fontSize.px]="10">
              <input matInput type="password" formControlName="password" [style.fontSize.px]="12" required>
              <mat-error *ngFor="let validation of account_validation_messages.password">
                <mat-error style="font-size: 12px;" *ngIf="loginForm.get('matchingPasswords').get('password').hasError(validation.type) && (loginForm.get('matchingPasswords').get('password').dirty ||
                   loginForm.get('matchingPasswords').get('password').touched)">
                  {{validation.message}}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="cce-col">
            <div style="margin-top:40px" class="cce-row">
              <h4 class="lable">REPEAT</h4>
              <h4 class="required">*</h4>
            </div>
            <mat-form-field appearance="fill" [style.fontSize.px]="10">
              <input matInput type="password" formControlName="confirm_password"
                [errorStateMatcher]="parentErrorStateMatcher" [style.fontSize.px]="12" required>
              <mat-error *ngFor="let validation of account_validation_messages.confirm_password">
                <mat-error style="font-size: 12px;" *ngIf="(loginForm.get('matchingPasswords').get('confirm_password').hasError(validation.type)||
                    loginForm.get('matchingPasswords').hasError(validation.type)) && (loginForm.get('matchingPasswords').get('confirm_password').dirty ||
                    loginForm.get('matchingPasswords').get('confirm_password').touched)">
                  {{validation.message}}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>

        </div>

      </mat-card-content>

      <mat-card-actions style="margin-top:30px" class="cce-text-align-center">
        <button class="send-button" mat-flat-button [disabled]="loading" type="submit">
          Confirm
        </button>
        <button class="cancel-button" (click)="onClose()" mat-flat-button [disabled]="loading" type="submit">
          Cancel
        </button>
      </mat-card-actions>

    </mat-card>
  </form>
</div>
