import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { remapIntervalUnit, remapIntervalOccurenceUnit } from '@app/helpers/RemapUnitsUtils.ts';
import { Constants } from '@app/helpers/Constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { sprintf } from '../../../../node_modules/sprintf-js';

@Component({
    selector: 'app-dialog-frequency',
    templateUrl: './dialog-frequency.component.html',
    styleUrls: ['./dialog-frequency.component.scss']
})
export class DialogFrequencyComponent implements OnInit {

    OCCURRENCE_ID__ONCE_AFTER = Constants.OCCURRENCE_ID__ONCE_AFTER;
    OCCURRENCE_ID__FIRST_TIME_AFTER = Constants.OCCURRENCE_ID__FIRST_TIME_AFTER;
    OCCURRENCE_ID__EVERY = Constants.OCCURRENCE_ID__EVERY;
    KM_TO_MILE = 1;
    MILE_TO_KM = 1;
    UNIT_ID__KILOMETRE = Constants.UNIT_ID__KILOMETRE;
    UNIT_ID__MILE = Constants.UNIT_ID__MILE;

    distanceUnit: string;
    isValidFormSubmitted: boolean;
    valid: boolean = true;

    formGroup: FormGroup;
    constructor(@Inject(MAT_DIALOG_DATA) public dataPlans: any, private messageService: HandleUiMessagesService, private formBuilder: FormBuilder, private dialogRef: MatDialogRef<DialogFrequencyComponent>) { }

    ngOnInit(): void {

        let onceAfterDur: number;
        let onceAfterDst: number;
        let firstTimeAfterDur: number;
        let firstTimeAfterDst: number;
        let everyDur: number;
        let everyDst: number;

        this.distanceUnit = remapIntervalUnit(this.dataPlans.sheet.distanceID as unknown as string);



        for (const tmp of this.dataPlans.elem) {

            if (tmp.intervalUnit == this.UNIT_ID__KILOMETRE && this.dataPlans.sheet.distanceID === this.UNIT_ID__MILE) {
              this.KM_TO_MILE = Constants.KM_TO_MILE;
              this.MILE_TO_KM = Constants.MILE_TO_KM;
            }

            if (tmp.intervalOccurence === this.OCCURRENCE_ID__ONCE_AFTER) {
                if (tmp.intervalType === Constants.TYPE_ID__DISTANCE) {
                    onceAfterDst = sprintf('%.0f', tmp.intervalVal * this.KM_TO_MILE);
                } else {
                    onceAfterDur = tmp.intervalVal;
                }
            } else if (tmp.intervalOccurence === this.OCCURRENCE_ID__FIRST_TIME_AFTER) {
                if (tmp.intervalType === Constants.TYPE_ID__DISTANCE) {
                    firstTimeAfterDst = sprintf('%.0f', tmp.intervalVal * this.KM_TO_MILE);
                } else {
                    firstTimeAfterDur = tmp.intervalVal;
                }
            } else {
                if (tmp.intervalType === Constants.TYPE_ID__DISTANCE) {
                    everyDst = sprintf('%.0f', tmp.intervalVal * this.KM_TO_MILE);
                } else {
                    everyDur = tmp.intervalVal;
                }
            }
        }

        this.formGroup = this.formBuilder.group({
            onceAfterDur: [onceAfterDur, Validators.compose([Validators.pattern(/^\d*$/), Validators.min(1)])],
            onceAfterDst: [onceAfterDst, Validators.compose([Validators.pattern(/^\d*$/), Validators.min(1)])],
            firstTimeAfterDur: [firstTimeAfterDur, Validators.compose([Validators.pattern(/^\d*$/), Validators.min(1)])],
            firstTimeAfterDst: [firstTimeAfterDst, Validators.compose([Validators.pattern(/^\d*$/), Validators.min(1)])],
            everyDur: [everyDur, Validators.compose([Validators.pattern(/^\d*$/), Validators.min(1)])],
            everyDst: [everyDst, Validators.compose([Validators.pattern(/^\d*$/), Validators.min(1)])],
        });
    }

    remapIntervalOccurenceUnit(value) {
        return remapIntervalOccurenceUnit(value);
    }

    remapIntervalUnit(value) {
        return remapIntervalUnit(value);
    }

    onSave() {
        if(!this.formGroup.valid) {
            this.messageService.showMessage('Invalid data. Please fix and try again.');
            return;
        }

        let newPlans: any = new Array;

        if(this.formGroup.controls['onceAfterDst'].value !== null && this.formGroup.controls['onceAfterDst'].value) {
            let val: any = new Object;
            val.intervalOccurence = Constants.OCCURRENCE_ID__ONCE_AFTER;
            val.intervalType = Constants.TYPE_ID__DISTANCE;
            val.intervalVal = sprintf('%.0f', Number(this.formGroup.controls['onceAfterDst'].value) * this.MILE_TO_KM)
            val.intervalUnit = Constants.UNIT_ID__KILOMETRE;//this.dataPlans.sheet.distanceID;
            newPlans.push(val);
        }

        if(this.formGroup.controls['onceAfterDur'].value !== '' && this.formGroup.controls['onceAfterDur'].value !== null) {
            let val: any = new Object;
            val.intervalOccurence = Constants.OCCURRENCE_ID__ONCE_AFTER;
            val.intervalType = Constants.TYPE_ID__DURATION;
            val.intervalVal = this.formGroup.controls['onceAfterDur'].value;
            val.intervalUnit = "5";
            newPlans.push(val);
        }

        if(this.formGroup.controls['firstTimeAfterDst'].value !== '' && this.formGroup.controls['firstTimeAfterDst'].value !== null) {
            let val: any = new Object;
            val.intervalOccurence = Constants.OCCURRENCE_ID__FIRST_TIME_AFTER;
            val.intervalType = Constants.TYPE_ID__DISTANCE;
            val.intervalVal = sprintf('%.0f', Number(this.formGroup.controls['firstTimeAfterDst'].value) * this.MILE_TO_KM)
            val.intervalUnit = Constants.UNIT_ID__KILOMETRE;
            newPlans.push(val);
        }

        if(this.formGroup.controls['firstTimeAfterDur'].value !== '' && this.formGroup.controls['firstTimeAfterDur'].value !== null) {
            let val: any = new Object;
            val.intervalOccurence = Constants.OCCURRENCE_ID__FIRST_TIME_AFTER;
            val.intervalType = Constants.TYPE_ID__DURATION;
            val.intervalVal = this.formGroup.controls['firstTimeAfterDur'].value;
            val.intervalUnit = "5";
            newPlans.push(val);
        }

        if(this.formGroup.controls['everyDst'].value !== '' && this.formGroup.controls['everyDst'].value !== null) {
            let val: any = new Object;
            val.intervalOccurence = Constants.OCCURRENCE_ID__EVERY;
            val.intervalType = Constants.TYPE_ID__DISTANCE;
            val.intervalVal = sprintf('%.0f', Number(this.formGroup.controls['everyDst'].value) * this.MILE_TO_KM);
            val.intervalUnit = Constants.UNIT_ID__KILOMETRE;
            newPlans.push(val);
        }

        if(this.formGroup.controls['everyDur'].value !== '' && this.formGroup.controls['everyDur'].value !== null) {
            let val: any = new Object;
            val.intervalOccurence = Constants.OCCURRENCE_ID__EVERY;
            val.intervalType = Constants.TYPE_ID__DURATION;
            val.intervalVal = this.formGroup.controls['everyDur'].value;
            val.intervalUnit = "5";
            newPlans.push(val);
        }

        this.dialogRef.close(newPlans);
    }
}


