import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-acar-util',
    templateUrl: './acar-util.component.html',
    styleUrls: ['./acar-util.component.scss']
})
export class AcarUtilComponent implements ICellRendererAngularComp {

    params: ICellRendererParams;
    value1: string;
    value2: string;
    value3: string;
    exists: number;

    constructor() { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.value1 = params.value.insurance;
        this.value2 = params.value.tyres;
        this.value3 = params.value.fuelCons;
        this.exists = 3;
        if (this.value1 == null || this.value1 === "" || this.value1.substring(0, 3) === "n/a") {
            this.exists--;
        }
        if (this.value2 == null || this.value2 === "" || this.value2.substring(0, 3) === "n/a") {
            this.exists--;
        }
        if (this.value3 == null || this.value3 === "" || this.value3.substring(0, 3) === "n/a") {
            this.exists--;
        }
    }

    refresh(params: any): boolean {
        // TODO: implement this properly
        return false;
    }

}
