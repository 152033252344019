import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from '@app/services/account.service';
import { Router } from '@angular/router';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-reset-pass-window',
  templateUrl: './reset-pass-window.component.html',
  styleUrls: ['./reset-pass-window.component.scss']
})
export class ResetPassWindowComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  logoYear = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private messageService: HandleUiMessagesService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;

    this.spinner.show();
    this.accountService.resetPass(this.loginForm.controls.email.value).subscribe(response => {
      this.spinner.hide();
      this.messageService.showMessage("Temporary password was sent to your email!");
      this.router.navigate(["account/login"]);
    }, error => {
      this.spinner.hide();
      this.loading = false;
      if (typeof error.error === "object") {
        this.messageService.showMessage("Error: " + error.error.message);
      } else {
        this.messageService.showMessage("Error: " + error.error);
      }

    })
  }

  onClose() {
    this.router.navigate(["account/login"]);
  }

}
