import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { PasswordValidator } from './../../helpers/password.validator';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '@app/services/account.service';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-change-pass-window',
  templateUrl: './change-pass-window.component.html',
  styleUrls: ['./change-pass-window.component.scss']
})
export class ChangePassWindowComponent implements OnInit {



  loginForm: FormGroup;
  matchingPasswordsGroup: FormGroup;
  parentErrorStateMatcher = new ParentErrorStateMatcher();
  loading = false;
  email;
  minPassSymbols = 10;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private router: Router,
    private messageService: HandleUiMessagesService,
    private spinner: NgxSpinnerService) {
    this.email = route.snapshot.queryParams["email"];
  }

  ngOnInit(): void {
    // matching passwords validation
    this.matchingPasswordsGroup = new FormGroup({
      password: new FormControl('', Validators.compose([
        Validators.minLength(this.minPassSymbols),
        Validators.required,
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#%&-_])[A-Za-z\d@$!%*?#%&-_]{8,32}$/)
      ])),
      confirm_password: new FormControl('', Validators.required)
    }, (formGroup: FormGroup) => {
      return PasswordValidator.areEqual(formGroup);
    });

    this.loginForm = this.formBuilder.group({
      // email: new FormControl('', Validators.compose([
      //   Validators.required,
      //   Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      // ])),
      oldPassword: new FormControl('', Validators.compose([
        // Validators.minLength(8),
        Validators.required,
        // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$') //this is for the letters (both uppercase and lowercase) and numbers validation
      ])),
      matchingPasswords: this.matchingPasswordsGroup
    });
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;

    if (!this.email) {
      this.router.navigate(["account/login"]);
      return;
    }

    let data = new NewPass();
    data.email = this.email;
    data.oldPassword = this.loginForm.controls.oldPassword.value;
    data.newPassword = this.matchingPasswordsGroup.controls.password.value;
    data.repeatPassword = this.matchingPasswordsGroup.controls.confirm_password.value;

    this.spinner.show();
    this.accountService.changePass(data).pipe(first()).subscribe(response => {
      this.spinner.hide();
      this.router.navigate(['/']);
      this.messageService.showMessage("Your password is successfully changed!");
    }, error => {
      this.spinner.hide();
      this.loading = false;
      if (typeof error.error === "object") {
        this.messageService.showMessage("Error: " + error.error.message);
      } else {
        this.messageService.showMessage("Error: " + error.error);
      }

    });

  }

  onClose() {
    this.router.navigate(["account/login"]);
  }

  account_validation_messages = {
    // 'email': [
    //   { type: 'required', message: 'Email is required' },
    //   { type: 'pattern', message: 'Enter a valid email' }
    // ],
    'oldPassword': [
      { type: 'required', message: 'Old password is required' },
    ],
    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password must be at least ' + this.minPassSymbols + ' characters long' },
      { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number' }
    ],
    'confirm_password': [
      { type: 'required', message: 'Repeat password is required' },
      { type: 'areEqual', message: 'Password mismatch' }
    ]
  }

}
//this make field to react by every change and set errorState
export class ParentErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = !!(form && form.submitted);
    const controlTouched = !!(control && (control.dirty || control.touched));
    const controlInvalid = !!(control && control.invalid);
    const parentInvalid = !!(control && control.parent && control.parent.invalid && (control.parent.dirty || control.parent.touched));

    return (controlTouched && (controlInvalid || parentInvalid));
  }
}

export class NewPass {
  email: string;
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
  autoLogin: boolean;
}


