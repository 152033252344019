import { SheetComponent } from '@app/components/sheet/sheet.component';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';
import { Constants } from '@app/helpers/Constants';
import { CarEquipmentComponent } from '../car-equipment/car-equipment.component';
import { MatDialog } from '@angular/material/dialog';
import { EqualizationResult } from '@app/domain/equalization-result';
import { AddScenarioComponent } from '../add-scenario/add-scenario.component';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { getCurrencySignByID } from '@app/helpers/RemapUnitsUtils.ts';
import { MixPanelService } from '@app/services/mix-panel.service';





@Component({
  selector: 'app-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss']
})
export class HeaderMainComponent implements IHeaderGroupAngularComp {

  readonly HEADER_COMPONENT_TCO = Constants.HEADER_COMPONENT_TCO;
  readonly HEADER_COMPONENT_EQUIPMENT = Constants.HEADER_COMPONENT_EQUIPMENT;
  readonly HEADER_COMPONENT_MAINTENANCE = Constants.HEADER_COMPONENT_MAINTENANCE;

  typeHC: number;
  name;
  menu: boolean;
  value: string;
  idsCol: number;
  sheetComponent: SheetComponent;
  calcSheet;


  constructor(
    private carEquipmentDialog: MatDialog, private addCenario: MatDialog,
    private messageService: HandleUiMessagesService, private mixPanelServ: MixPanelService,
    private spinner: NgxSpinnerService) { }

  agInit(params): void {
    this.name = params.name;
    this.menu = params.menu;
    this.typeHC = params.typeHC;
    this.calcSheet = params.calcSheet;
    this.sheetComponent = params.sheetComponent;
    this.idsCol = params.idsCol;
  }

  onToggleBenchmark() {
    if (!this.isBMColumn()) {
      this.calcSheet.setBenchMarkColumn(this.idsCol);
    } else {
      this.calcSheet.setBenchMarkColumn(-1);
    }
  }

  isBMColumn(): boolean {
    if (!this.calcSheet) {
      return false;
    }
    return (this.idsCol === this.calcSheet.getBenchMarkColumn());
  }

  openCarEquipment() {
    let additionalObj = {
      eventKey: "equipmentModuleCheckIn"
    }
    let endpoint = '/cce/transactions/equipmentCheckIn';
    this.mixPanelServ.logData(additionalObj, endpoint);

    const dialogRef = this.carEquipmentDialog.open(CarEquipmentComponent,
      {
        width: "85vw", minWidth: "1200px", maxWidth: "1400px", panelClass: "cce-dialog-container",
        data: {
          sheet: this.sheetComponent.getSheet(),
          ids: this.idsCol,
          currencySymbol: getCurrencySignByID(this.sheetComponent.getSheet().currencyID)
        }
      }).afterClosed().subscribe((response: EqualizationResult[]) => {
        if (!!response) {
          this.sheetComponent.applyEquipmentChanges(response);
        }
      });
  }

  newScenario() {
    const dialogRef = this.addCenario.open(AddScenarioComponent, {
      width: "85vw", minWidth: "1200px", panelClass: "cce-dialog-container", data: {
        distanceID: this.sheetComponent.getSheet().distanceID,
      }
    }).afterClosed().subscribe(response => {
      if (response !== undefined && response.selectedScenario.length > 0) {
        this.spinner.show();
        this.sheetComponent.addScenario(this.idsCol, response.selectedScenario).subscribe(data => {
          this.spinner.hide();
        },
          error => {
            console.log("error", error);
            let errorMsg = "Error adding vehicle calculation";
            if (!!error && !!error.error && !!error.error.status) {
              errorMsg = error.error.status;
            }
            this.messageService.showMessage(errorMsg);
            this.spinner.hide();
          });
      }
    });
  }

  removeCar() {
    if (this.isBMColumn()) {
      this.messageService.showMessage("Can not remove this column. The Column is benchmarked!");
    } else {
      this.sheetComponent.removeCar(this.idsCol);
    }
  }

  dotsColor(): string {
    return this.isBMColumn() ? 'triplePoint white' : 'triplePoint blue';
  }

  hasAlert() {
    if (this.sheetComponent !== undefined) {
      return this.sheetComponent.getSheet().failedColumns.has(this.idsCol);
    } else {
      return false;
    }
  }
}
