import { Component, OnInit, Input, ViewChild, AfterViewInit, } from '@angular/core';
import { GridOptions, ColDef } from 'ag-grid-community';
import { getCurrencySignByID, getDistanceUnitByID } from '@app/helpers/RemapUnitsUtils.ts';
import { CsrDispatchComponent } from '@app/components/csr-dispatch/csr-dispatch.component';
import { HeaderMainComponent } from '../header-main/header-main.component';
import { getColIndex22ToLength, getNameOfColumn, switchColWidth } from '@app/helpers/utils.js';
import { AgGridAngular } from 'ag-grid-angular';
import { Constants } from '@app/helpers/Constants.ts';
import { Sheet } from '@app/domain/sheet';
import { metadata } from '@environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { SheetComponent } from '../sheet/sheet.component';
import { LocalStorageService } from '@app/services/local-storage.service';





@Component({
  selector: 'app-calc-sheet',
  templateUrl: './calc-sheet.component.html',
  styleUrls: ['./calc-sheet.component.scss']
})
export class CalcSheetComponent implements OnInit, AfterViewInit {

  readonly HEADER_COMPONENT_TCO = Constants.HEADER_COMPONENT_TCO;

  @Input() sheetComponent: SheetComponent;
  @Input() columnsWidths;
  @Input() expandedOldRowsTCO;
  @Input() isPDF;
  @ViewChild('theGridTCO') theGrid: AgGridAngular;

  gridOptions: GridOptions;

  constructor(
    private localStorageService: LocalStorageService,
    private messageService: HandleUiMessagesService,
    private spinner: NgxSpinnerService) {
    this.frameworkComponents = { agColumnHeader: HeaderMainComponent };
    let that = this;
    this.gridOptions = {
      context: this,
      // this is the trick to display tree in the grid
      getNodeChildDetails: function getNodeChildDetails(rowItem) {
        if (rowItem.children) {
          return {
            expanded: (that.expandedRows) ? that.expandedRows.includes(rowItem.field) : false,
            group: true,
            children: rowItem.children,
            key: rowItem.nodeName
          };
        } else {
          return null;
        }
      },
      getRowHeight: function getRowHeight(params) {
        var retval = 30;
        var field = params.data.field;
        if (metadata[field].rowHeight) {
          retval = metadata[field].rowHeight;
        }
        return retval;
      },

      onFirstDataRendered: function onFirstDataRendered(this, params) {
        this.context.fixBMHeaderStyles();
      },
      onColumnMoved: function onColumnMoved(event) {
        console.log(event, "mouseMoved");
        this.context.toIndex = event.toIndex;
        this.context.fromIndex = getColIndex22ToLength(event.column['colDef']['field']) + 1;
      },

      onDragStopped: function onDragStopped(event) {
        const toIndex = this.context.toIndex;
        const fromIndex = this.context.fromIndex;
        if (!toIndex || !fromIndex || fromIndex === toIndex) {
          return;
        }
        const columnIds = this.context.dataSheet.columnIds;
        if (toIndex <= 0 || fromIndex <= 0 || toIndex > columnIds.length
          || fromIndex > columnIds.length) {
          this.context.toIndex = 0;
          this.context.fromIndex = 0;
          this.context.reloadData();
          return;
        }

        //arrayindex columnIds in sheet
        const toIndexInArray = toIndex - 1;
        const fromIndexInArray = fromIndex - 1;

        if (toIndexInArray < fromIndexInArray) {
          columnIds.splice(toIndexInArray, 0, columnIds[fromIndexInArray]);
          columnIds.splice(fromIndexInArray + 1, 1);
        } else {
          columnIds.splice(toIndexInArray + 1, 0, columnIds[fromIndexInArray]);
          columnIds.splice(fromIndexInArray, 1);
        }

        switchColWidth(fromIndex, toIndex, this.context.columnsWidths);

        this.context.toIndex = 0;
        this.context.fromIndex = 0;

        this.context.reloadData();
      },

      suppressDragLeaveHidesColumns: true,
      onRowGroupOpened: function onRowGroupOpened(params) {
        if ((params.node)) {
          if (params.node.expanded === false) {
            let field = params.node.data.field;
            let arrChild = Array<String>();
            that.getChields(arrChild, params.node.childrenAfterFilter);
            if (field !== null) {
              that.expandedRows = that.expandedRows.filter(item => item !== field);
            }
            if (arrChild !== null) {
              for (let tmp in arrChild) {
                that.expandedRows = that.expandedRows.filter(item => item !== arrChild[tmp]);
              }
            }
          } else {
            if (!that.expandedRows) {
              that.expandedRows = [];
            }
            let field = params.node.data.field;
            that.expandedRows.push(field);
          }
        }
      },
      onColumnResized: function onColumnResized(params) {
        that.hasGridChanges = true;

        //on finish set all cols size in columnsWidths
        if (params.finished && !!params.column && params.column.getColId().length > 22) {
          let allCols = that.gridOptions.columnApi.getAllGridColumns();
          if (allCols) {
            let indexOfMap = 0;
            for (const col of allCols) {
              if (col['colDef'].field !== "") {
                let width = col['actualWidth'];
                that.columnsWidths.set(indexOfMap, width);
                indexOfMap++;
              }
            }
          }
        }
      }

    };
  }

  logCheckIn() {
    //Nothing.
  }

  ngAfterViewInit(): void {
    const benchmarkColumnId = this.dataSheet.benchmarkColumnId;
    if (benchmarkColumnId && benchmarkColumnId > 0) {
      this.setBenchMarkColumn(benchmarkColumnId);
    }
  }

  isAnimationReady() {
    let renderers = this.gridOptions.api.getCellRendererInstances();
    let res = true;
    renderers.forEach(f => {
      let agComp = f['_agAwareComponent'];
      if (agComp instanceof CsrDispatchComponent) {
        if (agComp.renderAs === 'tcoChart' && agComp.tcoData.rendered === false) {
          res = false;
        }
        if (agComp.renderAs === 'imageURL') {
          if (!agComp.params.column.isVisible()) {
            res = false;
          }
        }
      }
    });
    return res;
  }

  fromIndex: number;
  toIndex: number;
  currencyUnit: string;
  distanceUnit: string;
  dataSheet: Sheet;
  gridData: any;
  expandedRows: String[];
  columnDefs: ColDef[];
  frameworkComponents;
  benchmarkColumnId = -1;
  hasGridChanges: boolean;

  setHasGridChange(hasChange) {
    this.hasGridChanges = hasChange;
  }

  getHasGridChange() {
    return this.hasGridChanges;
  }

  getDataSheet() {
    return this.dataSheet;
  }

  setExpandedRows(fieldName) {
    if (fieldName === undefined) {
      return;
    }
    this.expandedRows = fieldName;
    this.gridOptions.api.setRowData(this.gridData); // Refresh grid
  }

  refreshGrid() {
    this.expandedRows = this.expandedRows;
    // Refresh grid
    this.gridOptions.api.setRowData(this.gridData);
    this.reloadWidths(this.columnsWidths);
  }

  getExpandedRows() {
    return this.expandedRows;
  }

  showAllColumns() {
    this.gridOptions.columnApi.resetColumnState();
  }
  getRealCols() {
    let realCols = 0;
    for (let i = 0; i < this.columnDefs.length; i++) {
      const element = this.columnDefs[i];
      if (i !== 0 && element['field'] !== "") {
        realCols++;
      }
    }
    return realCols;
  }
  showThreeColsFromIndex(startIndex, width) {
    let realCols = this.getRealCols();
    if (startIndex >= realCols) {
      return null;
    }
    //this.gridOptions.columnApi.autoSizeAllColumns();
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    let lastIndex = startIndex + 3;
    for (let arrIndex in allCols) {
      let col = allCols[arrIndex];
      if (arrIndex !== "0" && col.getColDef()['field'] !== "") {
        //field = value[0] ....
        let index: number = (+arrIndex - 1);
        if ((startIndex <= index) && (index < lastIndex)) {
          this.gridOptions.columnApi.setColumnWidth(col, width);
          this.gridOptions.columnApi.setColumnVisible(col, true);
        } else {
          this.gridOptions.columnApi.setColumnVisible(col, false);
        }
      }
    }

    return lastIndex;
  }

  showFiveColsFromIndex(startIndex, width) {
    let realCols = this.getRealCols();
    if (startIndex >= realCols) {
      return null;
    }
    this.gridOptions.columnApi.autoSizeAllColumns();
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    let lastIndex = startIndex + 5;
    for (let arrIndex in allCols) {
      let col = allCols[arrIndex];
      if (arrIndex !== "0" && col.getColDef()['field'] !== "") {
        //field = value[0] ....
        let index: number = (+arrIndex - 1);
        if ((startIndex <= index) && (index < lastIndex)) {
          this.gridOptions.columnApi.setColumnWidth(col, width);
          this.gridOptions.columnApi.setColumnVisible(col, true);
        } else {
          this.gridOptions.columnApi.setColumnVisible(col, false);
        }
      }
    }
    return lastIndex;
  }

  getFirstColWidth() {
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    let width = 250;
    if (allCols.length > 0) {
      let col = allCols[0];
      width = col['actualWidth'];
    }
    return width;
  }

  setFirstColWidth(width) {
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    if (allCols.length > 0) {
      let col = allCols[0];
      this.gridOptions.columnApi.setColumnWidth(col, width);
    }
  }

  clearExpandedRows() {
    this.gridOptions.api.collapseAll();
    this.expandedRows = null;
  }

  getChields(arr, childrens) {
    childrens.forEach(child => {
      arr.push(child.data.field);
      if (child.childrenAfterFilter) {
        this.getChields(arr, child.childrenAfterFilter);
      }
    });
  }

  groupNameValueGetter(params) {
    const field = params.data.field;
    if (field !== undefined) {
      return metadata[field].label;
    }
    return;
  }

  myInnerRenderer(params) {
    if (params.data.children != null) {
      return params.value;
    } else {
      let field = params.data.field;
      let renderAs = metadata[field].renderAs;
      let benchMarkId = params.context['benchmarkColumnId'];
      if (renderAs === 'partsSubgrid' && benchMarkId != undefined && benchMarkId >= 0) {
        return '<span ><img src="/assets/legend.png";"/></span>';
      } else {
        return '<span style="font-weight: normal; color: black;">' + params.value + '</span>';
      }
    }
  }

  ngOnInit(): void {
    if (this.expandedOldRowsTCO !== undefined && this.expandedOldRowsTCO !== null) {
      this.expandedRows = this.expandedOldRowsTCO;
    }
    this.reloadData();
  }

  reloadOrigData() {
    this.theGrid.api.stopEditing();
    this.sheetComponent.reloadOrigData();
  }

  stopEditing() {
    if (!!this.theGrid && !!this.theGrid.api) {
      this.theGrid.api.stopEditing();
    }
  }

  removeCar(idsCol) {
    this.sheetComponent.removeCar(idsCol);
  }

  // private getTotalOptionalEquipment(node: any[], sum, counted): number[] {
  //   let resSoFar = sum;
  //   if (!!node && node.length > 0) {
  //     node.forEach(child => {
  //       if (!!child.children && child.children.length > 0) {
  //         resSoFar = this.getTotalOptionalEquipment(child.children, resSoFar, counted);
  //       } else {
  //         this.dataSheet.columnIds.forEach(element => {
  //           let tmpSum = resSoFar[element];
  //           if (!tmpSum) {
  //             tmpSum = 0;
  //           }
  //           let tmpCounted = counted[element];
  //           if (!tmpCounted) {
  //             tmpCounted = new Set<number>();
  //           }
  //           let options = child.values[element];
  //           if (!!options && options.length > 0) {
  //             options.forEach(option => {
  //               if (!tmpCounted.has(option.id)) {
  //                 tmpCounted.add(option.id);
  //                 if (!option.isStandard && option.isOptionalIncluded) {
  //                   tmpSum = tmpSum + option.price;
  //                 }
  //               }
  //             });
  //           }
  //           resSoFar[element] = tmpSum;
  //           counted[element] = tmpCounted;
  //         });
  //       }
  //     });
  //   }
  //   return resSoFar;
  // }

  public getGridStyle() {
    if (this.isPDF === 5) {
      return "width: 1600px; height: 1080px";
    } else if (this.isPDF === 3) {
      return "width: 1600px; height: calc(100vh - 40px - 48px - 48px - 36px - 36px)";
    } else {
      return "width: 100%; height: calc(100vh - 40px - 48px - 48px - 36px - 36px)";
    }
  }

  public reloadData() {
    this.dataSheet = this.sheetComponent.getSheet();
    // if (!!this.dataSheet.columnIds && this.dataSheet.columnIds.length > 0) {
    //   let totalOptions: {} = this.getTotalOptionalEquipment(this.dataSheet.dataEquipment.children, {}, {});
    //   this.applyValue(metadata['optionalEquipment'].path, totalOptions);
    // }

    this.currencyUnit = getCurrencySignByID(this.dataSheet.currencyID);
    this.distanceUnit = getDistanceUnitByID(this.dataSheet.distanceID);
    this.columnDefs = [];
    this.columnDefs.push({
      headerComponentParams: { typeHC: Constants.HEADER_COMPONENT_TCO, name: 'VEHICLE DESCRIPTION', menu: false, sheet: this, index: 0 }
      , valueGetter: this.groupNameValueGetter, cellRenderer: 'agGroupCellRenderer', pinned: 'left', cellRendererParams:
        { suppressCount: true, innerRenderer: this.myInnerRenderer }, minWidth: 250, resizable: true, cellClass: 'cce-group-cell', cellStyle: this.cellStyling
      , width: this.columnsWidths.size > 0 ? this.columnsWidths.get(0) : 250, lockPosition: true
    });

    for (let i = 0; i < this.dataSheet.columnIds.length; i++) {
      let currWidth = this.columnsWidths.get(i + 1);

      const colName = getNameOfColumn(this.dataSheet.dataTCO, this.dataSheet.columnIds[i]);
      this.columnDefs.push({
        headerComponentParams: {
          typeHC: Constants.HEADER_COMPONENT_TCO, name: colName, menu: true, idsCol: this.dataSheet.columnIds[i],
          calcSheet: this, width: 220, sheetComponent: this.sheetComponent
        },
        field: 'values[data.columnIds[' + i + ']]', cellRendererFramework: CsrDispatchComponent, type: "rightAligned",
        resizable: true, editable: this.isCellEditable.bind(this),
        minWidth: 220,
        valueSetter: this.setValueTable,
        valueGetter: this.getValueTable,
        cellStyle: this.cellStyling,
        width: currWidth
      });
    }


    this.columnDefs.push({
      headerName: '', field: '', flex: 1
    });
    this.gridData = this.dataSheet.dataTCO;

    if (this.dataSheet.benchmarkColumnId !== 0 && this.dataSheet.benchmarkColumnId !== -1) {
      this.benchmarkColumnId = this.dataSheet.benchmarkColumnId;
      this.setBenchMarkColumn(this.benchmarkColumnId);
    }

    setTimeout(() => {
      this.fixBMHeaderStyles();
      this.theGrid.api.setRowData(this.gridData);
    }, 0);
  }

  cellStyling(params) {
    if (params.data.field == "carSpecs" || params.data.field == "tcoChart" || params.data.field == "wpPartsDetail" || params.data.field == "spPartsDetail") {
      return { 'background-color': 'white' };
    }

    if (params.colDef.field === undefined) {
      return;
    }
    const ctx = params.context;
    const indexCol = getColIndex22ToLength(params.colDef.field);
    const idsCol = ctx.dataSheet.columnIds[indexCol];
    const field = params.data.field;
    const hasCursor = !ctx.dataSheet.locked && metadata[field].editable;

    if (idsCol !== undefined && params.data.editData[idsCol].modified) {
      if (hasCursor) {
        return { 'color': 'rgb(0,143,201)', 'cursor': 'cell' };
      }
      return { 'color': 'rgb(0,143,201)' };
    }

    if (hasCursor) {
      return { 'cursor': 'cell' };
    }
  }

  applyValue(paths, val) {
    let nodes = this.getDataNodes(paths);
    if (!!nodes && nodes.length > 0) {
      nodes.forEach(node => {
        node.values = val;
      });
    }
  }

  getDataNodes(paths) {
    let res = [];
    const jp = require('jsonpath');
    if (!!paths && paths.length > 0) {
      paths.forEach(path => {
        let tmpRes = jp.query(this.dataSheet.dataTCO, path);
        if (!!tmpRes && tmpRes.length > 0) {
          tmpRes.forEach(m => res.push(m));
        }
      });
    }
    return res;
  }

  getOldValue(path) {
    const jp = require('jsonpath');
    const obj = jp.query(this.gridData, path);
    // console.log('getoldvalue', obj[0],path);
    return obj[0];
  }

  setValue(path, value) {
    const jp = require('jsonpath');
    const obj = jp.value(this.gridData, path, value);
  }

  isCellEditable(params): boolean {
    if (this.dataSheet.locked) {
      return false;
    }
    const field = params.data.field;
    return metadata[field].editable;
  }

  setOldValueMainTable(params, idsCol, indexColBM) {
    const ctx = params.context;
    const field = params.data.field;
    if (!ctx.isCellEditable(params)) {
      return;
    }
    const sh: Sheet = ctx.sheetComponent.getSheet();

    this.localStorageService.add(ctx.sheetComponent.getSheet(), Constants.TYPE_VIEW_TCO);

    let oldValue;
    //set value for each path in metadatas
    metadata[field].path.forEach(el => {
      let path = el + '.editData[\'' + idsCol + '\']' + '.origValue';
      oldValue = ctx.getOldValue(path);

      path = el + '.values[\'' + + idsCol + '\']';
      ctx.setValue(path, oldValue);

      path = el + '.editData[\'' + idsCol + '\']' + '.modified';
      ctx.setValue(path, false);

      path = el + '.editData[\'' + idsCol + '\']' + '.error';
      ctx.setValue(path, false);

    });

    ctx.sheetComponent.setUserChangesMaps(idsCol, field, oldValue);

    if (indexColBM !== 0) {
      let data = params.node.data;
      params.node.data = ctx.sheetComponent.calculateBenchmarkData(sh, data, metadata, ctx.benchmarkColumnId);
      const rows = [];
      rows.push(params.node);
      params.api.redrawRows({ rowNodes: rows });
      // params.api.redrawRows();
    } else {
      params.api.refreshCells();
    }
  }

  setValueTable(params) {
    const ctx = params.context;
    const field = params.data.field;
    const indexCol = getColIndex22ToLength(params.colDef.field);

    const sh: Sheet = ctx.sheetComponent.getSheet();
    const idsCol = sh.columnIds[indexCol];
    const oldValPath = metadata[field].path[0] + '.editData[\'' + idsCol + '\']' + '.origValue';
    const oldValue = ctx.getOldValue(oldValPath);
    if (params.newValue == undefined) {
      params.newValue = "";//Because by export if there is filds with undefined FE don`t send this fields to BE
    }
    // tslint:disable-next-line: triple-equals
    if (params.newValue == oldValue) {// must not compare type
      ctx.setOldValueMainTable(params, idsCol, ctx.getBenchMarkColumn());
      return true;
    }
    ctx.localStorageService.add(ctx.sheetComponent.getSheet(), Constants.TYPE_VIEW_TCO);

    sh.addChange(idsCol, field, params.newValue);

    metadata[field].path.forEach(el => {
      let path = el + '.editData.' + idsCol + '.modified';
      ctx.setValue(path, true);
      path = el + '.values.' + idsCol;
      ctx.setValue(path, params.newValue);
    });
    if (ctx.benchmarkColumnId !== 0 && ctx.benchmarkColumnId !== -1) {
      let data = params.node.data;
      params.node.data = ctx.sheetComponent.calculateBenchmarkData(sh, data, metadata, ctx.benchmarkColumnId);
      const rows = [];
      rows.push(params.node);
      params.api.redrawRows({ rowNodes: rows });
    }

    return true;
  }

  getValueTable(params) {
    const ctx = params.context;
    const field = params.data.field;
    const indexCol = getColIndex22ToLength(params.colDef.field);
    const sh: Sheet = ctx.dataSheet;
    const idsCol = sh.columnIds[indexCol];

    const path = metadata[field].path[0] + '.values.' + idsCol;
    return ctx.getOldValue(path, params.newValue);
  }

  getBenchMarkColumn(): number {
    return this.benchmarkColumnId;
  }

  private fixBMHeaderStyles(): void {
    let ids = this.getBenchMarkColumn();
    const elCells = document.getElementsByClassName('calc-sheet' + this.sheetComponent.getSheetIdx());
    let headerCells = elCells[0].getElementsByClassName('ag-header-cell');
    for (let i = 1; i < headerCells.length; i++) {
      const elem = headerCells[i] as HTMLElement;
      const idsCol = elem.getAttribute('col-id');
      const toIdx = idsCol.indexOf(']]');
      const realIdsCol = this.dataSheet.columnIds[Number((idsCol).substring(22, toIdx))];
      if (Number(realIdsCol) === ids && toIdx !== -1) {
        elem.classList.remove("cce-grid-header-bgcolor-normal");
        elem.classList.add("cce-grid-header-bgcolor-benchmark");
      } else {
        elem.classList.remove("cce-grid-header-bgcolor-benchmark");
        elem.classList.add("cce-grid-header-bgcolor-normal");
      }
    }
  }

  setBenchMarkColumn(idsCol: number) {
    this.benchmarkColumnId = idsCol;
    this.dataSheet.benchmarkColumnId = idsCol;
    if (this.theGrid !== null && this.theGrid !== undefined) {
      this.spinner.show();
      let path = metadata['spPartsDetail'].path[0] + '.values';
      let sp = this.getOldValue(path);
      path = metadata['wpPartsDetail'].path[0] + '.values';
      let wp = this.getOldValue(path);

      path = metadata['cylinders'].path[0] + '.values';
      let cylinders = this.getOldValue(path);

      let tmp = this.sheetComponent.loadColors(wp, sp, cylinders, this.benchmarkColumnId);

      this.dataSheet.dataTCO = this.sheetComponent.calculateBenchmark(this.dataSheet, this.dataSheet.dataTCO,
        metadata, this.benchmarkColumnId);

      tmp.subscribe(data => {
        path = metadata['wpPartsDetail'].path[0] + '.values';
        this.setValue(path, data.wp);
        path = metadata['spPartsDetail'].path[0] + '.values';
        this.setValue(path, data.sp);
        this.theGrid.api.redrawRows();
        this.fixBMHeaderStyles();
        this.spinner.hide();
      },
        error => {
          this.messageService.showMessage("Error by loading Colors of Parts!");

          this.theGrid.api.redrawRows();
          this.fixBMHeaderStyles();
          this.spinner.hide();
        });
    }
  }

  getColumnWidths() {
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    if (allCols) {
      let indexOfMap = 0;
      this.columnsWidths.clear();
      for (let index in allCols) {
        if (indexOfMap == allCols.length - 1) {
          continue;
        }
        let col = allCols[index];
        let width = col['actualWidth'];
        this.columnsWidths.set(indexOfMap, width);
        indexOfMap++;
      }
    }
    return this.columnsWidths;
  }

  setColWidthSize(columnsWidths) {
    this.columnsWidths.forEach((value, key) => {
      this.columnsWidths.set(key, columnsWidths.get(key));
    });
  }
  reloadWidths(colWidths) {
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    if (allCols.length > 0) {
      let indexOfMap = 0;
      for (let arrIndex in allCols) {
        if (indexOfMap == allCols.length - 1) {
          continue;
        }
        let col = allCols[arrIndex];
        this.gridOptions.columnApi.setColumnWidth(col, colWidths.get(indexOfMap));
        indexOfMap++;
      }
    }
  }

  expandAll() {
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    if (allCols.length > 0) {
      let minWidth = this.getMinWidth(allCols);
      for (let arrIndex in allCols) {
        let col = allCols[arrIndex];
        if (arrIndex !== "0" && col.getColDef()['field'] !== "") {
          this.gridOptions.columnApi.setColumnWidth(col, minWidth + 20);
        }
      }
    }
  }

  collapseAll() {
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    if (allCols.length > 0) {
      let minWidth = this.getMinWidth(allCols);
      minWidth -= 20;
      for (let arrIndex in allCols) {
        let col = allCols[arrIndex];
        if (arrIndex !== "0" && col.getColDef()['field'] !== "") {
          if (col['minWidth'] > minWidth - 20) {
            minWidth = col['minWidth'];
          }
          this.gridOptions.columnApi.setColumnWidth(col, minWidth);
        }
      }
    }
  }

  getMinWidth(arrColDefs) {
    let minWidth;
    for (let arrIndex in arrColDefs) {
      let col = arrColDefs[arrIndex];
      if (arrIndex !== "0" && col.colDef['field'] !== "") {
        //value[0] >
        if (minWidth) {
          let width = col['actualWidth'];
          if (minWidth > width) {
            minWidth = width;
          }
        } else {
          minWidth = col['actualWidth'];
        }
      }
    }
    return minWidth;
  }
}
