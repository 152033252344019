import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: 'confirm-dialog.component.html',
    styleUrls: ['confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    message: any;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ConfirmDialogComponent>
    ) { }

    ngOnInit() { 
        this.message = this.data;  
    }

    getYes(){
        this.dialogRef.close(true);
    }
    getNo(){
        this.dialogRef.close(false);
    }
}  