<form novalidate [formGroup]="editModuleForm" class="cce-row">
  <div class="cce-col base-filters" style="flex:9">
    <div class="cce-row">
      <div class="cce-col">
        <mat-label class="cce-label">COUNTRY</mat-label>
        <mat-form-field class="cce-combo-field" floatLabel="never" appearance="none">
          <mat-select class="cce-combo" formControlName="marketControl">
            <mat-option [value]="all">ALL</mat-option>
            <mat-option class="cce-option" *ngFor="let market of combos['marketControl']" [value]="market">
              {{ market }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="cce-col">
        <mat-label class="cce-label">MAKE</mat-label>
        <mat-form-field class="cce-combo-field" floatLabel="never" appearance="none">
          <mat-select class="cce-combo" formControlName="makeControl">
            <mat-option [value]="all">ALL</mat-option>
            <mat-option class="cce-option" *ngFor="let make of combos['makeControl']" [value]="make">
              {{ make }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="cce-col">
        <mat-label class="cce-label">MODEL</mat-label>
        <mat-form-field class="cce-combo-field" floatLabel="never" appearance="none">
          <mat-select class="cce-combo" formControlName="modelControl">
            <mat-option [value]="all">ALL</mat-option>
            <mat-option class="cce-option" *ngFor="let model of combos['modelControl']" [value]="model">
              {{ model }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="cce-col">
        <mat-label class="cce-label">FUEL</mat-label>
        <mat-form-field class="cce-combo-field" floatLabel="never" appearance="none">
          <mat-select class="cce-combo" formControlName="fuelControl">
            <mat-option [value]="all">ALL</mat-option>
            <mat-option class="cce-option" *ngFor="let fuel of combos['fuelControl']" [value]="fuel">
              {{ fuel }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="cce-col additional-filters" style="flex:3; font-size:12px">
    <div class="cce-row small-combos currency-combo">
      <span class="cce-small-label">CURRENCY</span>
      <mat-form-field class="css-small-combo-field" floatLabel="never" appearance="none">
        <mat-select  (selectionChange)="onCurrencyChange($event)" class="cce-small-combo" [(value)]="userPreferences.currency">
          <mat-option *ngFor="let curr of currencies" value="{{curr}}">
            {{ curr }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="cce-row small-combos">
      <span class="cce-small-label">LANGUAGE</span>
      <mat-form-field class="css-small-combo-field" floatLabel="never" appearance="none">
        <mat-select (selectionChange)="onLanguageChange($event)" class="cce-small-combo" [(value)]="userPreferences.language">
          <mat-option *ngFor="let lng of languages" value="{{lng}}">
            {{ lng }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>
