import { Injectable } from "@angular/core";

@Injectable()
export class Constants {

  public static readonly SEPARATOR = "&&&";
  public static readonly UNIT_LITERS = 'l';
  public static readonly UNIT_KG = 'kg';
  public static readonly METADATA_FIELD_FUEL = 'fuel';
  public static readonly FUEL_HYDROGEN = 'Hydrogen';
  public static readonly METADATA_FIELD_TOTAL_FUEL_CONSUMPTION = 'totalFuelConsumption';
  public static readonly METADATA_FIELD_FUEL_CONSUMPTION = 'fuelConsumption';
  public static readonly FUEL_CNG = 'CNG';

  public static readonly WITH_CHANGE = 1;
  public static readonly WITHOUT_CHANGE = 0;

  public static readonly HEADER_COMPONENT_TCO = 0;
  public static readonly HEADER_COMPONENT_EQUIPMENT = 1;
  public static readonly HEADER_COMPONENT_MAINTENANCE = 2;

  //Main Constants
  public static readonly CURRENCY_EUR_ID = 2;
  public static readonly CURRENCY_CHF_ID = 7;
  public static readonly CURRENCY_GBP_ID = 9;
  public static readonly CURRENCY_PLN_ID = 11;
  public static readonly CURRENCY_CZK_ID = 12;
  public static readonly CURRENCY_RON_ID = 13;


  //Main Component Constants
  public static readonly ACTIVE_TRADE = 'trade';
  public static readonly ACTIVE_RETAIL = 'retail';

  public static readonly ACTIVE_TOTAL = 'sum';
  public static readonly ACTIVE_KM = 'per-month';//if change, change and backend
  public static readonly ACTIVE_MONTH = 'per-distance';//if change, change and backend

  public static readonly TYPE_EQ_ALL = 'all';
  public static readonly TYPE_EQ_STD = 'std';
  public static readonly TYPE_EQ_OPT = 'opt';
  public static readonly TYPE_EQ_INC = 'inc';


  public static readonly NO_MODIFIED_CELL = 0;
  public static readonly ERROR_MODIFIED_CELL = 1;
  public static readonly MODIFIED_CELL = 2;

  public static readonly TEXT_COLOR_GREEN = 1;
  public static readonly TEXT_COLOR_RED = 2;
  public static readonly TEXT_COLOR_GRAY = 3;
  public static readonly TEXT_COLOR_BLUE = 4;


  //Maintenance dialog
  public static readonly TYPE_ID__DISTANCE: string = '4';
  public static readonly TYPE_ID__DURATION: string = '5';

  public static readonly OCCURRENCE_ID__ONCE_AFTER: string = '8';
  public static readonly OCCURRENCE_ID__FIRST_TIME_AFTER: string = '13';
  public static readonly OCCURRENCE_ID__EVERY: string = '3';
  public static readonly OCCURRENCE_ID__DISPLAY: string = '1';

  public static readonly UNIT_ID__MONTH: string = '5';
  public static readonly UNIT_ID__YEAR: string = '4';
  public static readonly UNIT_ID__KILOMETRE: number = 3;
  public static readonly UNIT_ID__MILE: number = 8;
  public static readonly KM_TO_MILE: number = 0.621371;
  public static readonly MILE_TO_KM: number = 1.60934;

  //MixPanel partDetails Constants
  public static readonly SP_PARTS_DESCRIPTION = 1;
  public static readonly SP_PRICE = 2;
  public static readonly SP_QTY = 3;
  public static readonly SP_ADD = 4;
  public static readonly SP_DEL = 5;
  public static readonly WP_PARTS_DESCRIPTION = 6;
  public static readonly WP_PRICE = 7;
  public static readonly WP_QTY = 8;
  public static readonly WP_ADD = 9;
  public static readonly WP_DEL = 10;

  public static readonly ADD_TYPE = "Add";
  public static readonly DEL_TYPE = "Delete";
  public static readonly MOD_TYPE = "Modify";

  public static readonly TYPE_SERV_PLAN_NAME = "SP";
  public static readonly TYPE_ADD_TASKS_NAME = "AT";
  public static readonly TYPE_WEAR_PARTS_NAME = "WP";
  // Increment ONLY if introducing incompatible change
  public static readonly CURRENT_SHEET_VERSION = 2;

  public static readonly TYPE_VIEW_TCO = 0;
  public static readonly TYPE_VIEW_EQUIPMENT = 1;
  public static readonly TYPE_VIEW_MAINTENANCE = 2;
  public static readonly LOCATE_GENERAL_CHENGE = 999;
}
