export class CarsSpecFilters {

  private startRow = 0;
  private endRow = 100;
  private colSort;
  private sortOrder;

  constructor() {
  }

  clearFilters() {
  }

  public setStartRow(startRow: number) {
    this.startRow = startRow;
  }
  public getStartRow(): number {
    return this.startRow;
  }

  public setEndRow(endRow: number) {
    this.endRow = endRow;
  }
  public getEndRow(): number {
    return this.endRow;
  }
  public getSortOrder() {
    return this.sortOrder;
  }
  public setSortOrder(value) {
    this.sortOrder = value;
  }
  public getColSort() {
    return this.colSort;
  }
  public setColSort(value) {
    this.colSort = value;
  }

}
