<div class="head cce-row">
  <span>EXPORT SHEET TO EXCEL</span>
  <div class="close">
    <mat-icon class="gray" [mat-dialog-close]="true">cancel</mat-icon>
  </div>
</div>
<div class="content cce-row">
  <button (click)="withMaintenance()">WITH MAINTENANCE</button>
  <button (click)="withoutMaintenance()">WITHOUT MAINTENANCE</button>
</div>
