import { SheetComponent } from '@app/components/sheet/sheet.component';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { GridOptions, ColDef } from 'ag-grid-community';
import { MaintenanceService } from '@app/services/maintenance.service';
import { MrAccordionComponent } from '@app/components/mr-accordion/mr-accordion.component';
import { Constants } from '@app/helpers/Constants';
import { HeaderMainComponent } from '../header-main/header-main.component';
import { Sheet } from '@app/domain/sheet';
import { metadata } from '@environments/environment';
import { getColIndex22ToLength, getNameOfColumn, switchColWidth } from '@app/helpers/utils.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { MixPanelService } from '@app/services/mix-panel.service';


@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private mixPanelServ: MixPanelService) {
    this.frameworkComponents = { agColumnHeader: HeaderMainComponent };
    let that = this;
    this.gridOptions = {

      context: this,
      // rowSelection: 'single',

      // this is the trick to display tree in the grid
      getNodeChildDetails: function getNodeChildDetails(rowItem) {

        if (rowItem.children) {
          return {
            expanded: (that.expandedRows) ? that.expandedRows.includes(rowItem.field) : false,
            group: true,
            children: rowItem.children,
            key: rowItem.field
          };
        }
        return null;
      },
      onColumnMoved: function onColumnMoved(event) {
        this.context.toIndex = event.toIndex;
        this.context.fromIndex = getColIndex22ToLength(event.column['colDef']['field']) + 1;
      },
      onColumnResized: function onColumnResized(params) {
        //on finish set all cols size in columnsWidths
        if (params.finished && !!params.column && params.column.getColId().length > 22) {
          let allCols = that.gridOptions.columnApi.getAllGridColumns();
          if (allCols) {
            let indexOfMap = 0;
            for (const col of allCols) {
              if (col['colDef'].field !== "") {
                let width = col['actualWidth'];
                that.columnsWidths.set(indexOfMap, width);
                indexOfMap++;
              }
            }
          }
        }
      },
      onDragStopped: function onDragStopped(event) {
        const toIndex = this.context.toIndex;
        const fromIndex = this.context.fromIndex;

        if (!toIndex || !fromIndex || fromIndex === toIndex) {
          return;
        }
        const columnIds = this.context.dataSheet.columnIds;
        if (toIndex <= 0 || fromIndex <= 0 || toIndex > columnIds.length
          || fromIndex > columnIds.length) {
          this.context.toIndex = 0;
          this.context.fromIndex = 0;
          this.context.reloadData();
          return;
        }

        const toIndexInArray = toIndex - 1;
        const fromIndexInArray = fromIndex - 1;

        if (toIndexInArray < fromIndexInArray) {
          columnIds.splice(toIndexInArray, 0, columnIds[fromIndexInArray]);
          columnIds.splice(fromIndexInArray + 1, 1);
        } else {
          columnIds.splice(toIndexInArray + 1, 0, columnIds[fromIndexInArray]);
          columnIds.splice(fromIndexInArray, 1);
        }

        switchColWidth(fromIndex, toIndex, this.context.columnsWidths);

        this.context.toIndex = 0;
        this.context.fromIndex = 0;

        this.context.reloadData();
      },
      suppressDragLeaveHidesColumns: true,
      getRowHeight: function getRowHeight(params) {
        const row = 50;
        let retval = 500;
        if (params.data.wearParts) {
          return retval;
        }
        return undefined;
      },
      onRowGroupOpened: function onRowGroupOpened(params) {
        if ((params.node)) {
          if (params.node.key == "ServicePlan" && params.node.expanded === true) {
            this.context.logCheckInSP();
          }
          if (params.node.key == "AdditionalTask" && params.node.expanded === true) {
            this.context.logCheckInAT();
          }
          if (params.node.key == "WearParts" && params.node.expanded === true) {
            this.context.logCheckInWP();
          }

          if (params.node.expanded === false) {
            let field = params.node.data.field;
            let arrChild = Array<String>();
            that.getChildren(arrChild, params.node.childrenAfterFilter);
            if (field !== null) {
              that.expandedRows = that.expandedRows.filter(item => item !== field);
            }
            if (arrChild !== null) {
              for (let tmp in arrChild) {
                that.expandedRows = that.expandedRows.filter(item => item !== arrChild[tmp]);
              }
            }
          } else {
            if (!that.expandedRows) {
              that.expandedRows = [];
            }
            let field = params.node.data.field;
            that.expandedRows.push(field);
          }
        }
        let gridopt = params.context.gridOptions;
        gridopt.api.forEachNode(node => {
          if (node['data']['field'] === 'ServicePlanInternal') {
            let rowHeight = node['rowHeight'];
            that.servicePlanHeight = rowHeight;
          } else if (node['data']['field'] === 'AdditionalTaskInternal') {
            let rowHeight = node['rowHeight'];
            that.additionalTaskHeight = rowHeight;
          }
        });
      }, onGridReady: function (event) {
        console.log("event = ", event);
        event.api.resetRowHeights();
        that.gridOptions.api.forEachNode(node => {
          if (node['data']['field'] === 'ServicePlanInternal') {
            let rowHeight = node['rowHeight'];
            that.servicePlanHeight = rowHeight;
          } else if (node['data']['field'] === 'AdditionalTaskInternal') {
            let rowHeight = node['rowHeight'];
            that.additionalTaskHeight = rowHeight;
          }
        });
        that.spinner.hide();
      },
    };
  }

  @Input() sheetComponent: SheetComponent;
  @Input() columnsWidths;
  @Input() expandedOldRowsMaint;
  @ViewChild('theGrid') theGrid;
  gridData: any;
  columnDefs: ColDef[];
  frameworkComponents;
  expandedRows: String[];
  dataSheet: Sheet;
  servicePlanHeight: number;
  additionalTaskHeight: number;
  fromIndex: number;
  toIndex: number;

  // for cell Height
  readonly TYPE_SERV_PLAN = 0;
  SPCellSize = new Map<number, number>();
  ATCellSize = new Map<number, number>();
  gridOptions: GridOptions;

  logCheckInSP() {
    let additionalObj = {
      eventKey: "servicePlanCheckIn"
    }
    let endpoint = '/cce/transactions/servicePlanCheckIn';
    this.mixPanelServ.logData(additionalObj, endpoint);
  }
  logCheckInAT() {
    let additionalObj = {
      eventKey: "additionalTaskCheckIn"
    }
    let endpoint = '/cce/transactions/additionalTaskCheckIn';
    this.mixPanelServ.logData(additionalObj, endpoint);
  }
  logCheckInWP() {
    let additionalObj = {
      eventKey: "wearPartsCheckIn"
    }
    let endpoint = '/cce/transactions/wearPartsCheckIn';
    this.mixPanelServ.logData(additionalObj, endpoint);
  }

  logCheckIn() {
    this.logCheckInSP();
    this.logCheckInAT();
    this.logCheckInWP();
  }

  getChildren(arr, childrens) {
    childrens.forEach(child => {
      arr.push(child.data.field);
      if (child.childrenAfterFilter) {
        this.getChildren(arr, child.childrenAfterFilter);
      }
    });
  }

  setExpandedRows(fieldName) {
    if (fieldName === undefined) {
      return;
    }
    this.expandedRows = fieldName;
    this.reloadData();
  }

  getExpandedRows() {
    return this.expandedRows;
  }

  groupNameValueGetter(params) {
    const ctx = params.context;
    const field = params.data.field;
    if (field !== undefined) {
      return metadata[field].label;
    }
    return;
  }

  getDataSheet() {
    return this.dataSheet;
  }

  getValueFromTCO(path) {
    return this.sheetComponent.getValueFromTCO(path);
  }

  ngOnInit(): void {
    if (this.expandedOldRowsMaint !== undefined && this.expandedOldRowsMaint !== null) {
      this.expandedRows = this.expandedOldRowsMaint;
      this.spinner.show();
    }
    this.reloadData();
  }

  getColumnWidths() {
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    if (allCols) {
      let indexOfMap = 0;
      this.columnsWidths.clear();
      for (let index in allCols) {
        if (indexOfMap == allCols.length - 1) {
          continue;
        }
        let col = allCols[index];
        let width = col['actualWidth'];
        this.columnsWidths.set(indexOfMap, width);
        indexOfMap++;
      }
    }
    return this.columnsWidths;
  }

  reloadWidths(colWidths) {
    this.spinner.show();
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    if (allCols.length > 0) {
      let indexOfMap = 0;
      for (let arrIndex in allCols) {
        if (indexOfMap == allCols.length - 1) {
          continue;
        }
        let col = allCols[arrIndex];
        this.gridOptions.columnApi.setColumnWidth(col, colWidths.get(indexOfMap));
        indexOfMap++;
      }
    }
    setTimeout(() => {
      this.gridOptions.onGridReady(this.theGrid);
    }, 0);
  }

  public reloadData() {
    this.dataSheet = this.sheetComponent.getSheet();
    this.columnDefs = [];

    this.columnDefs.push({
      headerComponentParams: { typeHC: Constants.HEADER_COMPONENT_MAINTENANCE, name: 'MAINTENANCE', menu: false },
      headerName: '', valueGetter: this.groupNameValueGetter,
      cellRenderer: 'agGroupCellRenderer', cellRendererParams: { suppressCount: true }, pinned: 'left',
      width: this.columnsWidths.size > 0 ? this.columnsWidths.get(0) : 250, resizable: true,
      cellStyle: { 'font-weight': 'bold' }, cellClass: 'cce-group-cell', minWidth: 250, lockPosition: true
    });

    for (let i = 0; i < this.dataSheet.columnIds.length; i++) {
      let currWidth = this.columnsWidths.get(i + 1);

      const colName = getNameOfColumn(this.dataSheet.dataTCO, this.dataSheet.columnIds[i]);
      this.columnDefs.push({
        headerComponentParams: {
          typeHC: Constants.HEADER_COMPONENT_MAINTENANCE, name: colName, menu: true, idsCol: this.dataSheet.columnIds[i],
          sheetComponent: this.sheetComponent
        },
        field: 'values[data.columnIds[' + i + ']]', headerName: '', cellRendererFramework: MrAccordionComponent,
        autoHeight: true, /* type: "rightAligned",*/minWidth: 220, resizable: true, width: currWidth, type: 'rightAligned'
      });
    }

    this.columnDefs.push({
      headerName: '', field: '', flex: 1
    });

    this.gridData = [];

    this.gridData.push(this.dataSheet.dataMaintenance.servicePlan);
    this.gridData.push(this.dataSheet.dataMaintenance.additionalTasks);
    this.gridData.push(this.dataSheet.dataMaintenance.wearParts);
  }



  setColumnsSize(divSize, idsCol, typeShow): number {
    if (typeShow === this.TYPE_SERV_PLAN) {
      this.SPCellSize.set(idsCol, divSize);
      let heighest = 0;
      this.SPCellSize.forEach((value: number, key: number) => {
        if (heighest < value) {
          heighest = value;
        }
      });
      if (heighest < this.servicePlanHeight) {
        heighest = this.servicePlanHeight;
      }
      return heighest;
    } else {
      this.ATCellSize.set(idsCol, divSize);
      let heighest = 0;
      this.ATCellSize.forEach((value: number, key: number) => {
        if (heighest < value) {
          heighest = value;
        }
      });
      if (heighest < this.additionalTaskHeight) {
        heighest = this.additionalTaskHeight;
      }
      return heighest;
    }
  }

  expandAll() {
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    if (allCols.length > 0) {
      let minWidth = this.getMinWidth(allCols);
      for (let arrIndex in allCols) {
        let col = allCols[arrIndex];
        if (arrIndex !== "0" && col.getColDef()['field'] !== "") {
          this.gridOptions.columnApi.setColumnWidth(col, minWidth + 20);
        }
      }
    }
  }

  collapseAll() {
    let allCols = this.gridOptions.columnApi.getAllGridColumns();
    if (allCols.length > 0) {
      let minWidth = this.getMinWidth(allCols);
      minWidth -= 20;
      for (let arrIndex in allCols) {
        let col = allCols[arrIndex];
        if (arrIndex !== "0" && col.getColDef()['field'] !== "") {
          if (col['minWidth'] > minWidth - 20) {
            minWidth = col['minWidth'];
          }
          this.gridOptions.columnApi.setColumnWidth(col, minWidth);
        }
      }
    }
  }

  getMinWidth(arrColDefs) {
    let minWidth;
    for (let arrIndex in arrColDefs) {
      let col = arrColDefs[arrIndex];
      if (arrIndex !== "0" && col.colDef['field'] !== "") {
        //value[0] >
        if (minWidth) {
          let width = col['actualWidth'];
          if (minWidth > width) {
            minWidth = width;
          }
        } else {
          minWidth = col['actualWidth'];
        }
      }
    }
    return minWidth;
  }
}
