import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { GridOptions, RowSelectedEvent } from 'ag-grid-community';
import { FileTypeCellRenderer } from '../file-type/file-type-renderer-component';
import { CceFilesService } from '@app/services/cce-db-files.service';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { YesNoMessageComponent } from '../yes-no-message/yes-no-message.component';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';

@Component({
  selector: 'app-file-share',
  templateUrl: './file-share.component.html',
  styleUrls: ['./file-share.component.scss']
})
export class FileShareComponent implements OnInit, AfterViewInit {

  constructor(@Inject(MAT_DIALOG_DATA) public dataFromMain: any, private cceFilesService: CceFilesService,
    public dialogRef: MatDialogRef<FileShareComponent>, private spinner: NgxSpinnerService,
    private dialogSure: MatDialog, private messageService: HandleUiMessagesService,) {
    this.isForSave = this.dataFromMain.isForSave;
    let that = this;
    this.gridOptions = {
      context: this,
      rowData: this.rowData,
      rowHeight: 50,
      rowSelection: that.isForSave ? 'single' : 'multiple',
      defaultColDef: {
        width: 200,
        resizable: true,
        sortable: true,
        filter: false
      },
      isExternalFilterPresent: () => {
        return true;
      },
      doesExternalFilterPass: (node) => {
        let passes = this.cceFilesService.matches(node.data, this.currentFilter);
        return passes;
      },
      onRowSelected: this.onRowSelected,
      onGridReady: () => {
        console.log(this.rowData);
      },
      isRowSelectable: (rowNode) => {
        if ((that.userBK === rowNode['data']['businessKey']) || (that.userBK === rowNode['data']['owner'])) {
          // my files, I do as I like
          return true;
        } else if (that.isForSave) {
          // save file over another file only if I have write
          let perm = rowNode['data']['permissions'].toLowerCase();
          if (perm !== "none" && perm !== "read") {
            return true;
          } else {
            return false;
          }
        } else {
          // open cce file
          let perm = rowNode['data']['permissions'].toLowerCase();
          if (perm !== "none" && perm !== "write") {
            return true;
          } else {
            return false;
          }
        }
      },
    };

  }
  @ViewChild('fileNameProp') fileNameProp;
  @ViewChild('changeButtonProp') changeButtonProp;
  all = {};
  title: string;
  dialogType: number;
  changeButton: string;
  currentFilter = {};
  userBK: string;
  isForSave: boolean;
  //isForSave = true -> saveCCE
  //isForSave = false -> openCCE

  public columnDefs;


  setValueDate(params) {
    return moment(params.value).format('DD/MM/YYYY HH:mm');
  }

  public columnDefsAll = [
    { headerName: 'NAME', field: 'name', width: 350, checkboxSelection: true },
    { headerName: 'OWNER', field: 'owner' },
    { headerName: 'CREATED ON', field: 'createdOn', cellRenderer: this.setValueDate },
    { headerName: 'LAST MOD. BY', field: 'lastModifiedBy', width: 220 },
    { headerName: 'LAST MOD. ON', field: 'lastModifiedOn', width: 220, cellRenderer: this.setValueDate },
    { headerName: 'PERMISSIONS', field: 'permissions', width: 150 }
  ];

  public columnDefsMy = [
    { headerName: 'NAME', field: 'name', width: 350, checkboxSelection: true },
    { headerName: 'OWNER', field: 'owner' },
    { headerName: 'CREATED ON', field: 'createdOn', cellRenderer: this.setValueDate },
    { headerName: 'LAST MOD. BY', field: 'lastModifiedBy', width: 220 },
    { headerName: 'LAST MOD. ON', field: 'lastModifiedOn', width: 220, cellRenderer: this.setValueDate },
    {
      headerName: 'PERMISSIONS',
      field: 'permissions',
      editable: true,
      width: 150,
      headerComponentParams: { parent: this },
      cellRendererFramework: FileTypeCellRenderer,
      cellRendererParams: {
        parent: this
      }
    }
  ];
  public rowData = [
  ];

  ngOnInit(): void {
    let user = JSON.parse(sessionStorage['user']);
    this.dialogType = 0;
    this.currentFilter = {};
    this.currentFilter['owner'] = user['username'];

    this.spinner.show();
    this.userBK = user["subjectBusinessKey"];
    this.cceFilesService.loadCceFiles().subscribe(
      data => {
        this.spinner.hide();
        this.rowData = data;
        this.refreshWindow();
      }, error => {
        this.spinner.hide();
        console.log(error);
      }
    );
    this.setHeadLabels();
  }

  ngAfterViewInit(): void {
    if (this.isForSave) {
      this.changeButtonProp['_elementRef'].nativeElement.disabled = true;
      this.fileNameProp.nativeElement.value = this.dataFromMain.fileName;
    }
  }
  public gridOptions: GridOptions;

  onRowSelected(params: RowSelectedEvent) {
    if (params.context.isForSave && params.node.isSelected() === true) {
      params.context.fileNameProp.nativeElement.value = params['data']['name'];
    }
  }

  setHeadLabels() {
    if (this.dialogType === 0) {
      this.title = "FILES SHARED BY YOU";
      this.changeButton = "All Files";
      this.columnDefs = this.columnDefsMy;
    } else {
      this.title = "FILES SHARED BY OTHER USERS";
      this.changeButton = "My Files";
      this.columnDefs = this.columnDefsAll;
    }
  }

  changeDialogType() {
    if (this.dialogType === 0) {
      this.dialogType = 1;
      this.currentFilter = {};
      let user = JSON.parse(sessionStorage['user']);
      this.currentFilter['ownerDis'] = user['username'];
    } else {
      this.dialogType = 0;
      this.currentFilter = {};
      let user = JSON.parse(sessionStorage['user']);
      this.currentFilter['owner'] = user['username'];
    }
    this.refreshWindow();
  }

  refreshWindow() {
    this.setHeadLabels();
    this.refreshGrid();
  }

  refreshGrid() {
    this.gridOptions.api.setRowData(this.rowData); //refresh grid
  }

  onOpen() {
    if (this.gridOptions.api.getSelectedRows().length > 0) {
      this.dialogRef.close([this.gridOptions.api.getSelectedRows()]);
    }
  }

  onSave() {
    let fileName = this.fileNameProp.nativeElement.value;
    this.dialogRef.close([this.gridOptions.api.getSelectedRows(), fileName]);
  }

  onClose() {
    console.log("close");
  }

  onDelete() {
    if (this.gridOptions.api.getSelectedRows().length > 0) {
      if (!this.checkForWrite(this.gridOptions.api.getSelectedRows())) {
        this.messageService.showMessage("You can not delete files for Read!");
      } else {
        this.openDialogSure();
      }
    }
  }

  checkForWrite(rows): boolean {
    for (let index = 0; index < rows.length; index++) {
      const element = rows[index];
      if (((this.userBK !== element['businessKey']) && (this.userBK !== element['owner'])) &&
        (element.permissions.trim().toLowerCase() == "read" || element.permissions.trim().toLowerCase() == "none")) {
        return false;
      }
    }
    return true;
  }

  openDialogSure() {
    let dialog = this.dialogSure.open(YesNoMessageComponent, {
      data: {
        parent: this,
        question: "Are you sure you want to delete selected rows?",
      }
    });
  }

  deleteRows() {
    if (this.gridOptions.api.getSelectedRows().length > 0) {
      let rows = this.gridOptions.api.getSelectedRows();
      let ids = [];
      for (let i = 0; i < rows.length; ++i) {
        ids[i] = rows[i]["_id"];
      }

      this.spinner.show();
      this.cceFilesService.deleteCceFiles(ids).subscribe(
        data => {
          for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            for (let index = this.rowData.length - 1; index >= 0; index--) {
              const element = this.rowData[index];
              if (element['_id'] == id) {
                this.rowData.splice(index, 1);
              }
            }
          }
          this.refreshWindow();
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          this.messageService.showMessage(error.error.message);
        }
      );
    }
  }

}
