import { Component, OnInit, ViewChild, Input, AfterViewInit, AfterContentChecked } from '@angular/core';
import { ScenarioService } from '@app/services/scenario.service';
import { NewBatchCalculationComponent } from '../new-batch-calculation/new-batch-calculation.component';
import { GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';
import { Scenario } from '../../objects/Scenario';
import { ScenarioSelectionComponent } from '../scenario-selection/scenario-selection.component';
import { UserPreferencesService } from '@app/services/user-preferences.service';


@Component({
  selector: 'app-available-scenario',
  templateUrl: './available-scenario.component.html',
  styleUrls: ['./available-scenario.component.scss']
})

export class AvailableScenarioComponent implements OnInit {

  constructor(private userPrefService: UserPreferencesService) { }



  @Input() defScenarios;
  @Input() scenarioRows: any = [];
  self = this;
  selectedScenarios = [];
  @ViewChild('spaner') spaner: HTMLElement;
  @Input() singleSelection: boolean = false;

  gridColumnApi: any;
  @Input() parent: any;
  @ViewChild('theGrid') theGrid;

  ngOnInit(): void {
    if (this.defScenarios === null || this.defScenarios === undefined) {
      return;
    }
    let map = new Map();
    for (const scen of this.defScenarios) {
      map.set(scen.id, scen);
    }
    for (const row of this.scenarioRows) {
      for (let index = 0; index < row.children.length; index++) {
        let element = row.children[index];
        if (map.has(element.id)) {
          this.selectedScenarios[element.id] = true;
        }
      }
    }
  }

  getStyle(child) {
    return ((this.selectedScenarios[child.id] === true) ? 'selectedScenario' : 'scenario');
  }

  toggleSelection(child) {
    if (this.selectedScenarios[child.id]) {
      this.selectedScenarios[child.id] = false;
      this.parent.selectedScenarioGrid.delSelectedScenario(child);
    } else {
      if (this.singleSelection && this.selectedScenarios.length > 0) {
        this.selectedScenarios = [];
        this.parent.selectedScenarioGrid.clearAllSelScenario();
        this.selectedScenarios[child.id] = true;
      }else{
        this.selectedScenarios[child.id] = true;
      }
      this.parent.selectedScenarioGrid.addSelectedScenario(child);
    }
  }

}


