import { Component, ViewChild, Input, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { ScenarioService } from '@app/services/scenario.service';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { CarSuccesssors } from '@app/domain/CarSuccesssors';
import { Sheet } from '@app/domain/sheet';
// import { getNameOfColumn } from '@app/helpers/utils.js';

@Component({
  selector: 'app-successors-choose',
  templateUrl: './successors-choose.component.html',
  styleUrls: ['./successors-choose.component.scss']
})
export class SuccessorsChooseComponent implements OnInit {

  cars: CarSuccesssors[];
  oldSheet: Sheet;
  colIDFromAddScenario;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataFromMain: any, private _scenService: ScenarioService,
    private dialogRef: MatDialogRef<SuccessorsChooseComponent>, private messageService: HandleUiMessagesService,
    private spinner: NgxSpinnerService) {
    this.cars = this.dataFromMain.cars;
    this.oldSheet = this.dataFromMain.oldSheet;
    this.colIDFromAddScenario = this.dataFromMain.colIDFromAddScenario;

    const jp = require('jsonpath');

    for (let index = 0; index < this.cars.length; index++) {
      const car = this.cars[index];
      //for which idCol to show description in oldCar
      let colID = this.colIDFromAddScenario ? this.colIDFromAddScenario : car.columnID;

      let path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'id\')]' + '.values[\'' + colID + '\']';
      car.parent.carID = jp.value(this.oldSheet.dataTCO, path);
      path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'netListPrice\')]' + '.values[\'' + colID + '\']';
      car.parent.netPrice = jp.value(this.oldSheet.dataTCO, path);

      // console.log(this.cars, "successors", this.oldSheet.dataTCO,"id",colID ,this.colIDFromAddScenario );

    }
  }

  ngOnInit() {

  }

  continue() {

    for (let index = this.cars.length - 1; index >= 0; index--) {
      const car = this.cars[index];

      for (let index = car.successors.length - 1; index >= 0; index--) {
        const successor = car.successors[index];
        if (!successor.isSelected) {
          car.successors.splice(index, 1);
        }

      }
      if (car.successors.length == 0) {
        this.cars.splice(index, 1);
      }

    }
    // console.log("continue", this.cars);
    this.dialogRef.close(this.cars);
  }

  closeMe() {
    this.dialogRef.close();
  }

}
