import { Component, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Inject, ElementRef } from '@angular/core';
import { ScenarioService } from '@app/services/scenario.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SorcService } from '@app/services/sorc.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { UserPreferencesService } from '@app/services/user-preferences.service';
import { Observable, of } from 'rxjs';
import { share, mergeMap } from 'rxjs/operators';
import { CarsSpecFilters } from '@app/domain/CarsSpecFilters';
import { getCurrencySignByID } from '@app/helpers/RemapUnitsUtils';
import { _MatTabGroupBase } from '@angular/material/tabs';

@Component({
  selector: 'app-new-batch-calculation',
  templateUrl: './new-batch-calculation.component.html',
  styleUrls: ['./new-batch-calculation.component.scss', './new-batch-calculation-slider.component.scss', './new-batch-calculation-header.component.scss']
})


export class NewBatchCalculationComponent implements OnInit, AfterViewChecked {
  public filtersMETA: Map<String, Object>;

  nestedForm: FormGroup;
  uploader: FileUploader;
  filterTemplate: any[] = [];
  selectedFilters: string[] = [];
  selectedFiles: FileList;
  progress: { percentage: number } = { percentage: 0 };
  selectedAvailableNodes: any;
  filteresNotYetLoaded = true;
  totalFilteredItems = 0;
  isUploadAllowed = true;
  uploadedFilter = null;

  constructor(@Inject(MAT_DIALOG_DATA) public dataFromMain: any, private _scenService: ScenarioService, private _specsService: SorcService,
    private preferencesService: UserPreferencesService, public fb: FormBuilder, private dialogRef: MatDialogRef<NewBatchCalculationComponent>,
    private http: HttpClient, private spinner: NgxSpinnerService, private messageService: HandleUiMessagesService) {
    this.distanceID = this.dataFromMain.distanceID;
    this.currencyID = this.dataFromMain.currencyID;
    this.currencySign = getCurrencySignByID(this.currencyID);
  }

  @ViewChild('availableCarsGrid') availableCarsGrid;
  @ViewChild('selectedCarsGrid') selectedCarsGrid;
  @ViewChild('availableScenarioGrid') availableScenarioGrid;
  @ViewChild('selectedScenarioGrid') selectedScenarioGrid;
  @ViewChild('myUpload') myUpload: ElementRef;


  distanceID: number;
  currencyID: number;
  currencySign: string;
  childName: string;
  childSection: string;
  startDateStart = new Date(1990, 0, 1);
  startDateEnd = new Date(1990, 0, 1);
  combos = {};
  basket = {};
  all = {};
  editModuleForm: FormGroup;
  display = "none";
  users: [];
  firstCSPage = {};
  selCarRows = [];
  scenRows = [];
  selScenRows = [];

  numberPage = 0;
  selectedCount = 0;
  showCustomFilters: boolean = false;

  ngOnInit(): void {
    this.childName = 'SCENARIO SELECTION';

    this.nestedForm = this.fb.group({
      filters: ['', Validators.required]
    });

    this.filtersMETA = new Map<String, Object>();

    this.sshow('filters');
    this._specsService.loadFilters().subscribe(data => {
      Object.keys(data).forEach(k => {
        if (data[k].label === "") {
          delete (data[k]);
        }
      });
      this.filtersMETA = data;
      this.shide('filters');
    },
      error => {
        this.messageService.showMessage("Error loading filters!");
        this.shide('filters');
      });


    this.sshow('scenarios');
    this._scenService.loadBasket(this.distanceID).subscribe(data => {
      this.scenRows = data.rows;

      this.shide('scenarios');
    },
      error => {
        this.messageService.showMessage("Error loading scenarios!");
        this.shide('scenarios');
      });

    this.editModuleForm = this.fb.group({
      makeControl: null,
      marketControl: null,
      modelControl: null,
      trimLevelControl: null,
      saleStartControl: null,
      saleEndControl: null,
      freeControl: null
    });
    this.editModuleForm.controls['marketControl'].setValue(this.all);
    this.editModuleForm.controls['makeControl'].setValue(this.all);
    this.editModuleForm.controls['modelControl'].setValue(this.all);
    this.editModuleForm.controls['trimLevelControl'].setValue(this.all);
    this.editModuleForm.controls['saleStartControl'].setValue(this.all);
    this.editModuleForm.controls['saleEndControl'].setValue(this.all);
    this.editModuleForm.controls['freeControl'].setValue(null);

    this.sshow('basket');
    this._specsService.loadDefaultBasket().subscribe(res => {
      // console.log(res);
      this.setupComboInitialValues(res.allowedValues.market);
      this.shide('basket');
      this.sshow('preferences');
      this.preferencesService.loadUserPreferences().subscribe(prefs => {
        if (!!prefs.filterValues && Object.keys(prefs.filterValues).length > 0) {
          this.applyDefaultFilters(prefs);
        } else {
          this.firstCSPage = res.carSpecs;
          this.initFirstPageAC();
          this.totalFilteredItems = res.carSpecs.totalElements;
        }
        this.setupComboEvents();
        this.shide('preferences');
      }, error => {
        this.firstCSPage = res.carSpecs;
        this.initFirstPageAC();
        this.totalFilteredItems = res.carSpecs.totalElements;
        this.setupComboEvents();
        this.shide('preferences');
        this.messageService.showMessage("Error loading preferences!");
      });
    }, error => {
      this.shide('basket');
      this.messageService.showMessage("Error loading default basket!");
    }
    );

  }

  loadPagesCarsSpecs(filters: CarsSpecFilters): Observable<any> {
    let req = this._specsService.turnFilterToSpecRequest(this.getCurrentFilter(), "newPage", this.selectedFilters, this.currencyID);
    return this._specsService.postForFilters(req, filters);
  }

  setupComboEvents() {
    this.editModuleForm.controls['marketControl'].valueChanges.subscribe(
      value => {
        this.uploadedFilter = null;
        this.isUploadAllowed = (this.all === value);
        this.doComboFilter(value, 'makeControl');
      }
    );
    this.editModuleForm.controls['makeControl'].valueChanges.subscribe(
      value => {
        this.doComboFilter(value, 'modelControl');
      }
    );
    this.editModuleForm.controls['modelControl'].valueChanges.subscribe(
      value => {
        this.doComboFilter(value, 'trimLevelControl');
      }
    );
    this.editModuleForm.controls['trimLevelControl'].valueChanges.subscribe(
      value => {
        this.doComboFilter(value, 'saleStartControl');
      }
    );
    this.editModuleForm.controls['saleStartControl'].valueChanges.subscribe(
      value => {
        this.doComboFilter(value, 'saleEndControl');
      }
    );
    this.editModuleForm.controls['saleEndControl'].valueChanges.subscribe(
      value => {
        this.customFilterChange(null);
      }
    );
  }
  setupComboInitialValues(data) {
    this.combos['marketControl'] = data;
    this.editModuleForm.controls['makeControl'].disable();
    this.editModuleForm.controls['modelControl'].disable();
    this.editModuleForm.controls['trimLevelControl'].disable();
    this.editModuleForm.controls['saleStartControl'].disable();
    this.editModuleForm.controls['saleEndControl'].disable();
  }

  doComboFilter(value, detail) {
    this.editModuleForm.controls[detail].setValue(this.all);
    if (this.all === value) {
      this.editModuleForm.controls[detail].disable();
    } else {
      this.sshow(detail);
      let scope = this.getComboFilters();
      let crit = {};
      Object.keys(scope).forEach(key => {
        if (scope[key] != this.all) {
          crit[key] = scope[key];
        }
      });
      let valuesFor = this.replaceControlString(detail);
      this._specsService.getScope(crit, valuesFor).subscribe((data) => {
        console.log('detail filters', detail, data);
        this.combos[detail] = (data) ? data : [];
        this.editModuleForm.controls[detail].enable();
        // this.shide(detail)
      },
        error => {
          this.shide('detail');
          this.messageService.showMessage("Error loading list " + detail);
        });
    }

  }

  closeDialog() {
    this.dialogRef.close();
  }

  finishSelection(selectedScenario) {
    if (selectedScenario.length === 0) {
      this.messageService.showMessage("Please select scenario!");
      return;
    }
    this.dialogRef.close({ cars: this.selCarRows, scens: selectedScenario });
  }

  replaceControlString(k) {
    let re = /Control$/i;
    return k.replace(re, '');
  }

  getComboFilters() {
    let filter = {};

    for (let key in this.editModuleForm.controls) {
      let value = this.editModuleForm.controls[key].value;
      if (key === 'freeControl' && value === '') {
        delete (filter[key]);
      } else if (value === '' || value) {
        filter[this.replaceControlString(key)] = value;
      }
    }
    return filter;
  }

  getCurrentFilter() {
    const filter = this.getComboFilters();
    if (this.filterTemplate) {
      // console.log('real template', this.filterTemplate);
      Object.keys(this.filterTemplate).forEach(key => {
        if (this.isRange(key)) {
          // console.log('will use ', this.filterTemplate[key].from, this.filterTemplate[key].to), key;
          filter[key] = this.filterTemplate[key];
        } else if (this.isList(key)) {
          // console.log('will use ', this.filterTemplate[key].selected);
          filter[key] = this.filterTemplate[key].selected;
        }
      });
    }
    if (this.isUploadAllowed && this.uploadedFilter != null) {
      filter['natCode'] = this.uploadedFilter;
    }

    // console.log('big filter', filter);
    return filter;
  }

  checkEnableButton(): void {
  }

  onClickNextPage() {
    let prefs = this.preferencesService.getUserPreferencesNow();
    //if (!this.stringSetsAreEqual(prefs.filterState, this.selectedFilters)) {
    prefs.filterState = this.selectedFilters;
    prefs.filterCombos = this.serializeCurrentCombos();
    prefs.filterValues = this.serializeCurrentFilterValues();
    this.preferencesService.saveUserPreferences(prefs).subscribe(res => {
      // do nothing
    });
    //}
    this.numberPage = 1;
  }

  serializeCurrentCombos() {
    let result = {};
    for (let key in this.editModuleForm.controls) {
      if (key !== 'freeControl') {
        let value = this.editModuleForm.controls[key].value;
        result[key+"Value"] = value;
        result[key] = this.combos[key];
      }
    }
    return result;
  }

  serializeCurrentFilterValues() {
    let filter = this.filterTemplate;
    let result = {};
    Object.keys(filter).forEach(key => {
      if (!!filter[key] && filter[key] != {}) {
        if (this.isList(key)) {
          var val = {};
          val["values"] = Array.from(filter[key].values);
          val["selected"] = Array.from(filter[key].selected);
          result[key] = JSON.stringify(val);
        } else {
          result[key] = JSON.stringify(filter[key]);
        }
      }
    });
    return result;
  }

  applyDefaultFilters(prefs) {
    let filterCombos = prefs.filterCombos;
    Object.keys(this.editModuleForm.controls).forEach(key => {
      if (!!filterCombos[key]) {
        if (Object.keys(filterCombos[key + "Value"]).length == 0) {
          this.editModuleForm.controls[key].setValue(this.all);
        } else {
          this.editModuleForm.controls[key].setValue(filterCombos[key+"Value"]);
        }
        this.editModuleForm.controls[key].enable();
        this.combos[key] = filterCombos[key];
      } else {
        this.editModuleForm.controls[key].setValue(this.all);
        this.editModuleForm.controls[key].disable();
      }
    });
    this.editModuleForm.controls['freeControl'].setValue(null);
    this.editModuleForm.controls['freeControl'].enable();
    delete this.combos['freeControl'];

    if (prefs.filterState != null && prefs.filterState.length > 0) {
      this.selectedFilters = prefs.filterState;
      this.nestedForm.controls.filters.setValue(prefs.filterState);
      prefs.filterState.forEach(key => {
        if (!!prefs.filterValues[key]) {
          let val = JSON.parse(prefs.filterValues[key]);
          if (this.isList(key)) {
            val["values"] = new Set(val["values"]);
            val["selected"] = new Set(val["selected"]);
          }
          this.filterTemplate[key] = val;
        }
      });
      this.onFormSubmit(null);
      this.filteresNotYetLoaded = true;
      this.showCustomFilters = true;
    } else {
      this.customFilterChange(null)
      this.showCustomFilters = false;
    }
  }

  onClickBackPage() {
    this.numberPage = 0;
  }
  onClickFilters() {
    if (!this.showCustomFilters && this.filteresNotYetLoaded) {
      let prefs = this.preferencesService.getUserPreferencesNow();
      if (prefs.filterState != null && prefs.filterState.length > 0) {
        this.nestedForm.controls.filters.setValue(prefs.filterState);
        this.onFormSubmit(null);
        this.filteresNotYetLoaded = true;
      }
    }
    this.showCustomFilters = !this.showCustomFilters;
  }

  initFirstPageAC(): void {
    this.availableCarsGrid.replaceDataSource(this.firstCSPage);
  }

  ngAfterViewChecked(): void {
    this.checkEnableButton();
  }

  onFormSubmit(event) {
    // when adding/ removing filters, make sure to clean up everything that is potentially changed
    // i.e.
    // when filter 1 is removed, then all combos after it must be reloaded!
    // when filter 1 is dragged to front, then its ceil should be reloaded!
    let oldFilters = this.selectedFilters;
    this.selectedFilters = this.nestedForm.value.filters;
    let lastItem: string = null;
    let dependencyChainBroken = false;
    // lets make sure the current filters are not destroyed.
    if (oldFilters && oldFilters.length > 0) {
      let remainingFilters = oldFilters.filter(item => this.selectedFilters.indexOf(item) >= 0);
      if (remainingFilters && remainingFilters.length > 0) {
        for (let i = 0; i < oldFilters.length; i++) {
          if (remainingFilters.indexOf(oldFilters[i]) < 0) {
            delete this.filterTemplate[oldFilters[i]];
            dependencyChainBroken = true;
          } else {
            if (!dependencyChainBroken) {
              lastItem = oldFilters[i];
            }
          }
        }
        let newFilters = this.selectedFilters.filter(item => oldFilters.indexOf(item) < 0);
        newFilters.forEach(x => console.log("new Filter", x));
        this.selectedFilters = remainingFilters.concat(newFilters);
      }
    }
    this.internalFilterChange(lastItem);
  }

  isRange(key: string) {
    return (this.filtersMETA[key].type === 'int' || this.filtersMETA[key].type === 'float') && this.filterTemplate.hasOwnProperty(key) && (this.filterTemplate[key].from !== null) && (this.filterTemplate[key].to !== null);
  }

  isNaN(key: string) {
    // console.log('checking for null', this.filterTemplate[key], this.filtersMETA[key]);
    return (this.filtersMETA[key].type === 'int' || this.filtersMETA[key].type === 'float') && this.filterTemplate.hasOwnProperty(key) && (this.filterTemplate[key].from === null || this.filterTemplate[key].to === null);
  }

  private lastFilterTimer: NodeJS.Timeout = null;

  applyChangesToComboFilters(cceResponse, index) {
    let allowedValues = (cceResponse.allowedValues) ? cceResponse.allowedValues : {};
    if (index <= 0) {
      for (let i = 0; i < this.selectedFilters.length; i++) {
        let key = this.selectedFilters[i];
        if (this.filtersMETA.hasOwnProperty(key) && allowedValues.hasOwnProperty(key)) {
          this.filterTemplate[key] = this._specsService.aggregate(this.filtersMETA[key], allowedValues[key], this.currencySign);
          console.log('fixing all, current combo :' + key, allowedValues[key]);
        } else {
          console.log('fixing all, missing key <' + key + '>', this.filtersMETA, allowedValues)
        }
      }
    } else {
      if (index != this.selectedFilters.length - 1) {
        // if there are combos on the right, clear them!
        for (let i = index + 1; i < this.selectedFilters.length; i++) {
          let key = this.selectedFilters[i];
          delete this.filterTemplate[key];
          if (this.filtersMETA.hasOwnProperty(key) && allowedValues.hasOwnProperty(key)) {
            this.filterTemplate[key] = this._specsService.aggregate(this.filtersMETA[key], allowedValues[key], this.currencySign);
            console.log('fixing right, setting :' + key, allowedValues[key]);
          } else {
            console.log('fixing right, missing key <' + key + '>', this.filtersMETA, allowedValues)
          }
        }
      }
    }
  }

  applyChangesToBasket(cceResponse) {
    let data: any = {};
    data.content = cceResponse.carSpecs.content;
    data.totalElements = cceResponse.carSpecs.totalElements;
    this.availableCarsGrid.replaceDataSource(data);
    this.totalFilteredItems = cceResponse.carSpecs.totalElements;
  }

  executeApplyFilterChange(activeCombo) {
    // console.log('Request to fix the filter for: ' + activeCombo, this.selectedFilters);
    let filters: CarsSpecFilters = new CarsSpecFilters();
    let sort = this.availableCarsGrid.getGridSorting();
    if (sort && sort[0]) {
      filters.setColSort(sort[0].colId);
      filters.setSortOrder(sort[0].sort);
    }
    if (activeCombo === null || activeCombo === "sort") {
      // master filter changed
      // console.log('master');
      this.filterTemplate = [];
      let req = this._specsService.turnFilterToSpecRequest(this.getCurrentFilter(), activeCombo, this.selectedFilters, this.currencyID);
      return this._specsService.postForFilters(req, filters).pipe(
        mergeMap(
          cceResponse => {
            console.log('applying master response', cceResponse);
            this.applyChangesToComboFilters(cceResponse, 0);
            this.applyChangesToBasket(cceResponse);
            return of(this.filterTemplate);
          }
        )
      );
    } else {
      // console.log('filter', this.getCurrentFilter());
      let index = -1;
      for (let i = 0; i < this.selectedFilters.length; i++) {
        if (activeCombo === this.selectedFilters[i]) {
          index = i;
          break;
        };
      }
      if (index < 0) {
        console.log("Why am I here?!");
        return of([]);
      } else {
        let req = this._specsService.turnFilterToSpecRequest(this.getCurrentFilter(), activeCombo, this.selectedFilters, this.currencyID);
        return this._specsService.postForFilters(req, filters).pipe(
          mergeMap(cceResponse => {
            console.log('applying response for index ' + index + ' and ' + activeCombo, cceResponse);
            this.applyChangesToBasket(cceResponse);
            this.applyChangesToComboFilters(cceResponse, index);
            return of(this.filterTemplate);
          })
        );
      }
    }
  }

  showSelectedCarsInAvailableCarsGrid(maxRetries) {
    let allSelectedCars = this.selectedCarsGrid.selectedCars;
    if (allSelectedCars !== null && allSelectedCars.length > 0) {
      let allRenderedRows = this.availableCarsGrid.theGrid.api.getRenderedNodes();
      for (let i in allRenderedRows) {
        for (let j in allSelectedCars) {
          let rowNode = allRenderedRows[i];
          if (!rowNode.data) {
            //TODO ISPASOV not YET loaded
            // console.log(rowNode.data) is later filled!
            if (maxRetries > 0) {
              console.log('grid is not ready, one retry!', rowNode);
              setTimeout(() => this.showSelectedCarsInAvailableCarsGrid(maxRetries - 1), 500);
            } else {
              console.log('grid is not ready, no retries!', rowNode);
            }
            return;
            //TODO ISPASOV not YET loaded
            // console.log(rowNode.data) is later filled!
          } else {
            if (rowNode.data.carID === allSelectedCars[j].carID) {
              rowNode.setSelected(true);
            }
          }
        }
      }
    }
  }

  internalFilterChange(event) {
    // console.log('filter change', this.filterTemplate[event]);
    // this.selectedFilters = this.nestedForm.value.filters;
    if (event) {
      this.sshow((event) ? event : 'empty filter');
    }
    this.executeApplyFilterChange(event).subscribe((x) => {
      this.shide((event) ? event : 'empty filter');
    },
      error => {
        this.shide((event) ? event : 'empty filter');
        this.messageService.showMessage("Error loading list " + event);
      });
  }

  customFilterChange(event) {
    if (this.lastFilterTimer != null) {
      clearTimeout(this.lastFilterTimer);
      this.lastFilterTimer = null;
    }
    this.lastFilterTimer = setTimeout(() => this.internalFilterChange(event), 500);
  }

  isList(key: string) {
    return this.filtersMETA[key].type === 'set';
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedFilters, event.previousIndex, event.currentIndex);
    let minIdx = (event.previousIndex < event.currentIndex) ? event.previousIndex : event.currentIndex;
    if (minIdx !== 0) {
      this.customFilterChange(this.selectedFilters[minIdx - 1]);
    } else {
      console.log('Selector moved to start, renew all!');
      this.customFilterChange(null);
    }
  }

  getStyle(elem, item): string {
    return (this.filterTemplate[elem]['selected'].has(item)) ? 'cce-filter-item-selected' : 'cce-filter-item';
  }

  sortSet(key) {
    if (!key) {
      return [];
    } else {
      let fixed = Array.from(key);
      if (fixed && fixed.length > 0) {
        fixed = fixed.sort();
      }
      return fixed;
    }
  }

  toggleSelection(element: string, item: string) {
    if (this.filterTemplate[element].selected) {
      if (this.filterTemplate[element].selected.has(item)) {
        this.filterTemplate[element].selected.delete(item);
      } else {
        this.filterTemplate[element].selected.add(item);
      }
    }
    this.customFilterChange(element);
    // console.log('selected', this.filterTemplate[element].selected);
  }

  clearAllScenarios() {
    this.availableScenarioGrid.selectedScenarios = [];
    this.selectedScenarioGrid.selectedScenario = [];
    this.selectedScenarioGrid.refreshGridData();
  }

  semaphor = 0;
  sshow(reason?) {
    console.log('showing for ' + reason, this.semaphor, this.semaphor + 1)
    this.spinner.show();
    this.semaphor++;
  }

  shide(reason?) {
    console.log('hiding for ' + reason, this.semaphor, this.semaphor - 1)
    this.semaphor--;
    if (this.semaphor <= 0) {
      this.semaphor = 0;
      this.spinner.hide();
    }
  }

  upload(event: any) {
    this.sshow('upload');
    this.selectedFiles = event.target.files;
    this.progress.percentage = 0;
    let currentFileUpload = this.selectedFiles.item(0);

    const data: FormData = new FormData();
    data.append('file', currentFileUpload);

    this.http.post<any>(environment.uploadEnpoint, data).subscribe(response => {
      this.uploadedFilter = response;
      this.internalFilterChange(null);
    },
      error => {
        this.messageService.showMessage("Error uploading file!");
        this.shide('upload');
      });
    this.myUpload.nativeElement.value = '';
  }

  applyFilter(filters) {
    this.availableCarsGrid.applyFilter(filters);
  }

  goBackTo0() {
    this.numberPage = 0;
    setTimeout(() => {
      this.customFilterChange(null);
    }, 0);
  }

  private stringSetsAreEqual(s1: string[], s2: string[]): boolean {
    if (s1 === null) {
      return s2 === null;
    } else {
      if (s2 === null) {
        return false;
      } else {
        if (s1.length != s2.length) {
          return false;
        } else {
          return s1.every((el, idx) => { return el === s2[idx]; });
        }
      }
    }
  }
}


