<div id="scenario-page">
  <div class="cce-header-nbc-scenario">
    <span id="boldedText" style=" font-weight: bold;">{{section}}</span>
    <span id="boldedText">{{sectionName}}</span>
  </div>
  <div class="cce-header-nbc-scenario-body">
    <div class="cce-row" style="padding: 10px">
      <div class="months cce-col" style=" flex: 2; text-align: center; padding-left: 10px;">
        <span>DURATION</span>
      </div>
      <div class="cce-col" style="flex:14; padding-left: 10px">
        <span>DISTANCE</span>
      </div>
      <div class="cce-col" style=" flex:1; text-align: left">
        <span><button (click)="clearAllScenarios()" class="cce-header-blue-button">Clear all</button></span>
      </div>
      <div class="cce-col" style=" flex:4; text-align: center">
        <span> SELECTED SCENARIOS </span>
      </div>
      <ng-template [ngIf]="ShowDefault">
        <div class="cce-col cce-header-blue-button" style=" flex:1; text-align: right;">
          <span><button (click)="loadDefaultScenarios()" class="cce-header-blue-button">Default</button></span>
        </div>
      </ng-template>
    </div>
    <div class="cce-row">
      <app-available-scenario [defScenarios]="defScenarios" #availableScenarioGrid class="app-available-scenario"
        [parent]="this" [scenarioRows]="scenRows">
      </app-available-scenario>

      <app-selected-scenario [selectedScenario]="defScenarios" (updateScenarios)="forwardToParent($event)"
        #selectedScenarioGrid class="app-selected-scenario" [parent]="this">
      </app-selected-scenario>
    </div>
  </div>
</div>
