import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';

@Component({
  selector: 'app-admin-add-user',
  templateUrl: './admin-add-user.component.html',
  styleUrls: ['./admin-add-user.component.scss']
})
export class AdminAddUserComponent implements OnInit {

  isOldUser = true;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private messageService: HandleUiMessagesService, private formBuilder: FormBuilder, private http: HttpClient, public dialogRef: MatDialogRef<AdminAddUserComponent>) {
    this.id = null;
    if (!!data && !!data.id) {
      this.title = "EDIT USER";
      this.id = data.id;
      let parameters = {};
      let user = JSON.parse(sessionStorage['user']);
      parameters['userId'] = data.id;
      this.http.get(environment.getUser, { params: parameters }).subscribe(response => {
        if (!!response && !!response["dataExtension"]) {
          this.userForm.controls.description.setValue(response["dataExtension"]["description"]);
        }
        this.userForm.controls.userName.setValue(response["name"]);
        let policies = response["policies"];
        for (let i = 0; i < policies.length; ++i) {
          let policy = policies[i]["name"];
          if(!(this[policy] === undefined)) {
              this[policy]._checked = true;
          }
        }
        let roles = response["roles"];
        for (let i = 0; i < roles.length; ++i) {
          let role = roles[i]["name"];
          for (let j = 0; j < this.roles.length; ++j) {
            if (this.roles[j]['name'] === role) {
              this.roles[j]['selected'] = true;
            }
          }
        }
      }, error => {
        this.messageService.showMessage(error.error);
      });
    } else {
      this.isOldUser = false;
    }
  }

  @ViewChild('cceOpenLocal') cceOpenLocal;
  @ViewChild('cceOpenRemote') cceOpenRemote;
  @ViewChild('cceSaveLocal') cceSaveLocal;
  @ViewChild('cceSaveRemote') cceSaveRemote;
  @ViewChild('cceExportPDF') cceExportPDF;
  @ViewChild('cceExportExcel') cceExportExcel;
  @ViewChild('cceExportExcelFull') cceExportExcelFull;

  id: any;
  userForm: FormGroup;
  roles: any;
  submitCheck:boolean = false;
  usernamePristine(): boolean {
    return this.userForm.controls.userName.pristine && !this.submitCheck;
  }
  passwordPristine(): boolean {
    return this.userForm.controls.password.pristine && !this.submitCheck;
  }

  firstSubmit() {
    this.submitCheck = true;
  }

  user = {
    username: "",
    password: "",
    description: ""
  };
  title = "ADD NEW USER";
  ngOnInit(): void {
    let role = {};
    role['name'] = "cceUser";
    role['selected'] = false;
    let role2 = {};
    role2['name'] = "cceClientAdmin";
    role2['selected'] = false;
    let role3 = {};
    role3['name'] = "cceRestricted";
    role3['selected'] = false;
    this.roles = [role, role2, role3];

    let passwordValidators = [Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#%&-_])[A-Za-z\d@$!%*?#%&-_]{8,32}$/)];
    passwordValidators.push(Validators.minLength(10));
    // if (!this.id) {
    // passwordValidators.push(Validators.required);
    // }
    this.userForm = this.formBuilder.group({
      userName: [null, Validators.compose([Validators.required,
        Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)])],
      description: [null, Validators.compose([
        Validators.minLength(0)
      ])],
      password: [null,
      Validators.compose(passwordValidators)],
    });
  }

  getStyle(item): string {
    return (item['selected']) ? 'cce-filter-item-selected' : 'cce-filter-item';
  }

  toggleSelection(item) {
    if (item['selected']) {
      item['selected'] = false;
    } else {
      item['selected'] = true;
    }
  }

  onSubmit() {
    this.firstSubmit();
    if (this.userForm.invalid) {
      return;
    }
    this.user["roles"] = this.roles;
    this.user["policies"] = [
      {
        name: "cceOpenLocal",
        selected: this.cceOpenLocal._checked
      },
      {
        name: "cceOpenRemote",
        selected: this.cceOpenRemote._checked
      },
      {
        name: "cceSaveLocal",
        selected: this.cceSaveLocal._checked
      },
      {
        name: "cceSaveRemote",
        selected: this.cceSaveRemote._checked
      },
      {
        name: "cceExportPDF",
        selected: this.cceExportPDF._checked
      },
      {
        name: "cceExportExcel",
        selected: this.cceExportExcel._checked
      },
      {
        name: "cceExportExcelFull",
        selected: this.cceExportExcelFull._checked
      }
    ];
    if (!!this.id) {
      this.user["userId"] = this.id;
      this.http.put(environment.updateUser, this.user).subscribe(response => {
        this.dialogRef.close(true);
        this.messageService.showMessage(response["message"]);
      }, error => {
        this.messageService.showMessage(error.error["message"]);
      });
    } else {
      this.http.post(environment.createUser, this.user).subscribe(response => {
        this.dialogRef.close(true);
        this.messageService.showMessage(response["message"]);
      }, error => {
        this.messageService.showMessage(error.error);
      });
    }
  }





  onClose() {
    this.dialogRef.close(false);
  }

}
