<div id="header-topnav">
    <img src="/assets/CCE.png">
    <span class="cce-spacer"></span>
    <span class="header-span"><a class="header-link" href="https://autovistagroup.com/copyright-notices" target="_blank">Copyright & Use Notices</a></span>
    <mat-divider class="header-div" [vertical]="true"></mat-divider>
    <span class="header-span">{{userName}}</span>
    <mat-divider class="header-div" [vertical]="true"></mat-divider>
    <span class="header-span">{{companyName}}</span>
    <mat-divider class="header-div" [vertical]="true"></mat-divider>
    <a class="header-link" (click)="openAdmin()" [style.display]="isAdmin && hasAdminButton ? 'block' : 'none'">
        <mat-icon class="header-icon" title="Administration">supervised_user_circle</mat-icon>
    </a>
    <!-- <a class="header-link" (click)="openAbout()">
        <mat-icon class="header-icon" title="About">info_outline</mat-icon>
    </a> -->
  <a class="header-link" (click)="openHelp()">
    <mat-icon class="header-icon" title="Help">help_outline</mat-icon>
  </a>
  <mat-divider class="header-div" [vertical]="true"></mat-divider>

    <a class="header-link" (click)="logout()">
        <mat-icon class="header-icon" title="Logout">power_settings_new</mat-icon>
    </a>
</div>
