import { Component, OnInit, Input, ViewChild, AfterContentChecked, AfterViewInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { getColIndex22ToLength } from '@app/helpers/utils.js';
import { environment } from '@environments/environment';


@Component({
  selector: 'app-csr-image-url',
  templateUrl: './csr-image-url.component.html',
  styleUrls: ['./csr-image-url.component.scss']
})
export class CsrImageURLComponent implements OnInit {

  @Input() params: ICellRendererParams;
  value;
  isOldImage: Boolean;

  @ViewChild('image') image;

  constructor() {
  }
  ngOnInit(): void {
    const indexCol = getColIndex22ToLength(this.params.colDef.field);
    const idsCol = this.params.context.getDataSheet().columnIds[indexCol];

    if (this.params.data.values[idsCol] === null) {
      this.value = '';
      return;
    }

    var imageRef: String = this.params.data.values[idsCol];
    if(imageRef.startsWith('http://') || imageRef.startsWith('https://') ) {
        this.value = imageRef;
        this.isOldImage = true;
    }else {
        this.value = environment.imgEndpoint + this.params.data.values[idsCol];
        this.isOldImage = false;
    }
  }
}
