import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { CalcSheetComponent } from '../calc-sheet/calc-sheet.component';
import { EquipmentComponent } from '../equipment/equipment.component';
import { MaintenanceComponent } from '../maintenance/maintenance.component';
import { MainComponent } from '../main/main.component';
import { Sheet } from '@app/domain/sheet';
import { Observable } from 'rxjs';
import { RequestDataNewCols } from '@app/domain/RequestDataNewCols';
import { HttpClient } from '@angular/common/http';
import { catchError, map, share, timeout } from 'rxjs/operators';
import { environment, metadata } from '@environments/environment';
import { SheetService } from '@app/services/sheet.service';
import { Constants } from '@app/helpers/Constants';
import { EqualizationResult } from '@app/domain/equalization-result';
import * as lodash from 'lodash'; //copy array
import { getNameOfColumn, replacer, getVehicleFromSheet } from '@app/helpers/utils.js';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { copyCalcModel } from '@app/domain/calc-model';
import { calcPriceByRate } from '@app/helpers/utils.js';
import { MixPanelService } from '@app/services/mix-panel.service';
import { LocalStorageService } from '@app/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { CarSuccesssors } from '@app/domain/CarSuccesssors';
import { SuccessorsChooseComponent } from '../successors-choose/successors-choose.component';
import { ScenarioService } from '@app/services/scenario.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.scss']
})
export class SheetComponent implements OnInit {
  TEXT_COLOR_GREEN = Constants.TEXT_COLOR_GREEN;
  TEXT_COLOR_RED = Constants.TEXT_COLOR_RED;
  TEXT_COLOR_GRAY = Constants.TEXT_COLOR_GRAY;
  TEXT_COLOR_BLUE = Constants.TEXT_COLOR_BLUE;

  SP_PARTS_DESCRIPTION = Constants.SP_PARTS_DESCRIPTION;
  SP_PRICE = Constants.SP_PRICE;
  SP_QTY = Constants.SP_QTY;
  SP_ADD = Constants.SP_ADD;
  SP_DEL = Constants.SP_DEL;
  WP_PARTS_DESCRIPTION = Constants.WP_PARTS_DESCRIPTION;
  WP_PRICE = Constants.WP_PRICE;
  WP_QTY = Constants.WP_QTY;
  WP_ADD = Constants.WP_ADD;
  WP_DEL = Constants.WP_DEL;


  readonly ACTIVE_TOTAL = Constants.ACTIVE_TOTAL;
  readonly ACTIVE_KM = Constants.ACTIVE_KM;
  readonly ACTIVE_MONTH = Constants.ACTIVE_MONTH;
  readonly jp = require('jsonpath');

  @Input() private sheetIdx: number;
  private sheet: Sheet;
  @ViewChild(CalcSheetComponent) private calcSheet: CalcSheetComponent;
  @ViewChild(EquipmentComponent) private equipmentSheet: EquipmentComponent;
  @ViewChild(MaintenanceComponent) private maintenanceSheet: MaintenanceComponent;
  @Input() typeView;
  @Input() parent: MainComponent;
  @Input() columnsWidths;
  @Input() expandedOldRowsEquip;
  @Input() expandedOldRowsTCO;
  @Input() expandedOldRowsMaint;
  @Input() isPDF;

  private colID = 0;
  semaphor = 0;
  private stopMissingVehicleMeesage = false;


  constructor(
    private sheetService: SheetService, private _scenService: ScenarioService, private mixPanelServ: MixPanelService,
    private messageService: HandleUiMessagesService, private localStorageService: LocalStorageService,
    private http: HttpClient, private successorChoose: MatDialog, private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.sheet = this.sheetService.getSheet(this.sheetIdx);
  }

  getSheet(): Sheet {
    return this.sheet;
  }

  getSheetIdx() {
    return this.sheetIdx;
  }
  getTCOComp() {
    return this.calcSheet;
  }
  getEquipmentComp() {
    return this.equipmentSheet;
  }
  getMaintenanceComp() {
    return this.maintenanceSheet;
  }

  public addScenario(idsCol, selectedScenario) {
    let id_car: string;
    for (let index = 0; index < this.sheet.columnIds.length; index++) {
      if (this.sheet.columnIds[index] === idsCol) {
        id_car = this.getOldValue(idsCol, "id");
      }
    }

    const requestData = [];
    for (let i = 0; i < selectedScenario.length; i++) {
      let additional: RequestDataNewCols = new RequestDataNewCols(++this.colID, {}, selectedScenario[i]);
      getVehicleFromSheet(this.getSheet(), additional.vehicle, idsCol);
      // delete additional.forLoad;
      requestData[i] = additional;
    }

    // console.log("requestData", requestData);
    const requestObj: any = {};
    requestObj.sheet = JSON.parse(JSON.stringify(this.sheet));
    requestObj.idCar = id_car;
    requestObj.requestData = requestData;

    const r = this.http.post<any>(environment.addScenario, requestObj).pipe(share());
    r.subscribe((response) => {
      if (response.successors != null && response.successors.length > 0) {
        let fromScenario: any = {};
        fromScenario.scenarios = selectedScenario;
        fromScenario.idCol = idsCol;
        if (this.checkForAnySuccessor(response.successors)) {
          this.successorsChoose(response.successors, this.getSheet(), undefined, fromScenario);
        } else {
          this.messageService.showMessage("This vehicle is no longer available and there is no successor!");
        }
      } else if (response.sheet && response.sheet.columnIds && !this.isMissing(response.sheet.columnIds[0], response.sheet.tco)) {
        this.addCols(response);
      } else {
        this.messageService.showMessage("This vehicle is no longer available!");
      }
    });
    return r;

  }

  getOldValue(idsCol, field) {
    let path = metadata[field].path[0] + '.values.' + idsCol;
    const jp = require('jsonpath');
    const obj = jp.query(this.sheet.dataTCO, path);
    return obj[0];
  }

  getValueFromTCO(path) {
    const jp = require('jsonpath');
    const obj = jp.query(this.sheet.dataTCO, path);
    return obj[0];
  }

  public addCar(selCarRows, selectedScenario): Observable<any> {
    let carNames = this.getCurrentCarNames();
    //make new col
    const requestData: RequestDataNewCols[] = [];
    for (const vehicle of selCarRows) {
      for (const scen of selectedScenario) {
        const additional: RequestDataNewCols = new RequestDataNewCols(++this.colID, vehicle, scen);
        requestData.push(additional);
      }
    }

    const requestObj: any = {};
    requestObj.sheet = this.sheet;
    requestObj.requestData = requestData;

    const r = this.http.post<any>(environment.addVehicleEndpoint, requestObj).pipe(timeout(600000), // 10 minutes timeout
      share());
    r.subscribe((response) => {
      if (this.sheet.columnIds === undefined || this.sheet.columnIds.length === 0) {
        this.loadDataFromServer(response, carNames);
        let additionalObj = {
          eventKey: "NewSheet"
        }
        let endpoint = '/cce/transactions/NewSheet';
        this.mixPanelServ.logData(additionalObj, endpoint);
      } else {
        this.addCols(response);
      }
    });
    return r;
  }

  isMissing(colId, dataTCO) {
    const jp = require('jsonpath');
    let idPath = metadata['id'].path[0];
    const obj = jp.query(dataTCO, idPath + '.values[\'' + + colId + '\']');
    if (!obj || obj.length == 0 || obj[0] === '__') {
      return true;
    } else {
      return false;
    }
  }

  getScenarioFromSheet(sheet, columnID) {
    let path = '$[?(@.field==\'scenario\')].children[?(@.field==\'distance\')]' + '.values[\'' + columnID + '\']';
    let scenDistance = this.jp.query(sheet.dataTCO, path)[0];
    path = '$[?(@.field==\'scenario\')].children[?(@.field==\'duration\')]' + '.values[\'' + columnID + '\']';
    let scenDuration = this.jp.query(sheet.dataTCO, path)[0];
    return this._scenService.getScenarioObject(sheet.distanceID, scenDistance, scenDuration);
  }

  successorsChoose(cars: CarSuccesssors[], oldSheet: Sheet, sheetForCalc: Sheet, fromScenario?) {
    // console.log(cars, "oldSheet", oldSheet, scenarios)
    //fromScenario separate and use other here and in newScenario in header
    for (let index = 0; index < cars.length; index++) {
      const car = cars[index];
      car.scenarios = [];// create field, BE response have no this

      if (!!fromScenario && !!fromScenario.scenarios) {
        for (let i = 0; i < fromScenario.scenarios.length; i++) {
          car.scenarios[i] = fromScenario.scenarios[i];
        }
        //this case is addScenario and is for ONE car
        cars = cars.splice(0, 1);
        break;
      } else {
        if (!!oldSheet) {
          //this is scenario is for car description in successorsDialog
          car.parent.scenario = this.getScenarioFromSheet(oldSheet, car.columnID);
        }
        //this is scenarios for load calculation
        car.scenarios[0] = this.getScenarioFromSheet(sheetForCalc, car.columnID);
      }

    }
    const dialogRef = this.successorChoose.open(SuccessorsChooseComponent, {
      width: "85vw", minWidth: "1200px", maxHeight: "80vh", panelClass: "cce-dialog-container", data: {
        cars: cars,
        oldSheet: oldSheet,
        colIDFromAddScenario: fromScenario ? fromScenario.idCol : undefined
      }
    }).afterClosed().subscribe(response => {
      // console.log('DONE!response', response);\
      if (response !== undefined && response.successors !== null && response.length != 0) {
        this.spinner.show();
        this.addCarsForScens(response).subscribe(data => {
          // console.log('DONE!');
          this.spinner.hide();
        },
          error => {
            console.log("error", error);
            let errorMsg = "Error adding vehicle calculation";
            if (!!error && !!error.error && !!error.error.status) {
              errorMsg = error.error.status;
            }
            this.messageService.showMessage(errorMsg);
            this.spinner.hide();
          });
        // console.log("this.getActiveSheet()", this.getActiveSheet());
      }
    });
  }

  public addCarsForScens(cars: any[]): Observable<any> {
    let carNames = this.getCurrentCarNames();
    //make new col
    const requestData: RequestDataNewCols[] = [];
    for (const car of cars) {
      for (const successor of car.successors) {
        for (const scenario of car.scenarios) {
          const additional: RequestDataNewCols = new RequestDataNewCols(++this.colID, successor, scenario);
          requestData.push(additional);
        }
      }
    }
    const requestObj: any = {};
    requestObj.sheet = this.sheet;
    requestObj.requestData = requestData;

    const r = this.http.post<any>(environment.addVehicleEndpoint, requestObj).pipe(timeout(600000), // 10 minutes timeout
      share());
    r.subscribe((response) => {
      if (this.sheet.columnIds === undefined || this.sheet.columnIds.length === 0) {
        this.loadDataFromServer(response, carNames);
        let additionalObj = {
          eventKey: "NewSheet"
        }
        let endpoint = '/cce/transactions/NewSheet';
        this.mixPanelServ.logData(additionalObj, endpoint);
      } else {
        this.addCols(response);
      }
    });
    return r;
  }

  loadInNewSheet(oldSheet: Sheet, sheetForCalc: Sheet) {
    const carNames = this.sheetService.getCarNamesBeforeCall(sheetForCalc);
    const requestData = [];
    for (let i = 0; i < sheetForCalc.columnIds.length; i++) {
      let additional: RequestDataNewCols = new RequestDataNewCols(sheetForCalc.columnIds[i], {}, {});
      requestData[i] = additional;
    }
    this.startSheetBasedOnOld();

    const requestObj: any = {};
    requestObj.sheet = sheetForCalc;
    requestObj.requestData = requestData;
    const r = this.http.post<any>(environment.addNewSheetEndpoint, requestObj).pipe(
      map((res: any) => {
        let haveSuccessors = this.checkForAnySuccessor(res.successors);
        this.stopMissingVehicleMeesage = haveSuccessors;
        this.loadDataFromServer(res, carNames);
        if (haveSuccessors) {
          this.successorsChoose(res.successors, oldSheet, sheetForCalc);
        }
        this.stopMissingVehicleMeesage = false;
        return res;
      })
    );
    return r;
  }

  sendMixPanelByCalculation() {
    //check for change in TCO
    if (this.sheet.userChangesAfterLastCall) {
      this.sheet.userChangesAfterLastCall.forEach((valueMap: Map<string, string>, idCol) => {
        let changesToSend = [];
        let addPartsDescript = [];
        let delPartsDescript = [];
        this.fillMixPanelChanges(idCol, valueMap, changesToSend, addPartsDescript, delPartsDescript)

        if ((changesToSend && changesToSend.length > 0) ||
          (addPartsDescript && addPartsDescript.length > 0) ||
          (delPartsDescript && delPartsDescript.length > 0)
        ) {
          let additionalObj = {
            scenario: this.getOldValue(idCol, "scenario"),
            vehicleInformation: this.getVehicleInf(idCol),
            eventKey: "tcoChanges",
            detail: {
              modify: changesToSend,
              add: addPartsDescript,
              delete: delPartsDescript,
            }
          }
          let endpoint = '/cce/transactions/tcoChanges';
          this.mixPanelServ.logData(additionalObj, endpoint);
        }
      });
    }
    //check for change in Equipment
    if (this.sheet.columnChangedfromEquipment.size > 0) {
      this.sheet.columnChangedfromEquipment.forEach(idCol => {
        let additionalObj = {
          eventKey: "equipmentManualSelection",
          vehicleInformation: this.getVehicleInf(idCol),
        }
        let endpoint = '/cce/transactions/equipmentManualSelection';
        this.mixPanelServ.logData(additionalObj, endpoint);
      });
    }
    //check for change in Maintenance
    if (this.sheet.allChangesMaintenance.size > 0) {

      this.sheet.allChangesMaintenance.forEach((value, id) => {
        const indexOfSep = id.indexOf(Constants.SEPARATOR);
        const idCol = id.substring(0, indexOfSep);
        const typeRow = id.substring(indexOfSep + 3);

        let data = this.constructMixPanelMaitanance(value);

        if (data) {
          let additionalObj = {
            scenario: this.getOldValue(idCol, "scenario"),
            eventKey: this.getMaintenanceEvent(typeRow),
            detail: data,
            vehicleInformation: this.getVehicleInf(idCol),
          }
          let endpoint = '/cce/transactions/' + this.getMixPanelEndpoint(typeRow);
          this.mixPanelServ.logData(additionalObj, endpoint);
        }
      });

    }
  }

  getMaintenanceEvent(typeRow) {
    switch (typeRow) {
      case Constants.TYPE_SERV_PLAN_NAME:
        return "maintenanceServicePlanChanges";
      case Constants.TYPE_ADD_TASKS_NAME:
        return "maintenanceAdditionalTaskChanges";;
      case Constants.TYPE_WEAR_PARTS_NAME:
        return "maintenanceWearPartsChanges";;
    }
  }

  getMixPanelEndpoint(typeRow) {
    switch (typeRow) {
      case Constants.TYPE_SERV_PLAN_NAME:
        return "maintenanceServicePlanChanges";
      case Constants.TYPE_ADD_TASKS_NAME:
        return "maintenanceAdditionalTaskChanges";;
      case Constants.TYPE_WEAR_PARTS_NAME:
        return "maintenanceWearPartsChanges";;
    }
  }

  fillMixPanelChanges(idCol, valueMap, changesToSend, addPartsDescript, delPartsDescript) {

    let partDetailsTCOChanges = this.sheet.partDetailsTCOChanges.get(idCol);
    if (partDetailsTCOChanges) {
      partDetailsTCOChanges.forEach(element => {
        switch (element) {
          case this.SP_ADD:
            addPartsDescript.push("Service Part");
            break;
          case this.WP_ADD:
            addPartsDescript.push("Wear Part");
            break;

          case this.SP_DEL:
            delPartsDescript.push("Service Part");
            break;
          case this.WP_DEL:
            delPartsDescript.push("Wear Part");
            break;

          case this.SP_PARTS_DESCRIPTION:
            changesToSend.push("Service Part description");
            break;
          case this.SP_PRICE:
            changesToSend.push("Service Part price");
            break;
          case this.SP_QTY:
            changesToSend.push("Service Part qty");
            break;

          case this.WP_PARTS_DESCRIPTION:
            changesToSend.push("Wear Part description");
            break;
          case this.WP_PRICE:
            changesToSend.push("Wear Part price");
            break;
          case this.WP_QTY:
            changesToSend.push("Wear Part qty");
            break;
        }
      });
    }

    if (valueMap) {
      valueMap.forEach((value, field) => {

        switch (field) {
          case "spPartsDiscount":
            changesToSend.push("Service Part Discount");
            break;
          case "serviceLabourTime":
            changesToSend.push("Service Labour Time");
            break;
          case "serviceLabourRate":
            changesToSend.push("Service Labour Rate");
            break;
          case "serviceLabourDiscount":
            changesToSend.push("Service Labour Discount");
            break;
          case "unplannedServicePA":
            changesToSend.push("Unplanned Service PA");
            break; case "wpPartsDiscount":
            changesToSend.push("Wear Parts Discount");
            break;
          case "overhaulLabourTime":
            changesToSend.push("Overhaul Labour Time");
            break;
          case "overhaulLabourRate":
            changesToSend.push("Overhaul Labour Rate");
            break;
          case "overhaulLabourDiscount":
            changesToSend.push("Overhaul Labour Discount");
            break;
          case "unplannedWearPA":
            changesToSend.push("Unplanned Wear PA");
            break;
        }

      });
    }
  }

  constructMixPanelMaitanance(value) {

    let retval = {};
    retval["add"] = [];
    retval["delete"] = [];
    retval["modify"] = [];

    value.forEach((row, key) => {
      row["id"] = lodash.cloneDeep(key);

      let newRowAdd = {
        name: {},
        id: {},
        rows: [],
      }
      let newRowMod = {
        name: {},
        id: {},
        rows: [],
      }
      let newRowDel = {
        name: {},
        id: {},
        rows: [],
      }


      Object.entries(row).forEach(([keyInner, valueInner], index) => {

        let cloneValue = lodash.cloneDeep(valueInner);

        if (keyInner == "name" || keyInner == "id") {
          newRowAdd[keyInner] = cloneValue;
          newRowMod[keyInner] = cloneValue;
          newRowDel[keyInner] = cloneValue;
        } else if (keyInner == "IntervalDesc") {
          newRowMod[keyInner] = cloneValue;
          delete cloneValue["type"];
        } else {
          switch (cloneValue["type"]) {
            case Constants.ADD_TYPE:
              newRowAdd["rows"].push(cloneValue);
              break;
            case Constants.DEL_TYPE:
              newRowDel["rows"].push(cloneValue);
              break;
            case Constants.MOD_TYPE:
              newRowMod["rows"].push(cloneValue);
              break;
          }
          delete cloneValue["type"];
        }
      });

      if (newRowAdd.rows.length > 0) {
        retval["add"].push(newRowAdd);
      }
      if (newRowDel.rows.length > 0) {
        retval["delete"].push(newRowDel);
      }
      if (newRowMod.rows.length > 0 || newRowMod["IntervalDesc"]) {
        retval["modify"].push(newRowMod);
      }
    });

    //clean empty
    Object.entries(retval).forEach(([keyInner, valueInner], index) => {
      if (valueInner["length"] == 0) {
        delete valueInner[keyInner];
      }
    });

    if (!retval["add"] && !retval["modify"] && !retval["delete"] && !retval["IntervalDesc"]) {
      return null;
    }

    return retval;
  }

  getVehicleInf(idCol) {
    let retval = {
      natcode: this.getOldValue(idCol, "carSpecs").substring(7),
      market: this.getOldValue(idCol, "market"),
      make: this.getOldValue(idCol, "make"),
      model: this.getOldValue(idCol, "model"),
      nameDescription: this.getOldValue(idCol, "name"),
      trimLevel: this.getOldValue(idCol, "trimLevel"),
      saleStart: this.getOldValue(idCol, "saleStart"),
      saleEnd: this.getOldValue(idCol, "saleEnd"),
      body: this.getOldValue(idCol, "body"),
      driveType: this.getOldValue(idCol, "drive"),
      transmission: this.getOldValue(idCol, "transmission"),
      fuel: this.getOldValue(idCol, "fuel"),
    };

    return retval;
  }

  startSheetBasedOnOld() {
    let response = { sheet: this.getSheet() };
    this.colID = this.getBiggestIDFromFile(response);
  }

  loadCalc(oldSheet: Sheet, stopStorage?: boolean) {
    if (!stopStorage) {
      this.localStorageService.add(this.sheet, Constants.LOCATE_GENERAL_CHENGE);
    }

    const carNames = this.sheetService.getCarNamesBeforeCall(this.sheet);
    const origValues = this.sheetService.cacheOrigValues(this.sheet);
    this.sendMixPanelByCalculation();
    this.cleanUserChanges(origValues);
    const requestObj: any = {};
    requestObj.sheet = JSON.parse(JSON.stringify(this.sheet, replacer));
    const r = this.http.post<any>(environment.callCalcEndpoint, requestObj).pipe(
      map((res: any) => {
        let haveSuccessors = this.checkForAnySuccessor(res.successors);
        if (haveSuccessors) {
          this.successorsChoose(res.successors, oldSheet, this.sheet);
        }
        this.stopMissingVehicleMeesage = haveSuccessors;
        this.sheet.columnChangedfromMaintenance.forEach(
          idsCol => {
            if (this.sheet.userChangesBeforeLastCall.has(Number(idsCol))) {
              this.cleanUserChangesFromMaintenance(Number(idsCol));
            }
          }
        );
        if (!!res.failedColumns) {
          this.sheet.failedColumns = res.failedColumns;
        } else {
          this.sheet.failedColumns = new Set();
        }
        this.sheet.exchangeRates = this.loadExchangeRates(res.sheet.exchangeRates);
        this.sheet.canSeePrice = new Map<string, any>([...Array.from(this.sheet.canSeePrice), ...Object.entries(res.sheet.canSeePrice)]);
        this.fillCalcColumm(res, carNames, origValues);
        this.sheet.allChangesMaintenance.clear();
        this.sheet.columnChangedfromMaintenance.clear();
        this.sheet.userChangesAfterLastCall.clear();
        this.sheet.partDetailsTCOChanges.clear();
        this.sheet.columnChangedfromEquipment.clear();
        this.sheet.version = Constants.CURRENT_SHEET_VERSION;
        this.stopMissingVehicleMeesage = false;
        return res;
      })
    );
    return r;
  }

  checkForAnySuccessor(cars) {
    if (cars != null && cars.length > 0) {
      // for (const car of cars) {
      //   for (const successor of car.successors) {
      return true;
      //   }
      // }
    }
    return false;
  }

  cleanUserChangesFromMaintenance(idsCol) {
    let path = metadata['service'].path[0];
    const jp = require('jsonpath');
    let obj = jp.query(this.sheet.dataTCO, path);
    let service = obj[0]
    if (!!service) {
      this.removeFromUserChanges(service, idsCol);
    }

    path = metadata['wear'].path[0];
    obj = jp.query(this.sheet.dataTCO, path);
    let wear = obj[0]
    if (!!wear) {
      this.removeFromUserChanges(wear, idsCol);
    }

    path = metadata['tyres'].path[0];
    obj = jp.query(this.sheet.dataTCO, path);
    let tyre = obj[0]
    if (!!tyre) {
      this.removeFromUserChanges(tyre, idsCol);
    }
  }

  removeFromUserChanges(elem, idsCol) {
    this.sheet.userChangesBeforeLastCall.get(idsCol).delete(elem.field);
    if (elem.children) {
      elem.children.forEach(element => {
        this.removeFromUserChanges(element, idsCol);
      });
    }
  }

  //fill UChBefore from UChAfter
  cleanUserChanges(origValues) {
    this.sheet.userChangesAfterLastCall.forEach((valueMap: Map<string, string>, idCol) => {
      const mapBefore: Map<string, string> = this.sheet.userChangesBeforeLastCall.get(idCol);
      if (!!mapBefore) {
        valueMap.forEach((value, field) => {
          mapBefore.set(field, value);
        });
      } else {
        this.sheet.userChangesBeforeLastCall.set(idCol, new Map([...Array.from(valueMap.entries())]));
      }
    });

    this.sheet.userChangesBeforeLastCall.forEach((valueMap: Map<string, string>, idCol) => {
      const mapOriginValue: Map<string, string> = origValues.get(idCol);
      if (!!mapOriginValue) {
        valueMap.forEach((value, field) => {
          if (undefined !== mapOriginValue.get(field) && mapOriginValue.get(field) === value) {
            valueMap.delete(field);
            if (valueMap.size === 0) {
              this.sheet.userChangesBeforeLastCall.delete(idCol);
            }
          }
        });
      }
    });
  }

  fillCalcColumm(response, carNames, origValues?) {
    this.mergeColummTCO(this.sheet.dataTCO, response, origValues);
    // this.mergeCalcColummEquipment(currentSheet.dataEquipment, response);//for now is not necessary
    // this.mergeColummMaintenance(this.sheet.dataMaintenance, response);//now reload old data,maybe have to change
    if (this.sheet.userChangesBeforeLastCall != null && this.sheet.userChangesBeforeLastCall !== undefined &&
      this.sheet.userChangesBeforeLastCall.size > 0) {
      this.applyUserChanges(this.sheet.dataTCO, null, this.sheet.userChangesBeforeLastCall);
    }
    this.removeMissingCars(carNames);
    this.checkForInsufficient();
    this.formatByCalcModel();
    this.reloadComponents();
  }

  checkForInsufficient() {
    this.checkForInsufficientTCO(this.sheet.dataTCO);
    // Do not set this for now -- we want alert icon only if there is alert in basket
    //this.checkForInsufficientEQ(this.sheet.dataEquipment);
    //this.checkForInsufficientM(this.sheet.dataMaintenance);
  }

  checkForInsufficientM(dataM) {
    this.sheet.columnIds.forEach(id => {
      if (dataM.additionalTasks.children[0].values[id].length === 0 &&
        dataM.servicePlan.children[0].values[id].length === 0 &&
        dataM.wearParts.children[0].values[id].length === 0) {
        this.sheet.failedColumns.add(id);
      }
    });
  }

  checkForInsufficientEQ(dataEQ, child?, Cols?: Set<number>) {
    if ((dataEQ != null && (dataEQ.children == null || dataEQ.children === undefined)) ||
      (child !== undefined && (child.children == null || child.children === undefined))) {
      return;
    }

    if (dataEQ != null) {
      const allCols = new Set(this.sheet.columnIds);
      Object.values(dataEQ.children).forEach((element: any) => {
        this.checkForInsufficientEQ(null, element, allCols);
      });
      allCols.forEach(idCol => {
        this.sheet.failedColumns.add(idCol);
      });
    } else {
      for (const key of Object.keys(child.values)) {
        Cols.delete(Number(key));
      }
      Object.values(child.children).forEach((element: any) => {
        this.checkForInsufficientEQ(null, element, Cols);
      });
    }
  }

  checkForInsufficientTCO(dataTCO, children?) {
    if (dataTCO == null && (children == null || children === undefined)) {
      return;
    }
    const forIterate = dataTCO == null ? children : dataTCO;
    if (forIterate === null || forIterate === undefined) {
      return;
    }
    Object.values(forIterate).forEach((element: any) => {
      if (element.values === undefined || element.values === null) {
        return;
      }
      for (const key of Object.keys(element.values)) {
        if (element.values[key] === null || element.values[key] === undefined || element.values[key] === '') {
          if (metadata[element.field].insuffDataWhenEmpty === true) {
            this.sheet.failedColumns.add(Number(key));
          }
        }
      }
      this.checkForInsufficientTCO(null, element.children);
    });
  }

  applyUserChanges(dataTCO, children, basket) {

    if (dataTCO == null && (children == null || children === undefined)) {
      return;
    }
    const forIterate = dataTCO == null ? children : dataTCO;
    Object.values(forIterate).forEach((element: any) => {
      if (!element.values) {
        return;
      }
      for (const key of Object.keys(element.values)) {
        const uCh: Map<string, string> = basket.get(Number(key));
        if (uCh != null && uCh.has((element.field))) {
          element.values[key] = uCh.get(element.field);
          element.editData[key].modified = true;
        }
      }
      this.applyUserChanges(null, element.children, basket);
    });
  }

  fillUserChangesFromTCOModified(currentSheet, dataTCO, children) {
    if ((dataTCO == null || !dataTCO) && (children == null || children === undefined)) {
      return;
    }

    const forIterate = dataTCO == null ? children : dataTCO;
    Object.values(forIterate).forEach((element: any) => {
      if (element.editData == undefined || element.editData == null) {
        return;
      }
      for (const key of Object.keys(element.editData)) {
        if (element.editData[key].modified) {
          if (currentSheet.userChangesBeforeLastCall.has(Number(key))) {
            const innerMap = currentSheet.userChangesBeforeLastCall.get(Number(key));
            innerMap.set(element.field, element.values[key]);
          } else {
            const innerMap = new Map();
            innerMap.set(element.field, element.values[key]);
            currentSheet.userChangesBeforeLastCall.set(Number(key), innerMap);
          }
        }
      }
      this.fillUserChangesFromTCOModified(currentSheet, null, element.children);
    });
  }


  addCols(response) {
    this.localStorageService.add(this.sheet, Constants.LOCATE_GENERAL_CHENGE);

    response.sheet.columnIds.forEach(element => {
      this.sheet.columnIds.push(element);
    });
    this.sheet.canSeePrice = new Map<string, any>([...Array.from(this.sheet.canSeePrice), ...Object.entries(response.sheet.canSeePrice)]);
    //for old wrong file
    this.sheet.requestMetadata = this.sheet.requestMetadata == undefined || this.sheet.requestMetadata == null ? new Map() : this.sheet.requestMetadata;
    this.sheet.requestMetadata = Object.assign(this.sheet.requestMetadata, response.sheet.requestMetadata);
    this.sheet.exchangeRates = this.loadExchangeRates(response.sheet.exchangeRates);
    this.mergeColummTCO(this.sheet.dataTCO, response);
    this.mergeColummEquipment(this.sheet.dataEquipment, response);
    this.mergeColummMaintenance(this.sheet.dataMaintenance, response);//now reload old data,maybe have to change
    // will never need a message
    // this.removeMissingCars({});
    this.checkForInsufficient();
    this.formatByCalcModel();
    this.reloadComponents();

    // Moved here since the previous fix didn't fix it completely
    // [VNP 2021-03-22]
    if (response.sheet.failedColumns !== undefined) {
      response.sheet.failedColumns.forEach(item => this.sheet.failedColumns.add(item));
    }
  }

  removeMissingCars(carNames) {
    const jp = require('jsonpath');
    let idPath = metadata['id'].path[0];
    let isMissing = (colId) => {
      const obj = jp.query(this.sheet.dataTCO, idPath + '.values[\'' + + colId + '\']');
      if (!obj || obj.length == 0 || obj[0] === '__') {
        return true;
      } else {
        return false;
      }
    };
    let message = '';
    let noData = this.sheet.columnIds.find(isMissing);
    while (!!noData) {
      console.log('will have to remove', noData, this.sheet.columnIds);
      message = message + carNames[noData] + "\n";
      this.removeCarInternal(noData, false);
      noData = this.sheet.columnIds.find(isMissing);
    }
    if (message !== '' && !this.stopMissingVehicleMeesage) {
      this.messageService.showMessage('The following vehicles are no longer available: \n' + message);
    }
  }

  fillOrigValue(origValues, element, idCol) {
    const innerMap = this.sheet.userChangesBeforeLastCall.get(idCol);
    if (!!origValues && origValues.has(idCol) && origValues.get(idCol).has(element.field) &&
      !!innerMap && innerMap.has(element.field)) {
      element.editData[idCol].origValue = origValues.get(idCol).get(element.field);
    }
  }

  mergeColummTCO(dataTCO, response, origValues?) {
    let responseSheet = response.sheet;
    for (const idCol of responseSheet.columnIds) {
      Object.values(dataTCO).forEach((element: any) => {
        Object.values(responseSheet.tco).forEach((elementNew: any) => {
          if (element.field === elementNew.field) {
            if ("vehicleDiscount" === element.field) {
              console.log("Stop");
            }
            this.fillOrigValue(origValues, elementNew, idCol);
            element.values[idCol] = elementNew.values[idCol];
            element.editData[idCol] = elementNew.editData[idCol];
            this.mergeCalcColummChildren(element.children, elementNew.children, idCol, origValues);
          }
        });
      });
    }
  }

  mergeColummEquipment(dataEquipment, response) {
    console.log(response, "responseSheet.equipment");
    let responseSheet = response.sheet;
    for (const idCol of responseSheet.columnIds) {
      if (responseSheet.equipment.values === undefined) {
        responseSheet.equipment.values = {}; //TODO test if calc change values of equipment extras.Must modify totals.
      }
      if (dataEquipment.values === undefined) {
        dataEquipment.values = {};
      }
      if (dataEquipment.children === undefined || dataEquipment.children === null) {
        dataEquipment.children = [];
      }
      dataEquipment.values[idCol] = responseSheet.equipment.values[idCol];
      this.mergeCalcColummChildren(dataEquipment.children, responseSheet.equipment.children, idCol);
    }
  }

  mergeColummMaintenance(dataMaintenance, response) {
    let responseSheet = response.sheet;
    for (const idCol of responseSheet.columnIds) {
      Object.values(dataMaintenance).forEach((element: any) => {
        Object.values(responseSheet.maintenance).forEach((elementNew: any) => {
          if (element.field === elementNew.field) {
            this.mergeCalcColummChildren(element.children, elementNew.children, idCol);
          }
        });
      });
    }
  }

  mergeCalcColummChildren(children, newChildren, idCol, origValues?) {
    if (newChildren === null || newChildren === undefined) {
      return;
    }

    for (let index = 0; index < newChildren.length; index++) {
      const element = children[index];
      const newElement = newChildren[index];

      if (element !== undefined && element.field === newElement.field) {
        if (newElement.values === undefined) {
          newElement.values = {};//TODO test if calc change values of equipment extras.Must modify totals.
        }
        element.values[idCol] = newElement.values[idCol];
        if (newElement.editData !== undefined) {
          this.fillOrigValue(origValues, newElement, idCol);
          element.editData[idCol] = newElement.editData[idCol];
        }

        this.mergeCalcColummChildren(element.children, newElement.children, idCol, origValues);
      } else {

        let found = false;
        children.forEach(elementOld => {
          if (elementOld.field === newElement.field) {
            if (newElement.values === undefined) {
              newElement.values = {};
            }
            elementOld.values[idCol] = newElement.values[idCol];
            if (newElement.editData !== undefined) {
              this.fillOrigValue(origValues, newElement, idCol);
              elementOld.editData[idCol] = newElement.editData[idCol];
            }

            this.mergeCalcColummChildren(elementOld.children, newElement.children, idCol, origValues);
            found = true;
          }
        });

        if (!found) {
          children.push(newElement);
          //this is for equipment when have to add
          //nodes and when calc model remove column adding here(this can be optimize)
        }
      }
    }
  }

  reloadComponents() {
    if (this.calcSheet) {
      this.calcSheet.reloadData();
    }
    if (this.equipmentSheet) {
      this.equipmentSheet.reloadData();
    }
    if (this.maintenanceSheet) {
      this.maintenanceSheet.reloadData();
    }
  }

  getCurrentCarNames() {
    let carNames = {};
    this.sheet.columnIds.forEach(x => {
      carNames[x] = getNameOfColumn(this.sheet.dataTCO, x);
    });
    return carNames;
  }

  loadDataFromServer(response, carNamesBefore) {
    response.sheet.columnIds.forEach(element => {
      this.sheet.columnIds.push(element);
    });
    this.colID = this.getBiggestIDFromFile(response);
    this.sheet.columnIds = response.sheet.columnIds;
    if (!!response.sheet.canSeePrice) {
      this.sheet.canSeePrice = new Map(Object.entries(response.sheet.canSeePrice));
    }
    this.sheet.dataTCO = response.sheet.tco;
    this.sheet.viewingMode = response.sheet.viewingMode;
    this.sheet.residualType = response.sheet.residualType;
    if (response.sheet.label !== "") {
      this.sheet.title = response.sheet.label;
    }
    this.sheet.equipmentFilteringMode = response.sheet.equipmentFilterMode;
    this.sheet.benchmarkColumnId = response.sheet.benchmarkColumnId;
    this.sheet.dataEquipment = response.sheet.equipment;
    this.sheet.isMaintenanceDefault = response.sheet.isMaintenanceDefault;
    this.sheet.dataMaintenance = response.sheet.maintenance;
    if (response.sheet.failedColumns !== undefined) {
      response.sheet.failedColumns.forEach(item => this.sheet.failedColumns.add(item));
    }
    this.sheet.requestMetadata = response.sheet.requestMetadata;
    this.sheet.exchangeRates = this.loadExchangeRates(response.sheet.exchangeRates);
    this.fillUserChangesFromTCOModified(this.sheet, this.sheet.dataTCO, null);
    this.removeMissingCars(carNamesBefore);
    this.checkForInsufficient();
    this.formatByCalcModel();
  }

  loadExchangeRates(exchangeRates): Map<number, number> {
    const retVal = new Map();
    for (const ids of this.sheet.columnIds) {
      if (exchangeRates !== undefined && exchangeRates[ids] !== undefined && exchangeRates[ids] != null) {
        retVal.set(ids, exchangeRates[ids]);
      } else {
        if (this.sheet.exchangeRates === undefined || !this.sheet.exchangeRates.has(ids)) {
          retVal.set(ids, 1);
        } else {
          retVal.set(ids, this.sheet.exchangeRates.get(ids));
        }
      }
    }
    return retVal;
  }

  formatByCalcModel() {
    let correctModel = copyCalcModel(this.sheet.calcModel);
    correctModel.correctModel();
    for (let i = this.sheet.dataTCO.length - 1; i >= 0; i--) {
      let element = this.sheet.dataTCO[i];
      if (correctModel[element.field] === false || correctModel[element.field] === "none") {
        this.sheet.dataTCO.splice(i, 1);
      } else {
        this.checkForChildrenToHide(correctModel, element.children);
      }
    }
    if (this.sheet.dataTCO.length === 3) {
      this.sheet.dataTCO.splice(2, 1);//del tco if don`t have acq and util
    }
    this.reloadComponents();
  }

  checkForChildrenToHide(correctModel, children) {
    if (children === undefined) {
      return;
    }

    for (let i = children.length - 1; i >= 0; i--) {
      let child = children[i];
      if (correctModel[child.field] === false || correctModel[child.field] === "none") {
        children.splice(i, 1);
      } else {
        this.checkForChildrenToHide(correctModel, child.children);
      }
    }
  }

  reloadOrigData() {
    for (const el of this.sheet.dataTCO) {
      this.getOrigData(el);
    }
    this.reloadComponents();
  }

  //By load OrigValue!!!from undo and from pencil
  setUserChangesMaps(idCol, field, origValue) {
    if (this.sheet.userChangesAfterLastCall.has(idCol)) {
      const beforeMod = this.sheet.userChangesBeforeLastCall.get(idCol);
      const afterLast = this.sheet.userChangesAfterLastCall.get(idCol);
      //if this field had in userChangesBeforeLastCall add
      if (beforeMod != null && !!beforeMod && !!beforeMod.has(field)) {
        if (!afterLast || afterLast == null) {
          this.sheet.userChangesAfterLastCall.set(idCol, new Map());
        }
        this.sheet.userChangesAfterLastCall.get(idCol).set(field, origValue);
      } else {
        //if this is last field in last col, turn on recalc button
        this.sheet.userChangesAfterLastCall.get(idCol).delete(field);
        if (this.sheet.userChangesAfterLastCall.get(idCol).size === 0) {
          this.sheet.userChangesAfterLastCall.delete(idCol);
        }
      }
    } else if (this.sheet.userChangesBeforeLastCall.has(idCol) && this.sheet.userChangesBeforeLastCall.get(idCol).has(field)
      && origValue != this.sheet.userChangesBeforeLastCall.get(idCol).get(field)) {
      // this.sheet.userChangesBeforeLastCall.get(idCol).delete(field);
      // if (this.sheet.userChangesBeforeLastCall.get(idCol).size === 0) {
      //   this.sheet.userChangesBeforeLastCall.delete(idCol);
      // }
      const afterMod = this.sheet.userChangesAfterLastCall.get(idCol);
      if (afterMod == null && undefined === afterMod) {
        this.sheet.userChangesAfterLastCall.set(idCol, new Map());
      }
      this.sheet.userChangesAfterLastCall.get(idCol).set(field, origValue);
    }
  }

  getOrigData(el) {
    const field = el.field;
    // console.log(field, "field");
    const path = metadata[field].path;

    const pathValues = path[0] + '.values';
    const pathEditData = path[0] + '.editData';

    //get old arrays
    const jp = require('jsonpath');
    const objValues = jp.query(this.sheet.dataTCO, pathValues);
    const objEditData = jp.query(this.sheet.dataTCO, pathEditData);

    for (const idCol of this.sheet.columnIds) {
      if (objValues[0] !== undefined && objValues[0][idCol] != null &&
        objEditData[0][idCol] !== undefined) {  /*TCO don`t change*/
        const origValue = objEditData[0][idCol].origValue;
        this.setUserChangesMaps(idCol, field, origValue);
        metadata[field].path.forEach(el => {
          jp.value(this.sheet.dataTCO, el + '.values[\'' + idCol + '\']',
            Array.isArray(origValue) ? lodash.cloneDeep(origValue) : origValue);
          jp.value(this.sheet.dataTCO, el + '.editData[\'' + idCol + '\']' + '.modified', false);
          jp.value(this.sheet.dataTCO, el + '.editData[\'' + idCol + '\']' + '.error', false);
        });
      }
    }

    if (el.children) {
      for (const element of el.children) {
        this.getOrigData(element);
      }
    }
  }


  removeCar(idsCol) {
    this.removeCarInternal(idsCol, true);
  }

  removeCarInternal(idsCol, forceReload: boolean) {
    if (forceReload) {
      this.localStorageService.add(this.sheet, Constants.LOCATE_GENERAL_CHENGE);
    }
    for (let index = 0; index < this.sheet.columnIds.length; index++) {
      if (this.sheet.columnIds[index] === idsCol) {
        this.removeColWidth(index + 1);
        this.sheet.columnIds.splice(index, 1);
        this.sheet.userChangesBeforeLastCall.delete(idsCol);
        this.sheet.columnChangedfromMaintenance.delete(idsCol);
        this.sheet.columnChangedfromEquipment.delete(idsCol);
        this.sheet.userChangesAfterLastCall.delete(idsCol);
        this.sheet.partDetailsTCOChanges.delete(idsCol);
        this.sheet.allChangesMaintenance.delete(idsCol);
        break;
      }
    }
    this.removeColTCO(this.sheet.dataTCO, idsCol);
    this.removeColEquipment(this.sheet.dataEquipment, idsCol);
    this.removeColMaintenance(this.sheet.dataMaintenance, idsCol);

    if (forceReload) {
      this.reloadComponents();
    }
  }

  removeColWidth(indexForDel) {
    for (let index = indexForDel; index < this.parent.columnsWidths.size - 1; index++) {
      const nextWidth = this.parent.columnsWidths.get(index + 1);
      this.parent.columnsWidths.set(index, nextWidth);
    }
    this.parent.columnsWidths.delete(this.parent.columnsWidths.size - 1);
  }

  removeColTCO(dataTCO, idsCol) {
    dataTCO.forEach(element => {
      if (element.values) {
        delete element.values[idsCol];
      }
      if (element.editData) {
        delete element.editData[idsCol];
      }
      this.removeColFromChilds(element.children, idsCol);
    });
  }

  removeColEquipment(dataEquipment, idsCol) {
    if (dataEquipment.values) {
      delete dataEquipment.values[idsCol];
    }
    if (dataEquipment.editData) {
      delete dataEquipment.editData[idsCol];
    }
    this.removeColFromChilds(dataEquipment.children, idsCol);

  }

  removeColMaintenance(dataMaintenance, idsCol) {
    Object.values(dataMaintenance).forEach((element: any) => {
      this.removeColFromChilds(element.children, idsCol);
    });
  }

  removeColFromChilds(children, idsCol) {
    if (children === undefined || children === null) {
      return;
    }

    children.forEach(child => {
      if (child.values) {
        delete child.values[idsCol];
      }
      if (child.editData) {
        delete child.editData[idsCol];
      }

      this.removeColFromChilds(child.children, idsCol);
    });
  }



  //
  // FROM DEVICE
  //

  loadDataFromDevice(response, currentSheet: Sheet) {
    // console.log(currentSheet, "currentSheet");
    this.colID = this.getBiggestIDFromFile(response);
    currentSheet.columnIds = response.sheet.columnIds;
    if (!!response.sheet.canSeePrice) {
      currentSheet.canSeePrice = new Map(Object.entries(response.sheet.canSeePrice));
    }
    currentSheet.isMaintenanceDefault = response.sheet.isMaintenanceDefault;
    currentSheet.viewingMode = response.sheet.viewingMode;
    currentSheet.residualType = response.sheet.residualType;
    currentSheet.equipmentFilteringMode = response.sheet.equipmentFilterMode;
    currentSheet.title = response.sheet.label;
    currentSheet.benchmarkColumnId = response.sheet.benchmarkColumnId;
    currentSheet.dataTCO = response.sheet.tco;
    currentSheet.dataEquipment = response.sheet.equipment;
    currentSheet.dataMaintenance = response.sheet.maintenance;
    if (response.sheet.failedColumns !== undefined) {
      response.sheet.failedColumns.forEach(item => currentSheet.failedColumns.add(item));
    }
    this.sheet.requestMetadata = response.sheet.requestMetadata;
    this.sheet.exchangeRates = this.loadExchangeRates(response.sheet.exchangeRates);
    this.fillUserChangesFromTCOModified(currentSheet, currentSheet.dataTCO, null);
    this.checkForInsufficient();
    this.formatByCalcModel();
  }

  getBiggestIDFromFile(response): number {
    let maxId = 0;
    response.sheet.columnIds.forEach(element => {
      if (maxId < element) {
        maxId = element;
      }
    });
    return maxId;
  }

  saveSheetToDevice() {
    let currencyUnit = this.sheet.currencyID;
    let distanceUnit = this.sheet.distanceID;
    let label = this.sheet.title;
    let tco = null;
    if (!!this.sheet && !!this.sheet.dataTCO) {
      tco = this.sheet.dataTCO
    }
    let equipment = null;
    if (!!this.sheet && !!this.equipmentSheet &&
      !!this.equipmentSheet.gridData && !!this.equipmentSheet.gridData[0]) {
      equipment = this.equipmentSheet.gridData[0];
    }
    let maintenance = null;
    if (!!this.sheet && !!this.maintenanceSheet && !!this.maintenanceSheet.gridData) {
      maintenance = {
        servicePlan: this.maintenanceSheet.gridData["ServicePlan"],
        additionalTask: this.maintenanceSheet.gridData["AdditionalTask"],
        wearParts: this.maintenanceSheet.gridData["WearParts"]
      };
    }
    let data = {
      sheet: {
        tco: tco,
        equipment: equipment,
        maintenance: maintenance,
        columnIds: this.sheet.columnIds,
        currencyUnit: currencyUnit,
        distanceUnit: distanceUnit,
        numberOfColumns: this.sheet.columnIds.length,
        label: label
      }
    }
    let date = new Date();
    let month = date.getUTCMonth() + 1;
    let day = date.getUTCDate();
    let year = date.getUTCFullYear();
    let newdate = day + "-" + month + "-" + year;
    let fileContent = JSON.stringify(data);
    this.download(this.sheet.title + " " + newdate + ".ccej", fileContent);
  }

  download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }






  //
  //BENCHMARK
  //

  //currSheet is switching, because is bad write recursion(have to rewrite)
  calculateBenchmark(currSheet, dataTCO, metadata, bmColId) {
    for (let i = 0; i < dataTCO.length; ++i) {
      if (!!dataTCO[i] && !!dataTCO[i].children) {
        dataTCO[i].children = this.calculateBenchmark(currSheet, dataTCO[i].children, metadata, bmColId);
      }
      this.calculateBenchmarkData(currSheet, dataTCO[i], metadata, bmColId);
    }

    return dataTCO;
  }

  calculateBenchmarkData(currSheet, data, metadata, bmColId) {
    let values = data.values;
    let field = data.field;
    for (let colId in values) {
      if (!data.bmValues) {
        data.bmValues = [];
      }
      data.bmValues[colId] = this.calculateBenchmarkValue(currSheet, values, bmColId, parseInt(colId), metadata[field], field);
    }
    return data;
  }

  calculateBenchmarkValue(currSheet, values, bmColId, colId, metadataFild, field) {

    let value;
    let color;
    let backgroundColor;
    if (bmColId >= 0 && bmColId !== colId) {
      if (!metadataFild.benchmark) {
        return {
          value: values[colId],
          color: color,
          backgroundColor: backgroundColor
        }
      }
      let result;
      if (currSheet.dataTCO === undefined) {

      } else if (currSheet.viewingMode === this.ACTIVE_TOTAL || !metadataFild.baseUnit) {
        result = this.compareValues(values[colId], values[bmColId], metadataFild.renderAs, field, bmColId, colId);
      } else {
        result = this.compareValues(this.getScenarioBaseVal(currSheet, values[colId], colId),
          this.getScenarioBaseVal(currSheet, values[bmColId], bmColId), metadataFild.renderAs, field, bmColId, colId);
      }


      if (!result || (result <= 0.01 && result >= -0.01)) {
        value = 'identical';
        color = this.TEXT_COLOR_GRAY;
      } else if (result > 0 && result !== true) {
        color = this.TEXT_COLOR_RED;
        value = result;
      } else if (result < 0) {
        color = this.TEXT_COLOR_GREEN;
        value = result;
      } else {
        value = values[colId];
      }
    }
    else {
      if (currSheet.viewingMode === this.ACTIVE_TOTAL || !metadataFild.baseUnit) {
        value = values[colId];
      } else {
        if (currSheet.dataTCO !== undefined) {
          value = this.getScenarioBaseVal(currSheet, values[colId], colId);
        }
      }
    }

    return {
      value: value,
      color: color,
      backgroundColor: backgroundColor
    }
  }

  private compareValues(value, bmValue, renderAs, field, bmColId, colId) {
    switch (renderAs) {
      case "imageURL":
      case "literal":
        return value !== bmValue;

      case "currency":
      // if (field == 'optionalEquipment') {
      //   return calcPriceByRate(value, this.sheet.exchangeRates.get(colId)) - calcPriceByRate(bmValue, this.sheet.exchangeRates.get(bmColId));
      // }
      case "distance":
      case "integer":
      case "real":
      case "overlapTime":
        return value - bmValue;

      case "tcoChart":
        break;

      case "partsSubgrid":
        break;
    }
  }

  getScenarioBaseVal(sheet, value, idsCol) {
    let retval = value;
    for (const element of sheet.dataTCO[1].children) {
      if (element.field === 'duration' && sheet.viewingMode === this.ACTIVE_MONTH) {
        retval = value / element.values[idsCol];
        break;
      }
      if (element.field === 'distance' && sheet.viewingMode === this.ACTIVE_KM) {
        retval = value / element.values[idsCol];
        break;
      }
    }
    return retval;
  }

  public applyEquipmentChanges(equlaizationResults: EqualizationResult[]) {

    let updateEquipmentSelection = (nodes: any[], suggested: Set<number>[]) => {
      for (let i = 0; i < nodes.length; i++) {
        if (!!nodes[i] && !!nodes[i].children) {
          updateEquipmentSelection(nodes[i].children, suggested);
        } else {
          let values = nodes[i].values;
          Object.keys(values).forEach(j => {
            let subzoneOptions = values[j];
            if (subzoneOptions) {
              subzoneOptions.forEach(o => {
                if (suggested[j].has(o.id)) {
                  o.isOptionalIncluded = true;
                  this.sheet.columnChangedfromEquipment.add(o.columnId);
                }
              });
            }
          });
        }
      }
    };

    let dataEquipment = this.sheet.dataEquipment;
    let optsArr = [];
    equlaizationResults.forEach(resColumn => {
      let opts = new Set<string>();
      resColumn.needed.forEach(opt => {
        if (opt && opt.isOptionSuggested) {
          opts.add(opt.id);
        }
      });
      Object.keys(resColumn.ambiguous).forEach(key => {
        let classOptions = resColumn.ambiguous[key];
        classOptions.forEach(opt => {
          if (opt && opt.isOptionSuggested) {
            opts.add(opt.id);
          }
        });
      });

      if (opts.size > 0) {
        let additionalObj = {
          eventKey: "equipmentAdjust",
          vehicleInformation: this.getVehicleInf(resColumn.sheetId),
        }
        let endpoint = '/cce/transactions/equipmentAdjust';
        this.mixPanelServ.logData(additionalObj, endpoint);
      }

      optsArr[resColumn.sheetId] = opts;
    });
    if (!!dataEquipment) {
      this.localStorageService.add(this.sheet, Constants.TYPE_VIEW_EQUIPMENT);
      updateEquipmentSelection(dataEquipment.children, optsArr);
      this.equipmentSheet.reloadData();
    }
  }


  loadColors(wp, sp, cylinders, benchmarkID): Observable<any> {
    const requestObj: any = {};
    requestObj.wp = wp;
    requestObj.sp = sp;
    requestObj.cylinders = cylinders;
    requestObj.benchmarkID = benchmarkID;

    const r = this.http.post<any>(environment.heatmapColorsEndpoint, requestObj).pipe(share());
    return r;
  }


}
