import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';
import { DataAvail } from '../../domain/data-avail';

@Component({
    selector: 'app-data-availability',
    templateUrl: './data-availability.component.html',
    styleUrls: ['./data-availability.component.scss']
})
export class DataAvailabilityComponent implements ICellRendererAngularComp {

    hasAlertIcon: boolean;
    tooltipText: string;

    constructor() { }

    refresh(params: any): boolean {
        return false;
    }

    agInit(params: ICellRendererParams): void {

        this.tooltipText = "";
        this.hasAlertIcon = false;

        if (params.value == null || !(params.value instanceof Array)) {
            return;
        }

        let arr: DataAvail[] = params.value;
        let that = this;
        arr.forEach(function (value: DataAvail, index: number, array: DataAvail[]) {
            if (value.dataStatus == 2) {
                that.addTooltipText(value);
                that.hasAlertIcon = true;
            }
        });
        // console.log(this.tooltipText);
    }

    private addTooltipText(value: DataAvail): void {
        if (this.tooltipText !== '') {
            this.tooltipText += ' \n ';
        }
        let elem = '';
        switch (value.dataType) {
            case 1: elem = 'Specifications';
                break;
            case 2: elem = 'Equipment';
                break;
            case 3: elem = 'Residual Value';
                break;
            case 4: elem = 'Service';
                break;
            case 5: elem = 'Wear';
                break;
            case 6: elem = 'Parts';
                break;
            case 7: elem = 'Labour';
                break;
            case 8: elem = 'Insurance';
                break;
            case 9: elem = 'Tyres';
                break;
            case 10: elem = 'Fuel Cons';
                break;
            case 11: elem = 'Incomplete TCO';
                break;
            default: elem = '<Unknown element>';
                break;
        }
        this.tooltipText += elem;
        if(value.dataType !== 11) {
            this.tooltipText +=  ' not available.';
        }
    }
}
