import { Injectable } from "@angular/core";


export class CCEConst {
  public static readonly PERMISION_OPEN_LOCAL: string ="cceOpenLocal";
  public static readonly PERMISION_MESSAGE:String = "Not authenticated for action: "

  public static readonly PERMISION_SAVE_LOCAL: string ="cceSaveLocal";


  public static readonly PERMISION_OPEN_REMOTE: string ="cceOpenRemote";


  public static readonly PERMISION_SAVE_REMOTE: string ="cceSaveRemote";

  public static readonly PERMISION_EXPORT_EXCEL: string ="cceExportExcel";

  public static readonly PERMISION_FULL_EXPORT_EXCEL: string ="cceExportExcelFull";

  public static readonly PERMISION_EXPORT_PDF: string ="cceExportPDF";

  public static readonly PERMISION_EXPORT_CSV: string ="cceExportExcel";

}
