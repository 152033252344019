<div style="padding: 5px;" id="successor-page">
  <div class="successor-header">
    <span id="boldedText"> CHOOSE SUCCESSORS FOR UNAVAILABLE NATCODES</span>
  </div>
  <div class="successor-background">
    <div class="wrapper">

      <div class="successor-body">

        <div class="cce-row vehicle-header">
          <div class="old-vehicle-label">
            <span>OLD VEHICLE (NATCODE)</span>
          </div>
          <mat-divider class="header-div" [vertical]="true"></mat-divider>
          <div class="successor-vehicle-label">
            <span>SUCCESSOR VEHICLES (NATCODE)</span>
          </div>
        </div>

        <div class="cce-col table-body">
          <div *ngFor="let car of cars; let isLastCar = last">
            <div class="cce-row">

              <div class="left-container">
                <div class="left-row cce-row">
                  <!-- <mat-icon>radio_button_checked</mat-icon> -->
                  <span>⚫</span>
                  <div class="cce-col" style="margin-left: 10px;">
                    <p style="font-size: 16px;">{{car.parent.make + ", " + car.parent.car}} </p>
                    <div style="margin-left: 5px;">
                      <p *ngIf="car.parent.scenario">- Scenario: {{car.parent.scenario.description}} </p>
                      <p>- Natcode: {{car.parent.carID}} </p>
                      <p>- Sales start: {{car.parent.saleStart}} </p>
                      <p>- Sales end: {{car.parent.saleEnd}} </p>
                      <p>- Net price: {{car.parent.netPrice}} </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="right-container">
                <div class="right-row" *ngFor="let successor of car.successors; let isLast = last">
                  <div *ngIf="car.successors.length>0" class="cce-row">
                    <mat-checkbox [checked]="successor.isSelected"
                      (change)="successor.isSelected=!successor.isSelected">
                    </mat-checkbox>
                    <div class="cce-col" style="margin-left: 10px;">
                      <p style="font-size: 16px;">{{successor.make + ", " + successor.car}} </p>
                      <div style="margin-left: 5px;">
                        <p>- Natcode: {{successor.carID}} </p>
                        <p>- Sales start: {{successor.saleStart}} </p>
                        <p>- Net price: {{successor.netPrice}} </p>
                      </div>
                    </div>
                  </div>
                  <mat-divider *ngIf="!isLast"></mat-divider>
                </div>
                <div *ngIf="car.successors.length<=0" class="right-row cce-row">
                  <span>⚫</span>
                  <p style="margin-left: 10px;">No successor found, vehicle will be removed. </p>
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>

      <footer class="footer">
        <button mat-raised-button class="cce-footer-blue-button" (click)="continue()">CONTINUE</button>
        <button mat-raised-button class="cce-footer-grey-button" (click)="closeMe()">CANCEL</button>
      </footer>

    </div>
  </div>

</div>
