<div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#1d71b8" type="line-scale"></ngx-spinner>
  <div id="file-share-config">
    <div class="file-share-scope cce-row">
      <div class="default-parameters">{{title}}</div>
      <div class="cce-header-blue-button">
        <button mat-raised-button #changeButtonProp class="cce-blue-button" (click)="changeDialogType()">{{changeButton}}</button>
      </div>
      <div class="cce-header-blue-button">
        <button mat-raised-button class="cce-blue-button">Refresh List</button>
      </div>
    </div>

    <div class="file-share-form">
      <ag-grid-angular style=" height: calc(100vh - 20px - 30px - 160px - 45px - 205px - 45px)"
        class="ag-theme-balham file-share-grid" [gridOptions]=gridOptions [columnDefs]=columnDefs [rowData]=rowData>
      </ag-grid-angular>
      <div class="file_name_container" *ngIf="isForSave===true">
        <span class="label">File name</span>
        <input #fileNameProp matInput class="formInput"/>
      </div>
      <div class="cce-row button_container">
        <div class="container_delete">
          <button class="button_delete" type="submit" (click)="onDelete()" mat-button
            *ngIf="isForSave===false">DELETE</button>
        </div>
        <button class="button_open" type="submit" (click)="onSave()" mat-dialog-close mat-button
          *ngIf="isForSave===true">SAVE</button>
        <button class="button_open" type="submit" (click)="onOpen()" mat-button *ngIf="isForSave===false">OPEN</button>
        <button class="button_close" type="submit" (click)="onClose()" mat-dialog-close mat-button>CANCEL</button>
      </div>
    </div>
  </div>
</div>
