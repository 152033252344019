<div>
  <!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#1d71b8" type="line-scale"></ngx-spinner> -->
  <div class="d">
    <span id="home-icon" class="no-after"><img style="height: 18px;" src="../../../assets/home.png"></span>
    <div class="breadcrumb flat">
      <a>NEW BATCH</a>
      <a [class]="(numberPage >0)?'clickable-breadcrump':'active'" (click)="goBackTo0()">SELECT VEHICLE</a>
      <a class="active" *ngIf="(numberPage > 0)">SELECT SCENARIOS</a>
    </div>
  </div>
  <div id="cars-page" *ngIf="(numberPage === 0)">
    <div class="cce-header-nbc-vehicle">
      <div>
        <span id="boldedText">SEARCH BY </span>
        <span cdkFocusRegionstart id="bigTitileText">COUNTRY/MAKE/MODEL</span>
        <input type="file" ng2FileSelect (change)="upload($event)" (uploader)="uploader" hidden #myUpload />
        <button [disabled]="!isUploadAllowed" id="fileUploadButton" (click)="myUpload.click()">FILE UPLOAD</button>
      </div>
      <form novalidate [formGroup]="editModuleForm" class="cce-row" style="width: 100%;">
        <div class="cce-row" style="width: 100%;">
          <div class="cce-col" style="display: flex;">
            <div class="cce-row">
              <div class="cce-col">
                <mat-label class="cce-label">COUNTRY</mat-label>
                <mat-form-field class="cce-combo-field" floatLabel="never" appearance="none">
                  <mat-select class="cce-combo" formControlName="marketControl">
                    <mat-option [value]="all">ALL</mat-option>
                    <mat-option class="cce-option" *ngFor="let market of combos['marketControl']" [value]="market">
                      {{ market }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="cce-col">
                <mat-label class="cce-label">MAKE</mat-label>
                <mat-form-field class="cce-combo-field" floatLabel="never" appearance="none">
                  <mat-select class="cce-combo" formControlName="makeControl">
                    <mat-option [value]="all">ALL</mat-option>
                    <mat-option class="cce-option" *ngFor="let make of combos['makeControl']" [value]="make">
                      {{ make }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="cce-col">
                <mat-label class="cce-label">MODEL</mat-label>
                <mat-form-field class="cce-combo-field" floatLabel="never" appearance="none">
                  <mat-select class="cce-combo" formControlName="modelControl">
                    <mat-option [value]="all">ALL</mat-option>
                    <mat-option class="cce-option" *ngFor="let model of combos['modelControl']" [value]="model">
                      {{ model }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="cce-col">
                <mat-label class="cce-label">TRIM LEVEL</mat-label>
                <mat-form-field class="cce-combo-field" floatLabel="never" appearance="none">
                  <mat-select class="cce-combo" formControlName="trimLevelControl">
                    <mat-option [value]="all">ALL</mat-option>
                    <mat-option class="cce-option" *ngFor="let trim of combos['trimLevelControl']" [value]="trim">
                      {{ trim }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="cce-col">
                <mat-label class="cce-label">SALE START</mat-label>
                <mat-form-field class="cce-combo-field" floatLabel="never" appearance="none">
                  <mat-select class="cce-combo" formControlName="saleStartControl">
                    <mat-option [value]="all">ALL</mat-option>
                    <mat-option class="cce-option" *ngFor="let saleStart of combos['saleStartControl']"
                      [value]="saleStart">
                      {{ saleStart }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="cce-col">
                <mat-label class="cce-label">SALE END</mat-label>
                <mat-form-field class="cce-combo-field" floatLabel="never" appearance="none">
                  <mat-select class="cce-combo" formControlName="saleEndControl">
                    <mat-option [value]="all">ALL</mat-option>
                    <mat-option class="cce-option" *ngFor="let saleEnd of combos['saleEndControl']" [value]="saleEnd">
                      {{ saleEnd }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <span class="cce-spacer"></span>
          <div class="cce-col">
            <mat-label class="cce-label" style="padding-left: 11px;">FREE SEARCH</mat-label>
            <mat-form-field class="cce-nb-free-field" floatLabel="never" appearance="none">
              <div class="cce-free">
                <input matInput class="free-input" formControlName="freeControl" (input)="customFilterChange(null)">
              </div>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div class="cce-filters-new-batch">
      <div class="grid-info">
        <div class="cce-header-custom-filters">
          <div style="width: 20%;">
            <span class="cce-label" (click)="onClickFilters()"
              style="font-size: 16px; padding: 7px 0 0 0;cursor: pointer;">CUSTOM FILTERS</span>
            <button class="arrow-button" [ngClass]="{ '': showCustomFilters, 'selectArrow': !showCustomFilters  }"
              (click)="onClickFilters()">
              <img src="../../../assets/cce_arrow.png">
            </button>
          </div>
          <div *ngIf="!showCustomFilters"
            style="position: relative; width: calc(60% - 40px); padding: 10px; text-align: center;">
            <span>SHOWING {{totalFilteredItems}} VEHICLES</span>
          </div>

        </div>

        <ng-container *ngIf="showCustomFilters">
          <div style="padding: 0px 0 5px 0;">
            <div class="horizontalDelimiter"></div>
          </div>
          <div class="cce-row">
            <div cdkDropList cdkDropListOrientation="horizontal" class="cce-dd-list"
              (cdkDropListDropped)="drop($event)">
              <div cdkDrag cdkDragLockAxis="x" *ngFor="let elem of selectedFilters;" class="filter">
                <div class="cce-col">
                  <div class="filterLabel">
                    <span>{{ filtersMETA[elem].label }}</span>
                  </div>
                  <div *ngIf="isRange(elem)" class="cce-col cce-filter-slider">
                    <ng5-slider [(value)]="filterTemplate[elem].from" [(highValue)]="filterTemplate[elem].to"
                      [options]="filterTemplate[elem].options" (userChange)="customFilterChange(elem)">
                    </ng5-slider>
                  </div>
                  <div *ngIf="isNaN(elem)" class="cce-col cce-filter-list">
                    <span>NaN</span>
                  </div>
                  <div *ngIf="isList(elem)" class="cce-col cce-filter-list">
                    <span *ngFor="let item of sortSet(filterTemplate[elem]?.values)" [class]="getStyle(elem, item)"
                      (click)="toggleSelection(elem, item)">&nbsp;{{item}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="cce-filter-edit">
              <div class="filterLabel">
                <span>ADD FILTERS</span>
              </div>
              <form [formGroup]="nestedForm" class="cce-filter-field">
                <mat-form-field floatLabel="never" appearance="none">
                  <mat-select formControlName="filters" (selectionChange)="onFormSubmit($event)"
                    placeholder="- Select filter -" class="cce-combo" multiple>
                    <mat-option *ngFor="let item of filtersMETA | keyvalue;" [value]="item.key">
                      {{item.value.label}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
            </div>
            <span class="cce-spacer"></span>
            <span><button (click)="customFilterChange(null)" class="cce-header-blue-button">Clear
                all</button></span>
          </div>
        </ng-container>
        <div *ngIf="showCustomFilters" style="position: relative; padding: 10px; text-align: center;">
          <span>SHOWING {{totalFilteredItems}} VEHICLES</span>
        </div>
      </div>
    </div>
    <div>
      <div class="cce-row">
        <app-available-cars [currencyID]="currencyID" #availableCarsGrid [style.width]="(display==='none')?'100%':'65%'"
          [parent]="this" [firstCSPage]="firstCSPage" [selectedIds]="selectedAvailableNodes">
        </app-available-cars>
        <app-selected-cars #selectedCarsGrid [style.width]="(display==='none')?'0%':'35%'" [style.display]="display"
          [parent]="this" [selectedCars]="selCarRows">
        </app-selected-cars>
      </div>
    </div>
  </div>
  <div id="scenario-page" *ngIf="(numberPage === 1)">
    <app-scenario-selection [distanceID]="distanceID" [sectionName]="childName" [ShowDefault]="true"
      (forward)="finishSelection($event)">
    </app-scenario-selection>
  </div>
</div>
