import { Constants } from '@app/helpers/Constants';

export function getCurrencyIDByAbbr(currency): number {
  switch (currency) {
    case 'EUR':
      return Constants.CURRENCY_EUR_ID;
    case "GBP":
      return Constants.CURRENCY_GBP_ID;
    case "PLN":
      return Constants.CURRENCY_PLN_ID;
    case "CHF":
      return Constants.CURRENCY_CHF_ID;
    case "CZK":
      return Constants.CURRENCY_CZK_ID;
    case "RON":
      return Constants.CURRENCY_RON_ID;
    default:
      return -1;
  }
}


export function getCurrencySignByID(currencyID): string {
  switch (currencyID) {
    case Constants.CURRENCY_EUR_ID:
      return "€";
    case Constants.CURRENCY_GBP_ID:
      return "£";
    case Constants.CURRENCY_CHF_ID:
      return "Fr.";
    case Constants.CURRENCY_CZK_ID:
      return "Kč";
    case Constants.CURRENCY_PLN_ID:
      return "zł";
    case Constants.CURRENCY_RON_ID:
      return "lei";
    default:
      return "???";
  }
}

export function getDistanceUnitByID(distanceUnitID): string {
  switch (distanceUnitID) {
    case Constants.UNIT_ID__KILOMETRE:
      return "km";
    case Constants.UNIT_ID__MILE:
      return "mi";
    default:
      return "???";
  }
}

export function remapIntervalOccurenceUnit(intervalOccurenceID): string {
  switch (parseInt(intervalOccurenceID, 10)) {
    case 13:
      return 'First time after';
    case 8:
      return 'Once after';
    case 3:
      return 'Every';
    case 1:
      return 'According to display';
    default:
      return '???';
  }
}

export function remapIntervalUnit(intervalUnitID): string {
  switch (parseInt(intervalUnitID, 10)) {
    case Constants.UNIT_ID__KILOMETRE:
      return 'Km';
    case 4:
      return 'Years';
    case 5:
      return 'Months';
    case Constants.UNIT_ID__MILE:
      return 'Mi';
    default:
      return '???';
  }

}
