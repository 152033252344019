import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { environment } from '../../environments/environment';
import JwtDecode from 'jwt-decode';
import { ParamState, PrefState } from '../components/param-form/param-form.component';

@Injectable({
  providedIn: 'root'
})
export class ScopeService {

  constructor(private http: HttpClient) { }

  loadParamFormData(market: string, make: string, model: string, fuel: string): Observable<ParamState[]> {
    if (market) {
      let parameters = {};
      if (market) {
        parameters['market'] = market;
      }
      if (make) {
        parameters['make'] = make;
      }
      if (model) {
        parameters['model'] = model;
      }
      if (fuel) {
        parameters['fuel'] = fuel;
      }

      return this.http.get<ParamState[]>(environment.paramFormEndpoint, { params: parameters });
    } else {
      return EMPTY;
    }
  }

  saveParamWithPreferences(data: any): Observable<any> {
    return this.http.post<any>(environment.saveParamWithPreferencesEndpoint, data);
  }

}
