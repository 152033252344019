import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-acar-util-optional',
  templateUrl: './acar-util-optional.component.html',
  styleUrls: ['./acar-util-optional.component.scss']
})
export class AcarUtilOptionalComponent implements ICellRendererAngularComp {

  params: ICellRendererParams;
  value1: string;
  value2: string;
  value3: string;
  value4: string;
  exists: number;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value1 = params.value.labour;
    this.value2 = params.value.parts;
    this.value3 = params.value.service;
    this.value4 = params.value.wear;
    this.exists = 4;
    if (this.value1 == null || this.value1 === '' || this.value1.substring(0, 3) === 'n/a') {
      this.exists--;
    }
    if (this.value2 == null || this.value2 === '' || this.value2.substring(0, 3) === 'n/a') {
      this.exists--;
    }
    if (this.value3 == null || this.value3 === '' || this.value3.substring(0, 3) === 'n/a') {
      this.exists--;
    }
    if (this.value4 == null || this.value4 === '' || this.value3.substring(0, 3) === 'n/a') {
      this.exists--;
    }
  }

  refresh(params: any): boolean {
    // TODO: implement this properly
    return false;
  }

}
