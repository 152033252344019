<div class="admin">
    <mat-card>
        <mat-card-content style="height: 100%;width: 100%">
            <div class="header">{{ title }}</div>
            <div class="field-body" [ngStyle]="{'height' : isOldUser ?  '400px': '360px'}">
                <form [formGroup]="userForm" novalidate>
                    <div class="cce-row">
                        <div class="form_container">
                            <div class="pf-section-div">
                                <mat-form-field class="full-width hor-field">
                                    <span class="label" [ngClass]="{'form-unsubmitted': usernamePristine()}">User Name: </span>
                                    <input matInput class="formInput"[(ngModel)]='user.username' formControlName="userName" [readonly]="!!id">
                                </mat-form-field>
                            </div>
                            <div class="pf-section-div">
                                <mat-form-field class="full-width hor-field">
                                    <span class="label">Description: </span>
                                    <input matInput class="formInput" [(ngModel)]='user.description' formControlName="description">
                                </mat-form-field>
                            </div>
                            <div class="pf-section-div">

                                <mat-form-field *ngIf="isOldUser" class="full-width hor-field">
                                    <span class="label" [ngClass]="{'form-unsubmitted': passwordPristine()}">Password: </span>
                                    <input matInput class="formInput"
                                    [(ngModel)]='user.password' formControlName="password">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="second-section">
                    <div style="flex: 1;">
                        <div style="display: flex;">
                            <span class="check-label">OPEN LOCALLY</span>
                            <mat-checkbox #cceOpenLocal class="check"></mat-checkbox>
                        </div>
                        <div style="display: flex;">
                            <span class="check-label">OPEN REMOTELY</span>
                            <mat-checkbox #cceOpenRemote class="check"></mat-checkbox>
                        </div>
                        <div style="display: flex;">
                            <span class="check-label">SAVE LOCALLY</span>
                            <mat-checkbox #cceSaveLocal class="check"></mat-checkbox>
                        </div>
                        <div style="display: flex;">
                            <span class="check-label">SAVE REMOTELY</span>
                            <mat-checkbox #cceSaveRemote class="check"></mat-checkbox>
                        </div>
                        <div style="display: flex;">
                            <span class="check-label">EXPORT TO PDF</span>
                            <mat-checkbox #cceExportPDF class="check"></mat-checkbox>
                        </div>
                        <div style="display: flex;">
                            <span class="check-label">EXPORT TO EXCEL</span>
                            <mat-checkbox #cceExportExcel class="check"></mat-checkbox>
                        </div>
                        <div style="display: flex;">
                            <span class="check-label">DETAILED EXCEL</span>
                            <mat-checkbox #cceExportExcelFull class="check"></mat-checkbox>
                        </div>
                    </div>
                    <div style="flex: 1; display: flex; height: 170px;">
                        <div class="check-label">ROLES: </div>
                        <div class="cce-filter-list">
                            <span *ngFor="let role of roles;"  [class]="getStyle(role)"
                            (click)="toggleSelection(role)">{{role.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="button_container">
                    <button class="button_save" type="submit" (click)="onSubmit()" mat-button>SAVE</button>
                    <button class="button_close" type="button" (click)="onClose()" mat-dialog-close mat-button>CLOSE</button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
