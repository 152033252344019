
export class RequestDataNewCols {
  public columnID: number;
  public scenario;
  public vehicle;
  // public forLoad = false;


  public constructor(columnID: number, vehicle: any, scenario: any) {
    this.columnID = columnID;
    this.scenario = scenario;
    this.vehicle = vehicle;
    // this.forLoad = true;
  }

}
