<div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#1d71b8" type="line-scale"></ngx-spinner>

  <div *ngIf="!isSelectedScope" class="overlay">
    <span class="notification-text">PLEASE SELECT SCOPE TO REVIEW PARAMETERS</span>
  </div>
  <form [formGroup]="paramForm" novalidate>
    <div class="cce-row">
      <div class="cce-col">
        <div class="form_container">
          <div class="pf-section-label">Net Price:</div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Vehicle discount (%)</div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['vehicleDiscount']?'current-level-changed':''"
                [placeholder]="this.placeholders['vehicleDiscount']" formControlName="vehicleDiscount">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Net price reduction ({{currency}}) </div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['netPriceReduction']?'current-level-changed':''"
                [placeholder]="this.placeholders['netPriceReduction']" formControlName="netPriceReduction">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Optional equipment price ({{currency}}) </div>
              <input matInput class="formInput" [ngClass]="this.currentScope['optionalEquipmentPrice']?'current-level-changed':''"
                [placeholder]="this.placeholders['optionalEquipmentPrice']" formControlName="optionalEquipmentPrice">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Optional equipment (%) </div>
              <input matInput class="formInput" [ngClass]="this.currentScope['optionalEquipmentPCT']?'current-level-changed':''"
                [placeholder]="this.placeholders['optionalEquipmentPCT']" formControlName="optionalEquipmentPCT">
            </mat-form-field>
          </div>
        </div>

        <div class="form_container">
          <div class="pf-section-label">Financing:</div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Interest rate, p.a. (%)</div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['interestRatePA']?'current-level-changed':''"
                [placeholder]="this.placeholders['interestRatePA']" formControlName="interestRatePA">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Down payment ({{currency}})</div>
              <input matInput class="formInput" [ngClass]="this.currentScope['downPayment']?'current-level-changed':''"
                [placeholder]="this.placeholders['downPayment']" formControlName="downPayment">
            </mat-form-field>
          </div>
        </div>
        <div class="form_container">
          <div class="pf-section-label">Insurance:</div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Insurance rate, p.a. ({{currency}}) </div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['insuranceRatePA']?'current-level-changed':''"
                [placeholder]="this.placeholders['insuranceRatePA']" formControlName="insuranceRatePA">
            </mat-form-field>
          </div>
          </div>
      </div>
      <div class="vertical"></div>
      <div class="cce-col">
        <div class="form_container">
          <div class="pf-section-label">Fuel:</div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Petrol ({{currency}})</div>
              <input matInput class="formInput" [ngClass]="this.currentScope['petrol']?'current-level-changed':''"
                [placeholder]="this.placeholders['petrol']" formControlName="petrol">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Diesel ({{currency}})</div>
              <input matInput class="formInput" [ngClass]="this.currentScope['diesel']?'current-level-changed':''"
                [placeholder]="this.placeholders['diesel']" formControlName="diesel">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Ethanol ({{currency}})</div>
              <input matInput class="formInput" [ngClass]="this.currentScope['ethanol']?'current-level-changed':''"
                [placeholder]="this.placeholders['ethanol']" formControlName="ethanol">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Liquefied petroleum gas ({{currency}})</div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['liquefiedPetrоleumGas']?'current-level-changed':''"
                [placeholder]="this.placeholders['liquefiedPetrоleumGas']" formControlName="liquefiedPetrоleumGas">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Compressed natural gas ({{currency}})</div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['compressedNaturalGas']?'current-level-changed':''"
                [placeholder]="this.placeholders['compressedNaturalGas']" formControlName="compressedNaturalGas">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Electricity, per kWh ({{currency}})</div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['electricityPerkWh']?'current-level-changed':''"
                [placeholder]="this.placeholders['electricityPerkWh']" formControlName="electricityPerkWh">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">EV system efficiency (%)</div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['evSystemEfficiency']?'current-level-changed':''"
                [placeholder]="this.placeholders['evSystemEfficiency']" formControlName="evSystemEfficiency">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">AdBlue ({{currency}})</div>
              <input matInput class="formInput" [ngClass]="this.currentScope['adBlue']?'current-level-changed':''"
                [placeholder]="this.placeholders['adBlue']" formControlName="adBlue">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="vertical"></div>
      <div class="cce-col">
        <div class="form_container">
          <div class="pf-section-label">Service:</div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Service parts discount (%)</div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['servicePartsDiscount']?'current-level-changed':''"
                [placeholder]="this.placeholders['servicePartsDiscount']" formControlName="servicePartsDiscount">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Service labour discount (%)</div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['serviceLabourDiscount']?'current-level-changed':''"
                [placeholder]="this.placeholders['serviceLabourDiscount']" formControlName="serviceLabourDiscount">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Unplanned service, p.a. ({{currency}})</div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['unplannedService']?'current-level-changed':''"
                [placeholder]="this.placeholders['unplannedService']" formControlName="unplannedService">
            </mat-form-field>
          </div>
        </div>
        <div class="form_container">
          <div class="pf-section-label">Wear:</div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Wear parts discount (%)</div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['wearPartsDiscount']?'current-level-changed':''"
                [placeholder]="this.placeholders['wearPartsDiscount']" formControlName="wearPartsDiscount">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Overhaul labour discount (%)</div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['overhaulLabourDisc']?'current-level-changed':''"
                [placeholder]="this.placeholders['overhaulLabourDisc']" formControlName="overhaulLabourDisc">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Unplanned wear, p.a. ({{currency}})</div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['unplannedWear']?'current-level-changed':''"
                [placeholder]="this.placeholders['unplannedWear']" formControlName="unplannedWear">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="vertical"></div>
      <div class="cce-col">
        <div class="form_container">
          <div class="pf-section-label">Tyre:</div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Tyre discount (%)</div>
              <input matInput class="formInput" [ngClass]="this.currentScope['tyreDiscount']?'current-level-changed':''"
                [placeholder]="this.placeholders['tyreDiscount']" formControlName="tyreDiscount">
            </mat-form-field>
          </div>
        </div>
        <div class="form_container">
          <div class="pf-section-label">Taxation:</div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">VAT Rate (%)</div>
              <input matInput class="formInput" [ngClass]="this.currentScope['vatRate']?'current-level-changed':''"
                [placeholder]="this.placeholders['vatRate']" formControlName="vatRate">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Acquisition tax reduction ({{currency}})</div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['acquisitionTaxReduction']?'current-level-changed':''"
                [placeholder]="this.placeholders['acquisitionTaxReduction']" formControlName="acquisitionTaxReduction">
            </mat-form-field>
          </div>
          <div class="pf-section-div">
            <mat-form-field class="full-width" appearance="none" floatLabel="always">
              <div class="label">Utilisation tax reduction, p.a. ({{currency}})</div>
              <input matInput class="formInput"
                [ngClass]="this.currentScope['utilisationTaxReduction']?'current-level-changed':''"
                [placeholder]="this.placeholders['utilisationTaxReduction']" formControlName="utilisationTaxReduction">
            </mat-form-field>
          </div>
        </div>

      </div>
    </div>
    <div class="button_container">
      <button class="button_apply" type="submit" (click)="onApply()" mat-button>APPLY</button>
      <button class="button_close" type="submit" mat-dialog-close mat-button>CLOSE</button>
    </div>
  </form>
</div>
