import { Component, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Inject, ElementRef } from '@angular/core';
import { ScenarioService } from '@app/services/scenario.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';


@Component({
  selector: 'app-add-scenario',
  templateUrl: './add-scenario.component.html',
  styleUrls: ['./add-scenario.component.scss']
})


export class AddScenarioComponent implements OnInit {

  @ViewChild('availableScenarioGrid') availableScenarioGrid;
  @ViewChild('selectedScenarioGrid') selectedScenarioGrid;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataFromMain: any, private _scenService: ScenarioService,
    private dialogRef: MatDialogRef<AddScenarioComponent>, private messageService: HandleUiMessagesService,
    private spinner: NgxSpinnerService) {
    this.distanceID = this.dataFromMain.distanceID;
  }

  distanceID: number;
  selCarRows = [];
  scenRows = [];
  selScenRows = [];
  childName: string;
  selectedCount = 0;
  showCustomFilters: boolean = false;

  closeDialog() {
    this.dialogRef.close();
  }

  finishSelection(selectedScenario) {
    if (selectedScenario.length === 0) {
      this.messageService.showMessage("Please select scenario!");
      return;
    }
    this.dialogRef.close({ selectedScenario });
  }

  getIndexSheet() {
    return this.dataFromMain.sheetIdx;
  }

  clearAllScenarios() {
    this.availableScenarioGrid.selectedScenarios = [];
    this.selectedScenarioGrid.selectedScenario = [];
    this.selectedScenarioGrid.refreshGridData();
  }

  ngOnInit(): void {
    this.childName = 'SCENARIO SELECTION';
    this.spinner.show();
    this._scenService.loadBasket(this.distanceID).subscribe(data => {
      this.scenRows = data.rows;
      this.spinner.hide();
    },
      error => {
        this.messageService.showMessage("Error loading scenarios!");
        this.spinner.hide();
      });
  }

}


