<div matTooltip="Labour: {{value1}}&#13;Parts: {{value2}}&#13;Service: {{value3}}&#13;Wear {{value4}}"
  [matTooltipClass]="'multi-line-tooltip'">
  <div *ngIf="exists >= 4" style="background-color: rgb(64, 64, 192); height: 8px; width: 12px">&nbsp;</div>
  <div *ngIf="exists < 4" style="background-color: rgb(190, 190, 234); height: 8px; width: 12px">&nbsp;</div>
  <div *ngIf="exists >= 3" style="background-color: rgb(64, 64, 192); height: 8px; width: 12px">&nbsp;</div>
  <div *ngIf="exists < 3" style="background-color: rgb(190, 190, 234); height: 8px; width: 12px">&nbsp;</div>
  <div *ngIf="exists >= 2" style="background-color: rgb(64, 64, 192); height: 8px; width: 12px">&nbsp;</div>
  <div *ngIf="exists < 2" style="background-color: rgb(190, 190, 234); height: 8px; width: 12px">&nbsp;</div>
  <div *ngIf="exists >= 1" style="background-color: rgb(64, 64, 192); height: 8px; width: 12px">&nbsp;</div>
  <div *ngIf="exists < 1" style="background-color: rgb(190, 190, 234); height: 8px; width: 12px">&nbsp;</div>
</div>
