import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/helpers/auth.guard';
import { HomeComponent } from '@app/components/home/home.component';
import { LoginComponent } from '@app/components/login/login.component';
import { AdminComponent } from '@app/components/admin/admin.component';
import { UpdateTriggerComponent } from '@app/components/update-trigger/update-trigger.component';
import { ChangePassWindowComponent } from '@app/components/change-pass-window/change-pass-window.component';
import { ResetPassWindowComponent } from '@app/components/reset-pass-window/reset-pass-window.component';

const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'account/login', component: LoginComponent },
    { path: 'resetPass', component: ResetPassWindowComponent },
    { path: 'changePass', component: ChangePassWindowComponent },
    { path: 'admin', component: AdminComponent,  canActivate: [AuthGuard] },
    { path: 'update/trigger', component: UpdateTriggerComponent,  canActivate: [AuthGuard] },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
