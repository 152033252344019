import { Component, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { threadId } from 'worker_threads';
import { environment } from '@environments/environment';
import { CceFilesService } from '@app/services/cce-db-files.service';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';


@Component({
  selector: 'file-type-renderer-component ',
  templateUrl: './file-type-renderer-component.html',
  styleUrls: ['./file-type-renderer-component.scss']
})
export class FileTypeCellRenderer {
  params: any;
  parent: any;
  values = [];
  currentVal:any;
  @ViewChild('selectItem') selectItem;
  nrSelect;
  constructor(private http: HttpClient, private cceFilesService: CceFilesService,private messageService: HandleUiMessagesService) {
  }

  agInit(params: any): void {
    this.params = params;
    this.nrSelect = this.params.value;
  }


  onChange($event, value) {
    let ccefile = this.params.data;
    ccefile.permissions = value;

    this.cceFilesService.saveCceFileObject(ccefile).subscribe(response => {
      this.messageService.showMessage("Successfully saved!");
    }, error => {
      this.messageService.showMessage("Invalid save!");
    });

  }


}
