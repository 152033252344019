import { SheetService } from '@app/services/sheet.service';
import { Component, OnInit, ViewChild, Inject, Input, Output, EventEmitter, AfterViewInit, AfterContentChecked } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SorcService } from '@app/services/sorc.service';
import { Constants } from '@app/helpers/Constants.ts';
import { ScenarioService } from '@app/services/scenario.service';
import { UserPreferencesService } from '@app/services/user-preferences.service';
import { NullTemplateVisitor } from '@angular/compiler';
import { startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'app-scenario-selection',
  templateUrl: './scenario-selection.component.html',
  styleUrls: ['./scenario-selection.component.scss']
})
export class ScenarioSelectionComponent implements OnInit {

  scenRows: [];
  selCarRows = [];

  @Input() distanceID;
  @Input() showDefaultScen;
  defScenarios;
  @Input() sectionName: string;
  @Input() section: string = '';
  @Output() forward = new EventEmitter<any>();
  @Input() ShowDefault: boolean;



  checkEnableButton(): void {
  }

  @ViewChild('availableScenarioGrid') availableScenarioGrid;
  @ViewChild('selectedScenarioGrid') selectedScenarioGrid;

  constructor(
    private _scenService: ScenarioService,
    private dialogRef: MatDialogRef<ScenarioSelectionComponent>,
    private sheetService: SheetService, private userPrefService: UserPreferencesService) { }




  ngOnInit(): void {
    this._scenService.loadBasket(this.distanceID).subscribe(data => {
      if (this.showDefaultScen) {//from addVehicle
        const scens = this.userPrefService.getScenario(this.distanceID);
        this.defScenarios = scens;
      } else {
        this.defScenarios = [];
      }
      this.scenRows = data.rows;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  forwardToParent(event) {
    this.forward.emit(event);
  }


  clearAllScenarios() {
    this.availableScenarioGrid.selectedScenarios = [];
    this.selectedScenarioGrid.selectedScenario = [];
    this.selectedScenarioGrid.refreshGridData();
  }

  loadDefaultScenarios() {
    const scens = this.userPrefService.getScenario(this.distanceID);
    if (scens === undefined || scens === null) {
      this.selectedScenarioGrid.selectedScenario = [];
    } else {
      for (const scen of scens) {
        this.availableScenarioGrid.selectedScenarios[scen.id] = true;
        this.selectedScenarioGrid.addSelectedScenario(scen);
      }
    }
    this.selectedScenarioGrid.refreshGridData();
  }
}
