import { CalcModel, InsuranceModel, InsuranceRateModel, OtOwnershipTaxModel, OwnershipTaxModel, CPRoadTaxModel, RoadTaxModel } from '@app/domain/calc-model';
import { Injectable } from "@angular/core";
import { Sheet } from "@app/domain/sheet";
import { Observable, BehaviorSubject, of } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { map, flatMap, share } from 'rxjs/operators';
import { environment, metadata } from '@environments/environment';
import emptyData from '../../assets/emptyTCO.json';
import emptyDataMaintenance from '../../assets/emptyMaintenance.json';
import * as lodash from 'lodash'; //copy array
import { UserPreferencesService } from './user-preferences.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '@app/helpers/Constants';
import { getNameOfColumn } from '@app/helpers/utils.js';
import { LocalStorageService } from './local-storage.service';



@Injectable({
  providedIn: 'root'
})
export class SheetService {
  public sheets: Sheet[] = [];
  private colID = 0;

  public sub = new BehaviorSubject<Sheet[]>(this.sheets);

  TEXT_COLOR_GREEN = Constants.TEXT_COLOR_GREEN;
  TEXT_COLOR_RED = Constants.TEXT_COLOR_RED;
  TEXT_COLOR_GRAY = Constants.TEXT_COLOR_GRAY;
  TEXT_COLOR_BLUE = Constants.TEXT_COLOR_BLUE;


  readonly ACTIVE_TOTAL = Constants.ACTIVE_TOTAL;
  readonly ACTIVE_KM = Constants.ACTIVE_KM;
  readonly ACTIVE_MONTH = Constants.ACTIVE_MONTH;


  constructor(
    private http: HttpClient, private localStorageService: LocalStorageService,
    private spinner: NgxSpinnerService,
    private userPreferencesService: UserPreferencesService,
    private snackBar: MatSnackBar) {
  }

  getSheet(index): Sheet {
    return this.sheets[index];
  }

  public addFilledSheet(sheet: Sheet) {
    for (const el of this.sheets) {
      if (el.active === true) {
        el.active = false;
      }
    }
    let newSheet = lodash.cloneDeep(sheet);
    newSheet.transientFields.isLoadedFromFile = true;
    newSheet.transientFields.isRefreshed = true;
    newSheet.active = true;
    this.sheets.push(newSheet);
    this.sub.next(this.sheets);
    return newSheet;
  }

  public addSheet(sheet: Sheet, calcModel?, distanceID?, currencyID?) {
    for (const el of this.sheets) {
      if (el.active === true) {
        el.active = false;
      }
    }
    const up = this.userPreferencesService.getUserPreferencesNow();
    sheet.active = true;
    sheet.dataTCO = lodash.cloneDeep(emptyData);
    //set financing
    let financingPath = '$[?(@.field==\'acquisition\')].children[?(@.field==\'financing\')].children[?(@.field==\'leasing\')].field';
    this.setValue(financingPath, up.calcModel.financing, sheet.dataTCO);
    //set insurance
    let insuranceRatePath = '$[?(@.field==\'utilisation\')].children[?(@.field==\'insurance\')].children[?(@.field==\'comprehensiveCoverage\')].children[?(@.field==\'ccRate\')].field';
    let insuranceRateVal;
    if (up.calcModel.insurance === InsuranceModel.COMPREHENSIVE_COVERAGE) {
      insuranceRateVal = InsuranceRateModel.COMPREHENSIVE_COVERAGE;
    } else if (up.calcModel.insurance === InsuranceModel.PARTIAL_COVERAGE) {
      insuranceRateVal = InsuranceRateModel.PARTIAL_COVERAGE;
    } else if (up.calcModel.insurance === InsuranceModel.THIRD_PARTY_LIABILITY) {
      insuranceRateVal = InsuranceRateModel.THIRD_PARTY_LIABILITY;
    } else {
      insuranceRateVal = InsuranceRateModel.NONE;
    }
    this.setValue(insuranceRatePath, insuranceRateVal, sheet.dataTCO);
    let insurancePath = '$[?(@.field==\'utilisation\')].children[?(@.field==\'insurance\')].children[?(@.field==\'comprehensiveCoverage\')].field';
    this.setValue(insurancePath, up.calcModel.insurance, sheet.dataTCO);
    //set ownership
    let ownershipPath = '$[?(@.field==\'utilisation\')].children[?(@.field==\'utilisationTaxes\')].children[?(@.field==\'ownershipTax\')].children[?(@.field==\'otCommercialPA\')].field';
    let ownershpVal;
    if (up.calcModel.ownershipTax === OwnershipTaxModel.COMMERCIAL) {
      ownershpVal = OtOwnershipTaxModel.COMMERCIAL;
    } else if (up.calcModel.ownershipTax === OwnershipTaxModel.PRIVATE) {
      ownershpVal = OtOwnershipTaxModel.PRIVATE;
    } else {
      ownershpVal = OtOwnershipTaxModel.NONE;
    }
    this.setValue(ownershipPath, ownershpVal, sheet.dataTCO);
    //set road tax
    let roadTaxPath = '$[?(@.field==\'utilisation\')].children[?(@.field==\'utilisationTaxes\')].children[?(@.field==\'roadTax\')].children[?(@.field==\'rtCommercial\')].field';
    let roadTaxVal;
    if (up.calcModel.roadTax === RoadTaxModel.COMMERCIAL) {
      roadTaxVal = CPRoadTaxModel.COMMERCIAL;
    } else if (up.calcModel.roadTax === RoadTaxModel.PRIVATE) {
      roadTaxVal = CPRoadTaxModel.PRIVATE;
    } else {
      roadTaxVal = CPRoadTaxModel.NONE;
    }
    this.setValue(roadTaxPath, roadTaxVal, sheet.dataTCO);

    sheet.dataEquipment = { field: '/' };
    sheet.dataMaintenance = emptyDataMaintenance;
    sheet.currencyID = currencyID !== undefined ? currencyID : up.currencyUnitId;
    sheet.distanceID = distanceID !== undefined ? distanceID : up.distanceUnitId;
    sheet.calcModel = calcModel !== undefined ? calcModel : lodash.cloneDeep(up.calcModel);
    this.sheets.push(sheet);
    this.sub.next(this.sheets);
  }

  setValue(path, value, gridData) {
    const jp = require('jsonpath');
    const obj = jp.value(gridData, path, value);
  }

  public removeSheet(index: number) {
    if (this.sheets.length > 1) {
      this.localStorageService.removeAllForSheet(this.sheets[index].numberOfSheet);
      this.sheets.splice(index, 1);
      this.sheets[this.sheets.length - 1].active = true;
    } else {
      return -1;
    }
    this.sub.next(this.sheets);
    return this.sheets.length - 1;
  }


  public removeAllSheets() {
    this.sheets = [];
  }

  formatByCalcModel(currentSheet, calcModel) {

    for (let i = currentSheet.dataTCO.length - 1; i >= 0; i--) {
      let element = currentSheet.dataTCO[i];
      if (calcModel[element.field] === false || calcModel[element.field] === "none") {
        currentSheet.dataTCO.splice(i, 1);
      } else {
        this.checkForChildrenToHide(element.children, calcModel);
      }
    }

  }
  checkForChildrenToHide(children, correctModel) {
    if (children === undefined) {
      return;
    }

    for (let i = children.length - 1; i >= 0; i--) {
      const child = children[i];
      if (correctModel[child.field] === false || correctModel[child.field] === "none") {
        children.splice(i, 1);
      } else {
        this.checkForChildrenToHide(child.children, correctModel);
      }
    }
  }

  resetCurrencyOfAllSheets(newCurrencyID: number) {
    for (const sheet of this.sheets) {
      if (sheet.currencyID != newCurrencyID) {
        sheet.currencyID = newCurrencyID;
        this.markSheetAsDirty(sheet);
      }
    }
  }

  resetCurrencyOnSheet(sheet) {
    let newCurrencyID = this.userPreferencesService.getUserPreferencesNow().currencyUnitId;
    if (sheet.currencyID != newCurrencyID) {
      sheet.currencyID = newCurrencyID;
      this.markSheetAsDirty(sheet);
    }
  }

  markSheetAsDirty(sheet: Sheet): void {
    sheet.columnIds.forEach(item => sheet.columnChangedfromEquipment.add(item));
    //columnChangedfromEquipment because is the safest, or have to make new field for changes
    // sheet.columnIds.forEach(item => sheet.columnChangedAfterLastCall.add(item));
  }

  isDirty(sheet: Sheet): boolean {
    return ((!!sheet.userChangesAfterLastCall && sheet.userChangesAfterLastCall.size > 0) ||
      (!!sheet.columnChangedfromMaintenance && sheet.columnChangedfromMaintenance.size > 0) ||
      (!!sheet.columnChangedfromEquipment && sheet.columnChangedfromEquipment.size > 0));
  }

  //Cache all changes old and new
  cacheOrigValues(sheet: Sheet): any {
    const origValues = new Map();

    sheet.userChangesBeforeLastCall.forEach((valueMap: Map<string, string>, idCol) => {
      valueMap.forEach((value, field) => {
        this.fillFromOrigValue(field, idCol, sheet, origValues);
      });
    });

    sheet.userChangesAfterLastCall.forEach((valueMap: Map<string, string>, idCol) => {
      valueMap.forEach((value, field) => {
        this.fillFromOrigValue(field, idCol, sheet, origValues);
      });
    });

    return origValues;
  }

  private fillFromOrigValue(field: string, idCol: number, sheet: Sheet, origValues: Map<any, any>) {
    const path = metadata[field].path[0] + '.editData[\'' + idCol + '\']' + '.origValue';
    const jp = require('jsonpath');
    const oldValue = jp.query(sheet.dataTCO, path)[0];

    const tmp = origValues.get(idCol);
    if (tmp !== undefined) {
      tmp.set(field, oldValue);
    } else {
      const innerMap = new Map();
      innerMap.set(field, oldValue);
      origValues.set(idCol, innerMap);
    }
  }

  getCarNamesBeforeCall(sheet: Sheet): any {
    const carNames = {};
    sheet.columnIds.forEach(x => {
      carNames[x] = getNameOfColumn(sheet.dataTCO, x);
    });
    return carNames;
  }

}
