import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { CalcModel } from '@app/domain/calc-model';
import { UserPreferencesService } from '@app/services/user-preferences.service';


interface CalcModelNode {
    label: string;
    field: string;
    radioGroup?: string;
    children?: CalcModelNode[];
}

const TREE_DATA: CalcModelNode[] = [
    {
        label: 'ACQUISITION', field: 'acquisition', children: [
            { label: 'DEPRECIATION', field: 'depreciation' },
            {
                label: 'FINANCING', field: 'financing', children: [
                    { label: 'LEASING', field: 'leasing', radioGroup: "financing" },
                    { label: 'PURCHASE', field: 'purchase', radioGroup: "financing" }
                ]
            },
            {
                label: 'ACQUISITION TAXES', field: 'acquisitionTaxes', children: [
                    { label: 'VAT', field: 'acquisitionVAT' },
                    { label: 'REGISTRATION TAX', field: 'registrationTax' }
                ]
            },
        ]
    },
    {
        label: 'UTILISATION', field: 'utilisation', children: [
            {
                label: 'INSURANCE', field: 'insurance', children: [
                    { label: 'COMPREHENSIVE COVERAGE', field: 'comprehensiveCoverage', radioGroup: "insurance" },
                    { label: 'PARTIAL COVERAGE', field: 'partialCoverage', radioGroup: "insurance" },
                    { label: 'THIRD PARTY LIABILITY', field: 'thirdPartyLiability', radioGroup: "insurance" },
                ]
            },
            { label: 'FUEL', field: 'fuelTotal' },
            {
                label: 'SERVICE', field: 'service', children: [
                    { label: 'SERVICE PARTS', field: 'serviceParts' },
                    { label: 'SERVICE LABOUR', field: 'serviceLabour' },
                    { label: 'UNPLANNED SERVICE', field: 'unplannedService' },
                ]
            },
            {
                label: 'WEAR', field: 'wear', children: [
                    { label: 'WEAR PARTS', field: 'wearParts' },
                    { label: 'OVERHAUL LABOUR', field: 'overhaulLabour' },
                    { label: 'UNPLANNED WEAR', field: 'unplannedWear' },
                ]
            },
            { label: 'TYRES', field: 'tyres' },
            {
                label: 'UTILISATION TAXES', field: 'utilisationTaxes', children: [
                    { label: 'VAT', field: 'utilisationVAT' },
                    {
                        label: 'OWNERSHIP TAX', field: 'ownershipTax', children: [
                            { label: 'COMMERCIAL, P.A.', field: 'commercial', radioGroup: "ownershipTax" },
                            { label: 'PRIVATE, P.A.', field: '_private', radioGroup: "ownershipTax" }
                        ]
                    },
                    {
                        label: 'ROAD TAX', field: 'roadTax', children: [
                            { label: 'COMMERCIAL', field: 'commercial', radioGroup: "roadTax" },
                            { label: 'PRIVATE', field: '_private', radioGroup: "roadTax" }
                        ]
                    }
                ]
            }
        ]
    }
];

interface CalcModelFlatNode {
    expandable: boolean,
    label: string,
    field: string,
    level: number,
    radioGroup?: string;
}

@Component({
    selector: 'app-calc-model',
    templateUrl: './calc-model.component.html',
    styleUrls: ['./calc-model.component.scss']
})
export class CalcModelComponent implements OnInit {

    calcModel: CalcModel = new CalcModel();
    treeControl: FlatTreeControl<CalcModelFlatNode>;
    treeFlattener: MatTreeFlattener<CalcModelNode, CalcModelFlatNode>;
    dataSource: MatTreeFlatDataSource<CalcModelNode, CalcModelFlatNode>;

    constructor(private userPreferencesService: UserPreferencesService) {
        this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
        this.treeFlattener = new MatTreeFlattener(this.transformFunction, this.getLevel, this.isExpandable, this.getChildren);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        this.dataSource.data = TREE_DATA;
    }

    ngOnInit(): void {
        this.calcModel = this.userPreferencesService.getUserPreferencesNow().calcModel;

        // by default, expand the top-level tree nodes (this is how it works in the original application)
        this.treeControl.dataNodes.forEach(dataNode => {
            if(dataNode.level == 0) {
                this.treeControl.expand(dataNode);
            }
        });
    }

    private getLevel(dataNode: CalcModelFlatNode): number {
        return dataNode.level;
    }

    private isExpandable(dataNode: CalcModelFlatNode): boolean {
        return dataNode.expandable;
    }

    private transformFunction(node: CalcModelNode, level: number): CalcModelFlatNode {
        return {
            expandable: !!node.children && node.children.length > 0,
            label: node.label,
            field: node.field,
            level: level,
            radioGroup: node.radioGroup
        };
    }

    private getChildren(node: CalcModelNode): CalcModelNode[] {
        return node.children;
    }

    hasChild(level: number, node: CalcModelFlatNode) {
        return node.expandable;
    }

    private getFlatChildren(node: CalcModelFlatNode): CalcModelFlatNode[] {
        var descendants = this.treeControl.getDescendants(node);
        var thisLevel = this.getLevel(node);
        return descendants.filter(descNode => {
            var descLevel = this.getLevel(descNode);
            return descLevel == (thisLevel + 1);
        }, this);
    }

    //////////////////////////////////////////////////////////////////////////////////
    // CHECKED STATE LOGIC
    //////////////////////////////////////////////////////////////////////////////////

    // A non-leaf checkbox is a radio group if all its descendants are radio buttons
    private isRadioGroup(node: CalcModelFlatNode): boolean {
        return this.treeControl.getDescendants(node).every(child => child.radioGroup);
    }

    // A radio button is checked when the parent radioGroup's value = field's value
    radioChecked(node: CalcModelFlatNode): boolean {
        if(!this.calcModel) {
            return false;
        }
        var field = node.radioGroup;
        var value = node.field;
        return this.calcModel[field] === value;
    }

    // A leaf checkbox is checked when the model says so
    leafCheckboxChecked(node: CalcModelFlatNode): boolean {
        return !!this.calcModel && this.calcModel[node.field];
    }

    // A non-leaf node checkbox is checked if all its descendants are also checked
    // However, if a non-leaf node only contains radio buttons (e.g. Financing, etc.),
    // then it is checked if the value of the group is not 'none'
    nonLeafCheckboxChecked(node: CalcModelFlatNode): boolean {
        if(this.isRadioGroup(node)) {
            return !!this.calcModel && this.calcModel[node.field] !== 'none';
        }

        var children = this.getFlatChildren(node);
        return children.every(child => {
            if(child.expandable) {
                return this.nonLeafCheckboxChecked(child);
            }else {
                return this.leafCheckboxChecked(child);
            }
        }, this);
    }

    // A non-leaf node checkbox is indeterminate if some (but not all) of its
    // descendants are checked.
    nonLeafCheckboxIndeterminate(node: CalcModelFlatNode): boolean {
        // a radio group cannot be indeterminate
        if(this.isRadioGroup(node)) {
            return false;
        }

        // if it is checked, then it is not indeterminate
        if(this.nonLeafCheckboxChecked(node)) {
            return false;
        }

        // same thing as in 'checked', but 'some' instead of every
        var children = this.getFlatChildren(node);
        return children.some(child => {
            if(child.expandable) {
                return this.nonLeafCheckboxIndeterminate(child) || this.nonLeafCheckboxChecked(child);
            }else {
                return this.leafCheckboxChecked(child);
            }
        }, this);
    }

    //////////////////////////////////////////////////////////////////////////////////
    // TOGGLING LOGIC
    //////////////////////////////////////////////////////////////////////////////////

    // UI state toggled; reflect in the model: set the parent's radioGroup to this field value
    radioToggle(node: CalcModelFlatNode): void {
        var field = node.radioGroup;
        var value = node.field;
        this.calcModel[field] = value;
        // this.calcModelService.saveCalculationModel(this.calcModel);
    }

    // UI state toggled; reflect in the model
    leafCheckboxToggle(node: CalcModelFlatNode, dontSave?: boolean, forceValue?: boolean): void {
        var field = node.field;
        if (typeof forceValue !== 'undefined') {
            this.calcModel[field] = forceValue;
        }else {
            this.calcModel[field] = !this.calcModel[field];
        }
        // if(!dontSave) {
        //     this.calcModelService.saveCalculationModel(this.calcModel);
        // }
    }

    // Non-leaf toggle:
    //  - if this is a radio group and we're going to OFF, then set the value to none
    //  - if this is a radio group and we're going to ON, then set the value to first child
    //  - if this is NOT a radio group, then recursively toggle all children
    private _nonLeafCheckboxToggle(node: CalcModelFlatNode, forceValue: boolean): void {
        if(this.isRadioGroup(node)) {
            if(!!this.calcModel && !forceValue) {
                // it was ON; we're going to off: set the value to none
                this.calcModel[node.field] = 'none';
            }else {
                // it was OFF; we're going to ON: set the value to the first child
                this.calcModel[node.field] = this.getFlatChildren(node)[0].field;
            }
        }else {
            this.leafCheckboxToggle(node, true, forceValue);
            var children = this.getFlatChildren(node);
            children.forEach(child => {
                if(child.expandable) {
                    this._nonLeafCheckboxToggle(child, forceValue);
                }else {
                    this.leafCheckboxToggle(child, true, forceValue);
                }
            }, this);
        }
    }

    nonLeafCheckboxToggle(node: CalcModelFlatNode): void {
        var forceValue;
        if(this.nonLeafCheckboxIndeterminate(node)) {
            forceValue = true;
        }else {
            forceValue = !this.nonLeafCheckboxChecked(node);
        }
        this._nonLeafCheckboxToggle(node, forceValue);
        // this.calcModelService.saveCalculationModel(this.calcModel);
    }

    preventMenuClose(event) {
        event.stopPropagation();
    }
}
