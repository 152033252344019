import { getCurrencySignByID } from '@app/helpers/RemapUnitsUtils.ts';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ICellRendererParams, ColDef, GridOptions, RowNode } from 'ag-grid-community';
import { formatCurrency, getColIndex22ToLength, formatWithSuffixAndPrecision } from '@app/helpers/utils.js';
import { AgGridAngular } from 'ag-grid-angular';
import { Sheet } from '@app/domain/sheet';
import { IChangeDetect } from '@app/helpers/IChangeDetect';
import { CsrPartsSubgridInnerComponent } from '../csr-parts-subgrid-inner/csr-parts-subgrid-inner.component';
import * as lodash from 'lodash'; //copy array
import { Constants } from '@app/helpers/Constants';
import { v4 as uuid } from 'uuid';
import { LocalStorageService } from '@app/services/local-storage.service';



@Component({
  selector: 'app-csr-parts-subgrid',
  templateUrl: './csr-parts-subgrid.component.html',
  styleUrls: ['./csr-parts-subgrid.component.scss']
})


export class CsrPartsSubgridComponent implements OnInit, IChangeDetect {


  SP_PARTS_DESCRIPTION = Constants.SP_PARTS_DESCRIPTION;
  SP_PRICE = Constants.SP_PRICE;
  SP_QTY = Constants.SP_QTY;
  SP_ADD = Constants.SP_ADD;
  SP_DEL = Constants.SP_DEL;
  WP_PARTS_DESCRIPTION = Constants.WP_PARTS_DESCRIPTION;
  WP_PRICE = Constants.WP_PRICE;
  WP_QTY = Constants.WP_QTY;
  WP_ADD = Constants.WP_ADD;
  WP_DEL = Constants.WP_DEL;

  @Input() params: ICellRendererParams;
  @Input() benchData: ICellRendererParams;
  @ViewChild('theGrid') theGrid: AgGridAngular;
  private idsCol = 0;
  private indexOfLastClickedRow;

  constructor(private localStorageService: LocalStorageService) {
    let that = this;
    this.gridOptions = {
      context: this,
      defaultColDef: {
        width: 80,
        resizable: true
      },
      onRowClicked: function onRowClicked(params) {
        that.indexOfLastClickedRow = params.rowIndex;
      },
      rowClassRules: {
        'parts-row-color-red': function (parameters) {
          let color = parameters.data.color;
          if (color !== undefined) {
            if (color === 'red') {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
        , 'parts-row-color-yellow': function (parameters) {
          let color = parameters.data.color;
          if (color !== undefined) {
            if (color === 'yellow') {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }, 'parts-row-color-blue': function (parameters) {
          let color = parameters.data.color;
          if (color !== undefined) {
            if (color === 'blue') {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }, 'parts-row-color-green': function (parameters) {
          let color = parameters.data.color;
          if (color !== undefined) {
            if (color === 'green') {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }, 'parts-row-color-dark-green': function (parameters) {
          let color = parameters.data.color;
          if (color !== undefined) {
            if (color === 'dark-green') {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      }
    };
    this.rowSelection = 'single';
  }

  sheet: Sheet;
  rowData: any = [];
  columnDefs: ColDef[] = [];
  gridOptions: GridOptions;
  priceShow: boolean;
  rowSelection;// for turn on tableSelection

  ngOnInit(): void {
    this.sheet = this.params.context.getDataSheet();
    const indexCol = getColIndex22ToLength(this.params.colDef.field);
    this.idsCol = this.sheet.columnIds[indexCol];

    this.columnDefs = [];
    this.columnDefs.push({
      headerName: 'Part Description', field: 'description',
      autoHeight: true,
      cellRendererFramework: CsrPartsSubgridInnerComponent,
      cellRendererParams: {
        sheet: this.sheet,
        descriptionField: 'description',
        altDescriptionField: 'altDescription',
        partNumberField: 'partNumber'
      },
      editable: this.isCellEditable.bind(this), flex: 1, cellStyle: { 'text-align': 'left' },
      valueSetter: this.valueSetterDescr
    });

    const make: string = String(this.params.context.getOldValue('$[?(@.field==\'carSpecs\')].children[?(@.field==\'make\')]' + '.editData[\'' + this.idsCol + '\'].metadata.makeCode'));
    this.priceShow = this.sheet.canSeePrice.get(make);

    if (this.priceShow) {
      this.columnDefs.push({
        headerName: 'Price', field: 'price', editable: this.isCellEditable.bind(this),
        valueSetter: this.valueSetterPrice, valueFormatter: this.formatCurrency, type: 'rightAligned'
      });
    }
    this.columnDefs.push({
      headerName: 'Qty', field: 'quantity', editable: this.isCellEditable.bind(this),
      valueSetter: this.valueSetterQty, valueFormatter: this.formatQuantity, type: 'rightAligned'
    });

    this.rowData = this.params.data.values[this.idsCol];
  }

  onChange(params?: any) {
    this.sheet.addChange(this.idsCol, this.params.data.field, lodash.cloneDeep(this.params.data.values[this.idsCol]));
  }

  private formatQuantity(params) {
    let units = params.data.quantityUnitID;
    if (units !== undefined && units !== null) {
      if (units === "1") {
        return formatWithSuffixAndPrecision(params.data.quantity, "l", 1);
      }
    }
    return params.data.quantity;
  }

  private formatCurrency(params) {
    return formatCurrency(params.value, getCurrencySignByID(params.context.sheet.currencyID));
  }

  valueSetterDescr(params) {
    let that = params.context;
    that.localStorageService.add(that.sheet, Constants.TYPE_VIEW_TCO);

    if ("spPartsDetail" == that.params.data.field) {
      that.sheet.addPartDetailsTCOChanges(that.idsCol, that.SP_PARTS_DESCRIPTION)
    } else {
      that.sheet.addPartDetailsTCOChanges(that.idsCol, that.WP_PARTS_DESCRIPTION)
    }

    that.sheet.changePartsDescription.delete(params.data.description);
    that.sheet.changePartsDescription.add(params.newValue);
    params.data.description = params.newValue;
    that.onChange();
    return true;
  }

  valueSetterQty(params) {
    if (Number.isNaN(Number(params.newValue))) {
      return false;
    }
    let that = params.context;
    that.localStorageService.add(that.sheet, Constants.TYPE_VIEW_TCO);

    if ("spPartsDetail" == that.params.data.field) {
      that.sheet.addPartDetailsTCOChanges(that.idsCol, that.SP_QTY)
    } else {
      that.sheet.addPartDetailsTCOChanges(that.idsCol, that.WP_QTY)
    }

    params.data.quantity = params.newValue;
    that.onChange();
    return true;
  }

  valueSetterPrice(params) {
    if (Number.isNaN(Number(params.newValue))) {
      return false;
    }
    let that = params.context;
    that.localStorageService.add(that.sheet, Constants.TYPE_VIEW_TCO);
    if ("spPartsDetail" == that.params.data.field) {
      that.sheet.addPartDetailsTCOChanges(that.idsCol, that.SP_PRICE)
    } else {
      that.sheet.addPartDetailsTCOChanges(that.idsCol, that.WP_PRICE)
    }

    params.data.price = params.newValue;
    that.onChange();
    return true;
  }

  isCellEditable(): boolean {
    if (this.sheet.locked) {
      return false;
    } else {
      if (this.priceShow === true) {
        return true;
      } else {
        return false;
      }
    }
  }

  delSelectedRow() {
    this.localStorageService.add(this.sheet, Constants.TYPE_VIEW_TCO);

    if (this.theGrid.api.getSelectedRows() != null && this.theGrid.api.getSelectedRows() != undefined && this.theGrid.api.getSelectedRows().length > 0) {
      if ("spPartsDetail" == this.params.data.field) {
        this.sheet.addPartDetailsTCOChanges(this.idsCol, this.SP_DEL)
      } else {
        this.sheet.addPartDetailsTCOChanges(this.idsCol, this.WP_DEL)
      }
      this.params.data.values[this.idsCol].splice(this.indexOfLastClickedRow, 1);
      this.theGrid.api.applyTransaction({ remove: (this.theGrid.api.getSelectedRows()) });
      this.onChange();
    }
  }

  pushNewRow() {
    this.localStorageService.add(this.sheet, Constants.TYPE_VIEW_TCO);

    if ("spPartsDetail" == this.params.data.field) {
      this.sheet.addPartDetailsTCOChanges(this.idsCol, this.SP_ADD)
    } else {
      this.sheet.addPartDetailsTCOChanges(this.idsCol, this.WP_ADD)
    }

    let newRow: any = {};
    newRow.description = 'New Part';
    newRow.labourTime = 0;
    newRow.mastercode = uuid();
    newRow.partNumber = '';
    newRow.price = 0;
    newRow.quantity = 0;

    this.params.data.values[this.idsCol].push(newRow);
    this.theGrid.api.setRowData(this.params.data.values[this.idsCol]);
    this.onChange();
  }

}
