<div class="head cce-row">
  <span>{{question}}</span>
  <div class="close">
    <mat-icon class="gray" [mat-dialog-close]="true">cancel</mat-icon>
  </div>
</div>
<div class="content cce-row">
  <button (click)="submit(true)" [mat-dialog-close]="true">YES</button>
  <button [mat-dialog-close]="true">NO</button>
</div>
