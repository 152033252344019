<div class="head cce-row">
  <span>INFORMATION & GUIDES</span>
  <div class="close">
    <mat-icon class="gray" [mat-dialog-close]="true">cancel</mat-icon>
  </div>
</div>
<div class="content cce-row">
  <iframe style="border-radius   : 5px;" *ngIf="srclink"
     [src]="srclink" allowfullscreen="1"
        mozallowfullscreen
        msallowfullscreen
        oallowfullscreen
        webkitallowfullscreen
     width="100%">
     </iframe>
</div>
